import { stores } from '@/main';
import getEnv from '../../app.config';
import { USER_PERMISSIONS } from '@/helpers/constants';
import { dateFormatWithTime } from '@/helpers/dates';
import Cookies from '@/helpers/cookies';
import Tokens from '@/api/tokens';
import axios from 'axios';

/**
 * Debouncer is using to prevent from spamming with executing function which
 * is given in parameters
 * @param {function} fn - callback function which should be executed after delay
 * @param {number} delay
 * @returns function to be executed
 */
export function debounce(fn, delay) {
  let timeoutID = null;

  return function () {
    clearTimeout(timeoutID);
    let args = arguments;
    let that = this;

    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
}

/**
 * Check webkit browsers like chrome, opera and edge
 * @returns boolean value
 */
export const checkWebkit = () => {
  return 'webkitLineBreak' in document.documentElement.style;
};

/**
 * Adding repr field to invoice for text representation
 * @param {array} invoices
 * @returns repr: "Invoice #1 / Issued: 17 Nov 2023 / ZAR 1779.90 / Description: 10 seats in 1 plan/s"
 */
export const processInvoiceItems = invoices => {
  return invoices.map(payment => ({
    ...payment,
    repr: `Invoice #${payment.invoice[0].id} / Issued: ${dateFormatWithTime(
      payment.initiated_date,
    )
      .slice(0, -5)
      .trim()} / ${payment.currency} ${payment.amount} / Description: ${
      payment.description
    }`,
  }));
};

/**
 * Processing invoices to change data type of amount and tax_amount, and added
 * payment_method field for inoices table
 * @param {array} invoices
 * @returns
 */
export const processInvoices = invoices => {
  return invoices.map(history => ({
    ...history,
    payment_method: history.brand === 'CARD' ? 'Card Payment' : history.brand,
    amount: Number(history.amount),
    tax_amount: Number(history.tax_amount),
  }));
};

/**
 * Load font in PDF document
 * @param {jsPDF} doc - jsPDF document
 * @param {string} file - font file name in /assets/fonts/ folder
 * @param {string} name - font name
 */
export const loadFont = async (doc, file, name) => {
  const fontUrl5 = require(`../assets/fonts/${file}`);
  const fontBinStr5 = await axios
    .get(fontUrl5, { responseType: 'arraybuffer' })
    .then(res =>
      require('buffer').Buffer.from(res.data, 'binary').toString('base64'),
    );
  doc.addFileToVFS(file, fontBinStr5);
  doc.addFont(file, name, 'normal');
};

/**
 * Increment column code like in excel: A, B, C ..., Z, AA...
 * @param {string} c - column code to increment
 * @param {number} i - value to increment column code on
 */
export const incColumn = (c, i) => {
  let lastChar = c.slice(-1);
  let charCode = lastChar.charCodeAt(0) + i;

  if (charCode > 90) {
    return (
      'A' +
      c.slice(0, -1) +
      String.fromCharCode('A'.charCodeAt(0) + charCode - 91)
    );
  }

  return c.slice(0, -1) + String.fromCharCode(lastChar.charCodeAt(0) + i);
};

/**
 * Paints table headers and footers (if needed)
 * @param {Worksheet} worksheet
 * @param {list} header - list of table headers
 * @param {list} position - [headerPosition, footerPosition(optional)]
 * @param {string} colour
 * @param {boolean} merge - paint headers for merged or single cells
 */
export const paintTableHeaders = (
  worksheet,
  header,
  position,
  colour,
  merge,
) => {
  let cursor = 0;
  const paintCells = [];
  for (const head of header) {
    const width = head.cellWidth;
    paintCells.push(`${incColumn('A', cursor)}${position[0]}`);
    if (position[1]) {
      paintCells.push(`${incColumn('A', cursor)}${position[1]}`);
    }
    cursor += merge ? width : 1;
  }
  paintCells.forEach(h => {
    worksheet.getCell(h).fill = {
      type: 'pattern',
      pattern: 'darkVertical',
      fgColor: { argb: colour },
      bgColor: { argb: colour },
    };
  });
};

/**
 * Processed pdf footer to add text, page counters and so on
 * @param {jsPDF} doc - jsPDF document
 * @param {string} footerText
 */
export const pdfFooter = (doc, footerText) => {
  const pageCount = doc.internal.getNumberOfPages();
  for (let i = 1; i <= pageCount; i++) {
    doc.setFontSize(8);
    doc.setPage(i);
    doc.setFont('poppins-regular');
    doc.setTextColor('#818ba5');
    doc.text(footerText, 10, 202);
    doc.text(
      'Teamfu is a Cyber-Mint associated product. © Cyber-Mint Pty (Ltd) | www.cyber-mint.com',
      85,
      202,
    );

    const year = new Date().getFullYear();
    const month = new Date().toLocaleString('en', { month: 'short' });
    const day = new Date().getDate();
    const time = new Date().toTimeString().slice(0, 5);
    const date = `${day} ${month} ${year}, ${time}`;
    doc.text(`${date} | ${i} of ${pageCount} Pages`, 240, 202);
  }
};

export const downloadAttachment = at => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', `${getEnv('VUE_APP_ATTACHMENTS_ENDPOINT')}/${at.path}`, true);
  xhr.responseType = 'blob';

  // download attachment from attachment API
  xhr.onload = function () {
    if (this.status === 200) {
      _saveBlob(this.response, at.name);
    }
  };

  // error attachment download
  xhr.onloadend = function () {
    if (xhr.status == 0) {
      stores.dispatch('popups/setToast', [
        `Failed to download file by given path`,
        3000,
      ]);
    }
  };

  xhr.send();

  function _saveBlob(response, fileName) {
    if (navigator.msSaveBlob) {
      //OK for IE10+
      navigator.msSaveBlob(response, fileName);
    } else {
      _html5Saver(response, fileName);
    }
  }

  // download file
  function _html5Saver(blob, fileName) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';

    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    document.body.removeChild(a);
  }
};

/**
 * Check billing query with applied filter
 * @param {object} query - Billing Query instance
 * @param {object} filter - billing query filter
 * @returns boolean
 */
export const checkQueryFilter = (query, filter) => {
  let check = false;

  if (Object.keys(filter).length === 0) {
    return true;
  }

  if (filter.subject) {
    check = filter.subject.includes(
      query.subject.replaceAll(' ', '_').toLowerCase(),
    );

    if (!check) {
      return false;
    }
  }

  if (filter.created_on) {
    const filterDate = filter.created_on.map(d => new Date(d));
    const queryDate = new Date(query.created_on);
    check =
      (filterDate[1] &&
        filterDate[0] <= queryDate &&
        filterDate[1] >= queryDate) ||
      (filterDate[0] &&
        filterDate[0].toJSON().slice(0, 10) ===
          queryDate.toJSON().slice(0, 10));

    if (!check) {
      return false;
    }
  }

  if (filter.resolved) {
    check = filter.resolved.includes(query.is_resolved);

    if (!check) {
      return false;
    }
  }

  return check;
};

/**
 * Place non archived lists first and archived lists second for project
 * @param {array} lists
 * @returns sorted array of project lists
 */
export const sortListsArchLists = lists => {
  const nonArchLists = lists.filter(list => !list.archived);
  const archLists = lists.filter(list => !!list.archived);
  return nonArchLists.concat(archLists);
};

export const networkError = () => {
  stores.dispatch('viewState/setNetworkError', true);
};

export function detectMobile() {
  const ua = navigator.userAgent;
  let type = 'desktop';

  const newUA = navigator.userAgent || window.opera;
  const mobileRegex =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge|maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
  if (mobileRegex.test(newUA)) {
    type = 'mobile';
  }

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    type = 'tablet';
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    )
  ) {
    type = 'mobile';
  }

  if (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.oscpu) ||
    'ontouchstart' in document.documentElement
  ) {
    type = 'mobile';
  }

  return type;
}

export function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

/**
 * Monitor user activity
 * @param callback: () => {}
 * @returns {{stop: stop, start: start}}
 */
export const generateActionMonitor = callback => {
  const accessTimeout = parseInt(getEnv('VUE_APP_ACCESS_TIMEOUT'));
  const userEventsToMonitor = ['keydown', 'wheel', 'mousedown', 'mousemove'];

  let inactivityTimeout = null;
  let idleChecker = null;
  let timeoutCallback = callback;
  let refreshing = false;

  let cookies = Cookies.getAll();
  let refresh = Tokens.parse(cookies.refresh);

  const initLifeTime = async () => {
    inactivityTimeout = accessTimeout;

    if (
      stores.getters['auth/userProfile'] &&
      !refreshing &&
      refresh.exp - Date.now() / 1000 < 60
    ) {
      refreshing = true;
      await Tokens.refresh();
      refreshing = false;

      cookies = Cookies.getAll();
      refresh = Tokens.parse(cookies.refresh);
    }
  };

  const inactivityWrapper = () => {
    initLifeTime();
  };

  /**
   * Work a callback when the user stops performing any action in the application VUE_APP_ACCESS_TIMEOUT seconds
   */
  const start = () => {
    cookies = Cookies.getAll();
    refresh = Tokens.parse(cookies.refresh);

    initLifeTime();

    idleChecker = setInterval(() => {
      inactivityTimeout -= 1;

      if (
        inactivityTimeout === 0 ||
        (!refreshing && refresh.exp < Date.now() / 1000)
      ) {
        timeoutCallback();
      }
    }, 1000);

    userEventsToMonitor.forEach(event => {
      document.addEventListener(event, inactivityWrapper);
    });
  };

  const stop = () => {
    if (!idleChecker) return;

    clearInterval(idleChecker);

    userEventsToMonitor.forEach(event => {
      document.removeEventListener(event, inactivityWrapper);
    });
  };

  return {
    start,
    stop,
  };
};

/**
 * waiting until element appears
 * @param selector {string}
 * @returns {Promise<HTMLElementTagNameMap[keyof HTMLElementTagNameMap]>}
 */
export function waitForElmAppear(selector) {
  return new Promise(resolve => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function getRootDomain() {
  return getEnv('VUE_APP_ROOT_API') === 'http://localhost:8001'
    ? 'http://localhost:8080'
    : getEnv('VUE_APP_ROOT_API');
}

/**
 * Order permissions loaded from the BE
 * @param {array} list - array of permissions
 * @returns correct order of permissions
 */
export function getSequencePermissions(list) {
  if (!Array.isArray(list)) return undefined;

  const initialSequence = [
    USER_PERMISSIONS.CREATE,
    USER_PERMISSIONS.READ,
    USER_PERMISSIONS.UPDATE,
    USER_PERMISSIONS.DELETE,
    USER_PERMISSIONS.ARCHIVE,
    USER_PERMISSIONS.MOVE,
    USER_PERMISSIONS.MANAGEMENT,
    USER_PERMISSIONS.EXPORT,
    USER_PERMISSIONS.SEAT,
    USER_PERMISSIONS.PERMISSIONS,
  ];

  const includesPerms = initialSequence.filter(i => list.includes(i));
  const nonIncludesPerms = list.filter(i => !initialSequence.includes(i));

  return [...includesPerms, ...nonIncludesPerms];
}

/**
 * Select font awesome file icon by file type
 * @param {string} type - type of file
 * @returns font awesome icon key
 */
export function selectFileIcon(type) {
  if (!type) {
    return 'fa-solid fa-file';
  }

  if (type === 'application/pdf') {
    return 'fa-solid fa-file-pdf';
  } else if (type.slice(0, 5) === 'image') {
    return 'fa-regular fa-image';
  } else if (type === 'text/xml') {
    return 'fa-solid fa-file-code';
  } else if (
    type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ) {
    return 'fa-solid fa-file-excel';
  } else {
    return 'fa-solid fa-file';
  }
}

export function getRandomInt(min, max) {
  // Create byte array and fill with 1 random number
  const byteArray = new Uint8Array(1);
  window.crypto.getRandomValues(byteArray);

  const range = max - min + 1;
  const max_range = 256;
  if (byteArray[0] >= Math.floor(max_range / range) * range)
    return getRandomInt(min, max);
  return min + (byteArray[0] % range);
}

/**
 * Function works with array which have only primitive types
 * @param {array} arrA
 * @param {array} arrB
 * @returns Array with unique values only from first array
 */
export function intersectionArray(arrA, arrB) {
  return arrA.filter(x => arrB.includes(x));
}

/**
 * Function works with array with only primitive types
 * @param {array} prevState
 * @param {array} curState
 * @returns Array with unique values from both arrays
 */
export function uniqueMergeArrays(prevState = [], curState = []) {
  return [...new Set(prevState.concat(curState))];
}

/**
 * Sort project lists by position
 * @param {object} project
 */
export function orderListsByPosition(project) {
  if (project?.lists) {
    const uniqueLists = [...new Set(project.lists.map(l => +l.id))];

    project.lists = uniqueLists
      .map(lId => project.lists.find(list => list.id === lId))
      .sort((a, b) => a.position - b.position);
  }
}

/**
 * Sort list alphabetically
 * @param {array} objectsList array of instances for sorting
 * @param {string} propertyName object's field for sorting
 * @returns sorted list
 */
export function sortAlphabetically(objectsList, propertyName) {
  return objectsList.sort((a, b) => {
    const propertyA = a[propertyName].toUpperCase();
    const propertyB = b[propertyName].toUpperCase();

    if (propertyA < propertyB) {
      return -1;
    }
    if (propertyA > propertyB) {
      return 1;
    }

    return 0;
  });
}

/**
 * Process string value with array to unpack array into string with commas
 * @param {string} str
 * @param {array} arr
 * @returns `${str}arr[0],arr[1],arr[2]...`
 */
export function appendStringWithCommas(str, arr) {
  return str + arr.join(',');
}

/**
 * Functions builds array with unique values from 2 arrays
 * @param {array} arr1
 * @param {array} arr2
 * @param {string} field - to compare by
 * @returns array with unique elements
 */
export function findUniqueObjectsInArray(arr1, arr2, field) {
  const uniqueInArr1 = arr1.filter(
    obj1 => !arr2.some(obj2 => obj2[field] === obj1[field]),
  );
  const uniqueInArr2 = arr2.filter(
    obj2 => !arr1.some(obj1 => obj1[field] === obj2[field]),
  );

  return uniqueInArr1.concat(uniqueInArr2);
}

/**
 * Insert string by index in a big string
 * @param {*} str - initial string
 * @param {*} index - index for insert
 * @param {*} insert - string for insert
 * @param {*} newLine - add new line after inserting or not
 * @returns new string with inserted string
 */
export function insertAtIndexInString(str, index, insert, newLine) {
  if (!str) {
    return insert;
  } else if (newLine) {
    const firstPart = str.substring(0, index);
    const secondPart = str.substring(index);
    return `${firstPart}${
      firstPart[firstPart.length - 1] === '\n' ? '' : '\n'
    }${insert}${secondPart[0] === '\n' ? '' : '\n'}${secondPart}`;
  } else {
    return str.substring(0, index) + insert + str.substring(index);
  }
}
