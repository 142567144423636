export default {
  plans: state => {
    return state.plans;
  },

  planColours: state => {
    return state.planColours;
  },

  plansDetails: state => {
    return state.plansDetails;
  },

  planTypes: state => {
    return state.planTypes;
  },

  openPurchasePlanModal: state => {
    return state.openPurchasePlanModal;
  },

  purchasingPlan: state => {
    return state.purchasingPlan;
  },

  resetPlanModals: state => {
    return state.resetPlanModals;
  },

  purchaseSelectedPlan: state => {
    return state.purchaseSelectedPlan;
  },

  planBillingDetails: state => {
    return state.planBillingDetails;
  },

  purchaseData: state => {
    return state.purchaseData;
  },

  cardForPurchase: state => {
    return state.cardForPurchase;
  },

  paymentDone: state => {
    return state.paymentDone;
  },

  paymentLoading: state => {
    return state.paymentLoading;
  },

  paymentError: state => {
    return state.paymentError;
  },

  draggedUser: state => {
    return state.draggedUser;
  },

  planRenew: state => {
    return state.planRenew;
  },

  // availableSeats: state => {
  // calculate available seats
  // }
};
