import AxiosHandler from '@/helpers/axios';

export default {
  async initiateTaskMove({ commit, dispatch, rootGetters }, [li, ta]) {
    if (ta && !('pending_request' in ta)) {
      //---api---//

      const data = JSON.stringify({ list_id: li.id });

      const rq = await AxiosHandler.call(
        data,
        `/task/${ta.id}/move-task`,
        'put',
        'application/json',
      );

      if (rq.data.msg === 'Task-move confirmed and completed') {
        dispatch(
          'popups/setToast',
          [
            `Task <b> ${ta.id}-${ta.title}</b> has been moved to list <b><span style="color:var(--t-mood-${li.bullet_color})" >${li.title}</span></b>`,
            3000,
          ],
          { root: true },
        );

        return 'moved';
      } else {
        //------update-task----//

        if (rootGetters['auth/userProfile'].id !== li.owner) {
          dispatch(
            'popups/setToast',
            [
              `Pending move request of <b><span style="color:var(--t-mood-${ta.epic})" >#${ta.id}-${ta.title}</span></b> sent`,
              3000,
            ],
            { root: true },
          );
        }
        //------update-task----//
        ta['pending_request'] = rq.data;

        commit('tasksState/SET_DRAGGED_TASKS', ta, { root: true });
        dispatch('tasks/updateTaskInList', ta, { root: true });
      }
    }
  },

  async moveTask({ dispatch }, [li, ta]) {
    let data = JSON.stringify({ list_id: li.id });

    if (
      ta &&
      'pending_request' in ta &&
      ta.pending_request.state === 'accept-pending'
    ) {
      await AxiosHandler.call(
        data,
        `/task/${ta.id}/confirm-move`,
        'put',
        'application/json',
      );
      ta.list_id = li.id;
      delete ta.pending_request;

      dispatch(
        'popups/setToast',
        [
          `task <b> ${ta.id}-${ta.title}</b> has been moved to list <span style="color:var(--t-mood-${li.bullet_color})" >${li.title}</span>`,
          3000,
        ],
        { root: true },
      );

      dispatch('tasks/updateTaskInList', ta, { root: true });

      return 'done';
    } else {
      return 'not accepted';
    }
  },

  async cancelTaskMove({ dispatch, rootGetters }, ta) {
    await AxiosHandler.call('', `/task/${ta}/cancel-move`, 'put', '');

    dispatch('popups/setToast', [`Task move revoked`, 3000], { root: true });

    if (rootGetters['lists/allTasks'].some(t => t.id === ta)) {
      const task = rootGetters['lists/allTasks'].find(t => t.id === ta);
      delete task.pending_request;
      dispatch('tasks/updateTaskInList', task, { root: true });
    }
  },

  async validateTaskMove({ rootGetters, dispatch }, [tId, uid, token]) {
    const valRes = await AxiosHandler.call(
      '',
      `/task/${tId}/validate/?uid=${uid}&token=${token}`,
      'get',
      'application/json',
    );

    const tsk = rootGetters['lists/allTasks'].find(t => t.id === tId);

    if (valRes.status === 202 && tsk) {
      tsk.pending_request.state = 'accept-pending';
      dispatch('tasks/updateTaskInList', tsk, { root: true });
    }

    if (valRes.status === 200) {
      if (tsk) {
        dispatch(
          'popups/setToast',
          [`Task <b> ${tsk.id}-${tsk.title}</b> has been moved`, 3000],
          { root: true },
        );
      } else {
        const ta = await AxiosHandler.call(null, `/task/${tId}`, 'get', '');
        dispatch(
          'popups/setToast',
          [`Task <b> ${ta.data.id}-${ta.data.title}</b> has been moved`, 3000],
          { root: true },
        );
      }
    }

    return valRes;
  },

  async taskMoveModal({ commit, dispatch, rootGetters }, [tId, liId]) {
    commit('viewState/SET_LOAD_STATE', true, { root: true });

    const displayModal = () => {
      if (rootGetters['lists/selectedList']?.id !== liId) {
        dispatch('lists/listSelected', liId, { root: true });
      }

      commit(
        'lists/SET_LIST_TO_MOVE_TASK_TO',
        rootGetters['projects/allLists'].find(l => l.id === liId),
        { root: true },
      );

      commit('viewState/SET_LOAD_STATE', false, { root: true });
    };

    //fetches updated task

    try {
      const task = await AxiosHandler.call(
        '',
        `/task/${tId}/move-task`,
        'get',
        null,
      );

      const penReq = await AxiosHandler.call(
        null,
        `/pending_requests/${task.data.pending_request}`,
        'get',
        '',
      );

      task.data.pending_request = penReq.data;

      const taAccepted = task.data;

      let listToMove = rootGetters['projects/allLists'].find(
        l => l.id === liId,
      );
      if (!listToMove) {
        listToMove = await dispatch('lists/getListById', liId, {
          root: true,
        });
      }

      if (
        taAccepted.users.filter(
          u => !listToMove.users.map(lu => lu.id).includes(u),
        ).length === 0 &&
        penReq.data.state === 'accept-pending'
      ) {
        dispatch('moveTask', [listToMove, task.data]);

        commit('viewState/SET_LOAD_STATE', false, { root: true });

        if (rootGetters['notifications/notToDel']) {
          dispatch(
            'notifications/deleteNoteFromState',
            rootGetters['notifications/notToDel'],
            { root: true },
          );

          commit('notifications/NOTE_TO_DEL', null, { root: true });
        }
      } else {
        commit('tasksState/SET_DRAGGED_TASKS', taAccepted, { root: true });
        displayModal();
      }
    } catch (e) {
      commit('viewState/SET_LOAD_STATE', false, { root: true });

      dispatch(
        'popups/setToast',
        [`Accepting this request is no longer possible`, 3000],
        { root: true },
      );

      if (rootGetters['notifications/notToDel']) {
        dispatch(
          'notifications/deleteNoteFromState',
          rootGetters['notifications/notToDel'],
          { root: true },
        );

        commit('notifications/NOTE_TO_DEL', null, { root: true });
      }
    }
  },

  async resendRequest({ dispatch }, ta) {
    const resendRq = await AxiosHandler.call(
      '',
      `/task/${ta.id}/move-resend`,
      'post',
      null,
    );
    ta['pending_request'] = resendRq.data;
    dispatch('tasks/updateTaskInList', ta, { root: true });
  },
};
