<template>
  <router-view />

  <page-loading />
  <global-logo-loading />
  <Toast :text="toast" v-updated-show="toast" />
</template>

<script>
import { generateActionMonitor } from './helpers/helpers';
import { themeColors } from './style/styles';
import { isMobile } from 'mobile-device-detect';
import { mapGetters } from 'vuex';
import { MOBILE_DETECT_WIDTH, ROUTE_NAMES } from '@/helpers/constants';
import Cookies from '@/helpers/cookies';
import { defineAsyncComponent } from 'vue';
import PageLoading from '@/ui/loadings/PageLoading.vue';
import GlobalLogoLoading from '@/ui/loadings/GlobalLogoLoading.vue';

export default {
  name: 'App',

  components: {
    GlobalLogoLoading,
    PageLoading,
    Toast: defineAsyncComponent(
      () => import(/*webpackChunkName: "Toast"*/ './ui/toasts/toast.vue'),
    ),
  },

  data() {
    return {
      actionMonitor: generateActionMonitor(() => {
        this.$store.commit('auth/SET_FORCE_LOGOUT', true);
        this.$store.dispatch('auth/logoutUser');
      }),
    };
  },

  created() {
    window.addEventListener('resize', () => {
      this.detectMobile();
    });
  },

  mounted() {
    this.detectMobile();

    if (localStorage.getItem('teamfuLight') === 'light') {
      themeColors(true);
    }

    if (JSON.parse(localStorage.getItem('teamfu/taskView')) === false) {
      this.$store.dispatch('viewState/setTaskView');
    }

    if (this.authTokens.access) {
      this.actionMonitor.start();
    }

    if (this.authTokens.refresh) {
      this.callRefreshToken();
    }
  },

  beforeUnmount() {
    this.actionMonitor.stop();
  },

  computed: {
    ...mapGetters({ authTokens: 'auth/tokens' }),

    ...mapGetters('popups', ['toast']),
  },

  watch: {
    'authTokens.access'(accessToken) {
      if (accessToken) {
        this.actionMonitor.stop();
        this.actionMonitor.start();
      }

      if (!accessToken) {
        this.actionMonitor.stop();

        this.$router.push({
          name: ROUTE_NAMES.LOGIN,
        });
      }
    },

    $route: {
      handler: async function (newValue, oldValue) {
        const routesOut = [
          ROUTE_NAMES.LANDING,
          ROUTE_NAMES.LOGIN,
          ROUTE_NAMES.REGISTER,
          ROUTE_NAMES.THANKS,
          ROUTE_NAMES.CONFIRM_EMAIL,
          ROUTE_NAMES.PAS_RESET,
        ];

        /** Remove tokens from cookies **/
        if (routesOut.includes(newValue.name) && !oldValue.name) {
          Cookies.delete('refresh');
          Cookies.delete('access');
        }
      },
    },
  },

  methods: {
    detectMobile() {
      this.$store.dispatch(
        'common/setMobileDeviceState',
        window.innerWidth <= MOBILE_DETECT_WIDTH ||
          this.$isMobile() !== 'desktop' ||
          isMobile,
      );
    },

    callRefreshToken() {
      this.$store.dispatch('auth/refreshToken');
    },
  },
};
</script>

<style>
@import '@/style/colors.css';
@import '@/style/fontSizes.css';
@import '@/style/loading-animation.css';
@import '@/style/markdown.css';
@import '@/style/svg.css';
@import '@/style/animantions.css';
@import '@/style/style.css';
@import '@/style/style-1.css';
@import '@/style/components/header/header.css';
@import '@/style/combobox.css';
@import '@/style/components/filters/timesheets.css';
@import '@/style/components/user/user.css';
@import '@/style/components/datepicker/datepicker.css';
@import '@/style/components/calendars/ts-calendar.css';

#app {
  height: 100%;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  display: flex;
}
</style>
