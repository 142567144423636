export default {
  OPEN_DRAWER: (state, action) => {
    state.openDrawer = action;
  },

  SELECTED_TIMESHEET_ID: (state, action) => {
    state.selectedTimesheetId = action;
    state.selectedTimesheet = state.filteredTimesheets.find(
      p => p?.id === state.selectedTimesheetId,
    );
  },

  ADD_TIMESHEET_DATES: (state, date) => {
    state.newTimesheetDates = date;
  },

  ADD_TIMESHEET: (state, timesheet) => {
    if (Object.keys(state.filterTimesheets).length === 0) {
      state.loggedHoursByList += Number(timesheet.hours);
      state.loggedHoursByTask += Number(timesheet.hours);
    }

    if (Object.keys(state.calendarTsViews).length === 0) {
      state.filteredTimesheets = [timesheet].concat(state.filteredTimesheets);
    } else {
      state.filteredTimesheets = state.filteredTimesheets.concat([timesheet]);
    }
  },

  UPDATE_TIMESHEET: (state, timesheet) => {
    const prevTime = state.filteredTimesheets.find(t => t.id === timesheet.id);

    if (
      Object.keys(state.filterTimesheets).length === 0 ||
      Object.keys(state.calendarTsViews).length > 0
    ) {
      state.loggedHoursByList =
        state.loggedHoursByList -
        Number(prevTime.hours) +
        Number(timesheet.hours);
      state.loggedHoursByTask =
        state.loggedHoursByTask -
        Number(prevTime.hours) +
        Number(timesheet.hours);
    }

    if (prevTime) {
      state.filteredTimesheets = [
        ...state.filteredTimesheets.map(t =>
          t.id === timesheet.id ? timesheet : t,
        ),
      ];
    }
  },

  SET_TIMESHEET_LIST: (state, timesheets) => {
    const oldTimesheets = state.filteredTimesheets.filter(
      t => !timesheets.find(time => time.id === t.id),
    );

    state.filteredTimesheets = oldTimesheets.concat(timesheets);
  },

  SET_FILTERED_TIMESHEETS: (state, filteredTs) => {
    state.filteredTimesheets = filteredTs;
  },

  SET_FILTER_TIMESHEETS: (state, filter) => {
    state.filterTimesheets = filter;
  },

  SET_LOADED_TIMESHEETS: (state, loadedTs) => {
    state.loadedTimesheets = loadedTs;
  },

  PATCH_FILTER_TIMESHEETS: (state, filter) => {
    state.filterTimesheets = {
      ...state.filterTimesheets,
      ...filter,
    };
  },

  SET_LOGGED_HOURS_BY_LIST: (state, hours) => {
    state.loggedHoursByList = hours;
  },

  SET_LOGGED_HOURS_BY_TASK: (state, hours) => {
    state.loggedHoursByTask = hours;
  },

  SET_SHOW_DELETE_TIMESHEET_MODAL: (state, payload) => {
    state.showDeleteTimesheetModal = payload;
  },

  SHOW_TS_CALENDAR_VIEW: (state, payload) => {
    state.showTsCalendarView = payload;
  },

  SET_DAILY_CALENDAR_DAY: (state, payload) => {
    state.dailyCalendarDay = payload;
  },

  SET_TS_CALENDAR_DATES: (state, payload) => {
    state.calendarTsViews = payload;
  },

  DELETE_TIMESHEET: (state, id) => {
    const deleted = state.filteredTimesheets.find(t => t.id === id);

    if (deleted) {
      state.loggedHoursByList -= Number(deleted.hours);
      state.loggedHoursByTask -= Number(deleted.hours);
      state.filteredTimesheets = state.filteredTimesheets.filter(
        t => t.id !== id,
      );
    }
  },
};
