/** General **/
export const MOBILE_DETECT_WIDTH = 1079;
export const NETWORK_MARGIN = 8;

/** Routes **/
export const ROUTE_NAMES = {
  MAIN: 'main-page',
  LANDING: 'landing-page',
  TASK_LOGOUT: 'task-link-logged-out',
  LIST_LOGOUT: 'list-link-logged-out',
  TASK_MOVE_LOGOUT: 'task-move-logged-out',
  LOGIN: 'login',
  LIST: 'list-link',

  PROJECT_SETTINGS: 'project-settings',
  PROJECT_ARCHIVES: 'project-archives',
  PROJECT_PORTFOLIO: 'portfolio-link',

  TASK: 'task-link',
  TASK_MOVE: 'task-move',
  SEARCH: 'search-query',

  SETTINGS: 'settings',
  BILLING_QUERY: 'billing-query',
  INVOICE: 'invoice',
  TIMESHEETS: 'timesheets',
  PROJECT_TIMESHEETS: 'project-timesheets',
  PORTFOLIO_TIMESHEETS: 'portfolio-timesheets',

  EPICS: 'epics',

  REGISTER: 'register',
  THANKS: 'thank-you',

  CONFIRM_EMAIL: 'confirm-email',
  NOT_FOUND: 'NotFound',

  PAS_RESET: 'password-reset',

  PRIVACY_POLICY: 'privacy-policy',
  COPYRIGHT: 'copyright',
  CONTACT: 'contact',

  ABOUT: 'about-us',
  FEATURES: 'features',
  INTEGRATIONS: 'integrations',
  PRICING: 'pricing',
  USE_CASES: 'use-cases',
};

export const CARD_BRANDS = {
  VISA: 'VISA',
  MASTERCARD: 'MASTER',
  AMERICAN_EXPRESS: 'AMEX',
};

/** /docs/ui/Calendar%20Assets.md **/
export const CALENDAR_VIEWS = {
  MONTHLY: 'monthly', // Tabular view, all days of the month are displayed in calendar form
  WEEKLY: 'weekly', // Tabular view, columns - 7 days of this week, rows - time (0 - 24)
  DAILY: 'daily', // Tabular view, column - day, rows - time (0 - 24)
  ROADMAP_MONTHLY: 'roadmap_monthly', // Roadmap display, Columns - name of months
  ROADMAP_WEEKLY: 'roadmap_weekly', // Roadmap display, Columns - month names and week indexes
};

/** Direction of popups modals near element */
export const DIRECTIONS = {
  CENTER: 'c',

  LEFT: 'l',
  RIGHT: 'r',
  TOP: 't',
  BOTTOM: 'b',

  TOP_LEFT: 'tl',
  TOP_RIGHT: 'tr',
  BOTTOM_LEFT: 'bl',
  BOTTOM_RIGHT: 'rl',

  LEFT_TOP: 'lt',
  LEFT_BOTTOM: 'lb',
  RIGHT_TOP: 'rt',
  RIGHT_BOTTOM: 'rd',
};

export const imageExtensions = ['png', 'jpg', 'jpeg', 'gif'];

/** Permissions and Roles **/
export const ROLE_NAMES = {
  OWNER: 'Project Owner',
  MANAGER: 'Project Manager',
  MEMBER: 'Team Member',
  GUEST: 'Guest',
};

// Category icons
export const categories = [
  {
    category: 'Design',
    id: 1,
    icon: 'fas fa-pen-ruler',
  },
  {
    category: 'Development',
    id: 2,
    icon: 'far fa-file-code',
  },
  {
    category: 'Testing',
    id: 3,
    icon: 'fas fa-code-pull-request',
  },
  {
    category: 'Meeting',
    id: 4,
    icon: 'far fa-calendar',
  },
  {
    category: 'Marketing',
    id: 5,
    icon: 'fas fa-bullhorn',
  },
  {
    category: 'Sales',
    id: 6,
    icon: 'fas fa-coins',
  },
  {
    category: 'Devops',
    id: 7,
    icon: 'fas fa-infinity',
  },
  {
    category: 'Documentation',
    id: 8,
    icon: 'far fa-file',
  },
  {
    category: 'Project Management',
    id: 9,
    icon: 'fas fa-diagram-project',
  },
  {
    category: 'Research and Development',
    id: 10,
    icon: 'far fa-lightbulb',
  },
];

export const PROJECT_ROLES_DEFAULT = [
  { attribute: ROLE_NAMES.OWNER, color: 'var(--teamfu-pink)', title: 'Owner' },
  {
    attribute: ROLE_NAMES.MANAGER,
    color: 'var(--t-mood-5)',
    title: 'Project Manager',
  },
  { attribute: ROLE_NAMES.MEMBER, color: 'var(--t-mood-8)', title: 'Member' },
  { attribute: ROLE_NAMES.GUEST, color: 'var(--t-mood-6)', title: 'Guest' },
];

export const USER_PERMISSIONS = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  ARCHIVE: 'ARCHIVE',
  MOVE: 'MOVE',
  MANAGEMENT: 'USER MANAGEMENT',
  EXPORT: 'EXPORT',
  SEAT: 'SEATS',
  PERMISSIONS: 'PERMISSIONS',
};

// Profile settings pages
export const PROFILE_SETTINGS = {
  PROFILE: 'profile',
  CUSTOM_SETTINGS: 'custom settings',
  PLANS: 'plans',
  BILLING_DETAILS: 'billing details',
  BILLING_QUERIES: 'billing queries',
  PAYMENT_HISTORY: 'payment history',
};

/** Task statuses **/
export const HIDE_TASK_STATUSES = [
  'hideTodo',
  'hideProgress',
  'hideFeedback',
  'hideBlocked',
  'hideDone',
];

export const BACKEND_TASK_STATUSES = [
  'todo',
  'in_progress',
  'feedback',
  'blocked',
  'done',
];

/** Text **/
export const REGEXP_CONST = {
  numS: /^\d+\.\s/,
  dotS: /^-\s/,
  num: /^\d+\./,
  dot: /^-/,
  checkS: /^-\s\[[\sxX]]\s/,
  checked: /^-\s\[[xX]]\s/,
  space: /^\s*/,
  spaceEnd: /\s+$/,
  lettersDigits: /[A-Za-z0-9]/,
  special:
    /<span id=(.*?) class="usr-cmt-mkdwn">|<\/span>|[`_>~*^![]|]\(.*?\)/gi,
  bracket: /]\(.*?\)/gi,
  mention: /<span id=(.*?) class="usr-cmt-mkdwn">|<\/span>/gi,
  mentionStart: /<span id=(.*?) class="usr-cmt-mkdwn">/gi,
  mentionEnd: /<\/span>/gi,
  image: /!\[.*?]\(.*?\)/gi,
  imageStart: /!\[.*?]/,
};

export const ADDITIONAL_SYMBOLS = {
  nbsp: String.fromCharCode(160),
  checkedStart: '¶¦¦',
  checkedEnd: '¦¦¶',
};

export const UNASSIGNED_TASK = {
  id: '0',
  key: '0',
  title: 'Unassigned',
  tags: [],
};

export const UNASSIGNED_LIST = {
  id: '0',
  key: '0',
  title: 'Unassigned',
  bullet_color: null,
};

/** Dates **/
export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MONTHS_FULL_NAME = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const WEEKDAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const FULL_WEEKDAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wendesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const TIME_HOURS = [
  '12 AM',
  '1 AM',
  '2 AM',
  '3 AM',
  '4 AM',
  '5 AM',
  '6 AM',
  '7 AM',
  '8 AM',
  '9 AM',
  '10 AM',
  '11 AM',
  '12 PM',
  '1 PM',
  '2 PM',
  '3 PM',
  '4 PM',
  '5 PM',
  '6 PM',
  '7 PM',
  '8 PM',
  '9 PM',
  '10 PM',
  '11 PM',
];

/** Others **/
export const progressImages = {
  dark: [
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMqSURBVHgB7d1PUhNBFMfx15NQyI4jhBuwUqwSajiBLCxkh5wAb4CcADiBYWNBYZW5gVMF4lgszA3MEeLCMhFmnt0BLCKYpPNvJvH7WUAyNUlWv3rd/boTIxkKw7X5H79mSsVCWlIJ5kVNSQD0IY3is/fR3StGxsiFuXFVXDQmeG4/OVQ1iwJgWGq2QG7FZ0eRezKWcC89exGKKWyqyJr9wHkBMDpqVl3AizJCN6HesQ9D93yswwTgf2X0rf27MJK8/R1qAGNmq/dQK7ebUzfT2R1VeS0AMpSGQwv3UrhRaiT60T4sCYDMBTIET5fX1zTRr0KwgZwIooHn3E9WXm4blX0BkBe1+PR4YaDKvbSyvkOwgZyxvW73r+/KbYO9ad+kLADyYvBNLG7xzM2xh7UhRVWrxpiq0fS7iqkLAE9BdBvqW96r5a0tpHZVfJBgq0g90PRQpVB5VGxUo6hCoIEh8w53I5ndkz5XxV2ojZqDuWJjn0ADo+U1LH9sW16BmA/SBxvsylyhuUWogfHwqtw22HviqTUET2U3/nTMqjowRj2He2l545WNakk8uGCnSWH1y/m7qgAYK48+t+6Ih9tgXxBsIBM9hfu6avstormhOMEGstNj5dZN8WBUDj4zxwYy1TXcrbPZfueya3plCDaQse4LaqbgVbVF0t04PqkJgEx17HO7baaS6DfpXes0igDIXOdheeK3Qu6qtgDIhW5z7lB8XBYiAZAL/wx3H+2vchwf1QRALnSo3J5D8kvDkBzIkQfD7Q6IiE/VVomo2kC+BA9fNNvihaoN5M29VhjtL2A63K/ctL+AqdBWuanawPRoq9zmUtfETyQAcqkt3BqI30Ia7S8gt/6Em00rwHS5U7n9FtKSJD0QALnVCvfNme1Sz69SiS7OT/iWFSDHriu375ltkx4KgFwztL+A6RRI4nmsk00rwEQIRNNSrzcbkTpntoHJEKj2/qua7ieBaH8BkyFozjTLPd/NphVgYgTVqFJ33zPe7UZ3D1UbmBytVtjPYvONdNonbvvas9f3AJgQ7afC3BZU1U17NWxdsKF2Pe349KQsAAAge78BOA0k6IXKPo4AAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASPSURBVHgB7d1fUttWFAbwc2QzJm8sQeyAPrR1O01GXkHoTJvyZlhBugPwCgoriHnphKEzdVdQzUCDOnmIdxAtwX3oBAek03ttmeheCRkHy5Kn3+8hsa+E/fRx7l/BVCHP29369+OG22zErpCzRcIuAcBniP3g8jc/3cK0QjrM17fNHWbnufpmT4R3CACWJVQF8iC4fO3rNysJd/u7HzziRleIdtUXbhEAlEe4owPepBIloT5ULz39fqXdBID/K5ZX6t/tUvJmhxoAVkxV76VWbj2mHsetQxH6Off7iEYsNBSmHXTPAcoUew4tSdvbc6+j1rvcYAv5TPL9k8Z4O7g866jXfxAAlGoplfubpy9240heZaqxCrUaAPRms3efOOq9dAkASuI8fkLt62c/vVTV+jg9eNfdbyem3tVfZ8f2/V9+++MOSewSY3oNoCTho2fL289eHKokH9kfzDfcuQpeh7MGPRb/cNPaZ4eeEybZAMql1rr1f59dPlWwu+pD+uk2Zhm2nI8d3x+MZm26yy7Ev6iXLgFAmR6/iUVPnkkk76wxdrjZGH8xC/ZkN1rU0qHen/d5IjJk5iFL/I/6RTAiAFiQ49tzWwt3y6ehlT/tYJPqivsX02Dr8I/j+Hf1Mnd76WRMLvGpUGOw2bwepis9ACzHwuFOqrFrNOquQDLG1sEmFX5VgV37Z6fr3HzypHl9jEADlGuhcH+lxs+U7Wb3je5ANNn65to/q4I9UOvcBwg1wGosFG5nOjGWprvjvdmbZPbcS98wWxYLcpbFAKA8Dw53++nevoqqazQKn1rd8SPjsgp2HDU6f7/5dUgAsFILbD+Vw0zTLfXvXkfm9Vmw3yLYAJV4ULinVTszju4bVdsai+uuOIINUJ0HVu6cfeCpsbZdtVno5ApjbIBKzQ335Gx2dstoUdUO5ZYRbICKzZ9Q40Zx1Y7jrrnRLe4FwXlIAFCpwsqdN5bWxziD1KEQNWOevh4GF+d9AoDKFXfLo5wZckqta2cm2uIeAUAtzBtze+k3wjQ0N6db4b9p+AQAtXBvuPOWv1jik4LrfaO7DgCVKqjcmS65NZ62qzajSw5QI7nhTg6IuGZrfP9Y255kA4DKOfmN/NJqsqu2tTyGqg1QN5lwJ8tfntmaqtrZTS1h9ummAFC1bOXOLn+Fxix4ZlMLlr8A6sh4hlpybPO9dU8/uDg7uOe66q6fbRMA1I5RuflGdjN3FBwQUXwCgFoywi0O2RNphcc6sfwFUF934c49s11ctbFpBaDGUpX7/vBOnlNOZHTZo+jTbjUAqJ9JuJPlLde4kqraeixuPKdcyH/75hxPWQGosWnltpe3rB1nmbE4x6cEALXm5E6UFR7rxJltgHXgUJR5hFJYeKwTm1YA1oIz+VvZaSJ3XW67aqtx9whntgHWQ1OER5zep8bcbbf3+rRJec8iH2D5C2A9OOONcd9qc2lD3ifbTF3jCjatAKwNZ+gPRvo54/Nu1PegagOsj8lS2Ifm+IiK9omrpbHW9B4AWBPmqTA9gSbSVa3epEGFWq9pY+kLAACgJv4D/kP0YyPT9koAAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARuSURBVHgB7d1PctpWHAfw30/AON75BlVu4FVLZ+qMfIKy6LjekZwgvYHDCRKfIHjTScZd+AbVjNOETBblBqE3oItMILb0y3sCYb0nWcIxQiJ8PwuMBILVd37vz0+YqUKe19n79KXlNhuhK+TskbBLAPANQn/w5i8/eYZpjXSYJ9fNfWbnV/XNngjvEwCsykgVyCeDN698fbCWcLd/+c0jbnSFqKO+cI8AoDzChzrgTSrRPNQn6qmnj9c6TADYViwv1ePDUvJmh/oWIxEaOhT+J8RjWiUmVz10CWBbqeq90sqt59TTcOdEhfaP1HcRjR0Jz4QaFw+ak6HvX6w20Jb2wREh4LC9Qm9l4W57x+4kkL/VU9d6aSQhne62pv2yA21iIYAttpJh+c8HR52Q+GVysSyq1CH13v3z+kXWNe12x+VWY7+ULTDmH9TjYwLYVmpYfu9w//To96eqRpoBFvLpWi3JD16Nkqf1XFxvg6k5dofSFR4AVmM0uHz98F7D8vajoxMV5GfGSeGeWoY3ziUX2DBWBiiZ2uvWf765cqtgd9WH9K0PNYKt5+EURMvyHgFA2e7fxKJDK4H8azSk2MGeVWsdbLfo80RkyMxDlvD/lW+LAWwFx49DHbvzsDxqIVWr4rnBzqrqCeveFgPYRncO9yTYeU5mNR4tG2wdahY+3W1OXiDQAOW6U7h/VFteZG8xXfFh/HQ+x+5nXauCfbHbmD5BqAHW407hdoifJ4/VFtjpu/l2Vzxct6+J97sHt+x3A0A5lg53++D4sYqqGx+rOfdYrnkRWN12StbimQ52GDQO37/9c0gAsFbO8m+VE+NIDbPjJpVo9dzqJ4+D/QHBBqjEUuGeVW1rS+uKe4vngRn86IPVUBzBBqjOkpVb7Lur+smqTdYiWzQXxxwboFKF4Y6aUewOM+Gz+ClfSce6ZJSciwNANYoX1LhhVG1hGr6/vOmEEYeemheEvcHgfEQAUKncyp095A5PF6+n5+KjweV5nwCgcvnD8vRCmRVe+/WwRwBQC0Vzbs88vAnvfC7uGi9fNXwCgFq4Ndz2kFs3rSTDy+RYc+2bFXQAqF5O5S5oWmE2V8mT+94AULnMcM9vEHGNk3lNK0I+qjZAvTjZJ/nWIXfWCroapKNqA9RMKtzz8HrGyYKmFfsXIACgeunKbQ25ddNKMrxZTSsEALVjhBtNKwDfDyPcmUPu3KYV8gkAaskId96QO7tpBQtpAHW1CHf2PduJjjPrBhJC0wpArSUqd2rInbv9FQQ3c3EAqJ8o3IVD7oymlQ9vz/ErKwA1NqvcOUPuzKYVDs8IAGrNyQxvommFgtT/+cL2F8AGcDLDa3Sc4Z5tgE3kkISucUZkUbWLbvsEgPpqivCYk//rk7nbbh/36QGlW1ETt30CQL0509a0b51zqSUfVbA/EppWADaWM/Qvxvp3xoveqN+Dqg2wOaKtsM/N6TPK6xNX+9o7s/cAwIZIzrZnC2giXXXWi06oUOs9bWx9AQAA1MRX57D3hxsjEdwAAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASSSURBVHgB7d1NbttGFAfw90gZdnY+AnMDd9FWLZqAOUG1KFKjGzknSHMCWydIfIIom8JpulBOUAJxE6VZVDewjqAuikp1OK8zkigPR9RXrI8h8v8tbHJEWRv/8eaLFNMOxXHt8J//9qJKqCKh4JCEIwKAT6CS9uVvid3CtEUmzP2PlSPm4Hv9ybEIHxEArEtXF8hH7cuLxJxsJdzV736IicO6ENX0Bx4SAGyO8AMT8Apt0DjUp/owNudb7SYAfK5YnuufdzeSNzfULl3BeyTSZeYOi/pbiHtUJsx1/TMiAF/p6r3WcJsx9UDtn4rQz1OfpQPNwuf6MDmo9DtJ0ipXoC3V+DiiVK4IwFcijbV1y80/fD+V38mtaEKJLnWN9+NBPgBsx1rC/c29hzWVynNnsiw3c+eqVmsR74VHSlUiZinXJFsqdQLwWpDculv+9f0fH7PQM7tNn5/vVwZnbtcbs+YAW9Ftv3l591aVu3r/4anudp9l58NxNfGTd5cXzdx1945P9Ktmgi0y55g1B9gg3WM2vz45ZzrYdf1HmpO/p4Ot0vDBh7e/dLK2L7/96SgM06c0Y9YcANbq9ptYzOSZpPKX3bVO0/ALO9hF3fVZRKRT2mUxAC8EiTu/tXK3fLiFVM+K28FmRU/sYLvddZep8oGoF0Jhq+zLYgC+Wjnc/XTfdLOj7NxMnr374+WkQo+CzWdF783Wuu9U+s8QaIDNWincX+klL/3rxGrqmlnx7MR0xWdVbB3s1p1w8AihBtiOlcIdED/Nt6hGFtbxrq2pMfawC66o0baqOwBs3tLhHi9nRVaTXkt71ZycjXan5WQz6O+t8TgAbEew/KXDdWqLamRHo+Dnt50WLY0BwPYsFe6C8Oar9lTw9R/WXXEEG2B3lqzc7l5qq2qbLaVO1XZn0AFg+xaGexzeONd4HSaTYw7dmyi68pERbIAdW1y5p8PbbLcvuuZgOEOeXxojU9Wz1wFgd+aGuzC81zzpkusZcnes7YzFAWBX5lduN7xCSVaVj+Ka2X4a599wMxYHgN1a1C2P7RPFcp4dH6QHZrdalLvaHosDwE7NDHfR8tefb35t3ZxOdcmbGGsD+GNO5Z69aWW8xzzKvWyPxQFg5wrDXRDert3lDogf595gjcUBwA9BcaMTXroJ73gGPc6/jKoN4JupcBeGd9HyFx5bDOCd6crthFeIXi/atEIA4J1cuIvCy2JtJU2nHnSITSsAnsqFm6+l5rzudLmnuuQJAYCXcuGWgJyJtPn3bGP5C8Bfk3Avcc/2zBtIAMA/VuWe3eUumkFPU3VOAOCtYbiLHrgwd/lLKPnw9hWesgLgsVHlXvWebVYvCAC8FhSGV/gmvEpNPWkFy18A/gvctWth6uSWv4RP8m/BphWAMghIVGQ3sJLX2bE7g85EPdyzDVAOFRHusf1dn8z1avW4SQdUtBW1heUvgHIIBnuDptMW0Z5c6WBfETatAJRW0ElaPfOc8UUXmmtQtQHKY7gU9u/omzqTmVfpdW372zwBwH/2aHs0gSZS163xsEGH2qxpY+kLAADAE/8DLTn39oawwHYAAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASkSURBVHgB7d1PbttGFAbw90gZdna+QZkbeJNWNeqAOUEcoHGNbpycwO0JbJ8g9gmibIoYLlD1BGHhNGWQRXSDsDdQF0Ut2+TrDGVK5JCipOgPKeT7LRRyKFubfH7kvCHFVCHX3d3893rNadiRI2RtkrBDAPAZIs9/+6uXHmFaIh3mq9vGFrP1WH2yK8JbBADzEqgC+dx/+9rTO0sJd/O7711i+0CIdtUHbhIALI7wIx3wBi3QXaiP1Kar95d6mgDwpWJ5qV7vLyRvZqhHCESow0xdEvmboI/JUS8HBDALVb3nWrn1NXUvWj9Sof0p91lEXfWXpK3+4/6xYV+1Pa/dJSjU3NkjBBxmE7lzC3fT3XeuQnmjNp30eBxq4bN7jatTBHpSLAQwo7mcln+7s7cbEb9MT5Ylod4YEeq4yoe2ixaYgfkr9fqMAGahTstnDvc3D384VHXmND3GLB25tp74/usgPf5g++mW3eDH6kN30QYDWJjAvzy/P9NpefPh3pEq0cfpMRX0s3X7+tjzh9U6M8EmcVUHgEVRvW79z2dXbhXsA/VLWsYvPVH9teNkt2yCDQDmbvZFLHryTEL5mFmQYgRbv4cKJtiKiIhqiXGHJfpHiDHpBjA1y0tCnZg63PES0nD9I6VCq06z2+8vz58k+w+2f9yy7PDNqNVoerLNkuiVkN1WE24dzKIDzN/U19wq2C8oW40DvuGfk52yYKMtBrA8U4X7a9XyolybJjrx/YtAb/VPxcPfqDjY7Xt27zlCDbAcU4XbIn5hDLX8y4vWYG/EIhYrohP/z/NTAoClmTjczZ39ZyqqTmbwhk+STd3vVknOHNfBjkL70ft3v3QIAJbKmvytcmQMtJJFKvp0nI12VxLsDwg2QCUmCne/ahstLeFXg+0wvvsrc1yfiiPYANWZsHJL5g4lYepke2rZqq5Xqf2Fa2yASo0Nd7x01LgvmyU6GxzPV/VAbhnBBqjY+Ak1ts37ioPMDHnuWnzYGgOA6pRW7rhvXdDXHhzvV3UnddAIPgBUpfy0PMzNkAd0Y3uDvf6dXinD4ANAtcZdc7vGvpduf+WOp4MPAJUaGe7C9ldq0UpBVW+ZD2cAgOqUVO7yRStkXoungw8AlSsM990NIk5mMBVeDuUwc0yGp+sAUA9W8SCXhld/c0j2J1C1AeomF+7CiTKOBktNixatmE+AAIDq5St3Qftr3KIVAoDayYR77KKVoqqNRSsAtZQJN9+IcS2dq9rmUlSPAKCWMuEWi4yJtOFtndvbT/WXCLiZ42h/AdTWINyFi1ZuqZVsRrZ1aPwsFq0A1Fiqck+3aCUMh7d9AkD9xOEuuLurfKmp6nt/eHeBp6wA1Fi/chv3bAvR76U3iKT63gBQT1bRKTdL6kkq+eejof0FsAKsu/CmBWXPR8OiFYDVYJFETmZEZORSUybq4p5tgNXQEOEup78OkPmg2dxv0QblJtL0VwKh/QWwGqzeWq9ljDm0Jp9UsD9R2Qw6ANSa1fHaXf2c8XFv1O9B1QZYHXEr7L9G75jK1omrvvZ6/z0AsCLSV9v9CTSRAzXqxgMq1LqnjdYXAABATfwPhpX4zSR2e3oAAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR5SURBVHgB7d1NbttGFAfw90gZtnc+AnMDb9qqRRPQJ6gKFKnRjZwTpD2BoxPEPkGUTZHABaIblEDSREUW1Q3M3kBdFJUqky8z+pwZUh/+kEg5/9/CloaitfHfM3zzKDMVKAxrB//+vxNU/DQQ8g5IOCAAuIE0ar/7LTJHmDZIh7l3VTlk9r5T7xyK8CEBwF2J1QT5pP3uVaSfbCTc1W9/CIn9uhDV1BseEACsj/CRDniF1mgc6lP1MNTPN7pMAPhcsbxQXx+sJW9uqOeIRajjUfq3EHcJNoO5rr4GBPebmr3vNNz6mrqf7p6q0P6ceS+irifpSyG/tVfpdaKohUAXoBoeB5TIJcH9JtK4s2W5/qXpJfI7ZWeFWFXyGvv+oIVAA2zOnYT764ePa2kiL8xi2XCmTqnx4Y/XZ3nnVKu1gHf8Q2yBbVgidYLPgBfdeln+1aMfn7KQHWChaK/S/96dqfW1uN4GU9fYNcJ1H8C6xO23rx/cauauPnp8qoL8zBoUbqgyvDVmFtiEAGCt1F63/nbjmVsFu65+SNMc45R+MZfh4+KNLsuHBADrdvsmFh1aSeQvqyHFmbH1bC3sv1mlaUVEOszcYUn/wbYYwE140STUE9delg9bSFVVfFGw9XW4Wq6fzfvLgW0xgPW7drh7ye5zsothsRvsTIFtTIeahc/3K70zBBpgva4V7i/Vlpf6dmINDvho8vCLb346ZEnmBbu17/efINQAm3GtcHvEz60BvRxvv4r1w1HxLHnjnjPZ727P2e8GgPVYOdzVh8cnKqqBMRTTFTWnzxLRW13m8WGw08Q/+vP9rx0CgI3yVn/pMLymyJq1neX6JNgfEWyAQqwU7tGs7XSUDbgxfZxkgk96KY5gAxRnxZk704/cXDRrq2r5+QdcYwMUamm4h62jTodZkqTnk8ecyFPnlFiuGMEGKNjyghr79qwtFH18f9GZPaWafUKqKugXMQFAoRbO3HlLbuL05fR49lo8br+9aBIAFG7xsjxbKHPC6x5PGwQApbDsmju0n87CO+5WC6zDAz8iACiFueHOW3Lv+YPW7ER2C2nTCjoAFG/BzJ1tWpn0hY+vxUPrqLnvDQCFyw13/pJ7QdOKzLrVAKAcvPzB+Uvu3Ao6YdYGKJtMuHOX3MLT7S9KU7dbLXY/AQIAipedufOW3GZ4hU/sE7D9BVBGVrjRtAJwf1jh5oE4raTLmlYoIgAoJSvc4pFTSJstucc3kATWYWx/AZTWNNzukpuJumbHGZOHphWALWLM3PaSW3+gobn9JczWkt287RMAymcY7qVL7pwKunnbJwCUz2jmdu/ZXta0YlTQAaCcvNzwmk0rSeb/fGH7C2ALeLnhtTrOcM82wDbySNLAGhGZ27TiVtABoLwqItxl8z/2Mder1eMm7VGmkGZW0AGg3Lz+Tr/pjAW0I5cq2JeEphWAreV1olZXf874shfq12DWBtgew62w/yr9Z7SoT1zta++OXgMAW8K82h4V0ETqajQcDqhQ6z1tbH0BAACUxCeioPmw1kjdqwAAAABJRU5ErkJggg==',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAS0SURBVHgB7d1PUhtHFAbw92ZEsHfcIOMbsEoUV3CJExiqEsJOcAKcE4BOYDiBxcZlilRZOUGmyo4jFwvrBlZuoCxSIMT0c7ekwdOtYSQZSTOyv99CjHoGsaA+Xk//GZhyVKlsrf1/vRKUfBUIeWskHBAAfAEVNt/+ESZbmBbIhPnqprTO7D3VP7kiwusEALPS1gVyv/n2VWjeLCTc5Z9/qRD7VSHa0j9wjQBgfoQ3TcBLNEfDUB/qw4p5v9BuAsC3iuWFfn00l7y5oU6jq3hHv4TM5qv8S7D8mAL9UiXIn67eM63c5p66q1YPRehZ6gU6zPrcnxxx433zVZvgq1Pe2CEEvAhUZWbhLld2g6tI/tKHQbLdVGgWPqEbqjcR6G8AC0EhzKRb/tPGzpYifuEOlunfcoN7/HtaqONpMN+ndUyBfSWYv9evewT5093ye4f7xye/Hei/1cfW5w6q9XY8JB8zgb7sre6xR08p434cAO6l3Xxz9uhe3fLyk51DneSjZBuztOja205W6/78tvru4DLiZzrYmAoDmCc9122+fHHl1sGu6g+pJ9tMsFe9680wbHTiNlPZzR8AzG8DzN39F7GYwTOJ5EMysG6w+9U6Wn1NE3S/RaTFzC0W9Z8QdwgApuSF7m3w1OEehvYD2aPiberxZtwVN+GnlJHzmLkn90SdCvmNB6WrVrLSA8BsTH3PrYP9nJzQRpG/fdF82TbHWcGOp8Uelq6OEWiA+Zoq3D/oKS9ypzqEaxfvXrbit6zUa921DtzvNdNiD/3uPkINsBhThdsjfu40tXW3+nYazIyeuzu9+l1wRbXm32fHBAALM3G4yxu7ezqqgd2qanElHnbHj5JnTbBV5G++T1R2AFgMb/JL5dBp0BPl5/Xbs5FYVT0O9gWCDZCLicI9qNrOAJnwaXz4+PGv63rYfcv6YN0VR7AB8jNh5ZbRXT43VI8Ple8dJE+x0Mk/uMcGyNXYcPf3Zo8uRKlbc9r2CHpbbhjBBsjZ+AE19kerdo9rt8eRey+uas3meZsAIFeZK9SGI+AfneZ6883Z/h3n+7tRCAByl90tH6nKZFdtpZyqrmoEAIUw7p67Yr0TCq0HLwjvWed7fkgAUAh3hjt1+os+V+2U83iMEkCBZFTulEUr1pYy53yyuw4AuUsN93CDSGC3qrurtttdB4DceemNfOA0WUtNR6s6qjZA0YyEe7gopWK3Jqr2YFFLkDjZdp8AAQD5G63cUfYGkeF/EknA9BdAEVnhTllKam0QSanqTncdAIrCCjf3ZGvkisQGkZSqHhIAFJIVbvHIHUjL2iCC6S+AArsNd+qilcwNIli0AlBkicp9d3jTqnYUqRMCgMLqhztleit7g4hQePHuHE9ZASiwQeV292yP2yDC6pQAoNC81IGy7A0imP4CWAIeRSOPUMreIIJFKwBLwSNRgdUi8nnRilO1maiDPdsAy6Ekwh1OPmyJuVou79bpAY1Mf5l/CYTpL4Dl4HVXunWnLaAV+Th8NlpgncGiFYCl4bXCRsc8Z3zcheYaVG2A5dGfCrssdY8oa524nhpbHVwDAEvCerRxfwBNpKpbK/0GHWozp42pLwAAgIL4BDmjBMS0q7gRAAAAAElFTkSuQmCC',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASUSURBVHgB7d1PbttGFAbw9yi5dnY+AnMDb9qqRRPQJ6gKFKnRjZwTODewdYLYJ4i8KWK4QJUThEDclEUW0Q2s3sBdFJVsky8z+ssZUpQUSySFfL+FLQ5lCwjw5Q3nDWmmAnleffe/2y23WolcIWeXhF0CgM8Q+cHV7358hClHOsy9++oes/Oj+mRPhPcIAFalqwrk8+Dqta8Pcgl37YefPeJKQ4jq6gN3CQDWR3hfB7xKazQK9bF66enjXKcJAF8qllfq6+O15M0O9QxdEeow0w2J/EMA8zA31FeXYD5VvVcabn1N3Y+2j1VoXyQ+i3SIyWfmNzuVXtv32zcEsISad+BSKNcE84k0VzYt1//wvVDekvU/qw41C589qvZOEWiA/Kwk3N89eVaPQnmVWCwTbs4K9XjlXE21XLTAYCGhNAgW5PgPnpZ/+/SXIxY6jY8xS0dunZ+C4HU3Pj4IdPTVkfrQOtpgAGvTDd5dPH5Q5a49fXas5t0n5iifbzv9F34wrdbjBbZeOFxgEwKAtVG9bv3tsyu3CnZD/ZKW9Uubqr92Mj7MWmADgJV7+CYWvXgmoXw0rrGtYI9WNhMLbGlERLXEuMMS/SvEWHQDWJrjj0M9tnS4B9fN4fZHiodWtbiCq4v98eHX3/+651TCt7N2o+kVdEeic6FKe6fa62AVHWD1lr7mVsF+SWY17tL9cI6vZQUbbTGA/CwV7m9Uy0t9OzRHo2YQXHb1q+FUPPyD0oPdflTpP0eoAfKxVLgd4pfWUCt4d9maHIWDPa1u/A2DKXhEzeDPi1MCgNwsHO7ak4NDFVXXGLzjpnXei5/WwY7Cyv7f73/rEADkyln8rXJsHqpFNGOTinl+HOwPCDZAIRYK97Aq2y0tu2qb5/VUHMEGKM6ClTuxp7dr9tTMqs1CZ3/hGhugUHPDPdg6mrgvO8qq2l25ZwQboGDzKzdXklU7tkIuEh2Zp3VrzLxhBADylxnuQd86pa89Oa+qOrNxd5cRfAAoTnblDq1raf00lbuKPx2wq/o0+ABQrHnTci9+oHeZjafcqVU9HnwAKNTMcKe2v2KbVuyqTnq3Gq61AUojo3LPDm961WZMyQFKJDXcoxtE3PhYGEZnk4MoMq+1E7vVAKBoTvogm+0tFd4P7y+nu82ED82fQNUGKJtEuEdTbs8Y5Oh8cj5l04r9BAgAKF6ycicXyqzetX0e7S+AMjLCPW/TSsq1ODatAJSUEW6+k7p13ghv4lqcyCcAKCUj3OKQEV5hao9fp16Lo/0FUFqTcKdtWuFbnra/sGkFYKPEKvdym1aMvjcAlM4g3KN7tl3jTNZWU7vvDQClM6zc1t1dQvTGqtqe8VOxvjcAlJOTNuVmiT1JZfjH+9zYabS/ADaAMwpvXObz0bBpBWAzOCSRa4yIzNxqmnhYAwCUVlWEbzj+5wCZG7XaQYt2KLGQFn9YAwCUm9Pf6resMZe25FoF+5qyVtABoNScjt++0c8Zn/dG/R5UbYDNMWiF/V/tn1DWPnHV194evgcANkT8anu4gCbSUKPeYECFWve00foCAAAoiU/4jAD0jrQQnAAAAABJRU5ErkJggg==',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAATISURBVHgB7d1RUttWFAbgcyRTyBs7qNiBX9o6ncKYFdSdaYE3wwpIVwCsgLCCmJdOKJ2pd1B1oInbPMQ7iJfgPnTANdLJvbIsS1eyDcG2ZPJ/D8S6V5iZzBzO1Tn3GqYcVau19f/+X3FKtu8IWesk7BAAfALfbV3/5sZHmBZIB/PtXanMbH2vfnJVhMsEALPSUQnyoHX92tUXCwnuync/VontuhDV1A9cJwCYH+FtHeAlmqMwqI/Uy6q+XugyAeBzxfJKfd2YS7yZQZ1FZfEuiXSYuc3i/yvEXQLIC5OjvtTpqVDZe6aZWz9T9/zVIxF6kXmDkKv+A//0fKv57s0vbQIokMrmDj2dAPerMwvuSnXPufXkD/XSSU0Ogvpk+KAPUEws9ITMZFn+fHOn5hO/ShXLpgT1V9/+VLZtKotvrTMLCm2QH+Yv1dd9eirUsvzRwf3N1u6h+n330hxnn35++9dFYjxohXkrNSJLV87LqJwDzEWndXWx8ahleWVr50hl52PzjT3P/iH+TB0Etf/F4Y3HL4YBjco5wJyoXrf+55NjTAV2Xb1JwxjuUF/12FqvO9F9g8q5Ls07BADz9PhNLLp4Jp68N5bVqcB+vrV7OrZyHiMibbTEAB7Dcs3a1oODe/DcvPqezEzc541hYIf3nNKYAoXucVvinwvZzbXSbdt1mwhogBl78DN3GLROfCwonsUy9o23qivnNfN7dVCz8Nmz0u1LBDTAfD0oc3+tWl4W8e+JQdXual1fbA8vB0U2Pja/VwV285ndO0BQAyzGgzK3CuzT1ODdoDKnVTb39tUD9HF8OliC+3TSMtpiADBf9w7uIHBJHGO4MXzO1kU28uQoPqkD2/fs7b+x1RRg4az735oM3ECfT6LXvq/35DrR3WFgYw85QD7uFdyDrJ3qUyeztvB+4o3VUhyBDZCfe2ZuSZ+UiWVt7ouujDvRtdDZWzxjA+RqanAHO8zMc9m6Qh5rfYlFh7HZjtwxAhsgZ9MLamxnnG8dZe10oc0/abUuOwQAuZrY5w4r4B+M4eDESXTP5q6ed7LmACA/k5flXkaFXGXm4at0oW00BwD5mvbMXTWuVWa+bIwujeDv2y4BQCGMDe7s9tekrD1qjQFA/iZk7tSS3MzayUJbfEMLAOQuM7j1ARGalLXN9pjRGgOA/FnZg3xoDHXW7H4zukq1x5C1AYomFdxB+yu1aYXPh0c1w/n92GwHH1kMUDzpzJ3V/rqjxvh5tL8AiiixiWXMppVG6+riYMw8Nq0AFFQic4cHQJLiVfB0VncJAAopEdzGARAteazT/MBDtL8ACisK7sxNK/FjnZ6MDXwAKJ5Y5k4tuRNZW4xPM/U8/4wAoLCC4A43pTiJGdX+il57lJwXct+9ucSnrAAU2CBzm5tSgo8rjveujazO/jkBQKFZWYUynyVacmc8ixt7zAGgiKxwyR3X+efq19FWU8KmFYBlZJH4TmJEJFpym1mbibo4sw2wHEoi3OX4PjXmeqWy16A1oow/MtBE+wtgOVi9lV7DGHNoRT6E20ydxAw2rQAsDavtNrv6c8an3ajvQdYGWB5BK+ym1DumSfvEVWtsdXAPACyJ5KmwwV/prKvRajCgglr3tNH6AgAAKIiPnQj/pWCiTr4AAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASDSURBVHgB7d1fUttWFAbwcyR7SN5YgrKD9KV1Mg2jrKDOTJvw5rCCtCsArwBYQcxLJ0w6U3cF1UxpcIeHegdoCc5DJnaIdHKvbBHpSsgGbEkm3+8BpIswvHy+f86VzFQh121vfvjUdBp26AhZmyTsEADcQOgNTv7wki1MJdJhHn9uPGS2flJ/2RXhhwQAy+KrDnJncPLG0yelhLv1488usd0Rorb6g5sEAKsj/FQHvEErNAv1rjp09XmpwwSAbxXLa/X1wUryZobapHrwEYn4zDxkCd8L8YgA7jLmjvrqUFlU773UcOs59STc2BWhX3N+rOcDR+q7F88JAL4VLXfboUDOqSwi3aUNy/U/Pw7kbzLfnYQ89bbVRaAByrWUcD968rwdBvI6uVjGLEMJrd+uCnWr1Xao2XTVNZvqWiyywd0WSIdKZXm3Hpb/sPXiFQsdJNvU+eHpyXFmaI5Vc4BS+IN/jh/cqudubT3fVcPuvfhcL5Sx8LNTo7fW16matg57FGismgOskKp16283zpkKbEe9SC/R5AeB/ezs3e/Dy2umPbVelncIAFbt9ptY9OKZBPJ/PLSOeuwL/m4weONfXhP16ry3yOuJyBBlMYDbsDJVqGsPy6MtpGpVPDlntkh2TgfHfnz+aOvF/hXlsIh+M7AkPBKy+/ca46Hn9RFogCW7drjHwcY+JYfZwl21eNaPT4uCPZuTH95vjA8QaIDVutaw/HtV8rKI/0w0Raty8UnRUFwFu3/fnuwg1ADluFbPrYK9n2qYrcppsx04e+bvREPwkLqDf48PCABKs3C4W0+2X6qoOpcNYmwjne5OS9HBDgP76X+JFXQAKIe1+KWymz7nbnw0DX663BUH+wzBBqjEQuHOCa+fXnY3g69eWA3FEWyA6izYc5v7YsPCXjvafoo5NkCl5oY72mWWvi9brZC/7X09zWyI9+UzI9gAFZvfc7NthteLD6IV8swDGcJucqcaAFSjMNyz8L5MNV58XUhTK+TmXNvo1QGgKsU9dza8vbhXzg1+Yi4OANWaNyx3U2fTxyRNBTnPR7uwPQKAWrgy3Dcof/Uw1waoj4Ke2wxvcfkrNRcHgMrlhlvfIEJmr11U/tJbUdFrA9SKld/Ir4wmLz54/PgX/RFAbvrH6LUB6iYT7tzadWLIHdqWGXwfjy0GqJ9sz43yF8CdkAp3bngT5S++kLbx+9i0AlBTqXDnhjcx5BaLrpyLA0C9pMKdDS/KXwDr6jLcuZtWiu/+wqYVgBpL9NyZhTQvPsgrfwVBeEgAUFtRuGf3bDupnxSVv4S8s3dv8ZQVgBqb9tzZe7aLy18cHhEA1Jo1t/wVSHbTCspfALVnmbduCtMwVf4iMspj2LQCsA4sktBJNnAof8XH5go6E41wzzbAemiI8IiTHyrE3Gm1tnt0jzJbUfVHAqH8BbAerElz0jPaHGrKuQr2OWHTCsDasoZef6SfMz7vQn0Nem2A9RGVwj42JntUtE9c1bU3ptcAwJpIfYRvtIAm0lGtbtSgQq1r2ih9AQAA1MQXltXxB4q69gEAAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPcAAAAvCAYAAAA/4VwRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAASOSURBVHgB7d1NbttGFAfw90gZTna+QZkbuIu2alEHzAmqReEa3cg+QdoT2DpB4hNE3hQ2XKC+QQnETRhkUd0gOoK6KGo15rzOUB/gzFCSZeuDav6/hSzO0Nbq7zecGVJMaxTHjZ2//92KaqGKhIIdEo4IAO5BJen1r0mxhWmFTJhvbmu7zMF3+pNjEd4lAFiUri6QR+n1eWIOVhLu+rffx8RhU4ga+gN3CACWR/iZCXiNlmgY6mP9NjbHKx0mAHyqWF7p1ydLyZsb6jK6ivdIpMvMHRb1lxD3CADmxxTpl6bVpqv3Qiu3uabuq+1jEfqprN8EOhB1JhRePa7ddJLkCoEGWID63j7ZAVfxwsJdjw+im0x+128jr1Mo0R/ceje80AeARWPxWmgBvt7bbyjiVyWTZdbsnateb0S8Fe4qVYuYBRNtAPfB/Jl+PbTa9LD8weH+6ukPz/X/jJfe5wmdbtf6J+7QGzPnAEvXTV9fPHnQsLz+dP9YD7lP/B4+ent93rbO3Ts41P9OzCRblJ9BALAUerRsftw7YzrYTf1H2tbf1BNmKgufvX/zS2fU9sU3P+6GYfaCpsycA8BCPHwTi5k8k0z+dIfVWRZ+Xgy2GbKbyj5r+C0iHSyJATxEkLhzW3MPy/MtpHpW3A0sK/q5GOzJQ3Z7SewRlsQAlmLucN9k22aIHRXbzOTZ2z8uxpNqg2Dzifu7JtQsfKrXuF8i0ADLNVe4v9RLXuROuetxvpkVHx2MhuLu7+pgXz0O+0cINcBqzBXugPiF36pao8Caa3HKxFoWy4fgilppobIDwPLdOdzDpazIadbraZft0YEo9RsX5uhGs+fvCtfiALAac1TufI3aoVqjd3n4Rcb3Z5ctiwHA6gR3OWlQtb0941bVdsNvhuIINsD63CncOrhNv82p2oXwu7PnALB6M8Od7wX3d5e5VbsY/q7cMoINsGazr7k5nF61vfCrVppedgkA1mpq5c6XtkrWta2qPXjiSnkfAKzN9GF5NmOGfBD+uKwPANZr1jV37Bx36WOYjI/c8Bf7AGCtJoa7dPlL+CxNz7t5vz9kb4/6AGD9plTukiH5LbXH772qzRiSA1RIabiHN4hETnN7YtUWSlC1AaolKG/k515jsTJ7E22o2gBV4z2JZXhn1wenuZ2+vjia0J8/jI0AoFL8yl22/DW1amP5C6CKrMqNqg3w/2FVbv4oDe+MYtVWyt2KmhAAVJIVbgnInUizZ8iFD61eLH8BVNY43KWbVqxr7Xy3WrEfm1YAKqxQuZ2JMm/t2u7PMnVKAFBZebiHt21GdhdPfBiDCf77N5d4ygpAhQ0qt3vPtqna1rcXOFWd1RkBQKUFpfdsF8Jbci2Oe7YBNkAwnCgrmvrgQ2xaAdgMAYmKrBaRiVWbiXq4ZxtgM9REuMfFfWrMzXr9oE2PyNtqar4SCMtfAJsh6G/1205bRFvyYbjNNLJ6sGkFYGMEneSqZ54zPutEcw6qNsDmyJfC/hl8S2cy8Sy9NFb8Jk8AqD77rrDB9301dWucN+hQm2UxLH0BAABUxH//aQC5j+we+AAAAABJRU5ErkJggg==',
  ],
  light: [
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAL3SURBVHgB7d3LURtBFIXh012yjXeEMMqAKS8MK0QGOAJDBEAEmAwgAkMEhgiYnezyQiFIZKCtvZj2bQEqKF7Tes1I/r8qFZIK2B2dbt0eyalG3W4/U0uZvLIgrTu7CUCqgWWo2Mrbg/snnBYoBjm81673btsedkSQgRlypx9UnuR5e9jSnPV6/fW/8gdBoWMPOwt9JQH+K+Hwj9yG3dmZW84eBPpQNDOwMKEM+zMPNoEGalfMNNjd3/2Oa7nvdjcTgNp4zciv3s2xhfpahBqo3dSNHZfetmGPLb0rAE0w3VK82+tnXu6HzaA3BKARpnrzLIbaiaU30DDFZp7tTLTHjsvv2NQi1ECDhLMPCl/ivYkOqNg469jGWbNafg/tVth2fxjK8kYAkthWePjR6zyeOLt/Lnkp/rN3c2D/6lTTKcoyXDmvy4fnWwHMRlKw7/bVfU2usKY/sTAXAjA3SUtxd7uvnsTQKex/ztuXAjB3ld88s7be02RjrdjSOaEGFqdyY1tbHyuRBfrClt17ArBQlRr7rq0zJSDUQH0qBXuCth6sSYcCUIs3gx2v2FJaWw+trXceztQALNabwXattLa2UJ8xmwbq9eoce4K59WAzz9oCUKtXG9s5n9bWZTgRgNq92Ni0NbC8XmvsjpK4QgAa4cVgp464gkqW4UBDPHvyLP1AijvfyrOBADTCs41tbX2gBNbWFwLQGE+CfXcgJeVij4LLMIFmeRJs985/VQIbcdHWQMM8Gncx4gJWw6PG5kAKsBrGwb7u9dcVRt+ImfLXhQA0znjctXb7TR6ZKmPEBTTVuLE5kAKsjlGwUw+klCFccWkm0FyjYFtbJ424nNO0nysOYI4cIy5g9XhGXMDq8YkjrsHWp/a5ADRa3GNn1X/d8aH/wBKIwa78aaI24joTgMbzqnwRRzyQwogLWAY++NHo6q3WHnIgBVgePrZwUDjSy+GOXwBwRFsDy2N82WacZ9uPbzbT3tbtG2oW9HARpFNCDQBAzf4Bl2T9C8hdFVgAAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARISURBVHgB7d1dUttIEMDxnik2Yd+4wYobxLVbtbAvMSfY3CBwAuAEwAliThD7BElOEL1BKpXyESxu4FdShSY9RpjRh4UNyBKV/6/KFVmjmKd2a6Z7LCMturiYRLIhkViJnMiW0ZcAWFWiMRTv9raTuxNG1sgHsnsl76w1b/VtXwhk4BmZwWtJz3q97emGNGw8nmz9FHvoxPX1bX+t3yTAb8UdXYt5owd7jcVZENBHQmYG1sal7uDZA5uABloXP2tgX3yf9M2G+aiH0YJLkuzVFwCNsfJMvo2vTjSov0o5qKeavc9uxPV2etG2Hh8IgEY9OWP7W2+dsPss/a4w5AP6fFNk4FfpwoHLcTKRxVkdwNPET1oVvxhPop9iPunhm+IH+8wc1tW82fz7Rvpas54KgEbo4tno0RnbB7WR8q23v+3WgD7NXatzb/vK/q9/cF9YUAOaFOuUd+9RGft25dt8cuWg9ll6GF53bewHcW5fg1oANMmdvxY59UePCmwtZ51oEBduv91xGNQ+S1/723TnHsrQ/rY81un+1KXplQBYiZ/a/mllGK5lrRzYl+Oruxp18MGz2+9BeI2eHdR8TJym7oux8rk4DwfwdCvNsbN59aRwenZPf/fGl7000E8XfEScfQnEAqAxK2Vsc7sCnhPWpf3t94Kgnhq97t/e9mcB0LilA1uz9b4UylpZ9k2ycZ/NP1b811npa4dbbmBtlg5sDdqTwqlEX8P5uLEnulAWhRdoQI808PcFwFotFdhZto7yZ02824uSbDzyJa1wlKAG2rNUr3hFttbATc/m4z5b5yWbIkcCoBUPZmy/ICblbD2syda+R3yv2B8OYH0ezNhmY7Vs7Td+UJsG2lVbx66uW5vhTu+vg2A87BdP/NZMAdCq2oxdMXf22XoUvO1LcJvuUncmAFq3MGMv02VW2FdNtgY6oi5j94sn0ht3fndcLoGZWAB0wsLArmpI+e+f+5bQ4ni4oAagXZXlrqqGlHD+XJGt5+UvAO2rzNiajQ8Lp5Ldv+/3Wldk65EA6IxSYGcNKfnNHmG2LjesxGzDBLqlFNjmD/u+cCqfrQsNK/6H0wRAp+QCe9Fmjtx4frU8F/QAuiEX2FUNKVLcmhmgIQXopvmq+NfxZEuzdT8/XLvZw38txAKgc+YZe/P2SR5ROFi/NdMHPZs9gC6aZ+xyQ0p9tqYhBeiuWcaubEgJAteKzdW1U+e+kK2B7pplbM3WhRLXfbb2c29dGc89cM8YGQiAzrIVJaxcJ1nF3DuhIQXoNltR4sp1kpXaRylxAZ1niyWusJOsYu5NQwrwAvjFsyg8UbfZQ8/wJA/gBfCBnfs1Uf/sreDfKBzTufe5AOg8c/ljMtCZ9uESl85/xBBAt1lnZ6Wrh34DfEpDCvByWN9oonXqY1kc3P4BAMc0pAAvx/xXSrN69qkumL2V27m1BrobOZEBQQ0AQMt+AUEs1E3OOl5QAAAAAElFTkSuQmCC',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ5SURBVHgB7d3dcdtGFIbhsxslTu7UQcAOxHFmIuXGVAVxKrBUgewKJFYQqgLTFcSuQLiTnUyG6YBQB7xKxvEY612KhBfggqAs4kfm+8xoRACk7j6d/TkAlbTo+noayZ5EoiUyIvvK/giAu0pshuKjfi9ZnlDSIBdk85081Vo9sYcDIcjAFqnRI0mH/X5vtic1m0ym+/+LPjNiBvZw0Oh/EmCnmOfvRR3YF8e15cwL9HOhMgONMak53XqwNwz0TNy8QNQ/Jk1vZMuUVs/sr0iA3RRvNdjXf00Hak+9lHCo4jQ1b5SW1/4kvw7Xk2mkRE0F2FFbm2O/m9yc2yp9sXpFjY2kr2yYYwHQiHsH2w297YT9pQ3108Kl2J47PepHSegz/36QSH8jB3Vsc9lq/UyA3XW/obgb8mpRf9hwHnin7fzZDA/7vZH/Xhfm/1I5sVtdv8rt+1lQA2pwr8WzxTz2SvLz6cRW6WN/Ds3qONCo+LAfHWv5Ai6srlJLRajfTm7O7DB9uph7E2qgVubykZjf3KsvmmPbCuwWyvzhdy7Uiyp9vqjSVdzWV2xnxrM6tr6Ar52dCs9+0DJ2HWfLc3ceirsqbP+UP38OhFpdFebdRY1tfQG76E7BDu0Pu4n60ePe2L3eINRupXzI1hdQrzsNxdXtvNo/Mz56HI2XR++V/l2MCYV6puzW18/93msBULuNF89stT6RwraWkXS4PJgP0Y05CXzUVek+oQaas3HFttX6vHBmvGw+cUN0CXSd2UC7jrMTAdCojSr2olpH/jlbrS+Xr5XSLvT7+euEGmjLRhW7slqvDsGT70U22eoCUIPKiu3u2JLVap3NrRfV2jdz21/+nhqAZlUGW+0Vq/XnZytd2e0tW60H/kUb6kv2poF2rQ327aLY/NlkmfSjyebWdrjt7uiKvMuJDfWFAGjV2mAHhtnJLz993rYqzr1NaoYCoHWlwQ4tivnBDayUJ8sONADtWlexB4XjXHBXH2agYgHQCaXBDmxxZUPwPydT14E28K/6K+UA2hUMdlVDSqr0Wf4Tbl+blXCgK4INKrZaB4Jb3pDiHlYoADpjpWIvGlJyd2hVNKTE3IYJdMtKsNW3uviEz/UNKamhWgMdkwt2aJhd2ZDCFhfQOblg05ACfB2yYJcMs9c1pLhPxwKgc7JV8aphdqAhZRz6lg8A7csqdqAhJV6+Ct0MQkMK0F3zYJc0pJRucaXGvKEhBeiu+VB83TA7tFKulIwEQGfpqmF2aKWchhSg23RJJ1mSHa1ZKQfQTXpdJxn3XAMPk1s8i/wThS2u0ls3AXSXC3buaaLvJjfn3u/Iv+bfugmgu9Tbv6cjO9M+2+Ct48P+j6cCoPO00fOtq6pngM9oSAEeDu1WwI2YF1IebvcFAC9oSAEejuz7sRf72Rd2weyJ3M6tbdDNKyMyItQAALTsEy8g50K2C5wcAAAAAElFTkSuQmCC',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQ5SURBVHgB7d3dcdtGFIbhsxslTu7UQcAOxHFmIuXGVAVxKrBUgewKJFYQqgLTFcSuQLiTnUyG6YBQB7xKxvEY612KhBfggqAs4kfm+8xoRACk7j6d/TkAlbTo+noayZ5EoiUyIvvK/giAu0pshuKjfi9ZnlDSIBdk85081Vo9sYcDIcjAFqnRI0mH/X5vtic1m0ym+/+LPjNiBvZw0Oh/EmCnmOfvRR3YF8e15cwL9HOhMgONMak53XqwNwz0TNy8QNQ/Jk1vZMuUVs/sr0iA3RRvNdjXf00Hak+9lHCo4jQ1b5SW1/4kvw7Xk2mkRE0F2FFbm2O/m9yc2yp9sXpFjY2kr2yYYwHQiHsH2w297YT9pQ3108Kl2J47PepHSegz/36QSH8jB3Vsc9lq/UyA3XW/obgb8mpRf9hwHnin7fzZDA/7vZH/Xhfm/1I5sVtdv8rt+1lQA2pwr8WzxTz2SvLz6cRW6WN/Ds3qONCo+LAfHWv5Ai6srlJLRajfTm7O7DB9uph7E2qgVubykZjf3KsvmmPbCuwWyvzhdy7Uiyp9vqjSVdzWV2xnxrM6tr6Ar52dCs9+0DJ2HWfLc3ceirsqbP+UP38OhFpdFebdRY1tfQG76E7BDu0Pu4n60ePe2L3eINRupXzI1hdQrzsNxdXtvNo/Mz56HI2XR++V/l2MCYV6puzW18/93msBULuNF89stT6RwraWkXS4PJgP0Y05CXzUVek+oQaas3HFttX6vHBmvGw+cUN0CXSd2UC7jrMTAdCojSr2olpH/jlbrS+Xr5XSLvT7+euEGmjLRhW7slqvDsGT70U22eoCUIPKiu3u2JLVap3NrRfV2jdz21/+nhqAZlUGW+0Vq/XnZytd2e0tW60H/kUb6kv2poF2rQ327aLY/NlkmfSjyebWdrjt7uiKvMuJDfWFAGjV2mAHhtnJLz993rYqzr1NaoYCoHWlwQ4tivnBDayUJ8sONADtWlexB4XjXHBXH2agYgHQCaXBDmxxZUPwPydT14E28K/6K+UA2hUMdlVDSqr0Wf4Tbl+blXCgK4INKrZaB4Jb3pDiHlYoADpjpWIvGlJyd2hVNKTE3IYJdMtKsNW3uviEz/UNKamhWgMdkwt2aJhd2ZDCFhfQOblg05ACfB2yYJcMs9c1pLhPxwKgc7JV8aphdqAhZRz6lg8A7csqdqAhJV6+Ct0MQkMK0F3zYJc0pJRucaXGvKEhBeiu+VB83TA7tFKulIwEQGfpqmF2aKWchhSg23RJJ1mSHa1ZKQfQTXpdJxn3XAMPk1s8i/wThS2u0ls3AXSXC3buaaLvJjfn3u/Iv+bfugmgu9Tbv6cjO9M+2+Ct48P+j6cCoPO00fOtq6pngM9oSAEeDu1WwI2YF1IebvcFAC9oSAEejuz7sRf72Rd2weyJ3M6tbdDNKyMyItQAALTsEy8g50K2C5wcAAAAAElFTkSuQmCC',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAROSURBVHgB7d1vUttGGMfxZzekSd9xg8o3QNPOFNoXETfIDWJOAJwAuIE5QcwJkpwg6osOaTsd5wY2N3BeZZIZtNk1trJaCdnGtiwm388MY1lreMXPz/6TpGSLrq+HkexIJFoiI7Kr7I8AWNbIZig9iDuj2QklDXJBNj/JS63VC/s2EYIMrJHqPZPsIo474x3ZsMFguPtV9LERk9i3SaPfJMAPxZx8EbVnDw43ljMv0CdCZQYaYzJztPZgLxjosbhxgaiPItknk03ew1JavbIvkQAPl6412Nf/DRO1o15L9T9masP+l3u1g/xUUOl6MIyUqKEAK1jbGPufwc2ZDe55RZML9AVhBpqzcrBd19sO2F/b8L4MmmoD/fe/wz39RPZY5iqy1fqVAKtZrSvuuo1a1Bsbzr1iizndjzs9/4z7Avh8K4l+MvnHTYQwAxux0uTZdCz4Xorj6ZGt0kd+lWZ2HGhUuh9Hhw/qit+FdVKpI++0C/Whv/vFTaa5brr9DokEwIaZy2ci5+7oQcG2FdhNlPnd71KoaybTQm6pK7Wjy7HJshsBsBRbYMc/a+m7HWezc0t3xT8Mbo7tnyqMn2/FxH/GnY/eZ1yV7tb8mTTLzDul5a3/ZQBgPZYKdtUa63Tm+3z2fk6oWfoCGrBUV9yG+k1wauSHetr97lb86ljZSbXf485bAbBxetEP2mrdlWBZy42r/faaDSoxoQaas3DFttX6LDjTP4ijkTuablI5C3/HBvrKVvSuAGjUQhV7Wq0j/5yR7GJ27Napy+2EGtiWhYJdrtbFuzVUjKtHz0VOBMBWzA2222QiQTXObs1l3l6u5mM39vbX1AA0a26w1U6pWo/++O37RFhYzW2oL1mbBrarNthu3VruLtjImcxceO1dCbaV+stfALajNthK6VK1Pvi108/bg0sM/dAD2J57gz2p1qY4KeZmuvP2u7F34jUXQg9ge+oqdlJxrj87UE91cEMAlQqAVrh3g0rdhpTqap7RDQdaorJiz9uQUh57u9AzEw60RWXFttX6ODgTVuvC/c1s6K8EQGuUKvZ0Uiy42KMQXNfu3+KI2wkDLVMKdnlSbLI2nebt4YaUzFCtgZYpBLtyUmzehhSWuIDWKQR7gQ0pYbVmJhxooTzY7wfDXVutE7+xUK0rLgZxz+QVAK2TB/u5iJvpjry2kR9c/bRqppwlLqCN8uWuig0pqb/EZYyES1x0w4GWmlTsZTekZMa8o1oD7TWp2OUHwdVvH1VKegKgtXTVNde3kuV3SNFSv64NoH10xRJX6j/VI7yfGUtcQPvpiiWu79dcsyEFeJTc5Fnkn6jbkGLPcNN/4BFwwS7cTdQ9psd7jfw24429AbSX+vD/sGdH2scLfLS/H/9yJABaTxs9Wbqadw/wMRtSgMdDu40mdub7VO4Pt3sAwCkbUoDHI38+9nQ9+9xOmL2Qu7G1Dbq5MiI9Qg0AwJZ9A8pU4BvIMu6nAAAAAElFTkSuQmCC',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARkSURBVHgB7d3PcdtGFMfxt2sldm7qwFAH4vgQa3IwVUGcCiJVIKkCSRVEqiBUBbErMG5ykslQHRDqgKdkkkyweUuC9AJc/rNEAKK/nxmPCYCUDvaPb/ftgjTSoNvbQSI7koiVxInsGv0jANaVaYbSg85eNjlhpEY+yO5reWuteaOHXSHIwCMyV88lv+x09oY7smH9/mD3H7EnTlxXD7u1vpMAXxR3+reYfX1wuLGcBYE+FSozUBuXu+NHD/aKgR6KnxeIuXN5fi+oh/YyjJgfBdsufdSh+O3vg64OBX7W94wk9svy3L03Vt6Fk3zU67Y/EMK99bqPFuxf+/fnWqUvZq+Y3n+SX3/X2bsTNM4Yq/9MTrDdHjwU90PvcZWWt5VLqf4POo5VZ/+aP/+VxD6TfZa56mOe2Zca6iPBtksfFGwd1iVWzC8azv3g9FADfaaB7oXP9WH+K5cjXer6XsbPJ8zABjyoeeZDrXO1D/owCU5nGurDsErTHQdqlb7uJIdWPoMPq6/UEoRa3yHuqqH+2L8/0WH6oJh7E2pgo9z1c3E/+Eef1TzTCuwbZeHwO8v1B05CPZ5325/0Fx2t8OP80leqbw1Dlr6A9elUePiNlZ7fcTY5t/ZQ3Fdh/VFXwanS8HvOvLuKpS9gg9YKdjGvHoTn/ET94NW4UVZ0yPtSnneHfKf8UsOcCoCNWWsobsbz6vBM7+BV0pscFcteSeSlQ6NLX9929t4JgI1buXmm1fpIysNrHYLnl5MDv0FFZteyPV+lO4QaqM/KFVur9Xl4rGG9CefVsV1nxXOOBECtVqrYRbVOKqd7kwfG2PPqawg10JyVKna1Wo/m1p0k8498tY5sU8xeiJwKgEYsrdj+ji2pVOtwbh2p1n5L6WG4pgagXkuDbXbK1Tp37n04t9ZqXWqYaaivWZsGmrUw2KPgjj+bbMoYCTen+GvhVtFMQ30hABq1MNiRYXYWbi6Z6ZTn7lIANG5usGNNsTC4kU55NtmBBqBZiyp2t3JcCq5W65PyZZMKgFaYG+zZJS53PXlUdMpLN3mEnXIAzYoGO7YhxYlMt4Sar2zlw/D8ujadcKAtohtUIsPshRtStFrfCIDWmKnYy4bZkU55ym2YQLvMBHt2mP3py76Kat0NL2qnnGoNtEwp2PFhdmltuisscQGtVwo2G1KA7TAN9of+YDcyzF60IcW/OhUArTPtir8Yf/pJElyrbkiJLHGNO+UA2mVasSP3XE/XrX/rD3yXvBteZUMK0F6jYMc3pOTTnWa5saV17fDWTQDtMxqKLxpmxzrllVs3AbSMjd1zvWRDSsaGFKDd7JydZNn0aEGnHEA72UU7ybjnGniafPMsCU9UlrjmdsoBtJcPdunTRItv9Jj8nYTXwk45gPYyH/8YXOlM+2SFp/Zed14eC4DWs86Olq6WfQb4kA0pwNNhfQfciTuT+eH2XwBwxoYU4OmYfj92sZ59oQ2zNzKeW2vQ3Y0TuSLUAAA07H/vIeXuEgMDSwAAAABJRU5ErkJggg==',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARmSURBVHgB7d1NUhs5FMDxJxUhmR03mPYN6JrFwCrmBMOcADgBcALgBGNOEHyCkBOkN1MkMzXluYHNDZxlUhUrT/5C3e5u28F2t5P/r4rCLZlsUs9P0pNkIxV6eOhGsiORWImcyJ7RHwGwrJ7GUHIYN3qTBiMb5APZ7cqxtea1PjaFQAZWyLReyuAmjhv9HVmzTqe790XsuRPX1MfmRj9JgJ+Ku/gsZl9fHK0tzoKAvhAyM7AxbuDOVh7YSwT0/zp00J/BJzeQvmDrGWtO9FckqFqy0sB++LfbNDvmjeT/52rwurYukt2/0qD28wDBD+Wh042MmK6gciubY3/sPF5plr7O6dJPD3f7e9y4FwAb8ezA9kNvnbC/0aA+znT1dax/efhb4y7v7/wKuX0h+1+NRJS5fgyarU8EdfC8obgfelkxb3V4vR+2a5C3dbh9kR1u+6G63bV/aMCfCsEMrMWzFs/G86n3MjOfdpcHcaOVeu9o7n0lo9o1gPVJDuLo6LuG4qOV72GmjsJ2zdRnh/HT0Hsc/H4xrSkA1szdvhS59q++K7C1nOUXyrLD71RQ62Laibb5zD1vyO2H64nO0HROPngUAEvRBNv/xcpdOPVdOrA/dB4nNergH3Y3maAuWiGfSAYD985YuQ/3twJYjaXm2AV1ynsd0/85eZgT1Mn4QyARAGuzVMbWoH6baeppoF5OHj52uscFQd3XWvYZtWxgM+yib9RsfSqZspafrE+G0j6bOzF/5fypz9IxQQ1szsIZW7P1VaapF5a1jLFX4lwUvsHXszXwTwXARi2UscfZOgrbtAh+M3n99z/dfQ3q01Q/QQ1UZqHAzsvW/saGycPO7my/33kmACoxdyjud43JzO4ykxzGUW/Y7+fWTsJ94n3N1kec3gKqMzdjj7eCpjgZTIfhw7l1qu9pQQ1ANUrr2Pl1a3N3EP96VtCvC2pRQwBUqjRjZ7OxV5qtgwU1ANUpzNgLZuvwdBfZGqiJsozdzDZotm5n+qOnR5MIgFooDOycElcS7vHO9odDdADVyi13FWxIaRf3m7tJ+QtA9XIztmbj80xTL7y7LCdbtwVAbcwE9nhDSvoShWC1OyebJxzDBOplJrDNC5u9aTKbrVP94RAdQD2kAtuXsGYOc4TZepTNm0F3r+h6YQDVSQV2zoaU1GGPbDZnQwpQT9NV8fed7p5m62a6O33YI5vNw6AHUB/TjP1Khie0orCzbPvoqMTFYQ+gjqYZe3ZDylNtOnfuzYYUoLaGGTt3Q0pJth44945sDdTXMGPPfpnaTLZupnqNtARAbdlh4GYOfJQf9tASFxtSgFqzOSWu8sMelLiA2rPZYXb5YQ82pADbwC+eRWFD2WEPbeHSf2AL+MBO3Sbqv3sr+B2FfTr3vhUAtWc+/Ndt6Uz7fIG3Tq9FAlBv1tlh6WreHeB9NqQA28P6jSbjb8wsCm7/BQCXbEgBtsf0ltJxPftaF8xey2hurYHu2k6kRVADAFCxbytm2YF2vqNcAAAAAElFTkSuQmCC',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARNSURBVHgB7d3bcdtGFMbxs2vFdt7UgcEOzMlDpMmDqQ6cCkJVIKkCSx1QFYSqwFIFxlPkTJKhOiDVAfOYzBjrsyCJWVwIghYv0Pj/m/GYwFLUiz6evQEwskf39+NIDiQSK5ETOTT6TwCsa6IZio+7ncnihJEd8kF2L+W9teadHvaEIAMbZAavJLnqdjvTA9my0Wh8+L/YMyeup4e9nX6TAN8Vd/6fmLf64mRrOQsCfS5UZmBnXOJONx7shoGeih8XiHkQSf51SXoMLKfzMEbMb4Im4o12xe//Gve0K/C7fmdExTb9BnlIxN35X6qD/FiANd2PxkK4G+ltLNh/jh4/aJW+rGiK9fzVEWHGExlj9U/JCVZ7clfcd71nVVreFz7YV+iLZdU5Xep6KT2WudCEeWHfaKj7gibiJwVbu0aRFfNRw/k23+IutEIPSu/Xrro5MGfCUhewNU+aPPOh1vHOJ30ZBaen2lf6NazSzI4DOxUfdaOTbxpjz8KaVuooOD3R8J6Eu1/qJtMAbJq7fiVy6V99U7C1AvuJsrD7XQp1zWRakV/qinUUNXVJ8igA1qIFdvqjlaHfcbY4t3ZX/PPoUcfIbpD/4DTUcfAeX6X7NR8TJ4m7M1Zuwy8DAJuxVrDn4+pxeM4vZWk4LxfHK0Idz98fC4CtWasrrqH+WDg1CUM97373K350asSd/tzt3AqArbNN36jVui+FZS3fBc/adaKsZoNKl1ADu9O4Ymu1/lA4MzzuRpPs6CDdpJKjgb7Rit4XADvVqGLPq3UUnnOS3NS3E2pgXxoFu1yt8xdyVLRPXoucC4C9WBlsP3aWYjVOXF219rvPTsI1NQC7tTLYOnYuVePjnzrDrL1QrTXU16xNA/tVG2y/bi2zCzYyWq2vFq//+Hvsr+iKgubc8heA/agNtjG2tlrbF+mVWpkw9AD2Z2mw02pduv7V3Oba89U8F3oA+1NXsXvFE7rEdb14Xa7mJhYArbB0g0rdhpSqaq6hpxsOtERlxV6yISULbkW1HjITDrRHZbC1WucmxRLn7hbBnVfrXtge7kIDsH+lYM83pOQu9jBGwuuvfXsUHHM7YaBlSsE2P9jifZsnddtHw11oANohF+zKSbFgbbpi7M0SF9BCuWCv2pBSHHuzIQVopyzYn0bjw9KkWFitK8be/pm8AqB1smC/nj3JI1ocG3/30CC45bE3S1xAW2UbVMpXaZlbNqQAz1NasdfdkBKuawNon7Rilx9NWr99tLCuDaBlbNU111+Ciz2s1K9rA2gfW7HEFf/S7TwsDor3CWeJC2g/W7HEVXc/MzakAM+AnzyLwhN19zMLb7QAoL18sHN3E/WP6Qn+j8K28EYLANrLfP5nPNCR9lmDtw6Pum9OBUDrWWfTpatV9wCfsiEFeD6s32iiM98Xsjzc/gEAF2xIAZ6P7PnY8/XsS50weyezsbUG3d04kQGhBgBgz74Cbxfg2aQl7WgAAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARrSURBVHgB7d1dUts6FAfwI93cS/vGDq6yAzz34dInwg56V9CwAugKgB0kKyisAFhB/XIHOp2O2UHMDvLaztTqkXGMJMv5ABIL+v/NMCSWCTPMHI6kI8mCOnR9PVHUI0WSlCbaFvxFALCqnGMofZf089kFQRtkAln/Re+lFHv8dkAIZIBnJEZbVJwmSX/aozXLssn2D5KHmvSA3w42+p8E4Leij76T2OEX+2uLMyugjwiZGWBjdKEPnj2wVwjonL9uufsw1UVxRwAdElJ84G+KXof0WQP7+utkIHriE4X/QFP+810WVFy95V9sxgEEEInrbKIEiQm9Es82xv6S3R1zlj4JNE35+vgN0ShJFIIZYAOeHNim680D9k8cvO+9Jiugm9m57LL/pEEhyu66IoAOcbb+QK/H07ripvsiSVxwDXrHvs4feluQ/s+uq5X3c1dd9uQeB/yQEMwAa/GkybNqTPKZGgGqx7tJ/8i5937sfUz3tWsAWJ90N1H7j+qK3898l5la2dc5E59ylj5x75PH1Qw5AKyVHm8RnZhXjwrsKlid7rcf1P9nkx0ee19wi1rwcWb8naLsBfA4nGCnbyWd2XNZK3fFb7K7Q/6okXtVnO0mfx/M3lVdbw7q1jp2WhT6Ski69MfhAPB0KwV2S60v3yKdzP5bmEz9B4ms5SPSKrOnBABrs1JXXJRdaxcH6sEsqKvAvwj86FTwff8m/UsCgLWTy97IQTskr6xluuB29g3PkpdZOkFQA2zO0hmbg/bYv6apOJ29rlaeKbddn3PgDwkANmqpwK6ytXKvmmyt8qpdVYtOaghqgO4s1RVflK0lSX9nTP6GCLVrgI4szNimdEVzsvXnbLLtZWuzRnwfu7cAurMwY1dLQR2crc9nrzkzm80f6qFNj1GbBujW3Dp2S926XIs6e3OT5aZdVW9zbusTAHRqbsYWQjazdaHrbO1PqnHbKQFA51ozdtsqMzsjI1sDxGlexh74F+yM3CyBiZQAIAqtgR0oceXv/umftbXb5S8A6Faw3BVakOJk60YJ7KH8BQDdC2ZszsaH3iU3W/ca2fqcACAajcCusrG32UOP63aeVCN3/J1iGyZAXBqBLf6U/mmNuSaqd2b5JTC7/AUAcXDKXeES18PpKIF2lLgAIuRk7OCCFGu2O5CtMRMOEKF6Vtxs5iBdPhHT4m7N5Pah0yzNIYQAEJs6Y/ubOYx52bo6PSUnAIhOnbGbC1Ia2dp5hA8WpADEq8zYwQUpzYMU6qOEC62vkK0B4lUGduCBZKkduP6xR0LQiAAgWjKw4KR8qsfsdSCb51iQAhA3GShx3XpHCqPEBfDCSL/ExYFrLx8dkp+trTXjABAnM8ZW9oV5WzP5Cg79B3gBTGA7p4mag/+t78pu45nyMQFA9MTNt8mIR9qHS9zqPFETAOIltSxLV4vOAJ9iQQrAyyFNvZrLWx+pPbjNAwA+YkEKwMtRb9us6tknPGG2R/djaw50fa6JRghqAACAjv0CEALZrQvFLWwAAAAASUVORK5CYII=',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARLSURBVHgB7d1bbttGFMbxM4zTpG/eQegdiGiB2O1D5B2kK6i9AjsrkL2CSiuovYI4KwhfCicoCnkHknegxyZIODmjm4cX3WyJpOz/DxAiUrKAPHw6M3OGlJEKXV/3QtmRUAIJrciu0YcAWFVfMxQfRHv9yQkjJXJBtj/J2yAwb/SwKQQZWCPTfiHJeRTtDXZkw7rd3u5XCU6s2KYeNkv9JgGeFHv6RUxDnxxuLGdeoE+FygyUxib2eO3BXjLQfX3c6NBhYJPkVoDHTNeQjJg/pTzxWofi1//2mjoU+Fu/M8LMSwN93GjYL1+KXLk5gABPyHW3JyWGu7m2YH/u3rY0uGeZ0wM919EwtwkznjJjAo2ClbI8eCjuht6jKi1v/fP6vzifFWj3N/9/k4YbouhhKMAjZp4FrzTUR1Ke+EHB1uFFGIh5r99DDe90/7vYP36P9m7892ZXxwXARjxo8cyFWucMH8WruOM59Klfpf/p9hrPxPwlhBkoQ7wfhYf3mmOPqu+wUod3Z23nINo7Tb8naI1XxwFsnO28EDlzz+4V7HFgG/4H7nuhdtX8y7Ca51bHi7jqHtP6Au5HC+zg50Au/JHyykPxT93bE/2otvcBN6+jMJocj4fe72X+olicJPaDCeTK398KYD1WqtiuEvuhVv1EF8r818fz7lkbU2K3Wq5hjgXAxqwUbDOqxFO6+nZ+8Muo4i4I9cCIPX4d7V0JgI0Lln2jBvdI0m2tWEN9MTnQBnxLioffrkpHhBooz9IVW6txyz92Q+rJ82HoCxrwrv2lw+4jAVCqpSr2uFqH3qnYnydnQ+8QaqA6SwU7V60Tezl5XhB6p+82qgiASiwMtrtiS9LB7afm1vlq7S78OOSiD6A6C4NtdnLV+m5unQ+9G4J36E0D1Zob7FHfOrPHO3C7xEbM8yB7fWlfQ30mACo1N9jjFpZ/5mJSjYehz6yE+9UcQHVmBrswuJJMg5sPfXruDaA68yp2M3Ocum+xhj7zuokFQC3MDPaqLS6/mgOoVuHOs4LgLmhxubl32BcAtVBYsTW4J/7x4hZXcikAaiMX7HFwG5l3xdOnz9Ohl8z2UgDVywU735tOt7iszdyN1Jt7A6iHVLBpcQGPQyrYBcGdtrg+dnu72RYXG1KAepoGe0Zwp8Psl6MfBAgzfx0LgNqZtrsKgkuLC9hS04pdsCFlUYuLYThQU8NgF94sYU6LK7H2A5dmAvU1HIrnf97zbphd1OIyRtoCoLaComuuF7a42JAC1Fowr8U17mtnN6QwtwZqLsi2uJLvtuMdutf8HwBgQwqwBdziWeif+O3Xuxv7F7S4uOk/sAVcsFN3E/3cvW15/4b+azr37giA2jOf/uu1daZ9ssRbL/ajV8cCoPYCGwxbV4vuAT5gQwqwPQK3Am7FvpPZ4XY/APCODSnA9pj+8P24n32mC2ZvZDS31qDbSyvSJtQAAFTsBwWi28SJVKWzAAAAAElFTkSuQmCC',
    'iVBORw0KGgoAAAANSUhEUgAAAPYAAAAvCAYAAADQIzcvAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARESURBVHgB7d1NUhtHFMDx121iyI4bZHQDq7IIrCxuwA2AE4BPAJzA4gSIE9g+gWcVSCUp+QYabqBVqpxUqfNaaJRWq/WJxAyV/69KxUy3jFeP192ve8ZIhe7ve5nsSCZWMieyb/QjAFZVaAzlh81GUTYYeUE+kN1bObbWvNfblhDIwAaZ9q4MrpvNRn9Htqzb7e3/LfbciWvpbetF/5IA/yvu4ruYd3pxtLU4CwL6QsjMwItxA3e28cBeIaAL/XzT4UPfDQaPAmAtxpoT/ZEFTflGA/v+917L7Jjb6D8J5YOB+2KsfA4n+gDWd9/tZUZML2zb2Bz7t+7jpWbpq0RXX9tv9kTaflIvALbu2YHth946Yb/V4D2e7nU3uyJXqYD2/+6vfySzb8RP9jMBsBbN1idR0/OG4n4IYMV80hr0u6ir0EA/0+F2HjbGK+QCYOOetXg2Gtd/lSjb6i/89lbcUZilh9819lKcOxUA25QfNLOjtYbiT5l3mKmzsF0z8d1Bs3Eato3m3hca1JS8gK16mvr6q7UCW4fTPljj4bdf6T4tb0YZ/XY07J7HZ/acshewHk2w/R+tdMJR8sqB/dB9LGvUoWJX59TlzaxheoCyF7BFK82xU/UyeSpnNcsAXRDUuX73Ol5UA7BZK2VsDdhP063DQC381aj0lQrqvtGM/kuz8VkAbJ1d9ouaiU8lUdbSxbJ2efPd2I8yHdQ+SzcJauDlLJ2xNVtfxm1aL7sur3/9o3ccl7P8KvlhtEoOYPuWytijbJ1FzcXhz43O+Be9MR/DToIaqM5Sgb0oWycCv9gTuRAAlVgY2P7ElizI1lHg+1XyIw58ANVZGNhmZ0G2jgLfn+SiNg1Ua24de0bdWlfCs0Z589AtfHmrleoDUI25GXt4cCOSyNatVB+A6swMbJ+tE6exJufWP9iTWX0AqjMvY7fiBl/CKq+nA9/kAqAWZgZ2osRV6Kcz7o+G6U4GDMOBmkjuPEtvSDH5YTMrRv1xtu6UfQCql8zYmq3P47YwIyey9Z0AqI2pjD1a6Y4Oe/yXkROLajnHMIF6mcrY0Ur30NxsPXBka6BmJjaopDekmM5B86ezGf1sSAFqaCJjJzekzM/WrIQDNTSeY3/t9vZ17tya7J6aW0/2W/8QQgB1M87YeyL+TR5Z2Blmays2evGXD3oOewB1NM7Y0xtSJmvTTuKno7AhBairYcZObUgJa9Nx/8C5L2RroL6GgZ16qVdYm46zuTHSFgC1ZYeLYtGBD//s7/I69dgjNqQA9WYTJa5iXramxAXUn41LWIseUsiZa6D+/Bw7CxvmPKTQt/DQf+AV8IE98TRR/9rb4GcW9ulK+Y0AqD3z8GevrTPt8yW+Ot4zDqDerLPD0tWiZ4D32ZACvB7WbzTR8tYHmR3c/gUAH9iQArwe42Obo3r2lS6YvZenubUGurtzIm2CGgCAiv0LuK/eHVmwjJwAAAAASUVORK5CYII=',
  ],
};

export const projectImportStates = [
  {
    state: 'Upload project',
    increment: 6,
  },
  {
    state: 'Project summary',
    increment: 36,
  },
  {
    state: 'User mapping',
    increment: 66,
  },
  {
    state: 'Confirmation',
    increment: 95,
  },
];
