import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state() {
    return {
      isMobileDevice: false,
      resetDateRange: false,
      pendingData: [],
      flags: [
        { id: null, color: 'var(--bg-grey-300)' },
        { id: 1, color: 'var(--risk-low)' },
        { id: 2, color: 'var(--risk-medium)' },
        { id: 3, color: 'var(--risk-high)' },
      ],
      risks: [
        { key: 1, color: 'var(--risk-high)', title: 'High Risk' },
        { key: 2, color: 'var(--risk-medium)', title: 'Medium Risk' },
        { key: 3, color: 'var(--risk-low)', title: 'Low Risk' },
      ],
      statuses: [
        {
          text: 'To Do',
          id: 1,
          key: 1,
          icon: 'fas fa-clipboard-list',
          hide: 'hideTodo',
        },
        {
          text: 'In Progress',
          id: 2,
          key: 2,
          icon: 'fas fa-spinner',
          hide: 'hideProgress',
        },
        {
          text: 'Feedback',
          id: 3,
          key: 3,
          icon: 'far fa-comment-dots',
          hide: 'hideFeedback',
        },
        {
          text: 'Blocked',
          id: 4,
          key: 4,
          icon: 'far fa-stop-circle',
          hide: 'hideBlocked',
        },
        {
          text: 'Done',
          id: 5,
          key: 5,
          icon: 'far fa-check-circle',
          hide: 'hideDone',
        },
      ],
      inquirySubjects: [
        {
          id: 1,
          key: 1,
          inquiry: 'General Inquiry',
        },
        {
          id: 2,
          key: 2,
          inquiry: 'Account Renewal',
        },
        {
          id: 3,
          key: 3,
          inquiry: 'Incorrect Billing',
        },
        {
          id: 4,
          key: 4,
          inquiry: 'Refund',
        },
      ],
      portfolioTags: [],
    };
  },
  actions,
  getters,
  mutations,
};
