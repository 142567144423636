export default {
  SET_VIEW_STATE: (state, payload) => {
    return (state.view = payload);
  },

  SET_LAST_VISITED_URL: (state, payload) => {
    return (state.lastVisitedUrl = payload);
  },

  SET_VIEW_BREADCRUMB_STATE: (state, payload) => {
    return (state.viewBreadcrumb = payload);
  },

  SET_GLOBAL_LOADING: (state, payload) => {
    state.globalLoading = payload;
  },

  SET_NEXT_ROUTE: (state, payload) => {
    state.nextRoute = payload;
  },

  SET_TASK_VIEW: state => {
    if (window.screen.width <= 500) {
      state.taskView = true;
    } else {
      state.taskView = !state.taskView;
    }

    localStorage.setItem('teamfu/taskView', state.taskView);
  },

  SET_SIDE_BAR: state => {
    return (state.showSidebar = !state.showSidebar);
  },

  SET_SELECTED_SETTING: (state, payload) => {
    state.settingSelected = payload.toLowerCase();
  },

  LOGOUT_USER: state => {
    (state.view = 'Dashboard'), (state.settingSelected = 'profile');
  },

  SET_NETWORK_ERROR: (state, payload) => {
    return (state.networkError = payload);
  },

  SET_DEFAULT_AVATAR: (state, payload) => {
    state.encodeDefaultImg = payload;
  },

  SET_LOAD_STATE: (state, payload) => {
    return (state.loadPage = payload);
  },

  SET_LOGIN_PLAN: (state, payload) => {
    state.loginPlan = payload;
  },

  SHOW_EXPANDABLE_CALENDAR: (state, payload) => {
    state.showExpandableCalendar = payload;
  },

  CONSOLE_OPENED: state => {
    return (state.openConsole = !state.openConsole);
  },
};
