export default {
  search: state => {
    return state.search;
  },

  filteredSearchedTasks: state => {
    return state.filteredSearchedTasks;
  },

  searchedLists: state => {
    return state.searchedLists;
  },

  isSearching: state => {
    return state.isSearching;
  },

  taskSearching: state => {
    return state.taskSearching;
  },
};
