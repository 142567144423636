import { createStore } from 'vuex';
import UserModule from './modules/users/index';
import AuthModule from './modules/auth/index';
import ProjectModule from './modules/projects';
import ListModule from './modules/lists/index';
import TasksModule from './modules/tasks/index';
import TasksStateModule from './modules/tasksState';
import SearchStateModule from './modules/searchAll/index';
import CategoriesTagsModule from './modules/categoriesTagsMoods/index';
import ViewStateModule from './modules/view-sate';
import CommentsModule from './modules/comments/index';
import WebSocketsModule from './modules/websockets/index';
import ListSocketsModule from './modules/listSockets/index';
import ProjectSocketsModule from './modules/projectSockets/index';
import TaskSocketsModule from './modules/taskSockets/index';
import UserSocketsModule from './modules/userSockets/index';
import CommentsSocketsModule from './modules/commentsSockets/index';
import NotificationsModule from './modules/notifications/index';
import PopupsModule from './modules/popups/index';
import CommonModule from './modules/common';
import keyboadNavigationModule from './modules/keyboadNavigation';
import taskMoveModule from './modules/taskMove';
import TimesheetModule from '@/store/modules/timesheet';
import PermissionsModule from './modules/permissions';
import PlansModule from './modules/plans';
import EpicsModule from './modules/epics';
import MilestonesModule from './modules/milestones';

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common: CommonModule,
    auth: AuthModule,
    users: UserModule,
    plans: PlansModule,
    projects: ProjectModule,
    lists: ListModule,
    tasks: TasksModule,
    tasksState: TasksStateModule,
    searchAll: SearchStateModule,
    categoriesTagsMoods: CategoriesTagsModule,
    viewState: ViewStateModule,
    comments: CommentsModule,
    websockets: WebSocketsModule,
    listSockets: ListSocketsModule,
    projectSockets: ProjectSocketsModule,
    taskSockets: TaskSocketsModule,
    userSockets: UserSocketsModule,
    commentsSockets: CommentsSocketsModule,
    notifications: NotificationsModule,
    popups: PopupsModule,
    keyboadNavigation: keyboadNavigationModule,
    taskMove: taskMoveModule,
    timesheet: TimesheetModule,
    permissions: PermissionsModule,
    epics: EpicsModule,
    milestones: MilestonesModule,
  },
});
