export default {
  notifications: state => {
    return state.notifications;
  },

  notificationIds: state => {
    return state.notificationIds;
  },

  notification_created: state => {
    return state.notification_created;
  },

  notToDel: state => {
    return state.notToDel;
  },

  offset: state => {
    return state.offset;
  },

  unreadNotifications: state => {
    return state.unreadNotifications;
  },

  notificationsCount: state => {
    return state.notificationsCount;
  },
};
