import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,
  state() {
    return {
      draggedUser: null,
      tasksDisplayed: {
        hideTodo: false,
        hideProgress: false,
        hideFeedback: false,
        hideBlocked: false,
        hideDone: true,
      },
      taskChanges: [],
      editingUsers: [],
      showEditAdd: false,
      selectedUsers: [],
      addedTask: false,
      filteredOnUserTasks: [],
      taskToEdit: null,
      draggedTask: null,
      draggedAttachIntoDesc: null,
      isEditingNow: false,
    };
  },
  actions,
  getters,
  mutations,
};
