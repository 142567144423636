export const clickOutside = {
  /**
   * Implements click outside events and appends to given element that logic
   * @param {DOM} el - DOM element
   * @param {object} binding - value of vue directive
   */
  beforeMount: function (el, binding) {
    el.clickOutsideEvent = function (event) {
      // Check for the presence of an element with the specified id
      const excludeElement = document.getElementById(binding.arg);

      if (
        !(el == event.target || el.contains(event.target)) &&
        !(
          excludeElement &&
          (excludeElement == event.target ||
            excludeElement.contains(event.target))
        )
      ) {
        binding.value(event);
      }
    };

    document.body.addEventListener('mousedown', el.clickOutsideEvent);
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  /**
   * Custom event listener clean up
   * @param {DOM} el - DOM element
   */
  beforeUnmount: function (el) {
    document.body.removeEventListener('mousedown', el.clickOutsideEvent);
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};
