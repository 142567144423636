export default {
  users: state => {
    return state.users;
  },

  showInvitationModal: state => {
    return state.showInvitationModal;
  },

  invitationParams: state => {
    return state.invitationParams;
  },

  searchedUsers: state => {
    return state.searchedUsers;
  },

  isChangedProfile: state => {
    return state.isChangedProfile;
  },

  userUpdateStatus: state => {
    return state.userUpdateStatus;
  },

  oldUserProfile: state => {
    return state.oldUserProfile;
  },

  hasErrors: state => {
    return state.hasErrors;
  },

  userToView: state => {
    return state.userToView;
  },
};
