export default {
  view: state => {
    return state.view;
  },

  contactLinks: state => {
    return state.contactLinks;
  },

  viewBreadcrumb: state => {
    return state.viewBreadcrumb;
  },

  taskView: state => {
    return state.taskView;
  },

  nextRoute: state => {
    return state.nextRoute;
  },

  settingSelected: state => {
    return state.settingSelected;
  },

  networkError: state => {
    return state.networkError;
  },

  encodeDefaultImg: state => {
    return 'data:image.png;base64,' + state.encodeDefaultImg;
  },

  loadPage: state => {
    return state.loadPage;
  },

  openConsole: state => {
    return state.openConsole;
  },

  showSidebar: state => {
    return state.showSidebar;
  },

  lastVisitedUrl: state => {
    return state.lastVisitedUrl;
  },

  loginPlan: state => {
    return state.loginPlan;
  },

  globalLoading: state => {
    return state.globalLoading;
  },

  showExpandableCalendar: state => {
    return state.showExpandableCalendar;
  },
};
