import actions from './actions';

export default {
  namespaced: true,
  state() {
    return {
      task: null,
    };
  },
  actions,
};
