import { MONTHS } from '@/helpers/constants';

// 20/10/2010 -> 2020-10-20
export function slashesToDashes(date) {
  if (typeof date === 'string') {
    return date.split('/').reverse().join('-');
  }

  return date;
}

// Date -> 2020-10-20
export function dateFormatYYYYMMDD(d) {
  const date = new Date(d);
  const day = date.getDate();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  let text = `${year}-${month}-${day < 10 ? '0' + day : day}`;

  text = text.replace(/-+/g, '-');

  return text;
}

/**
 * Formatting date from Date instance to different variants of string
 * @param {Date} date
 * @param {boolean} withTime
 * @param {boolean} reducedYear
 * @returns Date -> 2024-10-20, 14:38
 * withTime = false -> 2024-10-20
 * reducedYear = true -> 24-10-20
 */
export function dateFormat(date, withTime = true, reducedYear = false) {
  const addZero = n => {
    return n > 9 ? n : `0${n}`;
  };

  const getDateObject = d => {
    if (typeof d?.getDate === 'function') {
      return {
        day: addZero(d.getDate()),
        month: addZero(d.getMonth() + 1),
        year: reducedYear ? d.getFullYear() % 100 : d.getFullYear(),
        h: addZero(d.getHours()),
        m: addZero(d.getMinutes()),
      };
    } else {
      return {
        day: 'dd',
        month: 'mm',
        year: reducedYear ? 'yy' : 'yyyy',
        h: 'hh',
        m: 'mm',
      };
    }
  };

  const stDate = getDateObject(getDate(date));

  return (
    `${stDate.year}-${stDate.month}-${stDate.day}` +
    (withTime ? `, ${stDate.h}:${stDate.m}` : '')
  );
}

/**
 * Function rounds minutes by 15 and returns results as a string instance
 * @param {string} time - 12:25
 * @returns 12:25 -> 12:30
 */
export function roundDateMinutesStr(time) {
  const timeFields = time.split(':');
  let hours = Number(timeFields[0]);
  let minutes = Number(timeFields[1]);
  if (minutes < 8) {
    minutes = 0;
  } else if (minutes >= 8 && minutes < 23) {
    minutes = 15;
  } else if (minutes >= 23 && minutes < 38) {
    minutes = 30;
  } else if (minutes >= 38 && minutes < 53) {
    minutes = 45;
  } else {
    hours += 1;
    minutes = 0;
  }

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;

  return `${hours}:${minutes}`;
}

/**
 * Formats dates array to string with dates only of 2 date instances
 * @param {array} dates -> [startDate, endDate] - array of Date instances
 * @returns [startDate, endDate] -> 2024/10/13 - 2024/10/15
 */
export function multidateFormat(dates) {
  const [start, end] = dates;

  const getDate = d => {
    return {
      day: d && typeof d?.getDate === 'function' ? d.getDate() : 'dd',
      month: d && typeof d?.getMonth === 'function' ? d.getMonth() + 1 : 'mm',
      year:
        d && typeof d?.getFullYear === 'function' ? d.getFullYear() : 'yyyy',
    };
  };

  const stDate = getDate(start);
  const enDate = getDate(end);

  return `${stDate.year}/${stDate.month}/${stDate.day} - ${enDate.year}/${enDate.month}/${enDate.day}`;
}

/**
 * Formats calendar cell ids with date data
 * @param {Date} date
 * @param {Array} hours - array of hours with formatting
 * @param {number} hoursIndex - hours index from array above
 * @param {string} minutes
 * @returns calendar-item/2024-03-15/7 AM/00
 */
export function processCellId(date, hours, hoursIndex, minutes) {
  const hour = hours[hoursIndex === 0 ? hours.length - 1 : hoursIndex - 1];

  const dayDate = new Date(date + ' UTC');
  const dateStr = dayDate.toJSON().slice(0, 10);

  return `calendar-item/${dateStr}/${hour}/${minutes}`;
}

/**
 * Process date to date time instance for calendar cells with additional processing
 * @param {Date} date
 * @param {string} time
 * @param {string} minutes
 * @returns Sun Apr 14 2024 23:15:00 GMT+0300 (Eastern European Summer Time) - as a string implementation
 */
export function processTime(date, time, minutes) {
  date = new Date(date + ' UTC');
  const dateTime = new Date(
    `${date.toJSON().slice(0, 10)} ${time
      .slice(0, 2)
      .trim()}:${minutes} ${time.slice(-2)}`,
  );

  dateTime.setHours(dateTime.getHours() - 1);
  if (dateTime.getHours() === 23) {
    dateTime.setDate(dateTime.getDate() + 1);
  }

  return dateTime.toString();
}

/**
 * Counts difference between 2 dates and returns difference as a number of hours
 * @param {Date} startTime
 * @param {Date} endTime
 * @returns 6.2
 */
export const diffTimeInputInHours = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return 0;
  }

  const startDate = getDate(startTime);
  const endDate = getDate(endTime);
  let diff = (endDate - startDate) / (1000 * 60 * 60);
  diff = Number(diff.toFixed(2));

  return diff;
};

/**
 * Function calculates Date instance and increasing it by given amount of hours
 * @param {Date} startTime
 * @param {number} hours
 * @returns Date + 4.5(hours)
 */
export const calculateTimeInputByAddHours = (startTime, hours) => {
  if (!startTime) return;

  const date = getDate(startTime).getTime() + Number(hours) * 60 * 60 * 1000;

  return getDate(date);
};

/**
 * @param {Date} date
 * @returns 2021-12-02 => 02 Dec 2021
 */
export const dateStrToStrMonth = date => {
  const splitedDate = date.split('-');

  const year = splitedDate[0];
  const month = MONTHS[parseInt(splitedDate[1]) - 1];
  const day = splitedDate[2];

  return `${day} ${month} ${year}`;
};

/**
 * @param {string} dateStr
 * @returns Date -> 2020-03-08, 10:25:00
 */
export const getDateISO = dateStr => {
  const date = new Date(dateStr);
  return `${date.toJSON().slice(0, 10)}, ${date.toTimeString().slice(0, 8)}`;
};

/**
 * @param {Date} date
 * @returns Date -> 12 May 2023 10:25
 */
export const dateFormatWithTime = date => {
  if (!date) {
    return '-';
  }

  let dat = new Date(date);
  if (!Date.parse(date)) {
    const index = date.lastIndexOf(' ');
    dat = new Date(date.slice(0, index).trim());
  }

  if (dat) {
    const day = dat.getDate() < 10 ? `0${dat.getDate()}` : dat.getDate();
    const hours = dat.getHours() < 10 ? `0${dat.getHours()}` : dat.getHours();
    const minutes =
      dat.getMinutes() < 10 ? `0${dat.getMinutes()}` : dat.getMinutes();
    return `${day} ${
      MONTHS[dat.getMonth()]
    } ${dat.getFullYear()} ${hours}:${minutes}`;
  }

  return date;
};

export const isDatesEqual = (date1, date2) => {
  if (!date1 && !date2) return true;

  if ((!date1 && date2) || (date1 && !date2)) return false;

  return getDate(date1) - getDate(date2) === 0;
};

/**
 * Process some dates from random string implementation to 1 specific date string instance
 * @param {string} date - consumes dates in format yyyy-mm-dd hh-mm-ss
 * @returns yyyy-mm-dd hh-mm-ss
 */
export const convertDateFormat = date => {
  if (typeof date === 'string') {
    const dateFormat =
      /(\d{4})-(\d{2})-(\d{2})\s(\d{2}):(\d{2}):(\d{2})\s(\+|)(\d{2})(\d{2})/;

    return date.replace(dateFormat, '$1-$2-$3 $4:$5:$6$7$8$9');
  }

  return date;
};

/**
 * Returns Date instance from different type of date instance from parameter
 * @param {*} date
 * @returns Date instance
 */
export const getDate = date => {
  if (date instanceof Date) return date;

  if (typeof date === 'string') return new Date(convertDateFormat(date));

  if (typeof date === 'number') return new Date(date);

  return date;
};

/**
 * get date object by Date
 * @param date {Date | string}
 * @returns {{d: number, y: number, m: 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec' | 'Jan'}}
 */
export const getDateObject = date => {
  const dd = getDate(date);

  return {
    d: dd.getDate(),
    m: dd.toLocaleString('en', { month: 'short' }),
    y: dd.getFullYear(),
  };
};

/**
 * difference in days between dates
 * @param date1 {Date | string}
 * @param date2 {Date | string}
 * @returns {number}
 */
export const differenceInDaysBetweenDates = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  const diffInMs = Math.abs(d2 - d1);
  const msInDay = 1000 * 60 * 60 * 24;

  return Math.floor(diffInMs / msInDay);
};

/**
 * @param {Date} date
 * @returns 2024-04-11
 */
export const getISODateFormat = date => {
  return new Date(date)
    .toLocaleDateString('en-GB')
    .replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1');
};

export const getDaysInMonth = date =>
  new Date(
    new Date(date).getFullYear(),
    new Date(date).getMonth() + 1,
    0,
  ).getDate();

/**
 * get week (index) number
 * @param curDate { number | string | Date }
 * @returns {number}
 */
export const getWeekNumber = curDate => {
  const dateGMT = new Date(curDate);
  const date = new Date(
    dateGMT.getFullYear(),
    dateGMT.getMonth(),
    dateGMT.getDate(),
  );
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const daysUntilFirstMonday = (8 - startOfYear.getDay()) % 7;

  const daysSinceStartOfYear =
    Math.ceil((date - startOfYear) / (24 * 60 * 60 * 1000)) + 1;

  return Math.ceil((daysSinceStartOfYear - daysUntilFirstMonday) / 7) + 1;
};

/**
 * get week ranges
 * @param curDate { number | string | Date }
 * @returns {{end_date: Date, start_date: Date}}
 */
export const getWeekRanges = curDate => {
  const date = new Date(curDate);
  const dayIndex = date.getDay() || 7;

  return {
    start_date: new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - (dayIndex - 1),
    ),
    end_date: new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + (7 - dayIndex),
    ),
  };
};

/**
 * get first day of the month
 * @param date { number | string | Date }
 * @returns {Date}
 */
export const getFirstDayOfTheMonth = date =>
  new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1);

/**
 * get last day of the month
 * @param date { number | string | Date }
 * @returns {Date}
 */
export const getLastDayOfTheMonth = date =>
  new Date(
    new Date(date).getFullYear(),
    new Date(date).getMonth(),
    getDaysInMonth(date),
  );
