import router from '@/router';
import AxiosHandler from '@/helpers/axios';

export default {
  async setSelectedUser({ rootGetters, commit, dispatch }, userId) {
    if (rootGetters['projects/selectedProject']) {
      if (Number.isInteger(userId)) {
        const user = rootGetters['users/users'].find(u => u.id === userId);

        commit('SET_SELECTED_USER', user);

        dispatch('getUserPermission', {
          projectId: rootGetters['projects/selectedProject'].id,
          userId,
        });
      } else {
        commit('SET_SELECTED_USER', null);
      }
    } else {
      commit('SET_SELECTED_USER', null);
    }
  },

  async getUserPermissionsForAllProject(
    { dispatch },
    { projects, userId, isRecall },
  ) {
    for (let pro of projects) {
      dispatch('getUserPermission', { projectId: pro, userId, isRecall });
    }
  },

  async getUserPermission(
    { commit, rootGetters, dispatch },
    { projectId, userId, isRecall = false, isPendingOwner = false },
  ) {
    if (!projectId) {
      projectId = rootGetters['projects/selectedProject']?.id;
    }
    const project = rootGetters['projects/projects'].find(
      pr => pr.id === projectId,
    );
    if (!userId) {
      userId = rootGetters['auth/userProfile']?.id;
    }
    const userPerms = rootGetters['permissions/filteredUserData'];
    const projectUsers = rootGetters['permissions/projectUserData'];

    const projectPerms = projectUsers.find(pr => pr.project === projectId);
    const owner = projectPerms?.data?.find(p => p.user === project.owner);
    if (isPendingOwner && owner) {
      return owner;
    }

    const isAdditionalCheck = isRecall || !userPerms || isPendingOwner;

    if (projectId && userId && isAdditionalCheck) {
      const pendingObj = { projectId, userId, module: 'permissions' };

      return await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            if (!isRecall && project?.ownership_change?.new_owner === userId) {
              await dispatch('setPendingOwner', [project, userId, false]);
            } else {
              const res = await AxiosHandler.call(
                '',
                `/permissions/?project=${projectId}&user=${userId}`,
                'get',
                'application/json',
              );

              if (res.data.results.length > 0) {
                commit('SET_USER_DATA_PER_PROJECT', res.data.results[0]);

                if (project && res.data.results[0].role === 1) {
                  project.owner = userId;
                }

                return res.data.results[0];
              }
            }
          },
        },
        { root: true },
      );
    }
  },

  async setPendingOwner({ commit, dispatch }, [project, usrId, recall]) {
    if (recall) {
      await dispatch('getUserPermission', {
        projectId: project.id,
        userId: usrId,
        isRecall: true,
      });
    } else {
      let roleData = await dispatch('getUserPermission', {
        projectId: project.id,
        userId: project.owner,
        isPendingOwner: true,
      });

      roleData = { ...roleData, user: usrId, id: 0 };
      commit('SET_USER_DATA_PER_PROJECT', roleData);
    }
  },

  async updateUserPermissions(
    { rootGetters, commit },
    { relationId, data, permission },
  ) {
    if (['project', 'list'].includes(permission)) {
      data.role = rootGetters['permissions/filteredUserData'].role;
    }

    const taskRoute = ['task'].includes(permission) ? '/list' : '';
    const res = await AxiosHandler.call(
      data,
      `/permissions${taskRoute}/${relationId}`,
      'patch',
      'application/json',
    );

    if (res?.data) {
      if (['project', 'list'].includes(permission)) {
        commit('SET_USER_DATA_PER_PROJECT', res.data);
      }

      if (
        ['task'].includes(permission) &&
        rootGetters['projects/selectedProject']
      ) {
        commit('SET_TASKS_PERMISSIONS_BY_USER', {
          results: [res.data],
          projectId: rootGetters['projects/selectedProject']?.id,
        });
      }
    }
  },

  async checkListPermissions(
    { rootGetters },
    [isRecall, projectId, userId, listId],
  ) {
    const projectUserData = rootGetters['permissions/projectUserData'].find(
      p => p.project === projectId,
    );

    if (!isRecall) {
      if (projectUserData) {
        const userData = projectUserData.data.find(p => p.user === userId);

        if (userData) {
          const listData = userData.lists.find(l => l.listId === listId);

          if (listData) {
            return null;
          }
        }
      }
    }
  },

  async getUserTaskPermsByList(
    { commit, rootGetters, dispatch },
    { projectId, listId, userId, isRecall = false },
  ) {
    const routeProject = router.currentRoute._rawValue.params.projectId;
    if (!userId) {
      userId = rootGetters['auth/userProfile']?.id;
    }
    if (!listId) {
      listId = rootGetters['lists/selectedList']?.id;
    }
    const listObj = rootGetters['projects/allLists'].find(l => l.id === listId);
    if (!projectId) {
      projectId =
        listObj?.project ??
        rootGetters['projects/selectedProject']?.id ??
        routeProject;
    }

    if (projectId && listId && userId) {
      const getPerm = async () => {
        const pendingObj = { projectId, userId, listId, module: 'permissions' };

        await dispatch(
          'common/pendingWrapper',
          {
            pendingObj,
            callback: async () => {
              const res = await AxiosHandler.call(
                '',
                `/permissions/list/?project=${projectId}&user=${userId}&list_id=${listId}`,
                'get',
                'application/json',
              );

              if (res?.data?.results) {
                commit('SET_TASKS_PERMISSIONS_BY_USER', {
                  results: res.data.results,
                  projectId: projectId,
                });
              }
            },
          },
          { root: true },
        );
      };

      const res = await dispatch('checkListPermissions', [
        isRecall,
        projectId,
        userId,
        listId,
      ]);
      if (res === null) {
        return;
      }

      await getPerm();
    }
  },

  async getUserTasksPermission(
    { commit, rootGetters, dispatch },
    { projectId, userId },
  ) {
    const userData = rootGetters['permissions/filteredUserData'];

    if (projectId && userId && userData) {
      const limit = 10;

      const fetchedCount = userData.lists?.length || 0;
      const offset = Number(
        fetchedCount ? fetchedCount - (fetchedCount % limit) : 0,
      );
      const pro = rootGetters['projects/projects'].find(
        p => p.id === projectId,
      );

      const pendingObj = {
        projectId,
        userId,
        offset,
        option: 'list permissions',
      };

      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            if (userData.listCount === 0 || userData.listCount > fetchedCount) {
              const res = await AxiosHandler.call(
                '',
                `/permissions/list/?project=${projectId}&user=${userId}&limit=${limit}&offset=${offset}`,
                'get',
                'application/json',
              );

              if (res.data?.results && res.data.count) {
                commit('SET_TASKS_PERMISSIONS_BY_USER', {
                  results: res.data.results,
                  count: res.data.count,
                  projectId: projectId,
                });
              }
            }

            dispatch('projects/fetchProjectLists', pro, { root: true });
          },
        },
        { root: true },
      );
    }
  },

  async getAllRoles({ commit, rootGetters, dispatch }) {
    if (rootGetters['permissions/roles'].length === 0) {
      const pendingObj = { fullRoles: true, module: 'permissions' };

      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            const res = await AxiosHandler.call(
              '',
              `/roles`,
              'get',
              'application/json',
            );

            commit('SET_FULL_ROLES', res?.data?.results || []);
          },
        },
        { root: true },
      );
    }
  },

  async changeUserRole({ commit }, { relation_id, newRole }) {
    const data = JSON.stringify({ role: newRole });

    try {
      const res = await AxiosHandler.call(
        data,
        `/permissions/${relation_id}`,
        'patch',
        'application/json',
      );

      if (res?.data) {
        commit('SET_USER_DATA_PER_PROJECT', res.data);
      }
    } catch (e) {
      throw new Error(e);
    }
  },

  async checkExistingProjectsData({ commit, rootGetters, dispatch }) {
    if (rootGetters['auth/userProfile']) {
      const existingProjects = rootGetters['projects/projects'].map(p => p.id);
      const profileProjects = rootGetters['auth/userProfile'].projects;
      const missedProjects = profileProjects.filter(
        pp => !existingProjects.includes(pp),
      );
      const oldProjects = existingProjects.filter(ep =>
        profileProjects.includes(ep),
      );

      if (oldProjects.length !== existingProjects.length) {
        commit(
          'projects/RESET_PROJECTS',
          rootGetters['projects/projects'].filter(pp =>
            oldProjects.includes(pp.id),
          ),
          { root: true },
        );
      }

      for (let pro of missedProjects) {
        await dispatch('projects/getProjectById', pro, { root: true });
      }

      commit('CLEARING_PROJECTS', profileProjects);
    }
  },

  async clearAll({ commit }) {
    commit('SET_SELECTED_USER', null);
    commit('SET_USER_DATA_PER_PROJECT', null);
  },
};
