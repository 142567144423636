import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,

  state() {
    return {
      errorMsg: null,
      tasksFetchingByStatus: [],
      taskComponentReady: false,
      taskUpdating: false,
      boardLimit: 7,
      listLimit: 15,
      noFetchTaskStatus: [],
      tempPosOfDraggedTask: null,
      tasksCountByStatus: {},
      searchTasksIds: [],
    };
  },
  actions,
  getters,
  mutations,
};
