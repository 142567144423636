import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import Cookies from '@/helpers/cookies';

export default {
  namespaced: true,
  state: () => {
    const { access, refresh } = Cookies.getAll();

    return {
      tokens: {
        access,
        refresh,
      },
      profile: null,
      destroy: false,
      resetRefresh: false,
      processLogout: false,
      appearCookiePopup: {},
      pendingRequests: [],
      userSocket: null,
      listSockets: [],
      projectSockets: [],
      taskSocket: null,
      isInitiatedLogoutProcess: false,
      preCommitUserEmailData: null,
      userBillingDetails: null,
      billingDetails: {},
      isChangedBilDetails: false,
      paymentCards: [],
      checkErrorInputs: false,
      billingQueries: [],
      billingNotes: [],
      selectedQuery: null,
      creatingQuery: null,
      showExportModal: false,
      paymentHistory: [],
      filteredPaymentHistory: [],
      filterPaymentHistory: {},
      filterBillingQuery: {},
      filteredBillingQueries: [],
      forceLogout: false,
    };
  },
  actions,
  getters,
  mutations,
};
