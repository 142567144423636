import actions from './actions';
import getters from './getters';
import mutations from './mutations';
export default {
  namespaced: true,
  state() {
    return {
      calendarPeriod: [],
      tempMilestoneId: 'temp-new-milestone',
    };
  },
  actions,
  getters,
  mutations,
};
