import router from '@/router';
import {
  ROUTE_NAMES,
  BACKEND_TASK_STATUSES,
  USER_PERMISSIONS,
} from '@/helpers/constants';
import {
  processInvoices,
  checkQueryFilter,
  sortListsArchLists,
} from '@/helpers/helpers';
import getEnv from '../../../../app.config';

export default {
  async added_to_list({ rootGetters, dispatch }, m) {
    let list = rootGetters['projects/allLists'].find(
      l => l.id === m.added_to_list,
    );

    if (!list && !m.expired) {
      list = await dispatch('lists/getListById', m.added_to_list, {
        root: true,
      });
    }

    const projects = rootGetters['projects/projects'];
    const project = projects.find(pr => pr.id === list.project);
    if (project && list && list.title.match(/^backlog$/i)) {
      project.backlog_list = list.key;
    }

    if (project?.lists?.length > 0) {
      dispatch('projects/fetchListPositions', project, { root: true });
    }
  },

  async delete_list({ commit, dispatch, rootGetters }, m) {
    const listDeleted = rootGetters['projects/allLists'].find(
      p => p.id === m.list_deleted,
    );
    const project = rootGetters['projects/projects'].find(
      pr => pr.id === m.list_project,
    );
    const epics = rootGetters['epics/epics'].filter(
      e => e.project === m.list_project,
    );
    const listSockets = rootGetters['auth/listSockets'];
    const selectedList = rootGetters['lists/selectedList'];

    if (epics.length > 0 && listDeleted.taskCount > 0) {
      commit('epics/SET_EPICS_LIST', [], { root: true });

      dispatch('epics/fetchProjectEpics', project, { root: true });
    }

    const socketUrl = `${getEnv('VUE_APP_WEBSOCKET_API')}/list/${
      m.list_deleted
    }`;
    const listSocket = listSockets.find(ls => ls.url === socketUrl);
    if (listSocket) {
      listSocket.close();
    }

    if (project?.lists?.length > 0) {
      dispatch('projects/fetchListPositions', project, { root: true });
    }

    if (selectedList?.id === listDeleted.id) {
      dispatch('lists/redirectFromListToPortfolio', listDeleted.id, {
        root: true,
      });

      const profile = rootGetters['auth/userProfile'];
      if (m.sender !== profile.id) {
        dispatch(
          'popups/setToast',
          [`List ${listDeleted.title} has been deleted`, 3000],
          {
            root: true,
          },
        );
      }
    }

    const filteredTs = rootGetters['timesheet/filteredTimesheets'];
    filteredTs.forEach(ts => {
      if (ts.list_id === m.list_deleted) {
        ts.list_id = null;
        ts.task = null;
      }
    });

    let filter;
    if (router.currentRoute.value.name === ROUTE_NAMES.EPICS) {
      filter = rootGetters['epics/filterEpics'];
    } else {
      filter = rootGetters['timesheet/filterTimesheets'];
    }
    if (filter.list_ids) {
      filter.list_ids = filter.list_ids.filter(id => id !== m.list_deleted);
    }

    if (project) {
      const newProject = await dispatch('projects/loadProject', [project.id], {
        root: true,
      });

      project.progress = newProject.progress;
      project.billed_hours = newProject.billed_hours;
      project.logged_hours = newProject.logged_hours;

      if (listDeleted && listDeleted.title.match(/^backlog$/i)) {
        project.backlog_list = null;
        project.tasks_in_backlog = 0;
      }
    }

    dispatch('lists/removeListFromState', listDeleted, { root: true });
  },

  async list_user_added({ commit, dispatch, rootGetters }, m) {
    const liUpdate = rootGetters['projects/allLists'].find(
      l => l.id === m.list_user_added.list,
    );

    if (liUpdate) {
      let userHere = rootGetters['users/users'].find(
        u => u.id === m.list_user_added.user,
      );

      if (!userHere) {
        await dispatch('users/getUsersById', [m.list_user_added.user], {
          root: true,
        });
      }

      const liUsers = liUpdate.users.map(u => u.id);

      if (!liUsers.includes(m.list_user_added.user)) {
        const taskCount = {
          todo: 0,
          in_progress: 0,
          feedback: 0,
          blocked: 0,
          done: 0,
        };
        liUpdate.actualTasks.forEach(task => {
          if (task.users.includes(m.list_user_added.user)) {
            taskCount[BACKEND_TASK_STATUSES[task.status - 1]] += 1;
          }
        });

        commit(
          'lists/SET_REMOVED_USERS',
          rootGetters['lists/removedUsers'].filter(
            ru => ru.id !== m.list_user_added.user,
          ),
          { root: true },
        );

        if (m.list_user_added.expired) {
          const remUsers = rootGetters['lists/removedUsers'];
          remUsers.push({
            id: m.list_user_added.user,
            taskCount: taskCount,
            expired: true,
          });
        } else {
          liUpdate.users.push({
            id: m.list_user_added.user,
            taskCount: taskCount,
          });

          dispatch('lists/updateListInProject', liUpdate, { root: true });
        }
      }
    }
  },

  async list_user_removed({ rootGetters, dispatch }, m) {
    const usr = rootGetters['auth/userProfile'];
    const projects = rootGetters['projects/projects'];
    const selList = rootGetters['lists/selectedList'];

    let updatedList = null;
    const project = projects.find(p => {
      updatedList = p.lists.find(l => l.id === m.list_user_removed.list);
      return updatedList;
    });

    if (selList?.id === m.list_user_removed.list) {
      const removedUser = rootGetters['lists/removedUsers'].some(
        u => u.id === m.list_user_removed.user,
      );
      if (!removedUser) {
        dispatch('lists/addRemovedUser', m.list_user_removed.user, {
          root: true,
        });
      }

      const user = rootGetters['users/users'].find(
        us => us.user === m.list_user_removed.user,
      );
      if (user) {
        user.todo = 0;
        user.in_progress = 0;
        user.feedback = 0;
        user.blocked = 0;
      }

      selList.actualTasks.forEach(at => {
        if (at.status !== 5) {
          at.users = at.users.filter(u => u !== m.list_user_removed.user);
        }
      });
    }

    if (
      project &&
      updatedList.users &&
      updatedList.id === m.list_user_removed.list
    ) {
      updatedList.users = updatedList.users.filter(
        u => u.id !== m.list_user_removed.user,
      );

      // check for list project being accessible
      dispatch('lists/updateListInProject', updatedList, { root: true });

      // if the logged in user is the one being removed from the list
      if (m.list_user_removed.user === usr.id) {
        dispatch('lists/redirectFromListToPortfolio', updatedList.id, {
          root: true,
        });

        dispatch(
          'popups/setToast',
          [`You were removed from list ${updatedList.title}`, 3000],
          { root: true },
        );

        dispatch('lists/removeListFromState', updatedList, { root: true });
      }
    }
  },

  async update_list({ rootGetters, dispatch }, m) {
    let list = rootGetters['projects/allLists'].find(l => l.id === m.list_id);
    if (!list) {
      list = await dispatch('lists/getListById', m.list_id, { root: true });
    }

    if (!list) return;

    const project = rootGetters['projects/projects'].find(
      pr => pr.id === list.project,
    );
    if (project) {
      const updatedTitle = m['changes'].title;
      if (updatedTitle) {
        if (updatedTitle.match(/^backlog$/i)) {
          project.backlog_list = list.key;
          project.tasks_in_backlog =
            list.taskCount > 0 ? list.taskCount : project.tasks_in_backlog;
        } else if (
          list.title.match(/^backlog$/i) &&
          !updatedTitle.match(/^backlog$/i)
        ) {
          project.backlog_list = null;
          list.taskCount = project.tasks_in_backlog;
          project.tasks_in_backlog = 0;
        }
      }
    }

    for (const [key, value] of Object.entries(m['changes'])) {
      list[key] = value;
    }

    dispatch('lists/updateListInProject', list, { root: true });
  },

  async tag_added({ rootGetters }, m) {
    const tag = {
      id: m.tag_added.id,
      tag: m.tag_added.tag,
    };

    const pro = rootGetters['projects/projects'].find(
      p => p.id === m.tag_added.project,
    );

    if (pro && !pro?.tags?.some(tat => tat.id === tag.id)) {
      pro.tags.push(tag);
    }

    if (router.currentRoute.value.name === ROUTE_NAMES.PORTFOLIO_TIMESHEETS) {
      const portfolioTags = rootGetters['common/portfolioTags'];

      portfolioTags.push(tag);
    }
  },

  async tag_removed({ commit, rootGetters }, m) {
    const pro = rootGetters['projects/projects'].find(p => p.id === m.project);

    if (pro && pro?.tags?.some(tat => tat.id === m.tag_removed)) {
      pro.tags = pro.tags.filter(t => t.id !== m.tag_removed);
    }

    if (router.currentRoute.value.name === ROUTE_NAMES.PORTFOLIO_TIMESHEETS) {
      const portfolioTags = rootGetters['common/portfolioTags'];

      commit(
        'common/SET_PORTFOLIO_TAGS',
        portfolioTags.filter(tag => tag.id !== m.tag_removed),
        { root: true },
      );
    }
  },

  async list_archived({ rootGetters, dispatch }, m) {
    const projects = rootGetters['projects/projects'];
    const lists = rootGetters['projects/allLists'];

    const archList = lists.find(l => l.id === m.archived_list);
    const pro = projects.find(pr => pr.id === archList?.project);

    if (!archList || !pro) {
      return;
    }

    if (pro) {
      const projPerms = rootGetters['permissions/userPermsByAllProjects'].find(
        p => p.project === pro.id,
      );

      if (projPerms?.project_permissions?.includes(USER_PERMISSIONS.ARCHIVE)) {
        pro.archived_backlog = true;
      } else {
        pro.backlog_disabled = true;
      }
    }

    if (archList && !archList.archived) {
      pro.lists = sortListsArchLists(pro.lists);
      const nonArchLen = pro.lists.filter(l => !l.archived).length;
      const index = pro.lists.findIndex(list => list.id === archList.id);
      archList.archived = true;
      archList.position = pro.lists[nonArchLen - 1].position;
      pro.lists.splice(index, 1);
      pro.lists.splice(nonArchLen - 1, 0, archList);
      pro.lists.forEach((list, ind) => {
        if (index <= ind && nonArchLen - 1 > ind) {
          list.position -= 1;
        }
      });
      dispatch('lists/updateListInProject', archList, { root: true });
      dispatch('lists/updateSelectedList', null, { root: true });

      pro.listCount = Math.max(0, pro.listCount - 1);

      if (!archList.taskCount) {
        dispatch('tasks/getTotalTasksByList', archList, { root: true });
      }

      const selectedList = rootGetters['lists/selectedList'];
      if (selectedList?.id === archList.id) {
        dispatch('lists/redirectFromListToPortfolio', archList.id, {
          root: true,
        });

        const profile = rootGetters['auth/userProfile'];
        if (m.sender !== profile.id) {
          dispatch(
            'popups/setToast',
            [`Your list ${archList.title} has been archived`, 3000],
            { root: true },
          );
        }
      }
    }

    if (pro?.lists?.length > 0) {
      dispatch('projects/fetchListPositions', pro, { root: true });
    }
  },

  async list_unarchived({ dispatch, rootGetters }, m) {
    const lists = rootGetters['projects/allLists'];
    let unarchList = lists.find(list => list.id === m.unarchived_list);
    if (!unarchList) {
      unarchList = await dispatch('lists/getListById', m.unarchived_list, {
        root: true,
      });
    }

    const projects = rootGetters['projects/projects'];
    const pro = projects.find(pr => pr.id === unarchList?.project);
    if (!pro || !unarchList) {
      return;
    }

    if (unarchList) {
      const index = pro.lists.findIndex(list => list.id === unarchList.id);
      pro.lists.splice(index, 1);
      pro.lists.forEach(list => (list.position += 1));
      unarchList.archived = false;
      unarchList.position = 0;
      pro.lists.unshift(unarchList);
      dispatch('lists/updateListInProject', unarchList, { root: true });

      pro.archivedListCount = Math.max(0, pro.archivedListCount - 1);
    }

    if (pro?.lists?.length > 0) {
      dispatch('projects/fetchListPositions', pro, { root: true });
    }

    pro.archived_backlog = false;
    pro.backlog_disabled = false;
  },

  /* --------------------------------------------------- Profile sockets ------------------------------------------------------------------------------------------------------------------------------------- */

  async profile_changed({ commit, rootGetters, dispatch }, m) {
    const userProfile = rootGetters['auth/userProfile'];
    const selectedProject = rootGetters['projects/selectedProject'];

    const updateProfile = profileToUpdate => {
      for (const [key, value] of Object.entries(m['changes'])) {
        if (`${key}` === 'avatar_id') {
          profileToUpdate.avatar_id = null;
          if (profileToUpdate) {
            dispatch('users/getAvatars', [profileToUpdate], { root: true });
          }
        }

        profileToUpdate[key] = value;
      }

      return profileToUpdate;
    };

    // added to/removed from project ------------------------------------------//

    if (m['changes'].projects && m.profile_id === userProfile.id) {
      const profile = userProfile;

      m['changes'].projects.forEach(pr => {
        if (!rootGetters['projects/projects'].some(proj => proj.id === pr)) {
          dispatch('projects/getProjectById', pr, { root: true });
        }
      });

      profile.projects.forEach(pr => {
        if (
          !m['changes'].projects.includes(pr) &&
          selectedProject?.id === pr &&
          router.currentRoute.value.name !== ROUTE_NAMES.SETTINGS
        ) {
          router.push({
            name: ROUTE_NAMES.PROJECT_PORTFOLIO,
          });
        }
      });

      const profiledUpdated = updateProfile(profile);

      commit('auth/SET_PROFILE', profiledUpdated, { root: true });

      const projectSockets = rootGetters['auth/projectSockets'];
      const projectUrls = m['changes'].projects.map(
        pr => `${getEnv('VUE_APP_WEBSOCKET_API')}/project/${pr}`,
      );
      projectSockets.forEach(pr => {
        if (!projectUrls.includes(pr.url)) {
          pr.close();
        }
      });
    }

    // ---------------------------------------------------------------------//

    const prof =
      m.profile_id === userProfile.id
        ? userProfile
        : rootGetters['users/users'].find(p => p.id === m.profile_id);

    if (prof) {
      const prodUpdated = updateProfile(prof);
      commit('users/SET_USER', prodUpdated, { root: true });
    }
  },

  async profile_deleted({ rootGetters }, m) {
    rootGetters['users/users'].find(p => p.id === m.profile_id);
  },

  // -----------------------------Project changes -----------------------------//

  async project_updated({ rootGetters, commit }, m) {
    const projectToUpdate = rootGetters['projects/projects'].find(
      p => p.id === m.project_changes.id,
    );

    Object.keys(m.project_changes).forEach(field => {
      if (field !== 'lists') {
        projectToUpdate[field] = m.project_changes[field];
      }
    });

    commit('projects/UPDATE_PROJECT', projectToUpdate, { root: true });
  },

  async receive_data({ commit, dispatch, rootGetters }, m) {
    if (m.reload?.project) {
      await dispatch(
        'permissions/getUserPermission',
        {
          projectId: m.reload.project.id,
          userId: m.reload.project.user,
          isRecall: true,
        },
        { root: true },
      );

      const selList = rootGetters['lists/selectedList'];
      if (selList) {
        if (m.reload.project.user === rootGetters['auth/userProfile'].id) {
          dispatch(
            'permissions/getUserTaskPermsByList',
            {
              listId: selList.id,
              userId: m.reload.project.user,
              isRecall: true,
            },
            { root: true },
          );
        }

        if (m.reload.project.id === selList.project) {
          commit('lists/SET_REMOVED_USERS', [], { root: true });
          dispatch('lists/fetchListRemovedUsers', selList, { root: true });
        }
      }
    }
  },

  async email_change_request({ commit, dispatch, rootGetters }, m) {
    const status = m.email_change_request.split(' ').pop();
    const userProfile = rootGetters['auth/userProfile'];
    const preCommitEmail = rootGetters['auth/preCommitUserEmailData'];

    if (status === 'rejected') {
      commit('auth/SET_PRE_COMMIT_USER_EMAIL', null, { root: true });
    } else if (status === 'accepted') {
      commit(
        'auth/SET_PROFILE',
        { ...userProfile, email: preCommitEmail.new_email },
        { root: true },
      );
      dispatch(
        'users/oldUserProfile',
        { ...userProfile, email: preCommitEmail.new_email },
        { root: true },
      );

      commit('auth/SET_PRE_COMMIT_USER_EMAIL', null, { root: true });
    }
  },

  project_ownership_request({ dispatch, rootGetters }, m) {
    const projects = rootGetters['projects/projects'];

    const project = projects.find(
      pr => pr.id === m.project_ownership_request.project,
    );

    if (project) {
      if (project.ownership_change) {
        dispatch(
          'permissions/setPendingOwner',
          [project, project.ownership_change.new_owner, true],
          { root: true },
        );

        if (m.project_ownership_request.project.action === 'accept') {
          project.owner = project.ownership_change.new_owner;
        }
      }

      project.ownership_change = null;
    }
  },

  seat_changed({ commit, rootGetters }, m) {
    const details = rootGetters['plans/plansDetails'];
    const plans = rootGetters['plans/plans'];

    if (details && m.changes.status === 'INVITED') {
      const plan = plans.find(pl => pl.id === m.changes.plan);

      const previousPlan = plans.find(pl =>
        pl.seats.find(s => s.id === m.seat_changed),
      );

      if (plan) {
        plan.seats.push({
          ...m.changes,
          id: m.seat_changed,
        });

        plan.unallocated_seats -= 1;

        details.totalAllocated += 1;
        details.totalVacant -= 1;
      }

      if (previousPlan) {
        const index = previousPlan.seats.findIndex(
          s => s.id === m.seat_changed,
        );

        if (index > -1) {
          previousPlan.seats.splice(index, 1);
          previousPlan.unallocated_seats += 1;

          if (previousPlan.status === 'EXPIRED') {
            details.totalAllocated -= 1;
            details.totalVacant += 1;
          }
        }
      }
    }

    if (details && m.changes.status === 'ACCEPTED') {
      const plan = plans.find(pl =>
        pl.seats.find(s => s.id === m.seat_changed),
      );
      const newPlan = plans.find(pl => pl.id === m.changes.plan);
      if (plan) {
        const index = plan.seats.findIndex(s => s.id === m.seat_changed);

        if (index > -1) {
          plan.seats.splice(index, 1);
          plan.unallocated_seats += 1;

          if (plan.status !== 'EXPIRED') {
            details.totalAllocated -= 1;
            details.totalVacant += 1;
          }
        }
      }

      if (newPlan && !newPlan.seats.some(s => s.id === m.seat_changed)) {
        newPlan.seats.push({
          ...m.changes,
          id: m.seat_changed,
        });
        newPlan.unallocated_seats -= 1;

        details.totalAllocated += 1;
        details.totalVacant -= 1;
      }

      commit('plans/SET_RESET_PLAN_MODALS', m.seat_changed, { root: true });
    }
  },

  seat_deleted({ commit, rootGetters }, m) {
    const details = rootGetters['plans/plansDetails'];
    const plans = rootGetters['plans/plans'];

    const plan = plans.find(pl => pl.seats.find(s => s.id === m.seat_deleted));
    if (plan) {
      const index = plan.seats.findIndex(s => s.id === m.seat_deleted);

      const userId = plan.seats[index]?.user;
      if (userId) {
        const selProj = rootGetters['projects/selectedProject'];
        if (selProj) {
          selProj.users = selProj.users.filter(usId => usId !== userId);
        }

        const selList = rootGetters['lists/selectedList'];
        if (selList) {
          selList.users = selList.users.filter(user => user.id !== userId);
        }
      }

      plan.seats.splice(index, 1);
      plan.unallocated_seats += 1;

      if (details && ['VALID', 'CANCELLED'].includes(plan.status)) {
        details.totalAllocated -= 1;
        details.totalVacant += 1;
      }
    }

    commit('plans/SET_RESET_PLAN_MODALS', m.seat_deleted, { root: true });
  },

  async card_updated({ commit, dispatch, rootGetters }, m) {
    const cards = rootGetters['auth/paymentCards'];
    const index = cards.findIndex(c => m.card_updated.id === c.id);
    if (index > -1) {
      cards.splice(index, 1, m.card_updated);
    }

    const purchasingPlan = rootGetters['plans/purchasingPlan'];
    if (m.card_updated.id === purchasingPlan?.card) {
      commit('plans/SET_PAYMENT_LOADING', true, { root: true });

      if (m.card_updated.status === 'ACCEPTED') {
        const billCreds = JSON.parse(localStorage.getItem('billing_details'));
        const planRenew = localStorage.getItem('plan_renew');
        if (planRenew) {
          commit('plans/SET_PLAN_RENEW', planRenew);
        }
        await dispatch(
          'plans/purchasePlan',
          {
            type: purchasingPlan.plan,
            card: purchasingPlan.card,
            ...billCreds,
          },
          { root: true },
        );
        localStorage.removeItem('billing_details');
        localStorage.removeItem('plan_renew');

        if (!rootGetters['plans/openPurchasePlanModal']) {
          dispatch(
            'popups/setToast',
            ['Your plan purchase was successful', 3000],
            { root: true },
          );
        }
      } else if (
        ['REJECTED', 'EXPIRED', 'TIMED_OUT'].includes(m.card_updated.status)
      ) {
        dispatch(
          'popups/setToast',
          ['Card for plan purchase was rejected', 3000],
          { root: true },
        );

        commit('plans/SET_PAYMENT_ERROR', true, { root: true });
      }

      commit('plans/SET_PAYMENT_LOADING', false, { root: true });
      commit('plans/SET_PURCHASING_PLAN', null, { root: true });
      commit('plans/SET_PAYMENT_DONE', true, { root: true });
    }
  },

  card_deleted({ commit, dispatch, rootGetters }, m) {
    let cards = rootGetters['auth/paymentCards'];
    const purchasingPlan = rootGetters['plans/purchasingPlan'];
    const isPrimary = cards.find(c => c.id === m.card_deleted)?.preferred_card;

    commit(
      'auth/SET_PAYMENT_CARDS',
      cards.filter(c => c.id !== m.card_deleted),
      { root: true },
    );

    if (isPrimary) {
      cards = rootGetters['auth/paymentCards'];
      const card = cards.find(card => card.status === 'ACCEPTED');
      if (card) {
        card.preferred_card = true;
      } else if (cards.length > 0) {
        cards[0].preferred_card = true;
      }
    }

    if (m.card_deleted === purchasingPlan?.card) {
      commit('plans/SET_PAYMENT_ERROR', true, { root: true });
      commit('plans/SET_PAYMENT_LOADING', false, { root: true });
      localStorage.removeItem('billing_details');
      localStorage.removeItem('plan_renew');

      if (!rootGetters['plans/openPurchasePlanModal']) {
        dispatch(
          'popups/setToast',
          ['Your plan purchase was unsuccessful', 3000],
          { root: true },
        );
      }
      commit('plans/SET_PURCHASING_PLAN', null, { root: true });
    }
  },

  plan_created({ commit, rootGetters }, m) {
    const details = rootGetters['plans/plansDetails'];
    let plans = rootGetters['plans/plans'];

    plans = plans.filter(
      pl => !(pl.status === 'CANCELLED' && pl.seats.length === 0),
    );
    commit('plans/SET_PLANS', plans, { root: true });

    details.totalSeats += m.plan_created.unallocated_seats;
    details.totalVacant += m.plan_created.unallocated_seats;
    plans.push(m.plan_created);
  },

  plan_deleted({ commit, rootGetters }, m) {
    const plans = rootGetters['plans/plans'];
    const details = rootGetters['plans/plansDetails'];

    const deletedPlan = plans.find(p => p.id === m.plan_deleted);
    if (details && deletedPlan && deletedPlan.status !== 'EXPIRED') {
      const allocatedSeats = deletedPlan.seats.filter(s => s.user).length;
      details.totalSeats -= deletedPlan.unallocated_seats + allocatedSeats;
      details.totalAllocated -= allocatedSeats;
      details.totalVacant -= deletedPlan.unallocated_seats;
    }

    commit(
      'plans/SET_PLANS',
      plans.filter(p => p.id !== m.plan_deleted),
      { root: true },
    );
  },

  async updatePermissions({ rootGetters, dispatch }, [owner]) {
    const selList = rootGetters['lists/selectedList'];
    const userPr = rootGetters['auth/userProfile'];

    let projects = [];
    if (owner) {
      projects = rootGetters['projects/projects']
        .filter(pr => pr.owner === owner)
        .map(pr => pr.id);
    } else {
      projects = rootGetters['projects/projects'].map(pr => pr.id);
    }

    await dispatch(
      'permissions/getUserPermissionsForAllProject',
      {
        projects: projects,
        userId: userPr.id,
        isRecall: true,
      },
      { root: true },
    );

    if (selList) {
      dispatch(
        'permissions/getUserTaskPermsByList',
        {
          listId: selList.id,
          userId: userPr.id,
          isRecall: true,
        },
        { root: true },
      );
    }
  },

  plan_updated({ dispatch, rootGetters }, m) {
    const userPr = rootGetters['auth/userProfile'];
    const plans = rootGetters['plans/plans'];
    const details = rootGetters['plans/plansDetails'];

    const checkUser =
      userPr.id === m.plan_updated.owner ||
      m.plan_updated.seats.find(s => s.user === userPr.id);
    const plan = plans.find(pl => pl.id === m.plan_updated.id);

    // update users permissions after plan update
    // plan could updated to have less seats so some users will lose their permissions
    if (checkUser && (!plan || plan.status !== m.plan_updated.status)) {
      dispatch('updatePermissions', [m.plan_updated.owner]);
    }

    if (details) {
      if (m.plan_updated.status === 'EXPIRED') {
        details.totalSeats -=
          m.plan_updated.unallocated_seats + m.plan_updated.seats.length;
        details.totalAllocated -= m.plan_updated.seats.length;
        details.totalVacant -= m.plan_updated.unallocated_seats;
      } else if (
        m.plan_updated.status === 'VALID' &&
        ['EXPIRED', 'PENDING'].includes(plan?.status)
      ) {
        details.totalSeats +=
          m.plan_updated.unallocated_seats + m.plan_updated.seats.length;
        details.totalAllocated += m.plan_updated.seats.length;
        details.totalVacant += m.plan_updated.unallocated_seats;
      }
    }

    // update permissions for expired users after changes for plans
    // plan could be renewed or become expired
    if (plan?.status !== m.plan_updated.status) {
      dispatch('updateExpiredUsers', m.plan_updated);
    }

    const planIndex = plans.findIndex(pl => pl.id === m.plan_updated.id);
    if (planIndex > -1) {
      plans.splice(planIndex, 1, m.plan_updated);
    }
  },

  async updateExpiredUsers({ dispatch, rootGetters }, plan) {
    const selList = rootGetters['lists/selectedList'];
    const selProj = rootGetters['projects/selectedProject'];

    if (selList) {
      if (['VALID', 'EXPIRED'].includes(plan.status)) {
        await dispatch('lists/fetchListUsers', selList, { root: true });

        dispatch('lists/fetchListTaskCount', selList, { root: true });
      }
    }

    if (selProj) {
      if (plan.status === 'VALID') {
        dispatch('projects/fetchProjectUsers', selProj, { root: true });
      } else if (plan.status === 'EXPIRED') {
        await dispatch('projects/fetchProjectExpiredUsers', selProj, {
          root: true,
        });

        const expUsers = rootGetters['projects/expiredUsers'];
        selProj.users = selProj.users.filter(
          us => !expUsers.some(eu => eu.id === us),
        );
      }
    }
  },

  seats_updated({ rootGetters }, m) {
    if (m.seats_updated.length > 0) {
      const details = rootGetters['plans/plansDetails'];
      const plans = rootGetters['plans/plans'];

      const planId = m.seats_updated[0].plan;
      const plan = plans.find(pl => pl.id === planId);
      if (plan && details) {
        m.seats_updated.forEach(seat => {
          const planRemove = plans.find(pl =>
            pl.seats.some(s => s.user === seat.user),
          );
          if (planRemove && planRemove.id !== m.seats_updated[0].plan) {
            planRemove.seats = planRemove.seats.filter(
              s => s.user !== seat.user,
            );
            planRemove.unallocated_seats += 1;
            details.totalAllocated -= 1;
            details.totalVacant += 1;
          }

          if (!plan.seats.some(s => s.user === seat.user)) {
            plan.seats.push(seat);
            plan.unallocated_seats -= 1;
            details.totalAllocated += 1;
            details.totalVacant -= 1;
          }
        });
      }
    }
  },

  billing_query_updated({ commit, rootGetters }, m) {
    const queries = rootGetters['auth/billingQueries'];
    const index = queries.findIndex(q => q.id === m.billing_query_updated);
    const check = checkQueryFilter(
      m.changes,
      rootGetters['auth/filterBillingQuery'],
    );
    if (index > -1 && check) {
      const query = {
        id: m.billing_query_updated,
        ...m.changes,
      };

      queries.splice(index, 1, query);

      const selectedQuery = rootGetters['auth/selectedQuery'];
      if (selectedQuery && selectedQuery.id === m.billing_query_updated) {
        commit('auth/SET_SELECTED_QUERY', query, { root: true });
      }
    }
  },

  billing_query_note_created({ rootGetters }, m) {
    const notes = rootGetters['auth/billingNotes'];
    if (notes.length > 0 && !m.changes.user) {
      notes.forEach(note => (note.editable = false));
      notes.push({ id: m.billing_query_note_created, ...m.changes });
    }
  },

  billing_query_note_updated({ rootGetters }, m) {
    const notes = rootGetters['auth/billingNotes'];
    const index = notes.findIndex(n => n.id === m.billing_query_note_updated);
    if (index > -1) {
      const note = {
        ...notes[index],
        ...m.changes,
      };

      notes.splice(index, 1, note);
    }
  },

  payment_created({ rootGetters }, m) {
    const payments = rootGetters['auth/paymentHistory'];

    const payment = processInvoices([m.payment_created])[0];

    if (
      !payments.some(
        p => p.merchant_transaction_id === payment.merchant_transaction_id,
      )
    ) {
      payments.unshift(payment);
    }
  },

  payment_updated({ rootGetters }, m) {
    const payments = rootGetters['auth/paymentHistory'];

    const index = payments.findIndex(
      p =>
        p.merchant_transaction_id === m.payment_updated.merchant_transaction_id,
    );

    if (index > -1) {
      const payment = processInvoices([m.payment_updated])[0];
      payments.splice(index, 1, payment);
    }
  },
};
