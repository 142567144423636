import { createRouter, createWebHistory } from 'vue-router';
import { detectMobile } from '@/helpers/helpers';
import { isMobile } from 'mobile-device-detect';
import { MOBILE_DETECT_WIDTH, ROUTE_NAMES } from '@/helpers/constants';
import { stores } from '@/main';

const loginRoutes = (n, p) => {
  return {
    path: `/login${p}`,
    name: n,
    component: () => import(/*webpackChunkName: "login"*/ '../views/login.vue'),
    // Reusable guards if required.
  };
};

const routes = [
  loginRoutes(ROUTE_NAMES.TASK_LOGOUT, '?task=:projectId/:taskKey'),
  loginRoutes(ROUTE_NAMES.LOGIN, ''),
  loginRoutes(ROUTE_NAMES.LIST_LOGOUT, '?list=:projectId/:listKey'),
  loginRoutes(
    ROUTE_NAMES.TASK_MOVE_LOGOUT,
    '?taskMove=:listKey/:taskKey/:token/:uuid',
  ),
  {
    path: '/',
    name: ROUTE_NAMES.LANDING,
    component: () =>
      import(/*webpackChunkName: "teamfu-terms"*/ '../views/landing.vue'),
    children: [
      {
        path: 'features',
        name: ROUTE_NAMES.FEATURES,
        component: () =>
          import(/*webpackChunkName: "features"*/ '../views/landing.vue'),
      },
      {
        path: 'use-cases',
        name: ROUTE_NAMES.USE_CASES,
        component: () =>
          import(/*webpackChunkName: "use-cases"*/ '../views/landing.vue'),
      },
      {
        path: 'integrations',
        name: ROUTE_NAMES.INTEGRATIONS,
        component: () =>
          import(/*webpackChunkName: "integrations"*/ '../views/landing.vue'),
      },
      {
        path: 'pricing',
        name: ROUTE_NAMES.PRICING,
        component: () =>
          import(/*webpackChunkName: "pricing"*/ '../views/landing.vue'),
      },
      {
        path: 'about-us',
        name: ROUTE_NAMES.ABOUT,
        component: () =>
          import(/*webpackChunkName: "about-us"*/ '../views/landing.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    component: () => import('../views/main-page.vue'),
    name: ROUTE_NAMES.MAIN,
    children: [
      {
        path: ':projectId/list/:listKey',
        name: ROUTE_NAMES.LIST,
        component: () =>
          import(
            /*webpackChunkName: "list-link-route"*/ '../components/main-page/LHS/project-lists.vue'
          ),
      },
      {
        path: ':projectId/settings',
        name: ROUTE_NAMES.PROJECT_SETTINGS,
        component: () =>
          import('../components/main-page/dashboard/projects/project-setting'),
      },
      {
        path: ':projectId/archives',
        name: ROUTE_NAMES.PROJECT_ARCHIVES,
        component: () =>
          import(
            '../components/main-page/dashboard/projects/project-archives.vue'
          ),
      },
      {
        path: 'Project-Portfolio',
        name: ROUTE_NAMES.PROJECT_PORTFOLIO,
        component: () =>
          import(
            /*webpackChunkName: "list-link-route"*/ '../components/main-page/dashboard/projects/ProjectPortfolio/ProjectPortfolio.vue'
          ),
      },
      {
        path: 'task-move/:listKey/:taskKey/:token/:uuid',
        name: ROUTE_NAMES.TASK_MOVE,
        component: () =>
          import(
            /*webpackChunkName: "task-move-route"*/ '../components/main-page/LHS/project-lists.vue'
          ),
      },
      {
        path: 'search',
        name: ROUTE_NAMES.SEARCH,
        component: () =>
          import(
            /*webpackChunkName: "search-query-route"*/ '../components/main-page/search/search.vue'
          ),
      },
      {
        path: ':projectId/list/:listKey/task/:taskKey',
        name: ROUTE_NAMES.TASK,
        component: () =>
          import(
            /*webpackChunkName: "task-link-route"*/ '../components/main-page/dashboard/add-edit-task-screen.vue'
          ),
      },
      {
        path: '/settings/:setting',
        name: ROUTE_NAMES.SETTINGS,
        component: () =>
          import(
            /*webpackChunkName: "settings-route"*/ '../components/main-page/settings/settings-body.vue'
          ),
        children: [
          {
            path: '/settings/:setting/query/:query',
            name: ROUTE_NAMES.BILLING_QUERY,
            components: () =>
              import(
                /*webpackChunkName: "billing-query-route"*/ '../components/main-page/settings/bodies/billing-queries-page.vue'
              ),
          },
          {
            path: '/settings/:setting/:documentId',
            name: ROUTE_NAMES.INVOICE,
            components: () =>
              import(
                /*webpackChunkName: "invoice-route"*/ '../components/main-page/settings/bodies/payment-history-page.vue'
              ),
          },
        ],
      },
      {
        path: '/epics/:projectId',
        name: ROUTE_NAMES.EPICS,
        component: () =>
          import(
            /*webpackChunkName: "epics-route"*/ '../components/main-page/headers/EpicsHeader.vue'
          ),
      },
      {
        path: '/timesheets/:projectId/list/:listKey/',
        name: ROUTE_NAMES.TIMESHEETS,
        component: () =>
          import(
            /*webpackChunkName: "list-timesheets"*/ '../views/main-page.vue'
          ),
      },
      {
        path: '/timesheets/:projectId/',
        name: ROUTE_NAMES.PROJECT_TIMESHEETS,
        component: () =>
          import(
            /*webpackChunkName: "project-timesheets"*/ '../views/main-page.vue'
          ),
      },
      {
        path: '/timesheets/',
        name: ROUTE_NAMES.PORTFOLIO_TIMESHEETS,
        component: () =>
          import(
            /*webpackChunkName: "portfolio-timesheets"*/ '../views/main-page.vue'
          ),
      },
    ],
  },
  {
    path: '/register',
    name: ROUTE_NAMES.REGISTER,
    component: () =>
      import(/*webpackChunkName: "register"*/ '../views/register.vue'),
  },
  {
    path: '/thank-you',
    name: ROUTE_NAMES.THANKS,
    component: () =>
      import(/*webpackChunkName: "thank-you"*/ '../views/thank-you.vue'),
  },
  {
    path: '/privacy-policy',
    name: ROUTE_NAMES.PRIVACY_POLICY,
    component: () =>
      import(
        /*webpackChunkName: "privacy-policy"*/ '../views/privacy-policy.vue'
      ),
  },
  {
    path: '/copyright',
    name: ROUTE_NAMES.COPYRIGHT,
    component: () =>
      import(/*webpackChunkName: "copyright"*/ '../views/copyright.vue'),
  },
  {
    path: '/contact',
    name: ROUTE_NAMES.CONTACT,
    component: () =>
      import(/*webpackChunkName: "contact"*/ '../views/contact.vue'),
  },
  {
    path: '/confirm-email',
    name: ROUTE_NAMES.CONFIRM_EMAIL,
    component: () =>
      import(/*webpackChunkName: "confirmEmail"*/ '../views/confirm-email.vue'),
  },
  {
    path: '/password-reset/:uuid/:token',
    name: ROUTE_NAMES.PAS_RESET,
    component: () =>
      import(
        /*webpackChunkName: "passwordReset"*/ '../views/password-reset.vue'
      ),
    // meta: {
    //   icon:"../public/favicon.ico"
    // },
  },
  {
    // catch all 404 - define at the very end
    path: '/:catchAll(.*)',
    name: ROUTE_NAMES.NOT_FOUND,
    component: () => import('../views/not-found.vue'),
    meta: {
      requiresAuth: false,
    },
  },
];

// Registration of router
const router = createRouter({
  history: createWebHistory(),
  routes,
});

/**
 * Initializes confirmation save dialog
 * @param {*} to - new route
 * @param {*} from - old route
 * @param {*} next - callback for navigation to specified route
 * @returns boolean value to continue or stop navigating to next route
 */
const showConfirmationSaveDialog = (to, from, next) => {
  next(from.fullPath);

  if (stores.getters['popups/navigationButton'] < 2) {
    stores.commit('popups/SET_SHOW_VERIFY_SAVE_MODAL_MESSAGE', {
      header: 'Save changes',
      save: false,
      bodyText: 'You have unsaved changes, would you like to save them?',
      nextRoute: to,
      previousRoute: from,
    });
  }
  return false;
};

//check if browser navigation button(back or forward) was clicked
const handlePopstate = () => {
  const navigationButton = stores.getters['popups/navigationButton'] + 1;
  stores.commit('popups/SET_BROWSER_NAVIGATION', navigationButton);

  if (navigationButton === 2) {
    stores.commit('popups/SET_SHOW_VERIFY_SAVE_MODAL_MESSAGE', null);
    stores.commit('popups/SET_BROWSER_NAVIGATION', 0);
  }
};
window.addEventListener('popstate', handlePopstate);

/* Checking that the user has access to the project settings page */
const checkReadProjectAccess = (to, next) => {
  if (to.name === ROUTE_NAMES.PROJECT_SETTINGS) {
    stores
      .dispatch('projects/checkReadProjectAccess', to.params.projectId)
      .then(res => {
        if (!res) {
          next({
            name: ROUTE_NAMES.PROJECT_PORTFOLIO,
          });

          stores.dispatch('popups/setToast', [`Project was not found`, 3000], {
            root: true,
          });
        } else next();
      });
  } else next();
};

/* Checking the route before redirecting */
router.beforeEach((to, from, next) => {
  /* Checking whether the user is trying to get to the main page from a mobile device */
  const isMobileDev =
    window.innerWidth <= MOBILE_DETECT_WIDTH ||
    detectMobile() !== 'desktop' ||
    isMobile;

  /* Checking for unsaved changes on the task editing page */
  if (!stores.getters['auth/forceLogout']) {
    if (
      from.name === ROUTE_NAMES.TASK &&
      to.name !== ROUTE_NAMES.TASK &&
      stores.getters['tasksState/taskChanges'].length > 0 &&
      !stores.getters['tasks/taskUpdating']
    ) {
      return showConfirmationSaveDialog(to, from, next);
    }

    /* Checking for unsaved changes on the user settings page */
    if (
      from.path.toLowerCase() === '/settings/profile' &&
      stores.getters['users/isChangedProfile']
    ) {
      return showConfirmationSaveDialog(to, from, next);
    }

    if (
      from.path.toLowerCase() === '/settings/billing_details' &&
      stores.getters['auth/isChangedBilDetails']
    ) {
      return showConfirmationSaveDialog(to, from, next);
    }
  }

  setImmediate(() => {
    stores.commit('popups/SET_BROWSER_NAVIGATION', 0);
  });

  /* Checking whether the user is trying to get to the main page from a mobile device */
  if (to.name === ROUTE_NAMES.LOGIN && isMobileDev) {
    next({
      name: ROUTE_NAMES.LANDING,
      query: {
        isMobile: true,
      },
    });

    return;
  }

  checkReadProjectAccess(to, next);
});

export default router;
