import AxiosHandler from '@/helpers/axios';
import { getISODateFormat, slashesToDashes } from '@/helpers/dates';

function configEpicObject(epic) {
  const data = {};

  ['name', 'project', 'start_date', 'end_date', 'colour', 'position'].forEach(
    el => {
      if (epic[el] !== undefined) {
        data[el] = epic[el];

        if (['start_date', 'end_date'].includes(el)) {
          data[el] = slashesToDashes(
            new Date(epic[el]).toLocaleDateString('en-GB'),
          );
        }
      }
    },
  );

  return data;
}

export default {
  addEpicFields({ state }, epicsFetched) {
    return epicsFetched.map(e => {
      const epicsExists = state.epics.find(ep => ep.id === e.id);

      e['tasksOffset'] = epicsExists ? epicsExists.tasksOffset : 0;

      return e;
    });
  },

  async getEpicsForExport({ dispatch }, [level, pro]) {
    let query = '';
    if (level === 'project') {
      query += `?project=${pro.id}`;
    }

    const pendingObj = { fetchingProjectEpics: true };
    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/epics/${query}`,
            'get',
            '',
          );

          return res.data.results;
        },
      },
      { root: true },
    );
  },

  async fetchProjectEpics({ commit, dispatch, state }, pro) {
    if (pro) {
      const limit = 10;

      const existingEpics = state.epics.filter(e => e.project === pro.id);
      const epicsCount = existingEpics.length;
      const offset = Number(epicsCount ? epicsCount - (epicsCount % limit) : 0);

      const pendingObj = { fetchingProjectEpics: true };
      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            if (pro.epicsCount === 0 || pro.epicsCount > epicsCount) {
              const res = await AxiosHandler.call(
                null,
                `/epics/?project=${pro.id}&offset=${offset}&limit=${limit}`,
                'get',
                '',
              );

              commit(
                'SET_EPICS',
                await dispatch('addEpicFields', res.data.results),
              );

              pro['epicsCount'] = res.data.count;
            }
          },
        },
        { root: true },
      );
    }
  },

  epicsFilterQuery({ rootGetters }) {
    const filter = rootGetters['epics/filterEpics'];
    let query = '';
    for (const item of Object.keys(filter)) {
      if (filter[item].length > 0) {
        query += `&${item}=${filter[item]}`;
      }
    }
    return query;
  },

  async filterEpics({ commit, dispatch, rootGetters }, [pro, key, value]) {
    const filter = rootGetters['epics/filterEpics'];
    if (value) {
      filter[key] = value;
    } else {
      delete filter[key];
    }

    const query = await dispatch('epicsFilterQuery');

    const pendingObj = { filteringEpics: pro.id };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/epics/?project=${pro.id}&limit=10&offset=0${query}`,
            'get',
            '',
          );

          commit('SET_FILTERED_EPICS', res.data.results);
        },
      },
      { root: true },
    );
  },

  async loadMoreEpics({ commit, dispatch, rootGetters }, pro) {
    const projectEpics = rootGetters['epics/projectEpics'];
    let offset = projectEpics?.length ? projectEpics.length : 0;
    const limit = 10;
    const query = await dispatch('epicsFilterQuery');

    const pendingObj = { filterListTimesheets: query };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/epics/?project=${pro.id}&limit=${limit}&offset=${offset}${query}`,
            'get',
            '',
          );

          commit('SET_FILTERED_EPICS', projectEpics.concat(res.data.results));
        },
      },
      { root: true },
    );
  },

  async getEpicById({ commit, dispatch }, id) {
    const pendingObj = { loadingEpic: id };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(null, `/epics/${id}/`, 'get', '');

          commit('SET_EPICS', [res.data]);
        },
      },
      { root: true },
    );
  },

  async postEpic({ dispatch }, epic) {
    if (!epic) return;

    const pendingObj = { epicToAdd: epic.name };
    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            JSON.stringify(configEpicObject(epic)),
            `/epics`,
            'post',
            'application/json',
          );

          return res.data;
        },
      },
      { root: true },
    );
  },

  async deleteEpic({ rootGetters }, epic) {
    if (!epic) return;

    const pro = rootGetters['projects/projects'].find(
      p => p.id === epic.project,
    );
    if (!pro) return;

    await AxiosHandler.call(null, `/epics/${epic.id}`, 'delete', '');
  },

  async patchEpic({ commit }, epic) {
    if (!epic) return;

    const res = await AxiosHandler.call(
      JSON.stringify(configEpicObject(epic)),
      `/epics/${epic.id}`,
      'patch',
      'application/json',
    );

    commit('PATCH_EPIC', res.data);
  },

  async reorderEpics({ commit }, { proId, oldPos, newPos, order }) {
    const data = JSON.stringify({
      moving: oldPos < newPos ? 'down' : 'up',
      start_position: Math.min(oldPos, newPos),
      end_position: Math.max(oldPos, newPos),
    });

    await AxiosHandler.call(
      data,
      `/epics/${proId}/reorder`,
      'put',
      'application/json',
    );

    commit('PATCH_EPICS', order);
  },

  createEpicObject({ commit, rootGetters }, isDraggable = false) {
    if (!rootGetters['projects/selectedProject']) return;

    const stDate = new Date();

    const enDate = new Date(
      stDate.getFullYear(),
      stDate.getMonth(),
      stDate.getDate() + 21,
    );

    commit('SET_CREATING_EPIC', {
      id: 'creating-epic',
      name: '',
      project: rootGetters['projects/selectedProject'].id,
      start_date: getISODateFormat(stDate),
      end_date: getISODateFormat(enDate),
      colour: 0,
      IS_DRAGGING_NOW: isDraggable,
      position: isDraggable ? 1000000 : -1,
    });
  },
};
