<template>
  <div v-updated-show="loadPage" class="loading-page">
    <div class="loading-div">
      <progress class="pure-material-progress-circular" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'page-loading',

  computed: {
    ...mapGetters('viewState', ['loadPage']),
  },
};
</script>

<style scoped>
.loading-page {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-div {
  margin: 5% 0 0 0;
  justify-content: center;
  width: 100%;
}
</style>
