import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,

  state() {
    return {
      filteredSearchedTasks: [],
      searchedLists: [],
      search: '',
      isSearching: false,
      taskSearching: {
        query: '',
        offset: 0,
        count: 0,
      },
    };
  },
  actions,
  getters,
  mutations,
};
