import router from '../../../router/index';
import { themeColors } from '@/style/styles';
import Cookies from '@/helpers/cookies';
import Tokens from '@/api/tokens';
import AxiosHandler from '@/helpers/axios';
import {
  processInvoices,
  processInvoiceItems,
  checkQueryFilter,
} from '@/helpers/helpers';

/**
 * @typedef {import('@/api/tokens.js').LoginData}
 */
export default {
  /**
   * login
   * @param dispatch
   * @param state
   * @param payload {LoginData}
   * @returns {Promise<void>}
   */
  async login({ dispatch, state }, payload) {
    await dispatch('authorize', payload);

    await dispatch('getUser');
    localStorage.setItem(
      'teamfuLight',
      `${state.profile.lightTheme ? 'light' : 'dark'}`,
    );

    await dispatch('projects/fetchProjects', {}, { root: true });

    dispatch('pendingRequests');
  },

  /**
   *
   * @param commit
   * @param payload {LoginData}
   * @returns {Promise<void>}
   */
  async authorize({ commit }, payload) {
    const JWT = await Tokens.obtain(payload);
    commit('SET_TOKENS', JWT);
  },

  async updateSettings({ commit }, payload) {
    const data = JSON.stringify(payload);

    const settings = await AxiosHandler.call(
      data,
      `profile/settings`,
      'patch',
      'application/json',
    );

    commit('USER_SETTINGS', settings.data.settings_list);
  },

  async refreshToken({ commit }) {
    const JWT = await Tokens.refresh();
    commit('SET_TOKENS', JWT);
    return JWT;
  },

  async getUser({ commit, dispatch }) {
    const user = await AxiosHandler.call('', `/profile`, 'get', '');
    commit('SET_PROFILE', user.data);
    commit('users/SET_USERS', [user.data], { root: true });

    dispatch('fetchSettngs');

    dispatch('notifications/getNotifications', {}, { root: true });
  },

  async fetchSettngs({ commit }) {
    const settings = await AxiosHandler.call(
      '',
      `/profile/settings`,
      'get',
      'application/json',
    );

    commit('USER_SETTINGS', settings.data.settings_list);
  },

  async pendingRequests({ commit }) {
    const res = await AxiosHandler.call(
      '',
      `/pending_requests`,
      'get',
      'application/json',
    );

    commit('SET_PENDDING_REQUESTS', res.data.results);
  },

  async getUsersBillingDetails({ commit }) {
    try {
      const res = await AxiosHandler.call(
        '',
        `/payment/details/`,
        'get',
        'application/json',
      );

      if (res.data.count > 0) {
        commit('SET_BILLING_DETAILS', res.data.results[0]);
        commit('SET_USER_BILLING_DETAILS', { ...res.data.results[0] });
        return res.data.results[0];
      } else {
        return {};
      }
    } catch (e) {
      return;
    }
  },

  async getCountries({ dispatch }) {
    const pendingObj = { loadingCountries: true };

    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            '',
            `/lookup/country/`,
            'get',
            'application/json',
          );

          return res.data.results;
        },
      },
      { root: true },
    );
  },

  async getCountryByCode(_, code) {
    const res = await AxiosHandler.call(
      '',
      `/lookup/country/${code}`,
      'get',
      'application/json',
    );

    return res.data;
  },

  async createBillingDetails({ commit }, data) {
    const res = await AxiosHandler.call(
      data,
      `/payment/details/`,
      'post',
      'application/json',
    );

    commit('SET_USER_BILLING_DETAILS', { ...res.data });
    commit('SET_BILLING_DETAILS', { ...res.data });
  },

  async updateBillingDetails(_, [userId, data]) {
    return await AxiosHandler.call(
      data,
      `/payment/details/${userId}/`,
      'patch',
      'application/json',
    );
  },

  async initializePaymentCard() {
    const res = await AxiosHandler.call(
      {},
      `/payment/details/cards/`,
      'post',
      'application/json',
    );

    return res.data;
  },

  async updatePaymentCard({ commit, rootGetters }, [id, data]) {
    let cards = rootGetters['auth/paymentCards'];

    await AxiosHandler.call(
      data,
      `/payment/details/cards/${id}`,
      'patch',
      'application/json',
    );

    if (data.preferred_card) {
      cards = cards.map(c => ({ ...c, preferred_card: false }));
    }

    const card = cards.find(c => c.id === id);
    if (card) {
      if (data.preferred_card) {
        card.preferred_card = true;
      }

      if (data.name) {
        card.name = data.name;
      }
    }

    commit('SET_PAYMENT_CARDS', cards);
  },

  async setCardTimeout(_, [cardId, data]) {
    await AxiosHandler.call(
      data,
      `/payment/details/cards/${cardId}/timeout`,
      'post',
      'application/json',
    );
  },

  async removePaymentCard({ commit, rootGetters }, [card]) {
    await AxiosHandler.call(
      null,
      `/payment/details/cards/${card.id}`,
      'delete',
      'application/json',
    );

    const cards = rootGetters['auth/paymentCards'].filter(
      c => c.id !== card.id,
    );
    if (card.preferred_card && cards.length > 0) {
      cards[0].preferred_card = true;
    }

    commit('SET_PAYMENT_CARDS', cards);
  },

  async getPaymentCards({ commit }) {
    const res = await AxiosHandler.call(
      {},
      `/payment/details/cards/`,
      'get',
      'application/json',
    );

    commit('SET_PAYMENT_CARDS', res.data.results);
  },

  async getPaymentCard(_, [cardId]) {
    const res = await AxiosHandler.call(
      {},
      `/payment/details/cards/${cardId}`,
      'get',
      'application/json',
    );

    return res.data;
  },

  async removeAddedCard(_, [id]) {
    await AxiosHandler.call(
      null,
      `/payment/details/cards/${id}`,
      'delete',
      'application/json',
    );
  },

  async pendingRequestsByList({ commit }, listId) {
    const res = await AxiosHandler.call(
      '',
      `/pending_requests/?previous_list=${listId}`,
      'get',
      'application/json',
    );

    commit('SET_PENDDING_REQUESTS', res.data.results);
  },

  async toggleTheme({ state, commit, dispatch }) {
    themeColors(!state.profile.lightTheme);
    await commit('SET_THEME', !state.profile.lightTheme);

    dispatch('viewState/setDefaultAvatar', !state.profile.lightTheme, {
      root: true,
    });

    const data = JSON.stringify({ lightTheme: state.profile.lightTheme });
    await AxiosHandler.call(data, `/profile`, 'put', 'application/json');
  },

  async getUserPreConfirmEmail() {
    return await AxiosHandler.call(
      '',
      `/profile/email/`,
      'get',
      'application/json',
    );
  },

  async loadBillingQuery(_, queryId) {
    const res = await AxiosHandler.call(
      '',
      `/billing/query/${queryId}`,
      'get',
      'application/json',
    );

    return res.data;
  },

  async loadBillingQueryNotes({ dispatch }, [queryId, offset]) {
    const pendingObj = { loadingNotes: true };

    const res = await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            '',
            `/billing/query/note/?limit=10&offset=${offset}&billing_query=${queryId}`,
            'get',
            'application/json',
          );

          return res.data;
        },
      },
      { root: true },
    );

    return res;
  },

  async loadBillingQueries({ commit, dispatch, rootGetters }) {
    const billQueries = rootGetters['auth/billingQueries'];
    const pendingObj = { loadingBillingQueries: true };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            '',
            `/billing/query/?limit=10&offset=${billQueries.length}`,
            'get',
            'application/json',
          );

          commit('SET_BILLING_QUERIES', billQueries.concat(res.data.results));
        },
      },
      { root: true },
    );
  },

  async billingSearchQuery({ rootGetters }) {
    const filter = rootGetters['auth/filterBillingQuery'];

    let query = '';

    if (filter?.subject) {
      query += '&subject=' + filter.subject;
    }

    if (filter?.created_on) {
      query += '&created_on=' + filter.created_on;
    }

    if (filter?.resolved) {
      query += '&resolved=' + filter.resolved;
    }

    return query;
  },

  async filterBillingQueries({ commit, dispatch, rootGetters }, [key, value]) {
    const filter = rootGetters['auth/filterBillingQuery'];
    if (value) {
      filter[key] = value;
    } else {
      delete filter[key];
    }

    const query = await dispatch('billingSearchQuery');

    const pendingObj = { filteredBillingQuery: query };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/billing/query/?limit=10&offset=0${query}`,
            'get',
            '',
          );

          commit('SET_FILTERED_BILLING_QUERIES', res.data.results);
        },
      },
      { root: true },
    );
  },

  async loadFilteredBillingQueries({ commit, dispatch, rootGetters }) {
    const billingQueries = rootGetters['auth/billingQueries'];
    const query = await dispatch('billingSearchQuery');

    const pendingObj = { filteredBillingQuery: query };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/billing/query/?limit=10&offset=${billingQueries.length}${query}`,
            'get',
            '',
          );

          commit(
            'SET_FILTERED_BILLING_QUERIES',
            rootGetters['auth/filteredBillingQueries'].concat(res.data.results),
          );
        },
      },
      { root: true },
    );
  },

  async exportBillingQueries({ dispatch }) {
    const query = await dispatch('billingSearchQuery');
    const pendingObj = { exportingQuery: true };
    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/billing/query/?offset=0${query}`,
            'get',
            '',
          );

          return res.data.results;
        },
      },
      { root: true },
    );
  },

  async addBillingQueryAttachments({ rootGetters }, [id, files]) {
    files.forEach(async file => {
      const FormData = require('form-data');
      const data = new FormData();

      data.append('file', file);

      const res = await AxiosHandler.call(
        data,
        `/attachment/${id}/billing/query`,
        'post',
        '',
      );

      const query = rootGetters['auth/selectedQuery'];
      if (query) {
        query.attachments.push(res.data);
      } else {
        const queries = rootGetters['auth/billingQueries'];
        const quer = queries.find(q => q.id === id);

        if (quer) {
          quer.attachments.push(res.data);
        }
      }
    });
  },

  async addBillingQuery({ dispatch, rootGetters }, postData) {
    const queries = rootGetters['auth/billingQueries'];
    const filter = rootGetters['auth/filterBillingQuery'];
    const pendingObj = { creatingBillingQuery: true };
    const res = await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            postData,
            `/billing/query/`,
            'post',
            'application/json',
          );

          if (checkQueryFilter(res.data, filter)) {
            queries.unshift(res.data);
          }
          return res.data;
        },
      },
      { root: true },
    );

    return res;
  },

  async editBillingQuery({ dispatch, rootGetters }, [id, data]) {
    const queries = rootGetters['auth/billingQueries'];
    const filter = rootGetters['auth/filterBillingQuery'];
    const pendingObj = { updatingBillingQuery: true };
    const res = await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            data,
            `/billing/query/${id}`,
            'patch',
            'application/json',
          );

          const index = queries.findIndex(q => q.id === id);
          const check = checkQueryFilter(res.data, filter);
          if (check && index > -1) {
            queries.splice(index, 1, res.data);
          }

          return res.data;
        },
      },
      { root: true },
    );

    return res;
  },

  async getInvoiceById(_, invoiceId) {
    const res = await AxiosHandler.call(
      null,
      `/payment/${invoiceId}`,
      'get',
      'application/json',
    );

    return processInvoiceItems([res.data])[0];
  },

  async addBillingNote({ dispatch }, data) {
    const pendingObj = { addingBillingNote: true };

    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            data,
            `/billing/query/note`,
            'post',
            'application/json',
          );

          return res.data;
        },
      },
      { root: true },
    );
  },

  async patchBillingNote({ dispatch, rootGetters }, [noteId, data]) {
    const notes = rootGetters['auth/billingNotes'];
    const note = notes.find(n => n.id === noteId);
    note.isEditing = false;

    const pendingObj = { editingBillingNote: true };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(
            data,
            `/billing/query/note/${noteId}`,
            'patch',
            'application/json',
          );

          note.note = data.note;
          note.edited = true;
        },
      },
      { root: true },
    );
  },

  async removeBillingNote({ commit, dispatch, rootGetters }, noteId) {
    const notes = rootGetters['auth/billingNotes'];
    const pendingObj = { removingBillingNote: true };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(
            null,
            `/billing/query/note/${noteId}`,
            'delete',
            'application/json',
          );

          dispatch(
            'popups/setToast',
            [`Billing note was removed from billing query`, 3000],
            { root: true },
          );
          commit(
            'SET_BILLING_NOTES',
            notes.filter(n => n.id !== noteId),
          );
        },
      },
      { root: true },
    );
  },

  async addNoteAttachments({ rootGetters }, [id, files]) {
    const notes = rootGetters['auth/billingNotes'];
    const note = notes.find(n => n.id === id);

    files.forEach(async file => {
      const FormData = require('form-data');
      const data = new FormData();

      data.append('file', file);

      const res = await AxiosHandler.call(
        data,
        `/attachment/${id}/billing/query/note`,
        'post',
        '',
      );

      note.attachments.push(res.data);
    });
  },

  async removeBillingNoteAttachment({ rootGetters }, [attId, noteId]) {
    const data = JSON.stringify({ id: attId });
    await AxiosHandler.call(
      data,
      `/attachment/${noteId}/billing/query/note`,
      'delete',
      'application/json',
    );

    const notes = rootGetters['auth/billingNotes'];
    const noteForUpdate = notes.find(n => n.id === noteId);
    if (noteForUpdate) {
      noteForUpdate.attachments = noteForUpdate.attachments.filter(
        a => a.id !== attId,
      );
    }
  },

  async removeBillingQuery({ commit, dispatch, rootGetters }, id) {
    const queries = rootGetters['auth/billingQueries'];
    const pendingObj = { removingBillingQuery: true };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(
            null,
            `/billing/query/${id}`,
            'delete',
            'application/json',
          );

          commit(
            'SET_BILLING_QUERIES',
            queries.filter(q => q.id !== id),
          );
          dispatch('popups/setToast', ['Billing query was removed', 3000], {
            root: true,
          });
        },
      },
      { root: true },
    );
  },

  async removeBillingQueryAttachment({ rootGetters }, [attId, queryId]) {
    const data = JSON.stringify({ id: attId });
    await AxiosHandler.call(
      data,
      `/attachment/${queryId}/billing/query`,
      'delete',
      'application/json',
    );

    const queryForUpdate = rootGetters['auth/selectedQuery'];
    queryForUpdate.attachments = queryForUpdate.attachments.filter(
      a => a.id !== attId,
    );
  },

  async updateUserEmailRequest(_, new_email) {
    const res = await AxiosHandler.call(
      JSON.stringify({ new_email }),
      `/profile/email`,
      'post',
      'application/json',
    );

    return res;
  },

  async updateUserProfile({ dispatch, rootGetters }, payload) {
    await AxiosHandler.call(payload, `/profile`, 'put', 'application/json');
    dispatch('users/setUser', rootGetters['auth/userProfile'], { root: true });
  },

  async updateUserName({ commit, rootGetters, dispatch }, payload) {
    const data = JSON.stringify({ display_name: payload });
    await AxiosHandler.call(data, `/profile`, 'put', 'application/json');
    await commit('SET_PROFILE_NAME', payload);
    dispatch('users/setUser', rootGetters['auth/userProfile'], { root: true });
  },

  async updateUserAvatar({ commit, rootGetters, dispatch }, payload) {
    const res = await AxiosHandler.call(
      JSON.stringify({ avatar: payload }),
      `/profile/${rootGetters['auth/userProfile'].id}/avatar`,
      'put',
      'application/json',
    );

    commit('SET_USER_AVATAR', res.data.avatar_id);
    dispatch('users/setUser', rootGetters['auth/userProfile'], { root: true });
  },

  destroyMainPage({ commit }) {
    commit('DESTROY_MAIN_PAGE');
  },

  async updateProjectOrder({ commit }, payload) {
    await commit('SET_PROJECT_ORDER', payload);

    let data = JSON.stringify({ projects: payload });

    AxiosHandler.call(data, `/profile`, 'put', 'application/json');
  },

  async processLogoutEvents({ dispatch, commit }) {
    await Tokens.revoke();

    commit('SET_TOKENS', {});
    dispatch('destroyMainPage');
    dispatch('destroySockets');
    dispatch('tasks/logout', {}, { root: true });
    dispatch('lists/logout', {}, { root: true });
    dispatch('searchAll/logout', {}, { root: true });
    dispatch('tasksState/logout', {}, { root: true });
    dispatch('viewState/logout', {}, { root: true });
    dispatch('permissions/clearAll', {}, { root: true });
    commit('notifications/LOGOUT_USER', {}, { root: true });
    commit('projects/LOGOUT_USER', {}, { root: true });
    commit('auth/LOGOUT_USER', {}, { root: true });
    dispatch('common/clearAll', {}, { root: true });

    Cookies.delete('refresh');
    Cookies.delete('access');
  },

  async logoutUser({ commit, state, dispatch, rootGetters }) {
    commit('SET_IS_INITIATED_LOGOUT_PROCESS', true);

    await router.push('/login');

    commit('SET_IS_INITIATED_LOGOUT_PROCESS', false);
    commit('SET_FORCE_LOGOUT', false);

    if (state.processLogout && !rootGetters['users/isChangedProfile']) {
      dispatch('processProfileLogout', false);
    }
  },

  processProfileLogout({ commit }, payload) {
    commit('SET_PROCESS_LOGOUT', payload);
  },

  resetRefresh({ commit }, payload) {
    commit('SET_REFRESH', payload);
  },

  setAppearCookiePopup({ commit }, payload) {
    commit('SET_APPEAR_COOKIE_POPUP', payload);
  },

  setUserSocket({ commit }, payload) {
    commit('SET_USER_SOCKET', payload);
  },

  destroySockets({ dispatch, commit, rootGetters }) {
    if (rootGetters['auth/userSocket']) {
      rootGetters['auth/userSocket'].close();
      commit('SET_USER_SOCKET', null);
    }

    if (rootGetters['auth/listSockets']?.length > 0) {
      rootGetters['auth/listSockets'].forEach(sock => sock.close());
      commit('SET_LIST_SOCKETS', []);
      dispatch('listSockets/disconnectAllLists', [], { root: true });
    }

    if (rootGetters['auth/projectSockets']?.length > 0) {
      rootGetters['auth/projectSockets'].forEach(sock => sock.close());
      commit('SET_PROJECT_SOCKETS', []);
      dispatch('projectSockets/disconnectAllProjects', [], { root: true });
    }

    if (rootGetters['auth/taskSocket']) {
      rootGetters['auth/taskSocket'].close();
      commit('SET_TASK_SOCKET', null);
    }
  },

  addProjectSocket({ commit }, payload) {
    commit('ADD_PROJECT_SOCKET', payload);
  },

  addListSocket({ commit }, payload) {
    commit('ADD_LIST_SOCKET', payload);
  },

  setTaskSocket({ commit }, payload) {
    commit('SET_TASK_SOCKET', payload);
  },

  async loadInvoices() {
    const res = await AxiosHandler.call(
      null,
      `/payment/?status=RESOLVED`,
      'get',
      'application/json',
    );

    return processInvoiceItems(res.data.results);
  },

  async loadPaymentHistory({ commit, dispatch, rootGetters }, offset) {
    const pendingObj = { loadingPaymentHistory: true };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/payment/?limit=10&offset=${offset}`,
            'get',
            'application/json',
          );

          const paymentHistory = rootGetters['auth/paymentHistory'];
          commit(
            'auth/SET_PAYMENT_HISTORY',
            paymentHistory.concat(processInvoices(res.data.results)),
            { root: true },
          );
        },
      },
      { root: true },
    );
  },

  paymentHistoryQuery({ rootGetters }) {
    const filter = rootGetters['auth/filterPaymentHistory'];
    let query = '';

    if (filter.initiated_date) {
      query += '&initiated_date=' + filter.initiated_date;
    }

    if (filter.status) {
      query += '&status=' + filter.status;
    }

    return query;
  },

  async filterPaymentHistory({ commit, dispatch, rootGetters }, [key, value]) {
    const filter = rootGetters['auth/filterPaymentHistory'];
    if (value) {
      if (Array.isArray(value)) {
        filter[key] = [...value];
      } else {
        filter[key] = value;
      }
    } else {
      delete filter[key];
    }

    const query = await dispatch('paymentHistoryQuery');

    const pendingObj = { filterListTimesheets: query };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/payment/?limit=10&offset=0${query}`,
            'get',
            '',
          );

          commit(
            'SET_FILTERED_PAYMENT_HISTORY',
            processInvoices(res.data.results),
          );
        },
      },
      { root: true },
    );
  },

  async loadFilteredPaymentHistory({ commit, dispatch, rootGetters }) {
    const filteredHistory = rootGetters['auth/filteredPaymentHistory'];
    let offset = filteredHistory?.length ? filteredHistory.length : 0;
    let query = await dispatch('paymentHistoryQuery');

    const pendingObj = { loadingPaymentHistory: query };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/payment/?limit=10&offset=${offset}${query}`,
            'get',
            '',
          );

          commit(
            'SET_FILTERED_PAYMENT_HISTORY',
            filteredHistory.concat(processInvoices(res.data.results)),
          );
        },
      },
      { root: true },
    );
  },

  async loadExportPaymentHistry({ dispatch }) {
    let query = await dispatch('paymentHistoryQuery');

    const res = await AxiosHandler.call(null, `/payment/?${query}`, 'get', '');

    return res.data.results;
  },
};
