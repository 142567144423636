export default {
  draggedUser: state => {
    return state.draggedUser;
  },

  tasksDisplayed: state => {
    return state.tasksDisplayed;
  },

  selectedUsers: state => {
    return state.selectedUsers;
  },

  addedTask: state => {
    return state.addedTask;
  },

  showEditAdd: state => {
    return state.showEditAdd;
  },

  taskToEdit: state => {
    return state.taskToEdit;
  },

  draggedTask: state => {
    return state.draggedTask;
  },

  editingUsers: state => {
    return state.editingUsers;
  },

  draggedAttachIntoDesc: state => {
    return state.draggedAttachIntoDesc;
  },

  isEditingNow: state => {
    return state.isEditingNow;
  },

  taskChanges: state => {
    return state.taskChanges;
  },
};
