export default {
  async comment_created({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    let com = m.comment_created;

    if (
      task.comments &&
      !task.comments.includes(c => c === m.comment_created.id)
    ) {
      task.comments = [com.id, ...task.comments];
    }

    if (
      task.actualComments &&
      !task.actualComments.some(c => c.id === m.comment_created.id)
    ) {
      com['actualReplies'] = [];
      com['total_replies'] = com['total_replies'] ? com['total_replies'] : 0;

      task.actualComments = [com, ...task.actualComments];
    }

    task.comments = [...new Set(task.comments)];

    dispatch('tasks/updateTaskInList', task, { root: true });

    commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
  },

  async update_comment({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (task?.actualComments?.some(c => c.id === m.comment_id)) {
      const editedComment = await task.actualComments.find(
        c => c.id === m.comment_id,
      );

      //modify editedComment

      for (const [key, value] of Object.entries(m['changes'])) {
        editedComment[key] = value;
      }

      const i = task.actualComments.map(e => e.id).indexOf(m.comment_id);
      task.actualComments.splice(i, 1, editedComment);
      task.actualComments = [...task.actualComments];
      dispatch('tasks/updateTaskInList', task, { root: true });

      commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
    }
  },

  async comment_deleted({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (task?.comments) {
      task.comments = task.comments.filter(c => c !== m.comment_deleted);
    }

    if (task?.actualComments) {
      task.actualComments = [
        ...task.actualComments.filter(c => c.id !== m.comment_deleted),
      ];
    }
    task.total_num_of_comments--;

    dispatch('tasks/updateTaskInList', task, { root: true });

    commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
  },

  async comment_reaction_added({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (
      task.actualComments.some(c => c.id === m.comment_reaction_added.comment)
    ) {
      const editedComment = await task.actualComments.find(
        c => c.id === m.comment_reaction_added.comment,
      );

      //modify editedComment

      if (
        !editedComment.reactions.some(
          r => r.reaction === m.comment_reaction_added.reaction,
        )
      ) {
        editedComment.reactions.push(m.comment_reaction_added);
      } else {
        editedComment.reactions.find(
          r => r.reaction === m.comment_reaction_added.reaction,
        ).users = m.comment_reaction_added.users;
      }

      const i = task.actualComments
        .map(e => e.id)
        .indexOf(m.comment_reaction_added.comment);

      task.actualComments.splice(i, 1, editedComment);
      task.actualComments = [...task.actualComments];
      dispatch('tasks/updateTaskInList', task, { root: true });

      commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
    }
  },

  async comment_reaction_removed({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (
      task.actualComments.some(c => c.id === m.comment_reaction_removed.comment)
    ) {
      const editedComment = await task.actualComments.find(
        c => c.id === m.comment_reaction_removed.comment,
      );

      //modify editedComment

      if (m.comment_reaction_removed.users.length === 0) {
        editedComment.reactions = editedComment.reactions.filter(
          r => r.reaction !== m.comment_reaction_removed.reaction,
        );
      } else {
        editedComment.reactions.find(
          r => r.reaction === m.comment_reaction_removed.reaction,
        ).users = m.comment_reaction_removed.users;
      }

      const i = task.actualComments
        .map(e => e.id)
        .indexOf(m.comment_reaction_removed.comment);
      task.actualComments.splice(i, 1, editedComment);
      task.actualComments = [...task.actualComments];
      dispatch('tasks/updateTaskInList', task, { root: true });

      commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
    }
  },

  async comment_attachment_created({ rootGetters, dispatch, commit }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (task.actualComments.some(c => c.id === m.comment_id)) {
      const comment = task.actualComments.find(
        comment => comment.id == m.comment_id,
      );

      if (!comment.attachments.some(at => at.id === m.attachment_created.id)) {
        comment.attachments.push(m.attachment_created);

        let i = task.actualComments.map(e => e.id).indexOf(m.reply_comment_id);

        task.actualComments[i] = comment;
        dispatch('tasks/updateTaskInList', task, { root: true });

        commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
      }
    }
  },

  async comment_attachment_deleted({ rootGetters, dispatch, commit }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    const comment = task.actualComments.find(c => c.id == m.comment_id);
    if (comment?.attachments.some(at => at.id === m.attachment_deleted)) {
      comment.attachments = comment.attachments.filter(
        at => at.id !== m.attachment_deleted,
      );
      commit('SET_COMMENT_ATTACHMENTS', comment.attachments);

      let i = task.actualComments.map(e => e.id).indexOf(m.reply_comment_id);

      task.actualComments[i] = comment;

      dispatch('tasks/updateTaskInList', task, { root: true });

      commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
    }
  },

  /* ----------------------------------------------------------- Reply sockets --------------------------------------------------------------------------------------------*/

  async reply_created({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (task.actualComments.some(c => c.id === m.reply_created.comment_id)) {
      const com = task.actualComments.find(
        c => c.id === m.reply_created.comment_id,
      );
      com.total_replies += 1;

      if (!com.actualReplies.some(r => r.id === m.reply_created.id)) {
        com.actualReplies = [m.reply_created, ...com.actualReplies];
      }

      dispatch('tasks/updateTaskInList', task, { root: true });
      commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
    }
  },

  async reply_updated({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (task.actualComments.some(c => c.id === m.comment_id)) {
      const com = task.actualComments.find(c => c.id === m.comment_id);

      if (com.actualReplies.some(r => r.id === m.reply_id)) {
        const replyUpdated = com.actualReplies.find(
          rep => rep.id == m.reply_id,
        );

        for (const [key, value] of Object.entries(m['changes'])) {
          replyUpdated[key] = value;
        }

        const i = com.actualReplies.map(e => e.id).indexOf(m.reply_id);
        com.actualReplies.splice(i, 1, replyUpdated);
        com.actualReplies = [...com.actualReplies];

        dispatch('tasks/updateTaskInList', task, { root: true });

        commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
      }
    }
  },

  async reply_deleted({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (task.actualComments.some(c => c.id === m.comment_id)) {
      const com = task.actualComments.find(c => c.id === m.comment_id);

      com.total_replies -= 1;
      com.actualReplies = com.actualReplies.filter(
        re => re.id !== m.reply_deleted,
      );

      task.total_num_of_comments--;

      dispatch('tasks/updateTaskInList', task, { root: true });

      commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
    }
  },

  async reply_reaction_added({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (
      task.actualComments.some(c => c.id === m.reply_reaction_added.comment_id)
    ) {
      const com = task.actualComments.find(
        c => c.id === m.reply_reaction_added.comment_id,
      );

      if (com.actualReplies.some(r => r.id === m.reply_reaction_added.reply)) {
        const replyUpdated = com.actualReplies.find(
          rep => rep.id == m.reply_reaction_added.reply,
        );

        if (
          !replyUpdated.reactions.some(
            r => r.reaction === m.reply_reaction_added.reaction,
          )
        ) {
          replyUpdated.reactions.push(m.reply_reaction_added);
        } else {
          replyUpdated.reactions.find(
            r => r.reaction === m.reply_reaction_added.reaction,
          ).users = m.reply_reaction_added.users;
        }

        const i = com.actualReplies
          .map(e => e.id)
          .indexOf(m.reply_reaction_added.reply);
        com.actualReplies.splice(i, 1, replyUpdated);
        com.actualReplies = [...com.actualReplies];

        dispatch('tasks/updateTaskInList', task, { root: true });

        commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
      }
    }
  },

  async reply_reaction_removed({ rootGetters, commit, dispatch }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (
      task.actualComments.some(
        c => c.id === m.reply_reaction_removed.comment_id,
      )
    ) {
      const com = task.actualComments.find(
        c => c.id === m.reply_reaction_removed.comment_id,
      );

      if (
        com.actualReplies.some(r => r.id === m.reply_reaction_removed.reply)
      ) {
        const replyUpdated = com.actualReplies.find(
          rep => rep.id == m.reply_reaction_removed.reply,
        );

        if (m.reply_reaction_removed.users.length === 0) {
          replyUpdated.reactions = replyUpdated.reactions.filter(
            re => re.reaction !== m.reply_reaction_removed.reaction,
          );
        } else {
          replyUpdated.reactions.find(
            r => r.reaction === m.reply_reaction_removed.reaction,
          ).users = m.reply_reaction_removed.users;
        }

        const i = com.actualReplies
          .map(e => e.id)
          .indexOf(m.reply_reaction_removed.reply);
        com.actualReplies.splice(i, 1, replyUpdated);
        com.actualReplies = [...com.actualReplies];

        dispatch('tasks/updateTaskInList', task, { root: true });

        commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
      }
    }
  },

  async reply_attachment_created({ rootGetters, dispatch, commit }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    if (task.actualComments.some(c => c.id === m.reply_comment_id)) {
      const comment = task.actualComments.find(
        c => c.id === m.reply_comment_id,
      );

      if (comment.actualReplies.some(r => r.id === m.reply_id)) {
        const reply = comment.actualReplies.find(r => r.id == m.reply_id);

        if (!reply.attachments.some(a => a == m.attachment_created)) {
          reply.attachments.push(m.attachment_created);
        }

        let i = comment.actualReplies.map(e => e.id).indexOf(m.reply_id);

        comment.actualReplies[i] = reply;

        i = task.actualComments.map(e => e.id).indexOf(m.reply_comment_id);

        task.actualComments[i] = comment;

        dispatch('tasks/updateTaskInList', task, { root: true });

        commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
      }
    }
  },

  async reply_attachment_deleted({ rootGetters, dispatch, commit }, m) {
    const task = rootGetters['tasksState/taskToEdit'];

    const comment = task.actualComments.find(c =>
      c.actualReplies.find(r => r.id === m.reply_id),
    );
    if (comment) {
      const reply = comment.actualReplies.find(r => r.id == m.reply_id);
      if (reply) {
        reply.attachments = reply.attachments.filter(
          at => at.id !== m.attachment_deleted,
        );
        commit('SET_COMMENT_ATTACHMENTS', reply.attachments);

        let i = comment.actualReplies.map(e => e.id).indexOf(m.reply_id);

        comment.actualReplies[i] = reply;

        i = task.actualComments.map(e => e.id).indexOf(m.reply_comment_id);

        task.actualComments[i] = comment;

        dispatch('tasks/updateTaskInList', task, { root: true });

        commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
      }
    }
  },
};
