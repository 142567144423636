import getEnv from '../../../../app.config';
import AxiosHandler from '@/helpers/axios';

const urlMedia = getEnv('VUE_APP_ATTACHMENTS_ENDPOINT');

export default {
  // ----------------------------------------- comments --------------------------------------------------------------------------------

  async createComment({ dispatch }, [comment, attachments, task]) {
    let com;

    const data = JSON.stringify({
      task: comment.task,
      edited: false,
      comment: comment.comment,
      attachments: comment.attachments,
      showReplies: false,
    });

    const pendingObj = { commentToAdd: comment.comment };

    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            data,
            `/comment`,
            'post',
            'application/json',
          );

          com = res.data;

          attachments.forEach(async attachment => {
            const FormData = require('form-data');
            const data = new FormData();

            data.append('file', attachment);

            await AxiosHandler.call(
              data,
              `/attachment/${com.id}/comment`,
              'post',
              '',
            );
          });

          //modify comment

          com['actualReplies'] = [];
          com['total_replies'] = com['total_replies']
            ? com['total_replies']
            : 0;

          //set task

          if (!task.comments.includes(c => c === com.id)) {
            task.comments = [com.id, ...task.comments];
          }

          if (!task.actualComments.some(c => c.id === com.id)) {
            task.actualComments = [com, ...task.actualComments];
          }

          task.comments = [...new Set(task.comments)];

          dispatch('tasks/updateTaskInList', task, { root: true });

          return com.id;
        },
      },
      { root: true },
    );
  },

  async getCommentsPerPage({ dispatch, rootGetters }, [task, limit, offset]) {
    const res = await AxiosHandler.call(
      '',
      `/comment/?limit=${limit}&offset=${offset}&task=${task.id}`,
      'get',
      null,
    );

    const comments = res.data.results;
    const users = rootGetters['users/users'];

    if (!task.actualComments) {
      task.actualComments = [];
    }
    if (!task.comments) {
      task.comments = [];
    }

    comments.forEach(com => {
      const comOwner = users.find(u => u.id === com.user);
      com['username'] = comOwner.display_name;

      com['actualReplies'] = [];

      com['replies'] = [];

      com.showReplies = false;

      const i = task.actualComments.map(c => c.id).indexOf(com.id);

      if (i != -1) {
        task.actualComments[i] = com;
      } else {
        task.actualComments = [com, ...task.actualComments];
      }
      if (!task.comments.some(c => c == com.id)) {
        task.comments = [com.id, ...task.comments];
      }
    });

    task.actualComments.sort((a, b) => (a.id < b.id ? 1 : -1));
    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async getCommentById({ rootGetters, dispatch }, [id, task]) {
    const res = await AxiosHandler.call('', `/comment/${id}`, 'get', null);

    const com = res.data;

    //modify comment

    const users = rootGetters['users/users'];

    const comOwner = users.find(u => u.id === com.user);

    com['username'] = comOwner.display_name;

    com['actualReplies'] = [];

    com.showReplies = false;

    //push to task

    if (task.actualComments.filter(c => c.id === com.id).length === 0) {
      task.actualComments = [com, ...task.actualComments];
    }

    task.actualComments.sort((a, b) => (a.id < b.id ? 1 : -1));
    //update store

    await dispatch('tasks/updateTaskInList', task, { root: true });

    dispatch('comments/getRepliesPerPage', [com, task, 9, 0], { root: true });
  },

  async editComment({ dispatch }, [comment, attachments, task]) {
    const data = JSON.stringify({
      task: comment.task,
      edited: true,
      comment: `${comment.comment}`,
      showReplies: true,
    });

    const res = await AxiosHandler.call(
      data,
      `/comment/${comment.id}`,
      'put',
      'application/json',
    );

    const editedComment = res.data;

    //add attachments to comment
    attachments
      .filter(att => !att.id)
      .forEach(async attachment => {
        const FormData = require('form-data');
        const data = new FormData();

        data.append('file', attachment);

        await AxiosHandler.call(
          data,
          `/attachment/${editedComment.id}/comment`,
          'post',
          '',
        );
      });

    //modify editedComment

    editedComment['actualReplies'] = comment.actualReplies;

    const i = task.actualComments.map(e => e.id).indexOf(comment.id);

    task.actualComments.splice(i, 1, editedComment);
    task.actualComments = [...task.actualComments];
    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async reactToComment({ dispatch }, [reaction, comment, task]) {
    const data = JSON.stringify({
      reaction: reaction.reaction,
      comment: comment.id,
    });

    const res = await AxiosHandler.call(
      data,
      `/reaction`,
      'post',
      'application/json',
    );

    const rea = res.data;

    if (!comment.reactions.some(r => r.reaction === reaction.reaction) && rea) {
      comment.reactions = [...comment.reactions, rea];
    } else if (!rea) {
      comment.reactions = comment.reactions.filter(
        r => r.reaction !== reaction.reaction,
      );
    } else {
      const i = comment.reactions
        .map(e => e.reaction)
        .indexOf(reaction.reaction);

      comment.reactions.splice(i, 1, rea);
      comment.reactions = [...comment.reactions];
    }

    const i = task.actualComments.map(e => e.id).indexOf(comment.id);
    task.actualComments.splice(i, 1, comment);
    task.actualComments = [...task.actualComments];
    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async deleteComment({ dispatch }, [commentId, task]) {
    await AxiosHandler.call('', `/comment/${commentId}`, 'delete', null);

    task.comments = task.comments.filter(c => c !== commentId);
    task.actualComments = task.actualComments.filter(c => c.id !== commentId);
    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async deleteCommentAttachment(
    { dispatch, rootGetters },
    [attachmentId, comment],
  ) {
    const data = JSON.stringify({ id: attachmentId });

    await AxiosHandler.call(
      data,
      `/attachment/${comment.id}/comment`,
      'delete',
      'application/json',
    );

    const task = rootGetters['lists/allTasks'].find(t =>
      t.comments?.includes(comment.id),
    );

    comment.attachments = comment.attachments.filter(
      at => at.id !== attachmentId,
    );

    let i = task.actualComments.map(c => c.id).indexOf(comment);
    task.actualComments[i] = comment;
    dispatch('tasksState/setTaskToEdit', task, { root: true });
  },

  // ------------------------------------------- replies -----------------------------------------------------------------------------------

  async createReply({ dispatch }, [reply, attachments, task]) {
    const data = JSON.stringify({
      comment_id: reply.comment_id,
      reply: `${reply.reply}`,
    });

    const res = await AxiosHandler.call(
      data,
      `/reply`,
      'post',
      'application/json',
    );

    const rep = res.data;

    attachments.forEach(async attachment => {
      const FormData = require('form-data');
      const data = new FormData();

      data.append('file', attachment);

      const res = await AxiosHandler.call(
        data,
        `/attachment/${rep.id}/reply`,
        'post',
        '',
      );

      rep.attachments.push(res);
    });

    //push to comment

    const com = task.actualComments.find(c => c.id === rep.comment_id);

    if (!com.actualReplies.some(r => r.id === rep.id)) {
      com.actualReplies = [rep, ...com.actualReplies];
    }
    com.showReplies = true;

    //update store

    dispatch('tasks/updateTaskInList', task, { root: true });

    return `${rep.id}-${com.id}`;
  },

  async getRepliesPerPage({ dispatch }, [comment, task, limit, offset]) {
    const res = await AxiosHandler.call(
      '',
      `/reply/?limit=${limit}&offset=${offset}&comment_id=${comment.id}`,
      'get',
      null,
    );

    const replies = res.data.results;

    replies.forEach(rep => {
      const i = comment.actualReplies.map(r => r.id).indexOf(rep.id);

      if (i != -1) {
        comment.actualReplies[i] = rep;
      } else {
        comment.actualReplies = [rep, ...comment.actualReplies];
      }
    });

    comment.actualReplies.sort((a, b) => (a.id < b.id ? 1 : -1));
    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async getReplyById({ dispatch }, [id, task]) {
    const res = await AxiosHandler.call('', `/reply/${id}/`, 'get', null);

    const rep = res.data;

    const com = task.actualComments.find(c => c.id === rep.comment_id);

    if (!com.actualReplies.some(r => r.id === rep.id)) {
      com.actualReplies = [rep, ...com.actualReplies];
    }

    com.actualReplies.sort((a, b) => (a.id < b.id ? 1 : -1));

    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async editReply({ dispatch }, [reply, attachments, task]) {
    const data = JSON.stringify({
      comment_id: reply.comment_id,
      reply: reply.reply,
      edited: true,
    });

    const res = await AxiosHandler.call(
      data,
      `/reply/${reply.id}/`,
      'put',
      'application/json',
    );

    const editedReply = res.data;

    // add attachments that have been added
    attachments
      .filter(a => !editedReply.attachments.map(at => at.id).includes(a.id))
      .forEach(async attachment => {
        const FormData = require('form-data');
        const data = new FormData();

        data.append('file', attachment);

        await AxiosHandler.call(
          data,
          `/attachment/${reply.id}/reply`,
          'post',
          '',
        );
      });

    const comment = task.actualComments.find(c => c.id === reply.comment_id);

    const i = comment.actualReplies.map(e => e.id).indexOf(reply.id);
    comment.actualReplies.splice(i, 1, editedReply);
    comment.actualReplies = [...comment.actualReplies];

    //update store

    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async reactToReply({ dispatch }, [reaction, reply, task]) {
    const data = JSON.stringify({
      reaction: reaction.reaction,
      reply: reply.id,
    });

    const res = await AxiosHandler.call(
      data,
      `/reaction`,
      'post',
      'application/json',
    );

    const rea = res.data;

    if (!reply.reactions.some(r => r.reaction === reaction.reaction) && rea) {
      reply.reactions = [...reply.reactions, rea];
    } else if (!rea) {
      reply.reactions = reply.reactions.filter(
        r => r.reaction !== reaction.reaction,
      );
    } else {
      const i = reply.reactions.map(e => e.reaction).indexOf(reaction.reaction);

      reply.reactions.splice(i, 1, rea);
      reply.reactions = [...reply.reactions];
    }

    const comment = task.actualComments.find(c => c.id === reply.comment_id);

    const i = comment.actualReplies.map(e => e.id).indexOf(reply.id);
    comment.actualReplies.splice(i, 1, reply);
    comment.actualReplies = [...comment.actualReplies];

    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async deleteReply({ dispatch }, [reply, task]) {
    await AxiosHandler.call(
      null,
      `/reply/${reply.id}`,
      'delete',
      'application/json',
    );

    //delete from comment
    const com = task.actualComments.find(c => c.id === reply.comment_id);

    com.actualReplies = com.actualReplies.filter(re => re.id !== reply.id);

    //update store

    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async deleteReplyAttachment(
    { dispatch, rootGetters },
    [attachmentId, reply],
  ) {
    const data = JSON.stringify({ id: attachmentId });

    await AxiosHandler.call(
      data,
      `/attachment/${reply.id}/reply`,
      'delete',
      'application/json',
    );

    const task = rootGetters['lists/allTasks'].find(t =>
      t.comments?.includes(reply.comment_id),
    );
    const comment = task?.actualComments.find(
      comment => comment.id == reply.comment_id,
    );

    reply.attachments = reply.attachments.filter(at => at.id !== attachmentId);

    let i = comment.actualReplies.map(r => r.id).indexOf(reply);
    comment.actualReplies[i] = reply;

    i = task.actualComments.map(c => c.id).indexOf(comment);
    task.actualComments[i] = comment;
    dispatch('tasksState/setTaskToEdit', task, { root: true });
  },

  async readAttachment({ dispatch }, a) {
    try {
      await AxiosHandler.call(
        null,
        `${urlMedia}/${a.path}`,
        'get',
        'application/json',
      );
    } catch (e) {
      dispatch(
        'popups/setToast',
        [`File not found. Unable to view this file.`, 3000],
        {
          root: true,
        },
      );
    }
  },
};
