export default {
  inputFocused: state => {
    return state.inputFocused;
  },

  focusTask: state => {
    return state.focusTask;
  },

  inputTyped: state => {
    return state.inputTyped;
  },
};
