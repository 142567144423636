import Cookies from '@/helpers/cookies';
import { NETWORK_MARGIN, ROUTE_NAMES } from '@/helpers/constants';
import { stores } from '@/main';
import getEnv from '../../app.config';
import router from '@/router';
import AxiosHandler, { CustomError } from '@/helpers/axios';

/**
 * The type of data login.
 * @typedef {Object} LoginData
 * @property {string} username - email or username.
 * @property {string} password - password
 */

/**
 * The type of received token data.
 * @typedef {Object} TokensData
 * @property {string} access
 * @property {string} refresh
 * @property {string} TEAMFU_VERSION
 */

/**
 * Helper class to work with access tokens
 */
export default class Tokens {
  /**
   * get token
   * @param token {string|undefined}
   * @returns {any}
   */
  static parse(token) {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return 'error';
    }
  }

  /**
   * get access tokens
   * @async
   * @returns {Promise<any|Record<string, string|undefined>>}
   */
  static async getAll() {
    const cookies = Cookies.getAll();
    const access = Tokens.parse(cookies.access);
    const refresh = Tokens.parse(cookies.refresh);

    const giveToken = async () => {
      if (access === 'error' || !access) {
        await Tokens.refresh();
        return Cookies.getAll();
      }

      if (access.exp - NETWORK_MARGIN < Date.now() / 1000) {
        if (access.exp - 3 > Date.now() / 1000) {
          await Tokens.refresh();
          return cookies;
        } else {
          await Tokens.refresh();
          return Cookies.getAll();
        }
      }

      return cookies;
    };

    if ((refresh && refresh.exp < Date.now() / 1000) || access === 'error') {
      Cookies.delete('refresh');
      Cookies.delete('access');
    } else {
      return await giveToken();
    }
  }

  /**
   * obtain tokens after successful login
   * @param payload {LoginData}
   * @returns {Promise<*>}
   */
  static async obtain(payload) {
    const FormData = require('form-data');
    const data = new FormData();

    data.append('username', `${payload.username}`);
    data.append('password', `${payload.password}`);

    const config = {
      method: 'post',
      url: `/token/obtain`,
      headers: {
        ...data,
      },
      data: data,
    };

    return AxiosHandler.request(config, false)
      .then(response => {
        Tokens.setAccessCookies(response.data);

        return response.data;
      })
      .catch(error => {
        if (error.toJSON().message === 'Network Error') {
          throw new CustomError('Network error');
        } else if (error.response.status === 401) {
          throw new CustomError('Incorrect email or password');
        } else {
          throw new CustomError(error.response.data.detail);
        }
      });
  }

  /**
   * Delete user tokens - after logout, executes on login page
   * @returns {Promise<void>}
   */
  static async revoke() {
    const cookies = Cookies.getAll();
    const refresh = Tokens.parse(cookies.refresh);
    if (refresh.exp < Date.now() / 1000) {
      return;
    }

    if (Cookies.getAll().refresh || Cookies.getAll().access) {
      const tokens = await Tokens.getAll();

      if (tokens?.access && tokens?.refresh) {
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `JWT ${tokens.access}`);
        myHeaders.append('Content-Type', 'application/json');

        const data = JSON.stringify({ refresh_token: `${tokens.refresh}` });

        const config = {
          method: 'post',
          url: `/token/revoke`,
          headers: {
            Authorization: `JWT ${tokens.access}`,
            'Content-Type': 'application/json',
          },
          data: data,
        };

        AxiosHandler.request(config, false).then(response => response.data);
      }
    }
  }

  /**
   * Set each token in cookies
   * @param data {TokensData}
   */
  static setAccessCookies(data) {
    Cookies.set('refresh', data.refresh, {
      SameSite: 'Lax',
    });
    Cookies.set('access', data.access, {
      SameSite: 'Lax',
    });
    Cookies.set('TEAMFU_VERSION', data.TEAMFU_VERSION, {
      SameSite: 'Lax',
    });

    if (data.TEAMFU_VERSION > getEnv('VUE_APP_TEAMFU_VERSION')) {
      location.reload();
    }
  }

  /**
   * Refresh both tokens
   * @returns {Promise<TokensData>}
   */
  static async refresh() {
    const data = JSON.stringify({ refresh: `${Cookies.getAll().refresh}` });

    const config = {
      method: 'post',
      url: `/token/refresh`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    try {
      const response = await AxiosHandler.request(config, false);

      Tokens.setAccessCookies(response.data);

      await stores.dispatch('auth/resetRefresh', true);
      stores.commit('auth/SET_TOKENS', response.data);

      return response.data;
    } catch (error) {
      if (router.currentRoute.value.name !== ROUTE_NAMES.LOGIN) {
        await stores.dispatch('auth/logoutUser');
      }
    }
  }
}
