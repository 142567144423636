export default {
  async projectSocket({ dispatch }, [ev, prId]) {
    const e = JSON.parse(ev.data);

    if (
      e.message === 'connected' ||
      e.message === '.' ||
      typeof e.message !== 'object'
    ) {
      return;
    } else if ('epic_id' in e.message && 'changes' in e.message) {
      dispatch('projectSockets/epic_changes', e.message, { root: true });
    } else if ('epic_reorder' in e.message) {
      dispatch('projectSockets/epic_reorder', e.message, { root: true });
    } else if ('epic_created' in e.message) {
      dispatch('projectSockets/epic_created', e.message, { root: true });
    } else if ('epic_deleted' in e.message) {
      dispatch('projectSockets/epic_deleted', e.message, { root: true });
    } else if ('milestone_created' in e.message) {
      dispatch('projectSockets/milestone_created', e.message, { root: true });
    } else if ('milestone_id' in e.message) {
      dispatch('projectSockets/milestone_updated', [e.message, prId], {
        root: true,
      });
    } else if ('milestone_deleted' in e.message) {
      dispatch('projectSockets/milestone_deleted', [e.message, prId], {
        root: true,
      });
    } else if ('project_user_removed' in e.message) {
      dispatch('projectSockets/project_user_removed', [e.message, prId], {
        root: true,
      });
    } else if ('project_user_added' in e.message) {
      dispatch('projectSockets/project_user_added', [e.message, prId], {
        root: true,
      });
    } else if ('list_reorder' in e.message) {
      dispatch('projectSockets/list_reorder', [e.message, prId], {
        root: true,
      });
    } else if (e.message?.tags === 'reload') {
      dispatch('projectSockets/reload_tags', prId, { root: true });
    } else if ('timesheet_created' in e.message) {
      dispatch('projectSockets/timesheet_created', e.message, { root: true });
    } else if ('timesheet_updated' in e.message) {
      dispatch('projectSockets/timesheet_updated', e.message, { root: true });
    } else if ('timesheet_deleted' in e.message) {
      dispatch('projectSockets/timesheet_deleted', e.message, { root: true });
    }

    return;
  },

  async listSocket({ dispatch }, ev) {
    const e = JSON.parse(ev.data);

    if (
      e.message === 'connected' ||
      e.message === '.' ||
      typeof e.message !== 'object'
    ) {
      return;
    } else if ('task_created' in e.message) {
      dispatch('listSockets/task_created', e.message, { root: true });
    } else if ('task_deleted' in e.message) {
      dispatch('listSockets/task_deleted', [e.message, ev], { root: true });
    } else if ('users' in e.message) {
      dispatch('listSockets/task_users_added', e.message, { root: true });
    } else if ('task_user_removed' in e.message) {
      dispatch('listSockets/task_users_removed', e.message, { root: true });
    } else if ('tag_added' in e.message) {
      dispatch('listSockets/tag_added', e.message, { root: true });
    } else if ('tag_removed' in e.message) {
      dispatch('listSockets/tag_removed', e.message, { root: true });
    } else if ('task_reorder' in e.message) {
      dispatch('listSockets/task_reorder', e.message.task_reorder, {
        root: true,
      });
    } else if ('task_id' in e.message) {
      dispatch('listSockets/task_update', [e.message, ev], { root: true });
    } else if (e?.message?.type === 'receive') {
      dispatch('listSockets/receive_data', e.message, { root: true });
    } else {
      return;
    }
  },

  async userSockets({ dispatch }, ev) {
    const e = JSON.parse(ev.data);

    if (
      typeof e.message !== 'object' ||
      e.message === 'connected' ||
      e.message === '.'
    ) {
      return;
    }

    const message = e.message;
    const messageType = getUserMessageType(message);

    if (!messageType) {
      return;
    }

    switch (messageType) {
      case 'archived_list':
        dispatch('userSockets/list_archived', message, { root: true });
        break;
      case 'unarchived_list':
        dispatch('userSockets/list_unarchived', message, { root: true });
        break;
      case 'pending_request_created':
        dispatch('listSockets/pending_request_created', [message, e.sender], {
          root: true,
        });
        break;
      case 'pending_request_updated':
        dispatch('listSockets/pending_request_updated', message, {
          root: true,
        });
        break;
      case 'pending_request_deleted':
        dispatch('listSockets/pending_request_deleted', message, {
          root: true,
        });
        break;
      case 'added_to_list':
        dispatch('userSockets/added_to_list', e.message, { root: true });
        break;
      case 'list_user_added':
        dispatch('userSockets/list_user_added', message, { root: true });
        break;
      case 'list_user_removed':
        dispatch('userSockets/list_user_removed', message, { root: true });
        break;
      case 'list_deleted':
        dispatch('userSockets/delete_list', message, { root: true });
        break;
      case 'list_id':
        dispatch('userSockets/update_list', message, { root: true });
        break;
      case 'profile_deleted':
        dispatch('userSockets/profile_deleted', message, { root: true });
        break;
      case 'tag_added':
        dispatch('userSockets/tag_added', message, { root: true });
        break;
      case 'tag_removed':
        dispatch('userSockets/tag_removed', message, { root: true });
        break;
      case 'profile_id':
        dispatch('userSockets/profile_changed', message, { root: true });
        break;
      case 'project_changes':
        dispatch('userSockets/project_updated', message, { root: true });
        break;
      case 'notification_created':
        dispatch('notifications/notification_created', message, { root: true });
        break;
      case 'notification_updated':
        dispatch('notifications/notification_updated', message, { root: true });
        break;
      case 'notification_deleted':
        dispatch(
          'notifications/deleteNoteFromState',
          message.notification_deleted,
          { root: true },
        );
        break;
      case 'email_change_request':
        dispatch('userSockets/email_change_request', message, { root: true });
        break;
      case 'project_ownership_request':
        dispatch('userSockets/project_ownership_request', e.message, {
          root: true,
        });
        break;
      case 'seat_changed':
        dispatch('userSockets/seat_changed', e.message, {
          root: true,
        });
        break;
      case 'seat_deleted':
        dispatch('userSockets/seat_deleted', e.message, {
          root: true,
        });
        break;
      case 'card_updated':
        dispatch('userSockets/card_updated', e.message, {
          root: true,
        });
        break;
      case 'card_deleted':
        dispatch('userSockets/card_deleted', e.message, {
          root: true,
        });
        break;
      case 'plan_created':
        dispatch('userSockets/plan_created', e.message, { root: true });
        break;
      case 'plan_deleted':
        dispatch('userSockets/plan_deleted', e.message, { root: true });
        break;
      case 'plan_updated':
        dispatch('userSockets/plan_updated', e.message, { root: true });
        break;
      case 'plans_invalid':
        dispatch('userSockets/plans_invalid', e.message, { root: true });
        break;
      case 'seats_updated':
        dispatch('userSockets/seats_updated', e.message, { root: true });
        break;
      case 'billing_query_updated':
        dispatch('userSockets/billing_query_updated', e.message, {
          root: true,
        });
        break;
      case 'billing_query_note_created':
        dispatch('userSockets/billing_query_note_created', e.message, {
          root: true,
        });
        break;
      case 'billing_query_note_updated':
        dispatch('userSockets/billing_query_note_updated', e.message, {
          root: true,
        });
        break;
      case 'payment_created':
        dispatch('userSockets/payment_created', e.message, {
          root: true,
        });
        break;
      case 'payment_updated':
        dispatch('userSockets/payment_updated', e.message, {
          root: true,
        });
        break;
      case 'reload':
        dispatch('userSockets/receive_data', message, { root: true });
        break;
      default:
        break;
    }
  },

  async taskSockets({ dispatch, rootGetters, commit }, ev) {
    const e = JSON.parse(ev.data);

    const task = rootGetters['tasksState/taskToEdit'];
    if (
      e.message === 'connected' ||
      e.message === '.' ||
      (typeof e.message !== 'object' && task === null)
    ) {
      return;

      /* ----------------------------------- comment sockets -------------------------------------------------------------- */
    } else if ('user_editing_task' in e.message) {
      commit('tasksState/SET_EDITING_USERS', e.message.user_editing_task, {
        root: true,
      });
    } else if ('comment_created' in e.message) {
      dispatch('commentsSockets/comment_created', e.message, { root: true });
    } else if (
      'comment_id' in e.message &&
      'changes' in e.message &&
      !('reply_id' in e.message)
    ) {
      dispatch('commentsSockets/update_comment', e.message, { root: true });
    } else if ('comment_deleted' in e.message) {
      dispatch('commentsSockets/comment_deleted', e.message, { root: true });
    } else if ('comment_reaction_added' in e.message) {
      dispatch('commentsSockets/comment_reaction_added', e.message, {
        root: true,
      });
    } else if ('comment_reaction_removed' in e.message) {
      dispatch('commentsSockets/comment_reaction_removed', e.message, {
        root: true,
      });
    } else if ('comment_id' in e.message && 'attachment_created' in e.message) {
      dispatch('commentsSockets/comment_attachment_created', e.message, {
        root: true,
      });
    } else if ('comment_id' in e.message && 'attachment_deleted' in e.message) {
      dispatch('commentsSockets/comment_attachment_deleted', e.message, {
        root: true,
      });

      /* ----------------------------------- reply sockets -------------------------------------------------------------- */
    } else if ('reply_created' in e.message) {
      dispatch('commentsSockets/reply_created', e.message, { root: true });
    } else if ('changes' in e.message && 'reply_id' in e.message) {
      dispatch('commentsSockets/reply_updated', e.message, { root: true });
    } else if ('reply_deleted' in e.message) {
      dispatch('commentsSockets/reply_deleted', e.message, { root: true });
    } else if ('reply_reaction_added' in e.message) {
      dispatch('commentsSockets/reply_reaction_added', e.message, {
        root: true,
      });
    } else if ('reply_reaction_removed' in e.message) {
      dispatch('commentsSockets/reply_reaction_removed', e.message, {
        root: true,
      });
    } else if ('attachment_created' in e.message && 'reply_id' in e.message) {
      dispatch('commentsSockets/reply_attachment_created', e.message, {
        root: true,
      });
    } else if ('attachment_deleted' in e.message && 'reply_id' in e.message) {
      dispatch('commentsSockets/reply_attachment_deleted', e.message, {
        root: true,
      });
    } else {
      return;
    }
  },
};

function getUserMessageType(message) {
  /**
   * Function used in the User websocket handler to tell which websocket events are currently being handled
   * @param message [object]: The "content" received from the websocket message event.
   * @returns [string]: A key from the expected_keys structure, after identifying which websocket message is being sent.
   */
  const expected_keys = [
    'archived_list',
    'unarchived_list',
    'pending_request_created',
    'pending_request_updated',
    'pending_request_deleted',
    'added_to_list',
    'list_user_added',
    'list_user_removed',
    'list_deleted',
    'list_id',
    'profile_deleted',
    'tag_added',
    'tag_removed',
    'profile_id',
    'project_changes',
    'notification_created',
    'notification_updated',
    'notification_deleted',
    'email_change_request',
    'project_ownership_request',
    'seat_changed',
    'seat_deleted',
    'card_updated',
    'card_deleted',
    'plan_created',
    'plan_deleted',
    'plan_updated',
    'plans_invalid',
    'seats_updated',
    'billing_query_updated',
    'billing_query_note_created',
    'billing_query_note_updated',
    'payment_created',
    'payment_updated',
    'reload',
  ];
  const filteredArray = Object.keys(message).filter(value =>
    expected_keys.includes(value),
  );

  if (filteredArray.length > 0) {
    return filteredArray[0];
  }
  return null;
}
