import { createApp } from 'vue';
import App from './App.vue';
import VueAxios from 'vue-axios';
import axios from 'axios';
import VueClipboard from 'vue3-clipboard';
import { dragscroll } from 'vue-dragscroll';
import router from './router';
import store from './store';
import setImmediate from 'setimmediate';
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import './assets/tailwind.css';
import detectMobile from '@/config';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { autoFocus } from '@/directives/AutoFocus';
import { clickOutside } from '@/directives/clickOutside';
import { updatedShow } from '@/directives/updatedShow';
// "vue-gtag": "^2.0.1" -> from dependencies in package.json
// import VueGtag from 'vue-gtag';

library.add(fas);
library.add(fab);
library.add(far);
dom.watch();

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(setImmediate);
app.directive('dragscroll', dragscroll);
app.directive('auto-focus', autoFocus);
app.directive('click-outside', clickOutside);
app.directive('updated-show', updatedShow);
app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
});
app.use(detectMobile);
// app.use(VueGtag, {
//   config: { id: 'G-9DCK0LG704' },
// });
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('date-picker', Datepicker);

app.config.productionTip = false;

export const stores = store;

app.mount('#app');
