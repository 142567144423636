import AxiosHandler from '@/helpers/axios';
import { ROUTE_NAMES } from '@/helpers/constants';
import { isDatesEqual } from '@/helpers/dates';
import router from '@/router';

export default {
  /**
   *
   * @param rootGetters
   * @param commit
   * @param m {{epic_id: number, changes: {}}}
   * @returns {Promise<void>}
   */
  async epic_changes({ rootGetters, commit }, m) {
    const epic = rootGetters['epics/epics'].find(ep => ep.id === m.epic_id);

    const tasks = m.changes.progress?.total_tasks;
    if (tasks > -1) {
      m.changes.task_count = tasks;
    }

    const users = m.changes.progress?.total_users;
    if (users > -1) {
      m.changes.user_count = users;
    }

    if (epic) {
      commit(
        'epics/PATCH_EPIC',
        { id: m.epic_id, ...m.changes },
        { root: true },
      );
    }
  },

  /**
   * epic reorder socket
   * @param _
   * @param m {{epic_reorder: {changed_items: string}}}
   * @returns {Promise<void>}
   */
  async epic_reorder({ commit }, m) {
    const res = await AxiosHandler.call(
      null,
      m.epic_reorder.changed_items,
      'get',
      '',
    );

    commit('epics/PATCH_EPICS', res.data.results, { root: true });
  },

  /**
   * epic created socket
   * @param _
   * @param m {{epic_created: {id: number, project: string, start_date: string, end_date: string, title: string}}}
   * @returns {Promise<void>}
   */
  async epic_created({ commit, rootGetters, dispatch }, m) {
    const pro = rootGetters['projects/projects'].find(
      p => p.id === m.epic_created.project,
    );
    const creatingEpic = rootGetters['epics/creatingEpic'];

    if (
      !pro ||
      rootGetters['epics/epics'].find(ep => ep.id === m.epic_created.id)
    )
      return;

    const newOrderEpics = rootGetters['epics/epics']
      .filter(ep => ep.project === pro.id)
      .map(ep => ({
        id: ep.id,
        position:
          m.epic_created.position <= ep.position
            ? ep.position + 1
            : ep.position,
      }));

    commit('epics/PATCH_EPICS', newOrderEpics, { root: true });
    commit(
      'epics/SET_EPICS',
      await dispatch('epics/addEpicFields', [m.epic_created], { root: true }),
      { root: true },
    );

    if (creatingEpic) {
      const isEqual =
        isDatesEqual(m.epic_created.start_date, creatingEpic.start_date) &&
        isDatesEqual(m.epic_created.end_date, creatingEpic.end_date) &&
        m.epic_created.title === creatingEpic.title;

      if (isEqual) {
        commit('epics/SET_CREATING_EPIC', null, { root: true });
      }
    }

    pro['epicsCount'] = pro['epicsCount'] + 1;
  },

  /**
   * epic deleted socket
   * @param _
   * @param m {{ epic_deleted: number }}
   * @returns {Promise<void>}
   */
  async epic_deleted({ commit, rootGetters }, m) {
    const epic = rootGetters['epics/epics'].find(
      ep => ep.id === m.epic_deleted,
    );

    if (!epic) return;

    const pro = rootGetters['projects/projects'].find(
      p => p.id === epic.project,
    );

    if (!pro) return;

    const pos = epic.position;
    const newOrderEpics = rootGetters['epics/epics']
      .filter(ep => ep.project === pro.id)
      .map(ep => ({
        id: ep.id,
        position: pos < ep.position ? ep.position - 1 : ep.position,
      }));

    commit('epics/DELETE_EPIC', epic.id, { root: true });
    commit('epics/PATCH_EPICS', newOrderEpics, { root: true });

    pro['epicsCount'] = pro['epicsCount'] - 1;
  },

  milestone_created({ rootGetters }, m) {
    const projects = rootGetters['projects/projects'];
    const project = projects.find(pr => pr.id === m.milestone_created.project);

    if (project && project['milestones']) {
      project['milestones'].push(m.milestone_created);
    }
  },

  milestone_updated({ rootGetters }, [m, projectId]) {
    const projects = rootGetters['projects/projects'];
    const project = projects.find(pr => pr.id === projectId);

    if (project) {
      project.milestones = project.milestones.map(mil =>
        mil.id === m.milestone_id ? { ...mil, ...m.changes } : mil,
      );
    }
  },

  project_user_added({ commit, rootGetters }, [m, projectId]) {
    const projects = rootGetters['projects/projects'];
    const project = projects.find(pr => pr.id === projectId);

    const expUsers = rootGetters['projects/expiredUsers'];
    if (m.expired) {
      expUsers.push({
        id: m.project_user_added,
        expired: true,
      });
    } else if (project) {
      commit(
        'projects/SET_EXPIRED_PROJECT_USERS',
        expUsers.filter(eu => eu.id !== m.project_user_added),
        { root: true },
      );

      project.users.push(m.project_user_added);
    }
  },

  project_user_removed({ commit, dispatch, rootGetters }, [m, projectId]) {
    const projects = rootGetters['projects/projects'];
    const selProj = rootGetters['projects/selectedProject'];
    const projectsPerms = rootGetters['permissions/projectUserData'];
    const selList = rootGetters['lists/selectedList'];

    const project = projects.find(pr => pr.id === projectId);
    if (project) {
      if (selList?.project === projectId) {
        selList.users = selList.users.filter(
          u => u.id !== m.project_user_removed,
        );
        dispatch('lists/updateListInProject', selList, { root: true });
      }

      if (selList && m.remove_user_from_tasks !== false) {
        selList.actualTasks.forEach(task => {
          if (task.status !== 5) {
            task.users = task.users.filter(
              usId => usId !== m.project_user_removed,
            );
          }
        });
      }

      project.users = project.users.filter(u => u !== m.project_user_removed);
    }

    const projectPerms = projectsPerms.find(per => per.project === projectId);
    if (projectPerms) {
      const index = projectPerms.data.findIndex(
        d => d.user === m.project_user_removed,
      );

      if (index > -1) {
        projectPerms.data.splice(index, 1);
      }
    }

    if (rootGetters['auth/userProfile']?.id === m.project_user_removed) {
      if (projectId === selProj?.id) {
        router.push({ name: ROUTE_NAMES.PROJECT_PORTFOLIO });
      }

      commit('projects/DELETE_PROJECT', projectId, { root: true });
    }

    if (selList?.project === projectId) {
      dispatch('lists/addRemovedUser', m.project_user_removed, { root: true });
    }

    if (selProj?.id === projectId) {
      commit(
        'projects/SET_EXPIRED_PROJECT_USERS',
        rootGetters['projects/expiredUsers'].filter(
          eu => eu.id !== m.project_user_removed,
        ),
        { root: true },
      );
    }
  },

  milestone_deleted({ rootGetters }, [m, projectId]) {
    const projects = rootGetters['projects/projects'];
    const project = projects.find(pr => pr.id === projectId);

    if (project) {
      project.milestones = project.milestones.filter(
        mil => mil.id !== m.milestone_deleted,
      );
    }
  },

  async list_reorder({ dispatch, rootGetters }, [m, projectId]) {
    const profile = rootGetters['auth/userProfile'];
    const project = rootGetters['projects/projects'].find(
      pr => pr.id === projectId,
    );

    if (profile.id !== m.sender && project) {
      dispatch('projects/setListPositions', [project, m.list_reorder], {
        root: true,
      });
    }
  },

  reload_tags({ rootGetters, dispatch }, projectId) {
    const projects = rootGetters['projects/projects'];
    const project = projects.find(pr => pr.id === projectId);

    if (project) {
      project.tags = [];
      dispatch('projects/fetchProjectTags', project, { root: true });
    }
  },

  timesheet_created({ commit, rootGetters }, m) {
    const timesheets = rootGetters['timesheet/filteredTimesheets'];

    if (!timesheets.some(ts => ts.id === m.timesheet_created.id)) {
      commit('timesheet/ADD_TIMESHEET', m.timesheet_created, { root: true });
    }
  },

  timesheet_updated({ commit }, m) {
    commit('timesheet/UPDATE_TIMESHEET', m.changes, { root: true });
  },

  timesheet_deleted({ commit, dispatch, rootGetters }, m) {
    commit('timesheet/DELETE_TIMESHEET', m.timesheet_deleted, { root: true });

    if (rootGetters['timesheet/selectedTimesheetId'] === m.timesheet_deleted) {
      dispatch('timesheet/setSelectedTimesheet', null, { root: true });
    }
  },

  connectProject({ commit }, id) {
    commit('PROJECT_TO_CONNECT', id);
  },

  disconnectAllProjects({ commit }) {
    commit('SET_PROJECT_TO_CONNECT', []);
  },
};
