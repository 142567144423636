export default {
  SELECT_LIST_ID: (state, listId) => {
    state.selectedListId = listId;
  },

  SET_LIST_TO_MOVE_TASK_TO: (state, payload) => {
    state.listToMoveTo = payload;
  },

  LOGOUT_USER: state => {
    state.selectedListId = null;
    state.listToMoveTo = null;
    state.removedUsers = [];
  },

  SET_COLOR_PICKER_LIST: (state, payload) => {
    state.colorPickerList = payload;
  },

  SET_USER_LISTS: (state, payload) => {
    state.userLists = payload;
  },

  SET_REMOVED_USERS: (state, payload) => {
    state.removedUsers = payload;
  },
};
