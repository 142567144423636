import actions from './actions';

// set state for the module in this file

export default {
  namespaced: true,
  state() {
    return {};
  },
  actions,
};
