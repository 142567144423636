import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,

  state() {
    return {
      selectedUser: null,
      projectUserData: [],
      roles: [],
      defaultRoleIds: {},
    };
  },
  actions,
  getters,
  mutations,
};
