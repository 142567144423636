import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,
  state() {
    return {
      notifications: [],
      notificationIds: [],
      notification_created: false,
      notToDel: null,
      unreadNotifications: 0,
      notificationsCount: 0,
      offset: 0,
    };
  },
  actions,
  getters,
  mutations,
};
