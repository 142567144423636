export default {
  SET_MOBILE_DEVICE_STATE: (state, payload) => {
    state.isMobileDevice = payload;
  },

  ADD_PENDING_DATA: (state, payload) => {
    const data = { ...payload };
    data.id = crypto.randomUUID();
    state.pendingData.push(data);
  },

  REMOVE_PENDING_DATA: (state, pendingId) => {
    state.pendingData = state.pendingData.filter(p => p.id !== pendingId);
  },

  CLEAR_PENDING_DATA: state => {
    state.pendingData = [];
  },

  SET_PORTFOLIO_TAGS: (state, payload) => {
    state.portfolioTags = payload;
  },

  SET_RESET_DATE_RANGE: (state, payload) => {
    state.resetDateRange = payload;
  },
};
