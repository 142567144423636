export default {
  SET_TOAST_TEXT: (state, payload) => {
    state.toast = payload;
  },

  SET_TIMEOUT: (state, payload) => {
    state.timeoutID = payload;
  },

  SET_DELETE_MODAL_MESSAGE: (state, payload) => {
    state.deleteModalMessage = payload;
  },

  SET_DELETE_ACTION: (state, payload) => {
    state.deleteAction = payload;
  },

  SET_SHOW_VERIFY_SAVE_MODAL_MESSAGE: (state, payload) => {
    state.showVerifySaveModalMessage = payload;
  },

  SET_BROWSER_NAVIGATION: (state, payload) => {
    state.navigationButton = payload;
  },
};
