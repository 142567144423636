export default {
  isMobileDevice: state => {
    return state.isMobileDevice;
  },

  pendingData: state => {
    return state.pendingData;
  },

  flags: state => {
    return state.flags;
  },

  risks: state => {
    return state.risks;
  },

  statuses: state => {
    return state.statuses;
  },

  inquirySubjects: state => {
    return state.inquirySubjects;
  },

  portfolioTags: state => {
    return state.portfolioTags;
  },

  resetDateRange: state => {
    return state.resetDateRange;
  },
};
