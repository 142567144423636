import Cookies from '@/helpers/cookies';

export default {
  SET_DRAGGED_USERS: (state, payload) => {
    state.draggedUser = payload;
  },

  SET_TASKS_DISPLAYED: (state, payload) => {
    state.tasksDisplayed = payload;
  },

  SET_SELECTED_USERS: (state, payload) => {
    state.selectedUsers = payload;
  },

  TOGGLE_ADDED_TASK: state => {
    state.addedTask = !state.addedTask;
  },

  SET_DRAGGED_ATTACH_INTO_DESCRIPTION: (state, payload) => {
    state.draggedAttachIntoDesc = payload;
  },

  SET_TASK_ADD_EDIT_STATE: (state, payload) => {
    if (state.taskChanges.length > 0) return;
    state.showEditAdd = payload;
  },

  SET_TASK_TO_EDIT: (state, payload) => {
    if ((payload && 'id' in payload) || payload === null) {
      state.taskToEdit = payload;
    }
  },

  SET_DRAGGED_TASKS: (state, payload) => {
    state.draggedTask = payload;
  },

  SET_EDITING_USERS: (state, payload) => {
    state.editingUsers = payload;
  },

  SET_IS_EDITING_NOW: (state, payload) => {
    state.isEditingNow = payload;
  },

  SET_TASK_CHANGES: (state, payload) => {
    state.taskChanges = payload;
  },

  LOGOUT_USER: state => {
    state.draggedUser = null;
    Cookies.delete('tasksDisplayed');
    state.showEditAdd = false;
    state.selectedUsers = [];
    state.addedTask = false;
    state.filteredOnUserTasks = [];
    state.taskToEdit = null;
    state.isEditingNow = false;
    state.taskChanges = [];
  },
};
