export const themeColors = theme => {
  const light = {
    '--t-mood-0': '#4E26C0',
    '--t-mood-1': '#722298',
    '--t-mood-2': '#D63495',
    '--t-mood-3': '#E02C2C',
    '--t-mood-4': '#DB9000',
    '--t-mood-5': '#E96200',
    '--t-mood-6': '#1F09CF',
    '--t-mood-7': '#07A89C',
    '--t-mood-8': '#008005',
    '--t-mood-9': '#04BC00',
    '--t-mood-10': '#0D0033',
    '--t-mood-11': '#CC0010',
    '--risk-high': '#CD1D1D',
    '--risk-medium': '#C8C000',
    '--risk-low': '#2BB930',
    '--bg-color-1': '#FCFCFF',
    '--bg-color-2': '#ebeef5',
    '--bg-color-7': '#FCFCFF',
    '--bg-color-12': '#F1F2F7',
    '--txt-color-1': '#0F1327',
    '--bg-grey-000': '#E8EBF2',
    '--bg-grey-100': '#FFFFFF',
    '--bg-grey-200': '#C8CFE0',
    '--bg-grey-300': '#818BA5',
    '--bg-grey-400': '#333E55',
    '--bg-grey-500': 'rgba(38, 57, 115, 0.2)',
    '--teamfu-white': '#ffffff',
    '--task-number-execption': '#333E55',
    '--mention': '#1603AA',
    '--qtd-text': '#AE23CF',
    '--attach': '#BE007E',
    '--teamfu-box-shadow': '1px -1px 1px 0.5px rgba(0,0,0,0.1)',
  };

  const dark = {
    '--t-mood-0': '#9BB4FF',
    '--t-mood-1': '#9966FF',
    '--t-mood-2': '#F500FF',
    '--t-mood-3': '#FF3D3D',
    '--t-mood-4': '#FF3D5E',
    '--t-mood-5': '#FF9900',
    '--t-mood-6': '#66E6FF',
    '--t-mood-7': '#33FFDD',
    '--t-mood-8': '#61FF67',
    '--t-mood-9': '#D1FF4F',
    '--t-mood-10': '#FDFAA8',
    '--t-mood-11': '#FFFEEE',
    '--risk-high': '#FF3D3D',
    '--risk-medium': '#E3DC37',
    '--risk-low': '#54E85A',
    '--bg-color-1': '#384050',
    '--bg-color-2': '#252935',
    '--bg-color-7': '#181c26',
    '--bg-color-12': '#2c3248',
    '--txt-color-1': '#dbe1ef',
    '--bg-grey-000': '#060C1D',
    '--bg-grey-100': '#1B2032',
    '--bg-grey-200': '#383D57',
    '--bg-grey-300': '#727D97',
    '--bg-grey-400': '#D1DEFA',
    '--bg-grey-500': 'rgba(0, 5, 20, 0.5)',
    '--teamfu-white': '#D1DEFA',
    '--task-number-execption': '#727D97',
    '--mention': '#4674FF',
    '--qtd-text': '#FF74F1',
    '--attach': '#FEC877',
    '--teamfu-box-shadow': '2px -2px 2px 1px rgba(0,0,0,0.2)',
  };

  if (theme === true) {
    Object.entries(light).forEach(prop => {
      document.documentElement.style.setProperty(prop[0], prop[1]);
    });
  } else {
    Object.entries(dark).forEach(prop => {
      document.documentElement.style.setProperty(prop[0], prop[1]);
    });
  }
};
