import router from '../../../router/index';
import getEnv from '../../../../app.config';
import AxiosHandler from '@/helpers/axios';
import { ROUTE_NAMES, BACKEND_TASK_STATUSES } from '@/helpers/constants';

const reorderTasks = async (list, obj) => {
  const res = await AxiosHandler.call(
    JSON.stringify(obj),
    `/list/${list.id}/reorder/`,
    'put',
    'application/json',
  );

  return res;
};

export default {
  async addList({ dispatch }, [list, proj]) {
    const pendingObj = { option: 'post', list };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            JSON.stringify(list),
            `/list`,
            'post',
            'application/json',
          );

          if (res.status === 208) {
            throw res;
          }

          const listChanged = await dispatch('setListsFields', [res.data]);
          listChanged[0].position = 0;
          proj.lists.forEach(list => (list.position += 1));
          proj.lists.unshift(listChanged[0]);
        },
      },
      { root: true },
    );
  },

  /**
   * Call this function to update selected list instance.
   * It is needed after array of lists at project instance was reinitialized
   * and selected list become outdated because of it.
   */
  updateSelectedList({ dispatch, rootGetters }) {
    const selList = rootGetters['lists/selectedList'];
    if (selList) {
      dispatch('setSelectedList', selList.id);
    }
  },

  redirectFromListToPortfolio({ commit, dispatch, rootGetters }, listId) {
    if (router.currentRoute.value.name === ROUTE_NAMES.SETTINGS) {
      return;
    }

    const selList = rootGetters['lists/selectedList'];
    const task = rootGetters['tasksState/taskToEdit'];

    // if user is in the list removed from/deleted while task opened
    if (task && task.list_id === listId) {
      dispatch('tasksState/toggleAddEditTask', false, { root: true });
      commit('tasksState/SET_TASK_TO_EDIT', null, { root: true });
      dispatch(
        'popups/setToast',
        `List of task "${task.title}" has been deleted`,
        { root: true },
      );
    }

    // if user is in the list removed from/deleted
    if (selList?.id === listId) {
      commit('SELECT_LIST_ID', null);

      router.push({
        name: ROUTE_NAMES.PROJECT_PORTFOLIO,
      });
    }
  },

  async deleteList({ dispatch, rootGetters }, id) {
    const pendingObj = { deletingList: id };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(null, `/list/${id}`, 'delete', '');

          dispatch('popups/setToast', [`Your list has been deleted`, 3000], {
            root: true,
          });

          const listSockets = rootGetters['auth/listSockets'];
          const socketUrl = `${getEnv('VUE_APP_WEBSOCKET_API')}/list/${id}`;
          const listSocket = listSockets.find(ls => ls.url === socketUrl);
          if (listSocket) {
            listSocket.close();
          }
        },
      },
      { root: true },
    );
  },

  async updateListInProject({ rootGetters, dispatch }, toUpdateList) {
    if (toUpdateList.actualTasks) {
      toUpdateList.actualTasks = [
        ...toUpdateList.actualTasks.sort((a, b) => a.position - b.position),
      ];
    }

    let project = rootGetters['projects/projects'].find(
      p => p.id === toUpdateList.project,
    );

    if (project?.lists) {
      const li = project.lists.find(l => l.id === toUpdateList.id);

      if (li && li.users?.length <= toUpdateList.users?.length) {
        const i = project.lists.findIndex(l => l.id === toUpdateList.id);
        project.lists.splice(i, 1, toUpdateList);
        dispatch('lists/updateSelectedList', null, { root: true });
      } else if (!li) {
        project.lists.unshift(toUpdateList);

        if (project.lists.filter(list => list.position === 0).length > 1) {
          dispatch(
            'projects/setListOrder',
            { pro: project, order: project.lists.map(p => p.id) },
            { root: true },
          );
        }
      }

      project.lists.sort((a, b) => (a.position > b.position ? 1 : -1));
    } else {
      await dispatch('projects/getProjectById', toUpdateList.project, {
        root: true,
      });

      project = rootGetters['projects/projects'].find(
        p => p.id === toUpdateList.project,
      );

      if (project && !project.lists) {
        project.lists = [];
      }

      if (!project.lists.some(l => l.id === toUpdateList.id)) {
        project.lists.push(toUpdateList);
      }
    }
  },

  async removeListFromState({ commit, dispatch, rootGetters }, list) {
    const projOfList = rootGetters['projects/projects'].find(
      p => p.id === list.project,
    );

    if (projOfList) {
      const oldListLen = projOfList.lists.length;
      projOfList.lists = projOfList.lists.filter(l => l.id !== list.id);

      if (oldListLen !== projOfList.lists.length) {
        projOfList.listCount -= 1;
      }

      commit('projects/SELECT_PROJECT_ID', projOfList.id, { root: true });

      dispatch(
        'projects/setListOrder',
        { pro: projOfList, order: projOfList.lists.map(p => p.id) },
        { root: true },
      );
    }
  },

  async updateList({ dispatch, rootGetters }, [changed, list]) {
    const project = rootGetters['projects/projects'].find(
      pr => pr.id === list.project,
    );
    const existingListsTitles = project?.lists?.map(element => element.title);
    if (existingListsTitles?.includes(changed.title)) {
      throw {
        status: 208,
      };
    }
    const pendingObj = { changed };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            changed,
            `/list/${list.id}`,
            'patch',
            'application/json',
          );

          if (res.status === 208) {
            throw res;
          }

          const updated = await dispatch('setListsFields', [res.data]);

          //commit to vuex state
          dispatch('updateListInProject', updated[0]);
        },
      },
      { root: true },
    );
  },

  updateRemovedUsers({ commit, rootGetters }, userId) {
    const remUsers = rootGetters['lists/removedUsers'];
    const user = remUsers.find(ru => ru.id === userId);
    if (user) {
      if (user.taskCount.done === 0) {
        commit(
          'SET_REMOVED_USERS',
          remUsers.filter(ru => ru.id !== userId),
        );
      } else {
        const index = remUsers.findIndex(ru => ru.id === userId);
        remUsers.splice(index, 1);
        remUsers.push(user);
      }
    }
  },

  async addRemoveUserFromList(
    { dispatch, rootGetters, commit },
    [userId, list, method],
  ) {
    let data = JSON.stringify({ user: userId });
    if (method === 'remove') {
      const pendingObj = { listRemoveUser: userId };
      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            try {
              commit(
                'users/SET_USER_LOADING',
                { userId, loading: true },
                { root: true },
              );

              await AxiosHandler.call(
                data,
                `/list/${list.id}/remove_user`,
                'put',
                'application/json',
              );

              const u = rootGetters['auth/userProfile'];
              if (userId === u.id && list.owner !== u.id) {
                dispatch('removeListFromState', list);
              }

              dispatch('updateRemovedUsers', userId);
            } catch (e) {
              console.error(e);
            } finally {
              commit(
                'users/SET_USER_LOADING',
                { userId, loading: false },
                { root: true },
              );
            }
          },
        },
        { root: true },
      );
    } else {
      const pendingObj = { listAddingUser: userId };
      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            await AxiosHandler.call(
              data,
              `/list/${list.id}/add_user`,
              'post',
              'application/json',
            );
          },
        },
        { root: true },
      );
    }

    const pro = rootGetters['projects/projects'].find(
      p => p.id === list.project,
    );
    if (pro) {
      dispatch('projects/fetchProjectUsers', pro, { root: true });
    }
  },

  /**
   *
   * @param _
   * @param list
   * @param draggedTask
   * @param droppedOnTask
   * @param order - new right order of task in list [{ id: Number, position: (oldPosition)}]
   * @returns {Promise<void>}
   */
  async sortListTaskOrder(_, { list, draggedTask, droppedOnTask }) {
    const daPos = draggedTask.position;
    const doPos = droppedOnTask.position;

    let startPos = Math.min(daPos, doPos);
    startPos = Number.isInteger(startPos) ? startPos : Math.round(startPos);
    let endPos = Math.max(doPos, daPos);
    endPos = Number.isInteger(endPos) ? endPos : Math.floor(endPos);

    const apiData = {
      moving: daPos > doPos ? 'up' : 'down',
      start_position: startPos,
      end_position: endPos,
    };

    reorderTasks(list, apiData);
  },

  /**
   * Fetch new task positions
   * @param { dispatch, rootGetters }
   * @param obj - {changed_items: (URL), end_position: Number, start_position: Number}
   * @returns {Promise<void>}
   */
  async fetchReorderingTasks({ dispatch, rootGetters }, obj) {
    const regex = /list\/(\d+)/;
    const match = obj.changed_items.match(regex);
    const listId = match[1];
    const list = rootGetters['projects/allLists'].find(l => l.id === +listId);

    if (listId && list) {
      const limit = 15;

      /* Get the number of pages for the server pagination */
      const pages = Math.floor((obj.end_position - obj.start_position) / limit);
      /* Calculate the offset array */
      const offsets = [...Array(pages + 1).keys()].map(e => e * limit);

      const createFetch = offset =>
        AxiosHandler.call(
          '',
          `/list/${listId}/reorder/?start_position=${obj.start_position}&end_position=${obj.end_position}&limit=${limit}&offset=${offset}`,
          'get',
          'application/json',
        );

      /* Waiting for all repositioning requests to be fulfilled */
      Promise.allSettled(offsets.map(off => createFetch(off))).then(results => {
        /* Retrieving the changed values */
        const arr = results
          .filter(r => r.status !== 'rejected')
          .map(r => r.value.data.results)
          .flat(1);

        /* Rewriting positions */
        arr.forEach(item => {
          const task = list.actualTasks.find(at => at.id === item.id);

          // setting final correct position
          if (task) {
            task.position = item.position;
          }
        });

        dispatch('updateListInProject', list);
      });
    }
  },

  async getListById({ dispatch, rootGetters }, id) {
    const res = await AxiosHandler.call(null, `/list/${id}`, 'get', '');

    const listChanged = await dispatch('setListsFields', [res.data]);

    const listGotten = listChanged[0];

    dispatch('updateListInProject', listGotten);

    if (listGotten.owner !== rootGetters['auth/userProfile'].id) {
      dispatch('TeamListAvatars', [listGotten.owner]);
    }

    return res.data;
  },

  async archiveProjectList(_, [list, archive]) {
    await AxiosHandler.call(
      JSON.stringify({ archived: archive }),
      `/list/${list.id}/archive`,
      'patch',
      'application/json',
    );

    // The logic of the state change is in the sockets
  },

  async getListByKeyAndProjectId({ dispatch, rootGetters }, payload) {
    const listKey = Number(payload.listKey);
    try {
      const listFound = rootGetters['projects/allLists'].find(elem => {
        return elem.key === listKey && elem.project === payload.projectId;
      });

      if (listFound) {
        return listFound;
      }

      if (!listKey || listKey == 0 || Number(payload.projectId) === 0) {
        if (payload.showToast) {
          dispatch(
            'popups/setToast',
            ['You have no access to this list', 3000],
            {
              root: true,
            },
          );
        }

        return undefined;
      }

      const res = await AxiosHandler.call(
        null,
        `/list/?project=${payload.projectId}&key=${listKey}`,
        'get',
        '',
      );

      if (res.data.results.length === 0) {
        if (payload.showToast) {
          dispatch(
            'popups/setToast',
            ['You have no access to this list', 3000],
            {
              root: true,
            },
          );
        }

        return undefined;
      }
      const addedTasksFieldLists = await dispatch(
        'setListsFields',
        res.data.results,
      );

      dispatch('updateListInProject', addedTasksFieldLists[0]);

      let toAvatar = addedTasksFieldLists.filter(
        l => l.owner !== rootGetters['auth/userProfile']?.id,
      );

      if (toAvatar.length > 0) {
        dispatch('TeamListAvatars', [...new Set(toAvatar.map(ta => ta.owner))]);
      }

      return addedTasksFieldLists[0];
    } catch (e) {
      if (e.status === 406 || e.status === 404) {
        dispatch('popups/setToast', ['You have no access to this list', 3000], {
          root: true,
        });
      }
    }
  },

  async setSelectedList({ commit, dispatch, rootGetters }, id) {
    const list = rootGetters['projects/allLists'].find(l => l.id === id);

    if (!list) {
      commit('SELECT_LIST_ID', null);
      return;
    }

    commit('SELECT_LIST_ID', list.id);
    localStorage.setItem('LAST_KEY', list.key);
    localStorage.setItem('PROJECT_ID', list.project);

    dispatch('permissions/getUserTaskPermsByList', {}, { root: true });

    if (
      rootGetters['projects/selectedProject']?.id !== list?.project &&
      list.project != null
    ) {
      dispatch('projects/setOpenProjects', ['open', list.project], {
        root: true,
      });

      const listProject = rootGetters['projects/projects'].find(
        p => p.id === list.project,
      );

      if (listProject) {
        commit('projects/SELECT_PROJECT_ID', listProject.id, { root: true });
      }
    }
  },

  async listSelected({ rootGetters, dispatch }, id) {
    const list = rootGetters['projects/allLists'].find(l => l.id === id);
    dispatch('setSelectedList', id);

    if (!list) return;

    if (
      ![
        ROUTE_NAMES.TASK,
        ROUTE_NAMES.SETTINGS,
        ROUTE_NAMES.BILLING_QUERY,
        ROUTE_NAMES.INVOICE,
        ROUTE_NAMES.TIMESHEETS,
        ROUTE_NAMES.PROJECT_TIMESHEETS,
        ROUTE_NAMES.PORTFOLIO_TIMESHEETS,
      ].includes(router.currentRoute._rawValue.name)
    ) {
      router
        .push(`/dashboard/${list.project}/list/${list.key}`)
        .catch(() => {});
    }

    dispatch('fetchListTasks', list);
    await dispatch('fetchListUsers', list);
    dispatch('fetchListTaskCount', list);

    const selUsers = rootGetters['tasksState/selectedUsers'].filter(uid =>
      list.users.some(us => us.id === uid),
    );
    dispatch('tasks/getTaskCountByStatus', [list, selUsers], { root: true });
  },

  async fetchListTasks({ dispatch, commit }, list) {
    try {
      await dispatch('tasks/getTasksForList', list, { root: true });
    } catch (e) {
      commit('tasks/SET_TASKS_FETCHING_BY_STATUS', [], { root: true });
    }
  },

  async fetchListUsers({ commit, dispatch }, list) {
    const pendingObj = { listFetching: list.id, option: 'user list fetching' };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/users/?list=${list.id}&seat_expired=false`,
            'get',
            '',
          );

          list['users'] = res.data.results.map(u => {
            let listUser = list['users'].find(lu => lu.id === u.id);
            if (!listUser) {
              listUser = {};
            }

            return {
              id: u.id,
              taskCount: {},
              ...listUser,
            };
          });

          commit('SET_REMOVED_USERS', []);

          await dispatch('fetchListExpiredUsers', list);
          dispatch('fetchListRemovedUsers', list);
          dispatch('updateListInProject', list);

          if (res.data.results.length > 0) {
            commit('users/SET_USERS', res.data.results, { root: true });
          }
        },
      },
      { root: true },
    );
  },

  async fetchListExpiredUsers({ commit, rootGetters }, list) {
    const res = await AxiosHandler.call(
      null,
      `/users/?list=${list.id}&seat_expired=true`,
      'get',
      '',
    );

    if (res.data.results.length === 0) {
      return;
    }

    commit(
      'SET_REMOVED_USERS',
      res.data.results.map(user => {
        let taskCount = rootGetters['users/users'].find(
          us => us.user === user.id,
        );
        if (!taskCount) {
          taskCount = {
            blocked: 0,
            done: 0,
            feedback: 0,
            in_progress: 0,
            todo: 0,
          };
        }

        return {
          id: user.id,
          taskCount: taskCount,
          expired: true,
        };
      }),
    );
  },

  async fetchListRemovedUsers({ commit, rootGetters }, list) {
    const res = await AxiosHandler.call(
      null,
      `/users/?list=${list.id}&removed_users=true`,
      'get',
      '',
    );

    const resCounters = await AxiosHandler.call(
      null,
      `/list/${list.id}/user/count/?removed=true`,
      'get',
      '',
    );

    commit(
      'SET_REMOVED_USERS',
      rootGetters['lists/removedUsers'].concat(
        res.data.results.map(user => {
          let taskCount = resCounters.data.results.find(
            us => us.user === user.id,
          );
          if (!taskCount) {
            taskCount = {
              blocked: 0,
              done: 0,
              feedback: 0,
              in_progress: 0,
              todo: 0,
            };
          }

          return {
            id: user.id,
            taskCount: taskCount,
            removed: true,
          };
        }),
      ),
    );

    if (res.data.results.length > 0) {
      commit('users/SET_USERS', res.data.results, { root: true });
    }

    if (resCounters.data.results.length > 0) {
      commit('users/SET_USERS', resCounters.data.results, { root: true });
    }
  },

  async fetchListTaskCount({ commit, dispatch, rootGetters }, list) {
    const pendingObj = { list: list.id, option: 'task_count' };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/list/${list.id}/user/count/`,
            'get',
            '',
          );

          const liUsers = res.data.results.filter(u =>
            list['users'].some(us => us.id === u.user),
          );
          list['users'] = liUsers.map(u => {
            const obj = { ...u };
            delete obj.user;

            let listUser = list['users'].find(lu => lu.id === u.id);
            if (!listUser) {
              listUser = {};
            }

            return {
              ...listUser,
              id: u.user,
              taskCount: {
                ...obj,
              },
            };
          });

          dispatch('updateListInProject', list);

          if (res.data.results.length > 0) {
            commit('users/SET_USERS', res.data.results, { root: true });

            const remUsers = rootGetters['lists/removedUsers'];
            res.data.results.forEach(u => {
              const remUser = remUsers.find(ru => ru.id === u.user);
              if (remUser) {
                remUser.taskCount = u;
              }
            });
          }
        },
      },
      { root: true },
    );
  },

  async fetchListTags({ dispatch }, list) {
    const pendingObj = { fetchingListTags: list.id };

    if (list.tags && list.tags.length + 1 >= list.tagsCount) return;

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/tag/?list_id=${list.id}`,
            'get',
            '',
          );

          list.tags = res.data.results;
          list.tagsCount = res.data.count;

          await dispatch('updateListInProject', list);
        },
      },
      { root: true },
    );
  },

  async setListsFields({ dispatch, rootGetters }, lists) {
    return await new Promise(resolve => {
      let track = lists.length;

      if (track === 0) {
        resolve(lists);
      }

      lists.map(l => {
        const existList = rootGetters['projects/allLists'].find(
          cl => cl.id === l.id,
        );

        track = track - 1;

        l['actualTasks'] = existList ? existList.actualTasks : [];
        l['taskCount'] = existList ? existList.taskCount : 0;
        l['users'] = existList ? existList.users : [];

        const taskOffset = {};

        BACKEND_TASK_STATUSES.forEach(status => {
          taskOffset[status] = {
            count: 0,
            offset: 0,
            fetched: false,
          };
        });

        l['taskOffset'] = existList ? existList.taskOffset : taskOffset;

        if (l.owner !== rootGetters['auth/userProfile']?.id) {
          dispatch('users/getUsersById', [l.owner], { root: true });
        }

        if (track === 0) {
          resolve(lists);
        }
      });
    });
  },

  async loadUsersLists({ commit, dispatch, rootGetters }, [projectId, userId]) {
    const userLists = rootGetters['lists/userLists']
      ? rootGetters['lists/userLists']
      : [];
    const pendingObj = { loadingUsersLists: userId };

    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/list/?project=${projectId}&users=${[userId]}&offset=${
              userLists.length
            }&limit=5`,
            'get',
            '',
          );

          const lists = res.data.results.map(list => ({
            ...list,
            taskCount: 0,
          }));
          commit('lists/SET_USER_LISTS', userLists.concat(lists), {
            root: true,
          });

          return res.data.count;
        },
      },
      { root: true },
    );
  },

  addRemovedUser({ rootGetters }, userId) {
    const selList = rootGetters['lists/selectedList'];
    const remUsers = rootGetters['lists/removedUsers'];

    if (!remUsers.some(us => us.id === userId)) {
      const taskCount = {
        blocked: 0,
        done: 0,
        feedback: 0,
        in_progress: 0,
        todo: 0,
      };
      if (selList) {
        const listUser = selList.users.find(us => us.id === userId);

        if (listUser) {
          taskCount.done = listUser.taskCount.done;
        }
      }

      remUsers.push({
        id: userId,
        taskCount: taskCount,
        removed: true,
      });
    }
  },

  async TeamListAvatars({ dispatch }, ids) {
    await dispatch('users/getUsersById', ids, { root: true });
  },

  logout({ commit }) {
    commit('LOGOUT_USER');
  },
};
