export default {
  setMobileDeviceState({ commit, state }, payload) {
    if (state.isMobileDevice !== payload) {
      commit('SET_MOBILE_DEVICE_STATE', payload);
    }
  },

  async getPendingDataId({ state }, data) {
    if (typeof data === 'object') {
      return state.pendingData.find(pd => {
        const pdKeysLen = Object.keys(pd).filter(k => k !== 'id').length;
        const dataKeysLen = Object.keys(data).length;

        return (
          pdKeysLen === dataKeysLen &&
          Object.keys(data).every(key => data[key] === pd[key])
        );
      })?.id;
    }
    return null;
  },

  async pendingWrapper({ dispatch, commit }, { pendingObj, callback }) {
    const pendingId = await dispatch('getPendingDataId', pendingObj);

    if (!pendingId) {
      commit('ADD_PENDING_DATA', pendingObj);

      try {
        return await callback();
      } catch (e) {
        console.error(e);
        throw e;
      } finally {
        commit(
          'REMOVE_PENDING_DATA',
          await dispatch('getPendingDataId', pendingObj),
        );
      }
    } else {
      console.warn('Trying to make a duplicate request', pendingObj);
    }
  },

  clearAll({ commit }) {
    commit('CLEAR_PENDING_DATA');
  },
};
