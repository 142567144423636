import AxiosHandler from '@/helpers/axios';

export default {
  async searching({ commit, dispatch, rootGetters }, payload) {
    dispatch('searchState');

    const search = encodeURIComponent(payload);

    if (search !== '') {
      commit('SET_IS_SEARCHING', true);

      //search lists

      // NB! NEED TO APPLY PAGINATION HERE
      const listsSearched = await AxiosHandler.call(
        '',
        `/list/?search=${search}`,
        'get',
        'application/json',
      );

      const toSetLists = await dispatch(
        'lists/setListsFields',
        listsSearched.data.results,
        { root: true },
      );

      toSetLists.map(l => {
        dispatch('lists/updateListInProject', l);
      });

      //search tasks

      // NB! NEED TO APPLY PAGINATION HERE
      commit(
        'tasks/SET_TASKS_FETCHING_BY_STATUS',
        [...rootGetters['tasks/tasksFetchingByStatus'], [1, 2, 3, 4, 5]],
        { root: true },
      );
      const tasksSearched = await AxiosHandler.call(
        '',
        `/task/?search=${search}`,
        'get',
        'application/json',
      );

      await dispatch('tasks/processFetchedTasks', tasksSearched.data.results, {
        root: true,
      });

      commit('tasks/SET_TASKS_FETCHING_BY_STATUS', [], { root: true });

      //-------------

      const tasks = rootGetters['lists/allTasks'].filter(task => {
        {
          if (
            task.title.toLowerCase().includes(payload.toLowerCase()) ||
            String(task.id).toLowerCase().includes(payload.toLowerCase())
          ) {
            return task;
          } else if (
            task.description !== null &&
            task.description.toLowerCase().includes(payload.toLowerCase())
          ) {
            return task;
          } else {
            return;
          }
        }
      });

      dispatch('setTasks', tasks);
    }

    commit('SET_IS_SEARCHING', false);
    commit('SET_SEARCHING_INPUT', payload);
  },

  async searchTasksForTimesheet(
    { commit, dispatch, rootGetters },
    [query, listId],
  ) {
    const selProj = rootGetters['projects/selectedProject'];
    const selList = rootGetters['lists/selectedList'];

    const search = escape(query);

    const pendingObj = {
      tasksForListsBeingSearch: query,
      searchOffset: rootGetters['searchAll/taskSearching'].offset,
    };

    const res = await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const taskSearching = rootGetters['searchAll/taskSearching'];

          if (taskSearching.query !== query) {
            commit(
              'searchAll/SET_TASK_SEARCH_PARAMS',
              {
                query: query,
                count: 0,
                offset: 0,
              },
              { root: true },
            );
          }

          let params = '';
          const list_id = selList ? selList.id : listId;
          if (list_id) {
            params += `&list_id=${list_id}`;
          } else if (selProj) {
            params += `&project=${selProj.id}`;
          }

          const tasksSearched = await AxiosHandler.call(
            JSON.stringify({ search }),
            `task/?search=${search}${params}&limit=7&offset=${taskSearching.offset}&archived=false`,
            'get',
            'application/json',
          );

          const list = rootGetters['projects/allLists'].find(
            l => l.id === list_id,
          );
          if (list) {
            if (!list.actualTasks) {
              list.actualTasks = [];
            }

            await dispatch(
              'tasks/placeTasksInlist',
              [tasksSearched.data.results, list],
              { root: true },
            );
          }

          commit('SET_TASK_SEARCH_PARAMS', {
            count: tasksSearched.data.count,
            offset: taskSearching.offset + tasksSearched.data.results.length,
            query: query,
          });

          return tasksSearched;
        },
      },
      { root: true },
    );

    return res.data.results;
  },

  async searchListsForTimesheet({ dispatch }, [query, offset, project]) {
    const pendingObj = {
      searchingListsCombobox: true,
    };

    let params = '';
    if (project) {
      params += `&project=${project.id}`;
    }

    const res = await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const lists = await AxiosHandler.call(
            JSON.stringify({ query }),
            `list/?search=${query}${params}&archived=false&limit=7&offset=${offset}`,
            'get',
            'application/json',
          );

          return lists;
        },
      },
      { root: true },
    );

    return res.data.results;
  },

  setSearchInput({ commit }, payload) {
    commit('SET_SEARCHING_INPUT', payload);
  },

  setTasks({ commit }, payload) {
    commit('SET_TASKS', payload);
  },

  searchState({ commit }) {
    commit('SET_TASKS', []);
  },

  logout({ commit }) {
    commit('LOGOUT_USER');
  },
};
