import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,
  state() {
    return {
      openDrawer: false,
      selectedTimesheetId: null,
      selectedTimesheet: null,
      timesheetLoading: false,
      tsLimit: 15,
      loggedHoursByList: 0,
      showDeleteTimesheetModal: false,
      showTsCalendarView: 0, // 1 - weekly, 2 - monthly, 3 - daily, 0 - default
      dailyCalendarDay: null,
      newTimesheetDates: [],
      calendarTsViews: [],
      filteredTimesheets: [],
      loadedTimesheets: [],
      filterTimesheets: {},
      loggedHoursByTask: 0,
    };
  },
  actions,
  getters,
  mutations,
};
