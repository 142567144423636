export default {
  taskSelected: state => {
    return state.taskSelected;
  },
  errorMsg: state => {
    return state.errorMsg;
  },
  taskComponentReady: state => {
    return state.taskComponentReady;
  },
  taskUpdating: state => {
    return state.taskUpdating;
  },
  noFetchTaskStatus: state => {
    return state.noFetchTaskStatus;
  },
  tasksFetchingByStatus: state => {
    return state.tasksFetchingByStatus;
  },
  tempPosOfDraggedTask: state => {
    return state.tempPosOfDraggedTask;
  },
  tasksCountByStatus: state => {
    return state.tasksCountByStatus;
  },
  searchTasksIds: state => {
    return state.searchTasksIds;
  },
};
