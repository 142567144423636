import AxiosHandler from '@/helpers/axios';

export default {
  async fetchProjectMilestones({ dispatch }, pro) {
    const pendingObj = { fetchProjectMilestones: pro.id };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/milestones/?project=${pro.id}`,
            'get',
            '',
          );

          pro['milestones'] = res.data.results;
        },
      },
      { root: true },
    );
  },

  async filterMilestones({ dispatch }, [pro, date]) {
    const pendingObj = { filteringMilestones: pro.id };
    const query = date ? `&date=${date}` : '';
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const milestones = await AxiosHandler.call(
            null,
            `/milestones/?project=${pro.id}${query}`,
            'get',
            '',
          );

          pro.milestones = milestones.data.results;
        },
      },
      { root: true },
    );
  },

  async postMilestone({ dispatch }, [milestone, pro]) {
    const pendingObj = {
      addProjectMilestones: pro.id,
      milestone: milestone.id,
    };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(
            JSON.stringify(milestone),
            `/milestones`,
            'post',
            'application/json',
          );
        },
      },
      { root: true },
    );
  },

  async patchMilestone({ state }, [field, id]) {
    if (id === state.tempMilestoneId) return;

    await AxiosHandler.call(
      JSON.stringify(field),
      `/milestones/${id}`,
      'patch',
      'application/json',
    );
  },

  async deleteMilestone({ state }, [mId]) {
    if (mId === state.tempMilestoneId) return;

    await AxiosHandler.call(null, `/milestones/${mId}`, 'delete', '');
  },
};
