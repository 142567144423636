import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,
  state() {
    return {
      selectedListId: null,
      projectLists: [],
      listToMoveTo: null,
      maxListCharacters: 50,
      colorPickerList: null,
      userLists: null,
      removedUsers: [],
    };
  },
  actions,
  getters,
  mutations,
};
