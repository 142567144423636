export default {
  ADD_NOTIFICATION: (state, payload) => {
    if (!state.notifications.some(n => n.id === payload.id)) {
      state.notification_created = !state.notification_created;

      payload['selectFilter'] = false;

      state.notifications.unshift(payload);
      state.notificationsCount += 1;
    }
  },

  UPDATE_NOTIFICATION: (state, payload) => {
    const i = state.notifications.map(n => n.id).indexOf(payload.id);

    state.notifications.splice(i, 1, payload);
  },

  SET_NOTIFICATIONS: (state, payload) => {
    payload.map(p => (p['selectFilter'] = false));
    state.notifications = state.notifications.concat(payload);
  },

  SET_NOTIFICATIONS_READ: state => {
    state.notifications.map(n => (n.read = true));
  },

  READ_NOTIFICATION: (state, id) => {
    if (state.notifications.length > 0) {
      const notificationRead = state.notifications.find(n => n.id === id);

      notificationRead.read = true;

      let i = state.notifications
        .map(function (n) {
          return n.id;
        })
        .indexOf(id);

      state.notifications.splice(i, 1, notificationRead);
    }
  },

  DELETE_READ_NOTIFICATIONS: state => {
    const oldLen = state.notifications.length;
    state.notifications = state.notifications.filter(n => n.read === false);
    state.notificationsCount -= oldLen - state.notifications.length;
  },

  NOTE_TO_DEL: (state, id) => {
    state.notToDel = id;
  },

  DELETE_NOTIFICATION: (state, id) => {
    state.notifications = state.notifications.filter(n => n.id !== id);
    state.notificationsCount -= 1;
  },

  REMOVE_DUPLICATE_NOTIFICATIONS: async state => {
    state.notifications = [
      ...state.notifications
        .reduce((map, obj) => map.set(obj.id, obj), new Map())
        .values(),
    ];
  },

  SET_IDS_FOR_NOTIFICATIONS: (state, ids) => {
    state.notificationIds = ids;
  },

  SET_OFFSET: (state, payload) => {
    state.offset = payload;
  },

  SET_NOTIFICATIONS_COUNT: (state, payload) => {
    state.notificationsCount = payload;
  },

  SET_UNREAD_NOTIFICATIONS: (state, payload) => {
    state.unreadNotifications = payload;
  },

  LOGOUT_USER: state => {
    state.notifications = [];
    state.notification_created = false;
    state.notificationsCount = 0;
    state.offset = 0;
  },
};
