export default {
  SET_CATEGORIES: (state, payload) => {
    state.categories = payload;
  },

  SET_TO_ADD_TAGS: (state, payload) => {
    state.toAddTags = payload;
  },

  SET_TO_RM_TAGS: (state, payload) => {
    state.toRemoveTags = payload;
  },

  /**
   *
   * @param state
   * @param payload - {
   *   query: String
   *   count: Number,
   *   tags: Array,
   * }
   * @constructor
   */
  SET_SEARCHING_STATE: (state, payload) => {
    state.searchingState = payload;
  },
};
