import AxiosHandler from '@/helpers/axios';
import router from '@/router';

/**
 * The type of data searchObj.
 * @typedef {Object} searchObj
 * @property {number} list?
 * @property {string} search?
 * @property {number} task?
 * @property {number} excluding_list?
 * @property {string} username?
 * @property {string} project?
 * @property {number} limit?
 * @property {number} offset?
 */

export default {
  async getUserById({ commit, state, dispatch }, userId) {
    const user = state.users.find(u => u.id === userId);

    if (!user) {
      const pendingObj = { user: userId, method: 'get' };

      const data = await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            const res = await AxiosHandler.call(
              '',
              `/users/${userId}`,
              'get',
              null,
            );

            await commit(
              'SET_USERS',
              [res.data].filter(
                u => !state.users.map(u => u.id).includes(u.id),
              ),
            );

            return res.data;
          },
        },
        { root: true },
      );

      return data;
    }

    return user;
  },

  async getUsersById({ commit, state, dispatch }, userIdsInParam) {
    let userIds = userIdsInParam.filter(
      i => !state.users.map(u => u.id).includes(i),
    );

    if (userIds && userIds.length > 0) {
      const gottenUsers = await new Promise(resolve => {
        let total = userIds.length;
        const totalUsers = [];

        userIds.map(async usr => {
          const res = await AxiosHandler.call('', `/users/${usr}`, 'get', null);

          total = total - 1;
          totalUsers.push(res.data);
          if (total === 0) {
            resolve(totalUsers);
          }
        });
      });

      await dispatch(
        'getAvatars',
        gottenUsers.filter(
          user => !state.users.map(u => u.id).includes(user.id),
        ),
      );

      await commit(
        'SET_USERS',
        gottenUsers.filter(
          user => !state.users.map(u => u.id).includes(user.id),
        ),
      );

      return gottenUsers;
    }
  },

  async getUsersByProject({ commit, dispatch, state }, project) {
    const pendingObj = { loadingProjectUsers: project.title };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const gottenUsers = await AxiosHandler.call(
            '',
            `/users/?project=${project.id}`,
            'get',
            null,
          );

          await dispatch(
            'getAvatars',
            gottenUsers.data.results.filter(
              user => !state.users.map(u => u.id).includes(user.id),
            ),
          );

          await commit(
            'SET_USERS',
            gottenUsers.data.results.filter(
              user => !state.users.map(u => u.id).includes(user.id),
            ),
          );
        },
      },
      { root: true },
    );
  },

  setUser({ commit }, payload) {
    commit('SET_USER', payload);
  },

  updateUserAvatar({ commit }, user) {
    commit('SET_USER', user);
  },

  isChangedProfile({ commit }, payload) {
    commit('SET_IS_CHANGED_PROFILE', payload);
  },

  setHasErrors({ commit }, payload) {
    commit('SET_HAS_ERRORS', payload);
  },

  updateStatus({ commit }, payload) {
    commit('SET_USER_UPDATE_STATUS', payload);
  },

  oldUserProfile({ commit }, payload) {
    commit('SET_OLD_USER_PROFILE', payload);
  },

  /**
   * @param commit: {Function}
   * @param state: {Object}
   * @param dispatch: {Function}
   * @param propSearchObj: {searchObj}
   * @returns {Promise<void>}
   */
  async searchUsers({ commit, dispatch, state }, propSearchObj) {
    const pendingObj = { searchUsers: propSearchObj.search };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const defParams = {
            search: '',
            excluding_list: -1,
            limit: 8,
            offset: 0,
          };

          const data = {
            ...defParams,
            ...propSearchObj,
          };

          data.search = encodeURI(data.search);

          if (data.excluding_list === -1) {
            delete data.excluding_list;
          }

          const res = await AxiosHandler.call(
            '',
            `/users/?${Object.keys(data)
              .map(key => `${key}=${data[key]}`)
              .join('&')}`,
            'get',
            null,
          );

          await commit('SET_USERS', res.data.results);
          const searchedUserIds = res.data.results.map(user => user.id);

          if (state.searchedUsers.length === 0) {
            commit('SET_SEARCH_USERS', searchedUserIds);
          } else {
            commit('SET_SEARCH_USERS', [
              ...new Set([...state.searchedUsers, ...searchedUserIds]),
            ]);
          }
        },
      },
      { root: true },
    );
  },

  async getAvatars({ commit }, users) {
    if (Array.isArray(users)) {
      for (const usr of users) {
        if (usr.avatar_id) {
          const res = await AxiosHandler.call(
            null,
            `/users/${usr.id}/avatar`,
            'get',
            'application/json',
          );

          usr['avatar_id'] = res.data;
          commit('SET_USER', usr);
        }
      }
    }
  },

  async inviteUser({ dispatch }, data) {
    try {
      const res = await AxiosHandler.call(
        data,
        `/invite`,
        'post',
        'application/json',
      );

      if (res.status === 206) {
        dispatch(
          'popups/setToast',
          ['Project owner does not have sufficient seats', 3000],
          { root: true },
        );
      } else if (res.status === 200) {
        const message = data.allocate_seat
          ? 'Your invitation has been sent. Once they accepted, they will be added to your plan.'
          : 'Your invitation has been sent.';
        dispatch('popups/setToast', [message, 3000], { root: true });
      }

      return res;
    } catch (err) {
      if (err.status === 302) {
        router.push('/settings/plans?openModal=true');

        dispatch(
          'popups/setToast',
          [
            `You don't have sufficient seats, purchase additional plan to be able to invite user`,
            5000,
          ],
          { root: true },
        );
      } else {
        dispatch(
          'popups/setToast',
          [
            `<span style="color: var(--prog-orange)">Invitation failed to send</span>`,
            3000,
          ],
          { root: true },
        );
      }

      return err;
    }
  },

  async revokeUserInvitation({ dispatch }, [seatId]) {
    const pendingObj = { revokeUserInvitation: seatId };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(null, `/plan/seat/${seatId}`, 'delete', '');
        },
      },
      { root: true },
    );
  },

  async removeUserFromProject(
    { commit, dispatch, rootGetters },
    [projectId, userId],
  ) {
    const pendingObj = { removingUser: userId };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(
            {
              user: userId,
            },
            `/project/${projectId}/user`,
            'delete',
            'application/json',
          );

          const tasks = rootGetters['lists/allTasks'];
          tasks.forEach(t => {
            if (t.project_id === projectId && t.status !== 5) {
              t.users = t.users.filter(u => u !== userId);
            }
          });

          commit(
            'projects/SET_EXPIRED_PROJECT_USERS',
            rootGetters['projects/expiredUsers'].filter(eu => eu.id !== userId),
            { root: true },
          );
        },
      },
      { root: true },
    );
  },
};
