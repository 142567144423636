export default {
  selectedProject: state => {
    return state.projects.find(pr => pr.id === state.selectedProjectId);
  },

  projects: state => {
    return state.projects;
  },

  maxProjectCharacters: state => {
    return state.maxProjectCharacters;
  },

  projectsCount: state => {
    return state.projectsCount;
  },

  projectsOffset: state => {
    return state.projectsOffset;
  },

  draggedList: state => {
    return state.draggedList;
  },

  openProjects: state => {
    return state.openProjects;
  },

  addProject: state => {
    return state.addProject;
  },

  expiredUsers: state => {
    return state.expiredUsers;
  },

  allLists: state => {
    return [
      ...new Set(
        state.projects
          .filter(p => p.lists)
          .reduce((acc, p) => acc.concat(p.lists), []),
      ),
    ];
  },
};
