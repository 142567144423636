/**
 * Helper class to work with cookies
 */
export default class Cookies {
  /**
   * Get all cookies
   * @returns {Object.<string, string|undefined>}
   */
  static getAll() {
    return document.cookie
      .split(';')
      .map(c => c.split(/=(.*)/s))
      .reduce(
        (accumulator, [key, value]) => ({
          ...accumulator,
          [key.trim()]: decodeURIComponent(value),
        }),
        {},
      );
  }

  /**
   * Configure cookie params
   * @param options {{Path: string?, expires: Date?, SameSite: string?}}
   * @returns {{Path: string, expires: string, SameSite: string }}
   */
  static setOptions(options) {
    const defaultOptions = {
      Path: '/',
      expires: 'Session',
      SameSite: '',
    };

    return {
      ...defaultOptions,
      ...options,
      expires: options.expires
        ? options.expires.toUTCString()
        : defaultOptions.expires,
    };
  }

  /**
   * Set one parameter in cookie
   * @param name {string} - key
   * @param value {string} - value
   * @param options {{Path: string?, expires: Date?, SameSite: string?}}
   * - expire time of this parameter
   */
  static set(name, value, options = {}) {
    const fullOptions = Cookies.setOptions(options);
    const opt = Object.entries(fullOptions)
      .map(a => a[0] + '=' + a[1])
      .join('; ');

    document.cookie = name + '=' + (value || '') + '; ' + opt + ';';
  }

  /**
   * Delete cookie by name
   * @param name {string}
   */
  static delete(name) {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  }
}
