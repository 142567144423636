export default {
  SET_TOKENS: (state, tokens) => {
    state.tokens = tokens;
  },

  SET_APPEAR_COOKIE_POPUP: (state, isAppear) => {
    state.appearCookiePopup = { appear: isAppear };
  },

  SET_PROFILE: (state, profile) => {
    profile.projects = [...new Set(profile.projects)];

    state.profile = profile;
  },

  SET_PROCESS_LOGOUT: (state, payload) => {
    state.processLogout = payload;
  },

  SET_THEME: (state, isLight) => {
    state.profile = { ...state.profile, lightTheme: isLight };
  },

  SET_PROFILE_NAME: (state, profileName) => {
    state.profile = { ...state.profile, display_name: profileName };
  },

  SET_USER_AVATAR: (state, profileAvatar) => {
    state.profile = { ...state.profile, avatar_id: profileAvatar };
  },

  SET_PROJECT_ORDER: (state, ListOrder) => {
    ListOrder = [...new Set(ListOrder)];

    state.profile = { ...state.profile, projects: ListOrder };
  },

  SET_PENDDING_REQUESTS: (state, payload) => {
    state.pendingRequests = payload;
  },

  DESTROY_MAIN_PAGE: state => {
    state.destroy = true;

    setTimeout(() => {
      state.destroy = false;
    }, 1000);
  },

  LOGOUT_USER: state => {
    state.profile = null;
    state.user = null;
    state.destroy = true;
    state.pendingRequests = [];
    state.billingDetails = {};
    state.paymentCards = [];

    setTimeout(() => {
      state.destroy = false;
    }, 1000);
  },

  SET_REFRESH: (state, payload) => {
    state.resetRefresh = payload;
  },

  USER_SETTINGS: (state, payload) => {
    state.userSettings = payload;
  },

  SET_USER_SOCKET: (state, payload) => {
    state.userSocket = payload;
  },

  ADD_LIST_SOCKET: (state, payload) => {
    state.listSockets = [...state.listSockets, payload];
  },

  REMOVE_LIST_SOCKET: (state, payload) => {
    const index = state.listSockets.findIndex(s => s.url === payload.url);
    if (index > -1) {
      state.listSockets.splice(index, 1);
    }
  },

  SET_LIST_SOCKETS: (state, payload) => {
    state.listSockets = [...payload];
  },

  ADD_PROJECT_SOCKET: (state, payload) => {
    state.projectSockets = [...state.projectSockets, payload];
  },

  REMOVE_PROJECT_SOCKET: (state, payload) => {
    const index = state.projectSockets.findIndex(s => s.url === payload.url);
    if (index > -1) {
      state.projectSockets.splice(index, 1);
    }
  },

  SET_PROJECT_SOCKETS: (state, payload) => {
    state.projectSockets = [...payload];
  },

  SET_TASK_SOCKET: (state, payload) => {
    state.taskSocket = payload;
  },

  SET_IS_INITIATED_LOGOUT_PROCESS: (state, payload) => {
    state.isInitiatedLogoutProcess = payload;
  },

  SET_PRE_COMMIT_USER_EMAIL: (state, payload) => {
    state.preCommitUserEmailData = payload;
  },

  SET_USER_BILLING_DETAILS: (state, payload) => {
    state.userBillingDetails = payload;
  },

  SET_BILLING_DETAILS: (state, payload) => {
    state.billingDetails = payload;
  },

  SET_IS_CHANGED_BIL_DETAILS: (state, payload) => {
    state.isChangedBilDetails = payload;
  },

  SET_PAYMENT_CARDS: (state, payload) => {
    state.paymentCards = payload;
  },

  SET_BILLING_QUERIES: (state, payload) => {
    state.billingQueries = payload;
  },

  SET_FILTER_BILLING_QUERY: (state, payload) => {
    state.filterBillingQuery = payload;
  },

  SET_FILTERED_BILLING_QUERIES: (state, payload) => {
    state.filteredBillingQueries = payload;
  },

  SET_BILLING_NOTES: (state, notes) => {
    state.billingNotes = notes;
  },

  SET_SELECTED_QUERY: (state, query) => {
    state.selectedQuery = query;
  },

  IS_CREATING_QUERY: (state, isCreating) => {
    state.creatingQuery = isCreating;
  },

  CHECK_ERROR_INPUTS: (state, payload) => {
    state.checkErrorInputs = payload;
  },

  CLEAR_BILLING_DETAILS: state => {
    state.paymentCards = [];
    state.isChangedBilDetails = false;
    state.billingDetails = {};
    state.userBillingDetails = null;
    state.checkErrorInputs = false;
  },

  SHOW_EXPORT_MODAL: (state, payload) => {
    state.showExportModal = payload;
  },

  SET_PAYMENT_HISTORY: (state, history) => {
    state.paymentHistory = history;
  },

  SET_FILTER_PAYMENT_HISTORY: (state, payload) => {
    state.filterPaymentHistory = payload;
  },

  SET_FILTERED_PAYMENT_HISTORY: (state, payload) => {
    state.filteredPaymentHistory = payload;
  },

  SET_FORCE_LOGOUT: (state, payload) => {
    state.forceLogout = payload;
  },
};
