import { BACKEND_TASK_STATUSES, HIDE_TASK_STATUSES } from '@/helpers/constants';
import { sortAlphabetically } from '@/helpers/helpers';

export default {
  async task_created({ rootGetters, dispatch }, m) {
    const selList = rootGetters['lists/selectedList'];
    const selUsers = rootGetters['tasksState/selectedUsers'];
    m.task_created['comments'] = [];
    m.task_created['actualComments'] = [];

    if ('pending_request' in m.task_created) {
      delete m.task_created.pending_request;
    }

    const li = rootGetters['projects/allLists'].find(
      l => l.id === m.task_created.list_id,
    );

    if (li && !li.actualTasks?.some(t => t.id === m.task_created.id)) {
      m.task_created.list_key = li.key;
      li.actualTasks.unshift(m.task_created);
      li.taskCount += 1;
      m.task_created.users.forEach(u => {
        const user = li.users.find(liUser => liUser.id === u);
        if (user) {
          user.taskCount[BACKEND_TASK_STATUSES[m.task_created.status - 1]] += 1;
        }
      });

      dispatch(
        'tasks/addDeleteTaskReorder',
        { list: li, option: 'add', taskId: m.task_created.id },
        { root: true },
      );
    }

    if (
      selList?.id === m.task_created.list_id &&
      (selUsers.length === 0 ||
        m.task_created.users.find(u => selUsers.includes(u)))
    ) {
      const taskColCount = rootGetters['tasks/tasksCountByStatus'];
      taskColCount[m.task_created.status] += 1;
    }
  },

  async task_deleted({ commit, rootGetters, dispatch }, [m, ogEv]) {
    const liId = parseInt(ogEv.target.url.split('/')[5]);
    const li = rootGetters['projects/allLists'].find(l => l.id === liId);
    const task = rootGetters['lists/allTasks'].find(
      t => t.id === m.task_deleted,
    );
    const tasksDisplayed = rootGetters['tasksState/tasksDisplayed'];
    const selList = rootGetters['lists/selectedList'];
    const selUsers = rootGetters['tasksState/selectedUsers'];

    rootGetters['timesheet/filteredTimesheets'].forEach(t => {
      if (t.task === m.task_deleted) {
        t.task = null;
        commit('timesheet/UPDATE_TIMESHEET', t, { root: true });
      }
    });

    if (
      task &&
      selList?.id === liId &&
      (selUsers.length === 0 || task.users.find(u => selUsers.includes(u)))
    ) {
      const taskColCount = rootGetters['tasks/tasksCountByStatus'];
      taskColCount[task.status] -= 1;
    }

    if (li) {
      dispatch(
        'tasks/addDeleteTaskReorder',
        { list: li, option: 'delete', taskId: m.task_deleted },
        { root: true },
      );
      li.actualTasks = li.actualTasks.filter(t => t.id !== m.task_deleted);
      li.taskCount = li.taskCount - 1;

      const taskHidden = tasksDisplayed[HIDE_TASK_STATUSES[task?.status - 1]];
      if (
        task &&
        liId === selList?.id &&
        !taskHidden &&
        task.users.length > 0
      ) {
        const taskUsers = selList.users.filter(u => task.users.includes(u.id));
        taskUsers.map(
          u => (u.taskCount[BACKEND_TASK_STATUSES[task.status - 1]] -= 1),
        );
      }

      //defence mechanism for deleting task in task move
      const editedTask = rootGetters['tasksState/taskToEdit'];
      if (editedTask?.id === m.task_deleted) {
        dispatch(
          'popups/setToast',
          [`task: ${editedTask.title}, has been deleted`, 3000],
          { root: true },
        );
        dispatch('tasksState/toggleAddEditTask', false, { root: true });
        commit('tasksState/SET_TASK_TO_EDIT', null, { root: true });
      }
    }
  },

  async task_users_added({ rootGetters, dispatch }, m) {
    const task = rootGetters['lists/allTasks'].find(t => t.id === m.users.task);

    if (task && task.users.length < m.users.users.length) {
      dispatch('tasks/addUserTask', [task, m.users.users], { root: true });
    }
  },

  async task_users_removed({ rootGetters, dispatch }, m) {
    const task = rootGetters['lists/allTasks'].find(
      t => t.id === m.task_user_removed.task,
    );

    if (task && task.users.length > m.task_user_removed.users.length) {
      dispatch('tasks/removeUserTask', [task, m.task_user_removed.users], {
        root: true,
      });
    }
  },

  async tag_added({ rootGetters, dispatch }, m) {
    const tag = {
      id: m.tag_added.added_tag.id,
      tag: m.tag_added.added_tag.tag,
    };

    const task = rootGetters['lists/allTasks'].find(
      t => t.id === m.tag_added.task,
    );

    const list = rootGetters['lists/selectedList'];

    if (list?.tags && !list.tags.some(t => t.id === tag.id)) {
      list.tags.push(tag);
      list.tags = sortAlphabetically(list.tags, 'tag');
      dispatch('lists/updateListInProject', list, { root: true });
    }

    if (task && !task.tags?.some(t => t.tag === tag.tag)) {
      if (!task?.tags) {
        task.tags = [tag];
      } else {
        task.tags.push(tag);
      }

      dispatch('tasks/updateTaskInList', task, { root: true });
    }
  },

  async tag_removed({ rootGetters, dispatch }, m) {
    const tagId = m.tag_removed.removed_tag.id;

    const list = rootGetters['lists/selectedList'];
    if (list?.tags) {
      list.tags = list.tags.filter(t => t.id !== tagId);

      dispatch('lists/updateListInProject', list, { root: true });
    }

    const task = rootGetters['lists/allTasks'].find(
      t => t.id === m.tag_removed.task,
    );
    if (task?.tags?.some(t => t.id === tagId)) {
      task['tags'] = task.tags.filter(t => t.id !== tagId);

      dispatch('tasks/updateTaskInList', task, { root: true });
    }
  },

  updateEditedTask({ commit, rootGetters }, m) {
    const userSettings = rootGetters['auth/userSettings'];
    const editedTask = rootGetters['tasksState/taskToEdit'];
    const isAutosave = userSettings.Task_Preferences.find(
      s => s.setting === 'Auto-save task',
    ).toggled;

    if (editedTask?.id === m.task_id) {
      for (const [key, value] of Object.entries(m['changes'])) {
        if (isAutosave && key === 'description') {
          continue;
        }

        editedTask[key] = value;
      }

      editedTask.attachments = [
        ...editedTask.attachments
          .reduce((map, obj) => map.set(obj.id, obj), new Map())
          .values(),
      ];

      if (!editedTask.start_date) {
        editedTask.start_date = '';
      }

      commit('tasksState/SET_TASK_TO_EDIT', editedTask, { root: true });
    }
  },

  async task_update({ rootGetters, dispatch }, [m, ogEv]) {
    const profile = rootGetters['auth/userProfile'];
    const liId = parseInt(ogEv.target.url.split('/')[5]);
    const li = rootGetters['projects/allLists'].find(l => l.id === liId);
    if (!li) {
      return;
    }

    const selList = rootGetters['lists/selectedList'];
    const selUsers = rootGetters['tasksState/selectedUsers'];

    let t = li.actualTasks.find(t => t.id === parseInt(m.task_id));

    if (m.sender !== profile.id && m.changes.task_marked_done && li) {
      li.actualTasks.forEach(at => (at.position += 1));
    }

    if (t) {
      for (const [key, value] of Object.entries(m['changes'])) {
        if (key === 'tags') {
          continue;
        }

        t[key] = value;
        if (key == 'comments') {
          t.actualComments = t.actualComments.filter(comment =>
            t.comments.includes(comment.id),
          );
        }
      }

      t.attachments = [
        ...t.attachments
          .reduce((map, obj) => map.set(obj.id, obj), new Map())
          .values(),
      ];

      const oldStatus = m['changes'].old_status;
      const newStatus = m['changes'].status;

      if (
        oldStatus &&
        selList?.id === t.list_id &&
        (selUsers.length === 0 || t.users.find(u => selUsers.includes(u)))
      ) {
        const taskColCount = rootGetters['tasks/tasksCountByStatus'];
        taskColCount[oldStatus] -= 1;
        taskColCount[newStatus] += 1;
      }

      if (
        selList &&
        t.list_id === selList.id &&
        t.users.length > 0 &&
        oldStatus !== newStatus
      ) {
        const users = selList.users.concat(rootGetters['lists/removedUsers']);
        const taskUsers = users.filter(u => t.users.includes(u.id));
        taskUsers.map(
          u => (u.taskCount[BACKEND_TASK_STATUSES[oldStatus - 1]] -= 1),
        );
        taskUsers.map(
          u => (u.taskCount[BACKEND_TASK_STATUSES[newStatus - 1]] += 1),
        );
      }

      if (rootGetters['tasks/tempPosOfDraggedTask']?.id === t.id) {
        t['position'] = rootGetters['tasks/tempPosOfDraggedTask'].pos;
      }

      dispatch('tasks/updateTaskInList', t, { root: true });
      dispatch('updateEditedTask', m);
    }
  },

  async pending_request_created({ rootGetters, dispatch }, [m, sender]) {
    const t = rootGetters['lists/allTasks'].find(
      t => t.id === m.pending_request_created.task_id,
    );

    if (t !== undefined && t.list_id !== m.pending_request_created.list_id) {
      if (rootGetters['auth/userProfile'].owned_lists === sender) {
        m.pending_request_created.state = 'accepted';
      }

      t['pending_request'] = m.pending_request_created;

      dispatch('tasks/updateTaskInList', t, { root: true });
    }
  },

  async pending_request_updated({ rootGetters, dispatch }, m) {
    const t = rootGetters['lists/allTasks'].find(
      t => t.id === m.pending_request_deleted,
    );

    if (t !== undefined && 'pending_request' in t) {
      delete t.pending_request;

      dispatch('tasks/updateTaskInList', t, { root: true });
    } else {
      return;
    }
  },

  async pending_request_deleted({ rootGetters, dispatch }, m) {
    let task = rootGetters['lists/allTasks'].find(
      t => t.id === m.pending_request_deleted,
    );

    if (task && 'pending_request' in task) {
      delete task.pending_request;

      dispatch('tasks/updateTaskInList', task, { root: true });
    } else {
      return;
    }
  },

  connectList({ commit }, id) {
    commit('LIST_TO_CONNECT', id);
  },

  disconnectAllLists({ commit }) {
    commit('SET_LIST_TO_CONNECT', []);
  },

  async receive_data({ dispatch }, m) {
    if (m.reload?.list) {
      await dispatch(
        'permissions/getUserTaskPermsByList',
        {
          listId: m.reload.list.id,
          userId: m.reload.list.user,
          isRecall: true,
        },
        { root: true },
      );
    }
  },

  async task_reorder({ dispatch }, m) {
    dispatch('lists/fetchReorderingTasks', m, { root: true });
  },
};
