import { getSequencePermissions } from '@/helpers/helpers';
import { PROJECT_ROLES_DEFAULT } from '@/helpers/constants';

const processProjectUserData = (pObj, lists, payload) => {
  const projectId = payload.projectId;
  const count = payload.count;
  const user = payload.results[0].user;

  if (pObj.project === projectId) {
    const data = pObj.data.map(usObj => {
      if (usObj.user === user) {
        usObj.lists = lists;

        if (count) usObj.listCount = count;
      }

      return usObj;
    });

    pObj.data = data;
  }

  return pObj;
};

export default {
  SET_SELECTED_USER: (state, payload) => {
    state.selectedUser = payload;
  },

  SET_USER_DATA_PER_PROJECT: (state, payload) => {
    if (payload === null) {
      state.projectUserData = [];
      return;
    } else if (payload === undefined) {
      return;
    }

    const newUserObj = {
      id: payload.id,
      user: payload.user,
      role: payload.role,
      project: payload.project,
      project_permissions: getSequencePermissions(payload.project_permissions),
      list_permissions: getSequencePermissions(payload.list_permissions),
      snapshot_permissions: getSequencePermissions(
        payload.snapshot_permissions,
      ),
      timesheet_permissions: getSequencePermissions(
        payload.timesheet_permissions,
      ),
      lists: [],
      listCount: 0,
    };

    const projectIndex = state.projectUserData.findIndex(
      pro => pro.project === payload.project,
    );

    if (projectIndex === -1) {
      state.projectUserData.push({
        project: payload.project,
        data: [newUserObj],
      });
    } else {
      const userIndex = state.projectUserData[projectIndex].data.findIndex(
        us => us.user === payload.user,
      );

      if (userIndex === -1) {
        state.projectUserData[projectIndex].data.push(newUserObj);
      } else {
        const oldRole =
          state.projectUserData[projectIndex].data[userIndex].role;
        const newRole = newUserObj.role;

        if (newRole === oldRole || oldRole == undefined) {
          newUserObj.lists =
            state.projectUserData[projectIndex].data[userIndex].lists;
          newUserObj.listCount =
            state.projectUserData[projectIndex].data[userIndex].listCount;
        }

        state.projectUserData[projectIndex].data.splice(
          userIndex,
          1,
          newUserObj,
        );
      }
    }
  },

  REMOVE_USER_PERMISSIONS: (state, payload) => {
    const index = state.projectUserData.findIndex(
      p => p.project === payload.projectId,
    );
    if (index > -1) {
      const index1 = state.projectUserData[index].data.findIndex(
        d => d.user === payload.userId,
      );
      if (index1 > -1) {
        state.projectUserData[index].data.splice(index1, 1);
      }
    }
  },

  SET_FULL_ROLES: (state, payload) => {
    state.roles = payload.map(i => {
      return {
        id: i.id,
        name: i?.name || '',
        project_permissions: getSequencePermissions(i.project_permissions),
        list_permissions: getSequencePermissions(i.list_permissions),
        task_permissions: getSequencePermissions(i.task_permissions),
        snapshot_permissions: getSequencePermissions(i.snapshot_permissions),
        timesheet_permissions: getSequencePermissions(i.timesheet_permissions),
        shortTitle:
          PROJECT_ROLES_DEFAULT.find(p => p.attribute === i?.name).title ||
          i?.name ||
          '',
        color:
          PROJECT_ROLES_DEFAULT.find(p => p.attribute === i?.name).color ||
          'var(--bg-grey-000)',
      };
    });

    const defaultRoleIds = {};
    payload.forEach(r => {
      if (r?.name) {
        defaultRoleIds[r.name] = r.id;
      }
    });
    state.defaultRoleIds = defaultRoleIds;
  },

  CLEARING_PROJECTS: (state, projects) => {
    state.projectUserData = state.projectUserData.filter(p =>
      projects.includes(p.project),
    );
  },

  SET_TASKS_PERMISSIONS_BY_USER: (state, payload) => {
    const projectId = payload.projectId;
    const user = payload.results[0].user;

    const userDataProject = state.projectUserData?.find(
      p => p.project === projectId,
    );

    if (userDataProject) {
      const oldLists = userDataProject.data
        ?.find(us => us.user === user)
        ?.lists.filter(
          l => !payload.results.find(res => res.list_id === l.listId),
        );

      const lists = oldLists?.concat(
        payload.results.map(t => {
          return {
            listId: t.list_id,
            id: t.id,
            task_permissions: getSequencePermissions(t.task_permissions),
          };
        }),
      );

      const newData = state.projectUserData.map(pObj => {
        return processProjectUserData(pObj, lists, payload);
      });

      state.projectUserData = [...newData];
    } else {
      state.projectUserData = [
        ...state.projectUserData,
        {
          project: projectId,
          data: payload.results.map(t => {
            return {
              user: user,
              lists: [
                {
                  listId: t.list_id,
                  id: t.id,
                  task_permissions: getSequencePermissions(t.task_permissions),
                },
              ],
              listCount: 0,
            };
          }),
        },
      ];
    }
  },
};
