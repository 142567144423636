export default {
  SET_CREATING_EPIC: (state, payload) => {
    state.creatingEpic = payload;
  },

  SET_FILTERED_EPICS: (state, payload) => {
    state.filteredEpics = payload;
  },

  SET_EPICS_FILTER: (state, payload) => {
    state.filterEpics = payload;
  },

  PATCH_CREATING_EPIC: (state, data) => {
    state.creatingEpic = {
      ...state.creatingEpic,
      ...data,
    };
  },

  PATCH_EPIC: (state, data) => {
    let isExist = false;

    state.epics = state.epics.map(ep => {
      if (data.id === ep.id) {
        isExist = true;

        return {
          ...ep,
          ...data,
        };
      } else return ep;
    });

    if (!isExist) {
      state.epics = [...state.epics, data];
    }
  },

  DELETE_EPIC: (state, epicId) => {
    state.epics = state.epics.filter(ep => ep.id !== epicId);
  },

  SET_RESIZE_MODAL_EPIC_ID: (state, epicId) => {
    state.resizeModalEpicId = epicId;
  },

  SET_OPEN_MENU_EPIC_ID: (state, epicId) => {
    state.openMenuEpicId = epicId;
  },

  SET_EPICS_LIST: (state, epics) => {
    state.epics = epics;
  },

  SET_EPICS: (state, epics) => {
    const tempArr = [];

    const oldEpics = state.epics.map(e => {
      const newEp = epics.find(ep => ep.id === e.id);

      if (newEp) {
        tempArr.push(newEp.id);
        return newEp;
      } else {
        return e;
      }
    });

    state.epics = oldEpics.concat(epics.filter(ep => !tempArr.includes(ep.id)));
  },

  PATCH_EPICS: (state, epics) => {
    state.epics = state.epics.map(epic => {
      const newEpData = epics.find(ep => ep.id === epic.id);

      if (newEpData) {
        return {
          ...epic,
          ...newEpData,
        };
      } else {
        return epic;
      }
    });
  },

  SET_UPDATED_DATE: (state, date) => {
    state.updatedDate = date;
  },

  SET_SHOW_WEEK_CALENDAR: (state, payload) => {
    state.showWeekCalendar = payload;
  },
};
