import { debounce } from '../../../helpers/helpers';

export default {
  SET_ERROR_MSG: state => {
    state.errorMsg = 'task name taken';

    debounce(() => {
      state.errorMsg = null;
    }, 3000)();
  },

  SET_COMPONENT: state => {
    state.taskComponentReady = true;
  },

  SET_TASK_UPDATING: (state, payload) => {
    state.taskUpdating = payload;
  },

  SET_TEMP_POS_OF_DRAGGED_TASK: (state, payload) => {
    state.tempPosOfDraggedTask = payload;
  },

  SET_TASKS_FETCHING_BY_STATUS: (state, payload) => {
    state.tasksFetchingByStatus = payload;
  },

  SET_SEARCH_TASKS_IDS: (state, payload) => {
    state.searchTasksIds = payload;
  },

  LOGOUT_USER: state => {
    state.errorMsg = null;
    state.taskUpdating = false;
    state.taskComponentReady = false;
    state.searchTasksIds = [];
  },
};
