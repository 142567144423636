export default {
  openDrawer: state => {
    return state.openDrawer;
  },

  selectedTimesheetId: state => {
    return state.selectedTimesheetId;
  },

  selectedTimesheet: state => {
    if (state?.selectedTimesheetId) {
      return state.filteredTimesheets.find(
        p => p?.id === state?.selectedTimesheetId,
      );
    } else {
      return null;
    }
  },

  timesheetLoading: state => {
    return state.timesheetLoading;
  },

  tsLimit: state => {
    return state.tsLimit;
  },

  showDeleteTimesheetModal: state => {
    return state.showDeleteTimesheetModal;
  },

  showTsCalendarView: state => {
    return state.showTsCalendarView;
  },

  dailyCalendarDay: state => {
    return state.dailyCalendarDay;
  },

  calendarTsViews: state => {
    return state.calendarTsViews;
  },

  filteredTimesheets: state => {
    return state.filteredTimesheets;
  },

  loadedTimesheets: state => {
    return state.loadedTimesheets;
  },

  filterTimesheets: state => {
    return state.filterTimesheets;
  },

  selectedTaskTimesheets: (state, rootGetters, rootState) => {
    const taskId = rootState.tasksState.taskToEdit?.id;

    return state.filteredTimesheets.filter(t => t.task === taskId);
  },

  loggedHoursByList: state => {
    return state.loggedHoursByList;
  },

  loggedHoursByTask: state => {
    return state.loggedHoursByTask;
  },

  newTimesheetDates: state => {
    return state.newTimesheetDates;
  },
};
