export default {
  categories: state => {
    return state.categories;
  },

  searchingState: state => {
    return state.searchingState;
  },

  moods: state => {
    return state.moods;
  },

  toAddTags: state => {
    return state.toAddTags;
  },

  toRemoveTags: state => {
    return state.toRemoveTags;
  },
};
