export default {
  SET_SEARCHING_INPUT: (state, payload) => {
    state.search = payload;
  },

  SET_TASKS: (state, payload) => {
    state.filteredSearchedTasks = payload;
  },

  LOGOUT_USER: state => {
    state.filteredSearchedTasks = [];
    state.search = '';
  },

  SET_IS_SEARCHING: (state, payload) => {
    state.isSearching = payload;
  },

  SET_TASK_SEARCH_PARAMS: (state, payload) => {
    state.taskSearching = payload;
  },
};
