export default {
  ALLOCATE_USER() {
    // allocate user to plan
  },
  SELECT_USER() {
    // pickedUser(user)
  },
  REMOVE_USER() {
    // remove user from plan - create empty seat
  },

  SET_PLANS_DETAILS: (state, payload) => {
    state.plansDetails = payload;
  },

  SET_PLANS: (state, payload) => {
    state.plans = payload;
  },

  SET_PLAN_TYPES: (state, payload) => {
    state.planTypes = payload;
  },

  SET_PURCHASE_PLAN_MODAL: (state, payload) => {
    state.openPurchasePlanModal = payload;
  },

  SET_PURCHASING_PLAN: (state, payload) => {
    state.purchasingPlan = payload;
  },

  SET_RESET_PLAN_MODALS: (state, payload) => {
    state.resetPlanModals = payload;
  },

  SET_PURCHASE_SELECTED_PLAN: (state, payload) => {
    state.purchaseSelectedPlan = payload;
  },

  SET_PLAN_BILLING_DETAILS: (state, payload) => {
    state.planBillingDetails = payload;
  },

  SET_PURCHASE_DATA: (state, payload) => {
    state.purchaseData = payload;
  },

  SET_PLAN_RENEW: (state, payload) => {
    state.planRenew = payload;
  },

  SET_CARD_FOR_PURCHASE: (state, payload) => {
    state.cardForPurchase = payload;
  },

  SET_PAYMENT_DONE: (state, payload) => {
    state.paymentDone = payload;
  },

  SET_PAYMENT_LOADING: (state, payload) => {
    state.paymentLoading = payload;
  },

  SET_PAYMENT_ERROR: (state, payload) => {
    state.paymentError = payload;
  },

  CLEAN_PAYMENT_PLANS: state => {
    state.purchaseSelectedPlan = null;
    state.planBillingDetails = {};
    state.cardForPurchase = null;
    state.paymentDone = false;
    state.paymentLoading = false;
    state.paymentError = false;
    state.planRenew = false;
  },

  SET_DRAGGED_USER: (state, payload) => {
    state.draggedUser = payload;
  },
};
