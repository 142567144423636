import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,
  state: () => {
    return {
      users: [],
      searchedUsers: [],
      userToView: null,
      isChangedProfile: false,
      userUpdateStatus: '',
      oldUserProfile: null,
      hasErrors: false,
      showInvitationModal: false,
      invitationParams: {},
    };
  },
  actions,
  getters,
  mutations,
};
