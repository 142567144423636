export default {
  userProfile: state => {
    return state.profile;
  },

  tokens: state => {
    return state.tokens;
  },

  destroy: state => {
    return state.destroy;
  },

  resetRefresh: state => {
    return state.resetRefresh;
  },

  userSettings: state => {
    return state.userSettings;
  },

  processLogout: state => {
    return state.processLogout;
  },

  appearCookiePopup: state => {
    return state.appearCookiePopup;
  },

  pendingRequests: state => {
    return state.pendingRequests;
  },

  userSocket: state => {
    return state.userSocket;
  },

  listSockets: state => {
    return state.listSockets;
  },

  projectSockets: state => {
    return state.projectSockets;
  },

  taskSocket: state => {
    return state.taskSocket;
  },

  isInitiatedLogoutProcess: state => {
    return state.isInitiatedLogoutProcess;
  },

  preCommitUserEmailData: state => {
    return state.preCommitUserEmailData;
  },

  userBillingDetails: state => {
    return state.userBillingDetails;
  },

  billingDetails: state => {
    return state.billingDetails;
  },

  isChangedBilDetails: state => {
    return state.isChangedBilDetails;
  },

  paymentCards: state => {
    return state.paymentCards;
  },

  checkErrorInputs: state => {
    return state.checkErrorInputs;
  },

  billingQueries: state => {
    if (Object.keys(state.filterBillingQuery).length > 0) {
      return state.filteredBillingQueries;
    } else {
      return state.billingQueries;
    }
  },

  filterBillingQuery: state => {
    return state.filterBillingQuery;
  },

  filteredBillingQueries: state => {
    return state.filteredBillingQueries;
  },

  selectedQuery: state => {
    return state.selectedQuery;
  },

  creatingQuery: state => {
    return state.creatingQuery;
  },

  billingNotes: state => {
    return state.billingNotes;
  },

  showExportModal: state => {
    return state.showExportModal;
  },

  paymentHistory: state => {
    if (Object.keys(state.filterPaymentHistory).length > 0) {
      return state.filteredPaymentHistory;
    } else {
      return state.paymentHistory;
    }
  },

  filterPaymentHistory: state => {
    return state.filterPaymentHistory;
  },

  filteredPaymentHistory: state => {
    return state.filteredPaymentHistory;
  },

  forceLogout: state => {
    return state.forceLogout;
  },
};
