import AxiosHandler from '@/helpers/axios';
import { sortAlphabetically } from '@/helpers/helpers';

export default {
  async searchingTags({ commit, dispatch, state }, { pro, query }) {
    const search = encodeURIComponent(query);

    if (pro && search) {
      const limit = 10,
        st = state.searchingState;
      const isStateHere = !!st;
      const isExistingQuery = st?.query === query;

      const offset =
        isStateHere && isExistingQuery
          ? st.tags.length - (st.tags.length % limit)
          : 0;
      const tagsCount = st?.tags ? st.tags.length : 0;
      const pendingObj = { searchingTag: query, offset };

      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            if (
              !st ||
              st?.count === 0 ||
              st?.count > tagsCount ||
              !isExistingQuery
            ) {
              const res = await AxiosHandler.call(
                null,
                `/tag/?project=${pro.id}&search=${search}&offset=${offset}&limit=${limit}`,
                'get',
                '',
              );

              const existingTags = isExistingQuery ? st.tags : [];
              const oldTags = existingTags.filter(
                t => !res.data.results.find(rt => rt.id === t.id),
              );

              commit('SET_SEARCHING_STATE', {
                query,
                count: res.data.count,
                tags: oldTags.concat(res.data.results),
              });
            }
          },
        },
        { root: true },
      );
    }
  },

  async addRemoveTempTags({ state, dispatch, commit }, taskId) {
    const toAdd = state.toAddTags;

    await toAdd.forEach(async t => {
      await dispatch('createTag', { taskId, tag: t });
    });

    const toRemove = state.toRemoveTags;

    await toRemove.forEach(async t => {
      await dispatch('deleteTag', { taskId, tag: t });
    });

    commit('SET_TO_ADD_TAGS', []);
    commit('SET_TO_RM_TAGS', []);
  },

  async createTag({ rootGetters, commit, dispatch }, { taskId, tag }) {
    const pendingObj = { tagToAdd: tag };

    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const data = JSON.stringify({ tag });

          const res = await AxiosHandler.call(
            data,
            `/task/${taskId}/tag`,
            'post',
            'application/json',
          );

          const currentTaskEdited = rootGetters['tasksState/taskToEdit'];

          if (taskId === currentTaskEdited?.id && !!taskId) {
            if (!currentTaskEdited.tags.some(t => t.tag === res.data.tag)) {
              currentTaskEdited.tags.push(res.data);
              commit('tasksState/SET_TASK_TO_EDIT', currentTaskEdited, {
                root: true,
              });
            } else {
              const i = currentTaskEdited.tags
                .map(e => e.tag)
                .indexOf(res.data.tag);
              currentTaskEdited.tags.splice(i, 1, res.data);

              commit('tasksState/SET_TASK_TO_EDIT', currentTaskEdited, {
                root: true,
              });
            }

            const list = rootGetters['lists/selectedList'];
            if (list.tags && !list.tags.some(t => t.id === res.data.id)) {
              list.tags.push(res.data);
              list.tags = sortAlphabetically(list.tags, 'tag');
              dispatch('lists/updateListInProject', list, { root: true });
            }

            const pro = rootGetters['projects/projects'].find(
              p => p.id === currentTaskEdited.project_id,
            );
            if (pro && !pro?.tags?.some(pt => pt.id === res.data.id)) {
              pro.tags.push(res.data);
            }
          }

          return res.data;
        },
      },
      { root: true },
    );
  },

  async deleteTag({ rootGetters, commit }, { taskId, tag }) {
    await AxiosHandler.call(
      '',
      `/task/${taskId}/tag/${tag.id}`,
      'put',
      'application/json',
    );

    const currentTaskEdited = rootGetters['tasksState/taskToEdit'];

    if (taskId === currentTaskEdited?.id && !!taskId) {
      currentTaskEdited.tags = currentTaskEdited.tags.filter(
        t => t.id !== tag.id,
      );
      commit('tasksState/SET_TASK_TO_EDIT', currentTaskEdited, {
        root: true,
      });

      const pro = rootGetters['projects/projects'].find(
        p => p.id === currentTaskEdited.project_id,
      );

      if (pro) {
        pro.tags = pro?.tags?.filter(pt => pt.id !== tag.id) || [];
      }
    }
  },
};
