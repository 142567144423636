import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { categories } from '@/helpers/constants';

export default {
  namespaced: true,

  state() {
    return {
      categories: categories,
      moods: [
        //dark
        [
          '#9BB4FF',
          '#9966FF',
          '#F500FF',
          '#FF3D3D',
          '#FF3D5E',
          '#FF9900',
          '#66E6FF',
          '#33FFDD',
          '#61FF67',
          '#D1FF4F',
          '#FDFAA8',
          '#FFFEEE',
        ],
        //light
        [
          '#6400FF',
          '#793499',
          '#C66FDB',
          '#FF4E4E',
          '#FF8A00',
          '#FFB800',
          '#1800D2',
          '#009D91',
          '#00B207',
          '#628200',
          '#0D0033',
          '#CC0010',
        ],
      ],
      toAddTags: [],
      toRemoveTags: [],
      searchingState: null,
    };
  },
  actions,
  getters,
  mutations,
};
