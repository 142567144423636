import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,
  state() {
    return {
      projects: [],
      selectedProjectId: null,
      maxProjectCharacters: 25,
      projectsCount: 0,
      projectsOffset: 0,
      openProjects: [],
      addProject: false,
      draggedList: null,
      expiredUsers: [],
    };
  },
  actions,
  getters,
  mutations,
};
