export default {
  async attachment_created({ rootGetters, dispatch, commit }, m) {
    const task = rootGetters['lists/allTasks'].find(
      t => t.id === parseInt(m.task_id),
    );

    if (!task.attachments.some(at => at.id === m.attachment_created.id)) {
      task.attachments.push(m.attachment_created);

      dispatch('tasks/updateTaskInList', task, { root: true });

      commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
    }
  },

  async attachment_deleted({ rootGetters, dispatch, commit }, m) {
    const task = rootGetters['lists/allTasks'].find(
      t => t.id === parseInt(m.task_id),
    );

    if (task.attachments.some(at => at.id === m.attachment_deleted)) {
      task.attachments = task.attachments.filter(
        at => at.id !== m.attachment_deleted,
      );

      dispatch('tasks/updateTaskInList', task, { root: true });

      commit('tasksState/SET_TASK_TO_EDIT', task, { root: true });
    }
  },
};
