export default {
  selectedList: (state, getters, rootState, rootGetters) => {
    return rootGetters['projects/allLists'].find(
      list => list.id === state.selectedListId,
    );
  },

  projectLists: state => {
    return state.projectLists;
  },

  listToMoveTo: state => {
    return state.listToMoveTo;
  },

  allTasks: (state, getters, rootState, rootGetters) => {
    return [
      ...new Set(
        rootGetters['projects/allLists'].reduce(
          (acc, l) => acc.concat(l.actualTasks),
          [],
        ),
      ),
    ];
  },

  colorPickerList: state => {
    return state.colorPickerList;
  },

  maxListCharacters: state => {
    return state.maxListCharacters;
  },

  userLists: state => {
    return state.userLists;
  },

  removedUsers: state => {
    return state.removedUsers;
  },
};
