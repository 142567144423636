export default {
  SET_INPUT_FOCUSED: (state, payload) => {
    state.inputFocused = payload;
  },

  SET_FOCUS_TASK: (state, payload) => {
    state.focusTask = payload;
  },

  SET_INPUT_TYPED: (state, payload) => {
    state.inputTyped = payload;
  },
};
