import AxiosHandler from '@/helpers/axios';

export default {
  async loadPlans({ commit, dispatch }) {
    const pendingObj = { loadUserPlans: 'loading user plans' };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(null, `/plan/`, 'get', '');

          commit('SET_PLANS_DETAILS', {
            totalAllocated: res.data.total_allocated,
            totalSeats: res.data.total_seats,
            totalVacant: res.data.total_vacant,
          });
          commit('SET_PLANS', res.data.results);
        },
      },
      { root: true },
    );
  },

  async fetchPlanTypes({ commit, dispatch }, countryCode) {
    const pendingObj = { loadUserPlanTypes: 'loading user plan types' };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          let url = '/plan/types/';
          if (countryCode) {
            url += `?country=${countryCode}`;
          }

          const res = await AxiosHandler.call(null, url, 'get', '');

          commit('SET_PLAN_TYPES', res.data.results);
          commit('SET_PURCHASE_DATA', {
            months_until_renewal: res.data.months_until_renewal,
            vat_rate: res.data.vat_rate,
            renewal_date: res.data.renewal_date,
          });
        },
      },
      { root: true },
    );
  },

  async purchasePlan({ dispatch, rootGetters }, plan) {
    const pendingObj = { purchasingPlan: 'plan' };
    const planRenew = rootGetters['plans/planRenew'];
    const planQuery = planRenew ? `?renew_plan=${planRenew}` : '';
    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          return await AxiosHandler.call(
            plan,
            `/plan/${planQuery}`,
            'post',
            'application/json',
          );
        },
      },
      { root: true },
    );
  },

  async patchPlan({ dispatch }, [planId, data]) {
    const pendingObj = { updatingPlan: true };

    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          return await AxiosHandler.call(
            data,
            `/plan/${planId}/`,
            'patch',
            'application/json',
          );
        },
      },
      { root: true },
    );
  },

  async cancelPlan(_, [planId, password]) {
    const res = await AxiosHandler.call(
      { password: password },
      `/plan/${planId}/cancel`,
      'delete',
      'application/json',
    );

    return res;
  },

  async removeUser({ dispatch }, userId) {
    const pendingObj = { removeUserFromPlan: 'remove user from plan' };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(
            null,
            `/plan/seat/${userId}`,
            'delete',
            'application/json',
          );
        },
      },
      { root: true },
    );
  },

  async changePlanForUser({ dispatch }, [seatId, plan]) {
    const pendingObj = { changePlanForUser: 'change plan for user' };
    if (!plan.unallocated_seats) {
      dispatch('popups/setToast', ['This plan has no available seats', 3000], {
        root: true,
      });
      return;
    }
    const data = JSON.stringify({ plan: plan.id });

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(
            data,
            `/plan/seat/${seatId}`,
            'patch',
            'application/json',
          );
        },
      },
      { root: true },
    );
  },
};
