import router from '../../../router/index';
import { ROUTE_NAMES, USER_PERMISSIONS } from '@/helpers/constants';
import AxiosHandler from '@/helpers/axios';
import getEnv from '../../../../app.config';

export default {
  async projectInvitationAnswer(_, { type, id }) {
    await AxiosHandler.call(
      JSON.stringify({ button_type: type }),
      `/invite/project/${id}`,
      'put',
      'application/json',
    );
  },

  async projectRevokeChangeOwnership(_, [projectId]) {
    await AxiosHandler.call(
      null,
      `/project/${projectId}/ownership/`,
      'delete',
      'application/json',
    );
  },

  async projectSendChangeOwnership(_, [projectId, newOwnerId]) {
    await AxiosHandler.call(
      JSON.stringify({ new_owner: newOwnerId }),
      `/project/${projectId}/ownership/`,
      'post',
      'application/json',
    );
  },

  async projectOwnerChangeAnswer(_, { type, id }) {
    await AxiosHandler.call(
      JSON.stringify({ action: type }),
      `/project/${id}/ownership/`,
      'put',
      'application/json',
    );
  },

  async addProjectFields({ dispatch, state }, projectsFetched) {
    return await new Promise(resolve => {
      let track = projectsFetched.length;

      projectsFetched.map(p => {
        const projectExists = state.projects.find(pro => pro.id === p.id);

        if (projectExists) {
          p['users'] = projectExists.users;
          p['lists'] = projectExists.lists;
          p['listCount'] = projectExists.listCount;
          p['epicsCount'] = projectExists.epicsCount;
          p['archivedListCount'] = projectExists.archivedListCount;
          p['tags'] = projectExists.tags;
          p['tagsCount'] = projectExists.tagsCount;
        } else {
          p['users'] = [];
          p['lists'] = [];
          p['listCount'] = 0;
          p['epicsCount'] = 0;
          p['archivedListCount'] = 0;
          p['tags'] = [];
          p['tagsCount'] = 0;
        }

        track = track - 1;
        if (!track || !projectsFetched.length) {
          resolve(projectsFetched);
        }
      });

      dispatch('lists/updateSelectedList', null, { root: true });
    });
  },

  async addProject({ commit, rootGetters, dispatch }, title) {
    const pendingObj = { projectToAdd: title };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const data = JSON.stringify({ title: title });

          const res = await AxiosHandler.call(
            data,
            `/project`,
            'post',
            'application/json',
          );

          if (res.status === 208) {
            throw res;
          }

          const projectAdded = await dispatch('addProjectFields', [res.data]);

          await dispatch(
            'permissions/getUserPermission',
            { projectId: res.data.id },
            { root: true },
          );

          const u = rootGetters['auth/userProfile'];

          u.projects = [projectAdded[0].id, ...u.projects];

          commit('auth/SET_PROFILE', u, { root: true });

          commit('ADD_PROJECT', projectAdded[0]);
        },
      },
      { root: true },
    );
  },

  async fetchListPositions({ dispatch }, pro) {
    const res = await AxiosHandler.call(
      null,
      `/project/${pro.id}/reorder/?start_position=0&end_position=9999`,
      'get',
      'application/json',
    );

    dispatch('setListPositions', [pro, res.data.results]);
  },

  async reorderLists({ dispatch }, { pro, oldPos, newPos }) {
    const data = JSON.stringify({
      moving: oldPos < newPos ? 'down' : 'up',
      start_position: Math.min(oldPos, newPos),
      end_position: Math.max(oldPos, newPos),
    });

    const res = await AxiosHandler.call(
      data,
      `/project/${pro.id}/reorder`,
      'put',
      'application/json',
    );

    dispatch('setListPositions', [pro, res.data.results]);
  },

  async setListPositions(_, [pro, orders]) {
    if (!orders) return;

    orders.forEach(order => {
      const list = pro.lists.find(list => list.id === order.id);
      if (list) {
        list.position = order.position;
      }
    });

    pro.lists.sort((a, b) => (a.position > b.position ? 1 : -1));
  },

  setListOrder({ commit, dispatch }, { pro, order }) {
    pro.lists = pro.lists.map(list => {
      return {
        ...list,
        position: order.indexOf(list.id),
      };
    });

    commit('UPDATE_PROJECT', pro);
    dispatch('lists/updateSelectedList', null, { root: true });
  },

  async patchProject({ commit, dispatch }, [proj, id]) {
    const data = JSON.stringify(proj);

    const res = await AxiosHandler.call(
      data,
      `/project/${id}`,
      'patch',
      'application/json',
    );

    if (res.status === 208) {
      throw res;
    }

    const patchedProject = await dispatch('addProjectFields', [res.data]);
    commit('UPDATE_PROJECT', patchedProject[0]);
  },

  async getProjectById({ commit, state, dispatch, rootGetters }, id) {
    try {
      const project = rootGetters['projects/projects'].find(p => p.id === id);

      if (project) {
        return project;
      }

      const res = await AxiosHandler.call(null, `/project/${id}`, 'get', null);

      let prjct = state.projects?.find(pr => pr.id === id);

      if (!prjct) {
        prjct = await dispatch('addProjectFields', [res.data]);
        prjct = prjct[0];
      }

      if (state.projects.length === 0 && !localStorage.getItem('PROJECT_ID')) {
        commit('SELECT_PROJECT_ID', prjct.id);
        dispatch('selectProject', prjct);
      }

      await dispatch(
        'permissions/getUserPermission',
        { projectId: prjct.id },
        { root: true },
      );

      commit('SET_PROJECTS', [prjct]);
      return prjct;
    } catch (e) {
      router.push({ name: ROUTE_NAMES.PROJECT_PORTFOLIO });
    }
  },

  async loadProject(_, [id]) {
    const res = await AxiosHandler.call(null, `/project/${id}`, 'get', null);

    return res.data;
  },

  async fetchProjects({ commit, dispatch, rootGetters, state }) {
    if (rootGetters['auth/userProfile'].projects.length > 0) {
      const res = await AxiosHandler.call(
        null,
        `/project/?limit=10&offset=0`,
        'get',
        null,
      );
      const projectsFetched = res.data.results.filter(
        pf => pf && !rootGetters['projects/projects'].find(p => p.id === pf.id),
      );

      const projs = await dispatch('addProjectFields', projectsFetched);

      await dispatch(
        'permissions/getUserPermissionsForAllProject',
        {
          projects: res.data.results.map(p => p.id),
          userId: rootGetters['auth/userProfile'].id,
        },
        { root: true },
      );

      commit('SET_PROJECTS_COUNT', res.data.count);
      commit('SET_PROJECTS_OFFSET', 15);
      commit('SET_PROJECTS', projs);
    }

    if (
      router.currentRoute._rawValue.name === ROUTE_NAMES.PORTFOLIO_TIMESHEETS
    ) {
      return;
    }

    const lastProjId = localStorage.getItem('PROJECT_ID');
    const isProject = Boolean(
      lastProjId && state.projects.find(p => p.id === lastProjId),
    );

    if (
      !isProject &&
      router.currentRoute.value.name === ROUTE_NAMES.PROJECT_SETTINGS
    ) {
      router.push({
        name: ROUTE_NAMES.NOT_FOUND,
        params: {
          catchAll: '404',
        },
      });
    } else if (isProject) {
      const projectToSelect = state.projects.find(p => p.id === lastProjId);
      dispatch('selectProject', projectToSelect);
      dispatch('setOpenProjects', ['open', lastProjId]);
    } else if (router.currentRoute.value.name === ROUTE_NAMES.SETTINGS) {
      return;
    } else if (
      router.currentRoute.value.name === ROUTE_NAMES.PROJECT_TIMESHEETS
    ) {
      dispatch('popups/setToast', [`Project was not found`, 3000], {
        root: true,
      });

      router.push({ name: ROUTE_NAMES.PORTFOLIO_TIMESHEETS });
    } else if (
      ![ROUTE_NAMES.LOGIN, ROUTE_NAMES.PROJECT_PORTFOLIO].includes(
        router.currentRoute.value.name,
      )
    ) {
      dispatch('popups/setToast', [`Project was not found`, 3000], {
        root: true,
      });

      router.push({ name: ROUTE_NAMES.PROJECT_PORTFOLIO });
    } else {
      router.push({ name: ROUTE_NAMES.PROJECT_PORTFOLIO });
    }
  },

  async fetchMoreProjects({ commit, dispatch, state, rootGetters }) {
    if (state.projectsCount > state.projectsOffset) {
      const res = await AxiosHandler.call(
        null,
        `/project/?limit=15&offset=${state.projectsOffset}`,
        'get',
        null,
      );

      commit('SET_PROJECTS_OFFSET', state.projectsOffset + 15);

      let projs = await dispatch('addProjectFields', res.data.results);

      projs = projs.filter(p => !state.projects.some(po => po.id === p.id));

      dispatch(
        'permissions/getUserPermissionsForAllProject',
        {
          projects: projs.map(p => p.id),
          userId: rootGetters['auth/userProfile'].id,
        },
        { root: true },
      );

      commit('SET_PROJECTS', projs);
    }
  },

  async selectProjectWithoutRedirect({ commit, dispatch }, pro) {
    if (pro) {
      localStorage.setItem('PROJECT_ID', pro.id);

      dispatch('epics/fetchProjectEpics', pro, { root: true });
      dispatch('fetchProjectLists', pro);
      dispatch('fetchProjectUsers', pro);
      dispatch('fetchProjectTags', pro);
      commit('SELECT_PROJECT_ID', pro.id);
    } else {
      commit('SELECT_PROJECT_ID', null);
      commit('lists/SELECT_LIST_ID', null, { root: true });
    }
  },

  async fetchPortfolioTags({ dispatch }, offset) {
    const pendingObj = { fetchingTags: true };

    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/tag/?offset=${offset}&limit=15`,
            'get',
            '',
          );

          return res.data.results;
        },
      },
      { root: true },
    );
  },

  async fetchProjectTags({ dispatch }, pro) {
    if (!pro) return;

    const limit = 10;

    const offset = pro['tags'].length
      ? pro['tags'].length - (pro['tags'].length % limit)
      : 0;
    const tagsCount = pro['tags'].length ? pro['tags'].length : 0;
    const pendingObj = { fetchingTags: true };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          if (pro.tagsCount === 0 || pro.tagsCount > tagsCount - 1) {
            const res = await AxiosHandler.call(
              null,
              `/tag/?project=${pro.id}&offset=${offset}&limit=${limit}`,
              'get',
              '',
            );

            if (res.data.results.length > 0) {
              const fetchedTags = res.data.results;
              const previousTags = pro['tags'].filter(
                el => !fetchedTags.find(t => t.id === el.id),
              );

              pro['tags'] = [...previousTags, ...fetchedTags];
            }

            pro['tagsCount'] = res.data.count;
          }
        },
      },
      { root: true },
    );
  },

  async fetchProjectFilterLists({ dispatch }, [project, offset]) {
    const pendingObj = { fetchingProjectLists: true };
    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/list/?project=${project.id}&offset=${offset}&limit=10`,
            'get',
            '',
          );

          return res.data.results;
        },
      },
      { root: true },
    );
  },

  async fetchPortfolioLists({ dispatch }, offset) {
    const pendingObj = { fetchingPortfolioLists: true };
    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/list/?offset=${offset}&limit=10`,
            'get',
            '',
          );

          return res.data.results;
        },
      },
      { root: true },
    );
  },

  async getListsForExport({ dispatch }, [level, pro]) {
    let query = '';
    if (level === 'project') {
      query += `?project=${pro.id}`;
    }

    const pendingObj = { exportingLists: true };
    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/list/${query}`,
            'get',
            '',
          );

          return res.data.results;
        },
      },
      { root: true },
    );
  },

  async fetchProjectLists({ commit, dispatch }, pro) {
    const nonArchivedLists = pro['lists']?.filter(l => !l.archived) || [];
    const listCount = nonArchivedLists.length;

    if (pro.listCount > 0 && listCount >= pro.listCount) {
      return;
    }

    const limit = 10;
    const offset = Number(listCount ? listCount - (listCount % limit) : 0);

    const pendingObj = { fetchProjectList: pro.id, offset };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/list/?project=${pro.id}&offset=${offset}&limit=${limit}&archived=false`,
            'get',
            '',
          );

          if (res.data.results.length > 0) {
            const fetchedLists = res.data.results;
            const previousLists = pro['lists'].filter(
              el => !fetchedLists.some(l => l.id === el.id),
            );

            const updatedLists = await dispatch(
              'lists/setListsFields',
              fetchedLists,
              { root: true },
            );

            pro['lists'] = [...previousLists, ...updatedLists];
            pro['lists'].sort((a, b) => (a.position > b.position ? 1 : -1));
          }

          pro['listCount'] = res.data.count;
          commit('UPDATE_PROJECT', pro);
          dispatch('lists/updateSelectedList', null, { root: true });
        },
      },
      { root: true },
    );
  },

  async selectProject({ commit, dispatch, rootGetters }, pro) {
    const selectedProject = rootGetters['projects/selectedProject'];
    const routeRawValue = router.currentRoute._rawValue;
    const route = routeRawValue.name;
    const excludeRoutes = [
      ROUTE_NAMES.EPICS,
      ROUTE_NAMES.SETTINGS,
      ROUTE_NAMES.SEARCH,
      ROUTE_NAMES.PROJECT_ARCHIVES,
      ROUTE_NAMES.PROJECT_PORTFOLIO,
      ROUTE_NAMES.PROJECT_SETTINGS,
      ROUTE_NAMES.PROJECT_TIMESHEETS,
    ];

    if (selectedProject?.id === pro?.id) return;
    if (pro) {
      await dispatch('fetchProjectLists', pro);
      dispatch('epics/fetchProjectEpics', pro, { root: true });
      dispatch('fetchProjectUsers', pro);
      dispatch('fetchProjectTags', pro);

      if (
        localStorage.getItem('PROJECT_ID') !== pro.id &&
        pro.lists.length > 0
      ) {
        localStorage.setItem('LAST_KEY', pro.lists[0].key);
      }
      localStorage.setItem('PROJECT_ID', pro.id);

      commit('SELECT_PROJECT_ID', pro.id);
      const projectNonArchLists = pro.lists.filter(l => l.archived === false);

      if (route && !excludeRoutes.includes(route)) {
        if (projectNonArchLists.length > 0) {
          let listKey =
            routeRawValue.params.projectId === pro.id &&
            !!routeRawValue.params.listKey
              ? routeRawValue.params.listKey
              : localStorage.getItem('LAST_KEY');

          if (routeRawValue.query.list?.includes('/')) {
            const params = routeRawValue.query.list.split('/');
            pro.id = params[0];
            listKey = params[1];
          }

          if (listKey) {
            const list = await dispatch(
              'lists/getListByKeyAndProjectId',
              {
                listKey: listKey,
                projectId: pro.id,
                showToast: true,
              },
              { root: true },
            );

            if (list?.project === pro.id && list.archived === false) {
              dispatch('lists/listSelected', list.id, { root: true });
            } else {
              dispatch('lists/listSelected', projectNonArchLists[0].id, {
                root: true,
              });
            }
          } else {
            dispatch('lists/listSelected', projectNonArchLists[0].id, {
              root: true,
            });
          }
        } else {
          commit('lists/SELECT_LIST_ID', null, { root: true });
          router.push('/dashboard/Project-Portfolio');
        }
      }
      return;
    }

    if (route !== ROUTE_NAMES.LOGIN) {
      commit('SELECT_PROJECT_ID', null);
      router.push(`/dashboard/Project-Portfolio`).catch(() => {});
      commit('lists/SELECT_LIST_ID', null, { root: true });
    }
  },

  async checkReadProjectAccess({ state, dispatch, rootGetters }, projectId) {
    let access = false;

    if (!rootGetters['auth/userProfile']) {
      await dispatch('auth/getUser', {}, { root: true });
      await dispatch('projects/fetchProjects', {}, { root: true });
    }

    function checkAccess(pro) {
      if (!pro) {
        router.push({
          name: ROUTE_NAMES.NOT_FOUND,
          params: {
            catchAll: '404',
          },
        });
      } else {
        const allPerms = rootGetters['permissions/userPermsByAllProjects'];

        return (
          allPerms
            .find(u => u.project === projectId)
            ?.project_permissions.includes(USER_PERMISSIONS.READ) ||
          pro.permissions.includes(USER_PERMISSIONS.READ)
        );
      }
    }

    if (
      !state.projects ||
      state.projects.length === 0 ||
      !state.projects.find(pro => pro.id === projectId)
    ) {
      const pro = await dispatch('getProjectById', projectId);
      access = Boolean(checkAccess(pro));
    } else {
      const pro = state.projects.find(pro => pro.id === projectId);

      const allPerms = rootGetters['permissions/userPermsByAllProjects'];
      if (pro && !allPerms.find(u => u.project === projectId)) {
        await dispatch(
          'permissions/getUserPermission',
          { projectId: pro.id },
          { root: true },
        );
      }

      access = Boolean(checkAccess(pro));
    }

    return access;
  },

  setOpenProjects({ commit }, [action, projId]) {
    if (action === 'open') {
      commit('OPEN_PROJECT', projId);
    } else {
      commit('CLOSE_PROJECT', projId);
    }
  },

  async fetchProjectUsers({ dispatch, commit, rootGetters }, pro) {
    const pendingObj = { project: pro.id, option: 'user project fetching' };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/users/?project=${pro.id}&seat_expired=false`,
            'get',
            '',
          );

          const project = rootGetters['projects/selectedProject'];
          if (project?.id === pro.id) {
            pro = project;
          }

          pro['users'] = [
            ...new Set([pro.owner, ...res.data.results.map(u => u.id)]),
          ];

          if (res.data.results.length > 0) {
            commit('users/SET_USERS', res.data.results, { root: true });
          }

          dispatch('fetchProjectExpiredUsers', pro);
        },
      },
      { root: true },
    );
  },

  async fetchProjectRemovedUsers({ commit, dispatch }, prId) {
    const pendingObj = { fetchingProjectRemovedUsers: prId };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/users/?project=${prId}&removed_users=true`,
            'get',
            '',
          );

          commit(
            'lists/SET_REMOVED_USERS',
            res.data.results.map(user => {
              return {
                id: user.id,
                removed: true,
              };
            }),
            { root: true },
          );

          if (res.data.results.length > 0) {
            commit('users/SET_USERS', res.data.results, { root: true });
          }
        },
      },
      { root: true },
    );
  },

  async fetchProjectExpiredUsers({ commit }, pro) {
    const res = await AxiosHandler.call(
      null,
      `/users/?project=${pro.id}&seat_expired=true`,
      'get',
      '',
    );

    commit('SET_EXPIRED_PROJECT_USERS', []);

    if (res.data.results.length === 0) {
      return;
    } else {
      commit('users/SET_USERS', res.data.results, { root: true });
    }

    commit(
      'SET_EXPIRED_PROJECT_USERS',
      res.data.results.map(user => {
        return {
          id: user.id,
          expired: true,
        };
      }),
    );
  },

  async fetchProjectArchivedLists({ dispatch }, pro) {
    if (pro) {
      const limit = 10;

      const archivedLists = pro['lists']?.filter(l => l.archived) || [];
      const archivedListCount = archivedLists.length;
      const offset = archivedListCount
        ? archivedListCount - (archivedListCount % limit)
        : 0;

      const pendingObj = { fetchProjectArchivedList: pro.id, offset };
      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            if (
              pro.archivedListCount === 0 ||
              pro.archivedListCount > archivedListCount
            ) {
              const res = await AxiosHandler.call(
                null,
                `/list/?project=${pro.id}&offset=${offset}&limit=${limit}&archived=true`,
                'get',
                '',
              );

              if (res.data.results.length > 0) {
                const fetchedLists = res.data.results;
                const previousLists = pro['lists'].filter(
                  el => !fetchedLists.find(l => l.id === el.id),
                );

                const updatedLists = await dispatch(
                  'lists/setListsFields',
                  fetchedLists,
                  { root: true },
                );

                pro['lists'] = [...previousLists, ...updatedLists];
                pro['lists'].sort((a, b) => (a.position > b.position ? 1 : -1));

                pro['archivedListCount'] = res.data.count;

                for (const list of pro['lists']) {
                  if (
                    !list.taskCount &&
                    list.archived &&
                    fetchedLists.some(fl => fl.id === list.id)
                  ) {
                    dispatch('tasks/getTotalTasksByList', list, { root: true });
                  }
                }

                dispatch('lists/updateSelectedList', null, { root: true });
              }
            }
          },
        },
        { root: true },
      );
    }
  },

  async fetchProjectListsCount(_, pro) {
    const unarchivedRes = await AxiosHandler.call(
      null,
      `/list/?project=${pro.id}&offset=999999999&limit=1&archived=false`,
      'get',
      '',
    );

    pro['listCount'] = unarchivedRes.data.count;
  },

  async fetchProjectArchivedListsCount(_, pro) {
    const archivedRes = await AxiosHandler.call(
      null,
      `/list/?project=${pro.id}&offset=999999999&limit=1&archived=true`,
      'get',
      '',
    );

    pro['archivedListCount'] = archivedRes.data.count;
  },

  async loadProjectsList(_, pro) {
    const res = await AxiosHandler.call(
      null,
      `/list/?project=${pro.id}&offset=0&limit=1`,
      'get',
      '',
    );

    return res.data.results[0];
  },

  async deleteProject({ commit, dispatch, rootGetters, state }, id) {
    const u = rootGetters['auth/userProfile'];

    u.projects = u.projects.filter(projectId => projectId !== id);

    commit('auth/SET_PROFILE', u, { root: true });

    if (state.selectedProjectId === id) {
      dispatch('selectProject', null);
      commit('lists/SELECT_LIST_ID', null, { root: true });
      router.push({
        name: ROUTE_NAMES.PROJECT_PORTFOLIO,
      });
    }

    commit('DELETE_PROJECT', id);

    await AxiosHandler.call(
      null,
      `/project/${id}`,
      'delete',
      'application/json',
    );

    dispatch('popups/setToast', [`Your project has been deleted`, 3000], {
      root: true,
    });

    const projectSockets = rootGetters['auth/projectSockets'];
    const socketUrl = `${getEnv('VUE_APP_WEBSOCKET_API')}/project/${id}`;
    const projectSocket = projectSockets.find(prs => prs.url === socketUrl);
    if (projectSocket) {
      projectSocket.close();
    }
  },
};
