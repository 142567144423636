export default {
  creatingEpic: state => {
    return state.creatingEpic;
  },

  epics: state => {
    return state.epics;
  },

  showWeekCalendar: state => {
    return state.showWeekCalendar;
  },

  resizeModalEpicId: state => {
    return state.resizeModalEpicId;
  },

  updatedDate: state => {
    return state.updatedDate;
  },

  openMenuEpicId: state => {
    return state.openMenuEpicId;
  },

  allEpics: state => {
    return state.epics.map(e => ({ key: e.id, ...e }));
  },

  selectedProjectEpics: (state, rootGetters, rootState) => {
    const proId = rootState.projects.selectedProjectId;

    return state.epics
      .filter(e => e.project === proId)
      .map(e => ({ key: e.id, ...e }));
  },

  projectEpics: (state, getters) => {
    if (Object.keys(state.filterEpics).length > 0) {
      return state.filteredEpics;
    } else {
      return getters.selectedProjectEpics;
    }
  },

  filterEpics: state => {
    return state.filterEpics;
  },

  epicProgressColours: state => {
    return state.epicProgressColours;
  },

  tempEpicId: state => {
    return state.tempEpicId;
  },
};
