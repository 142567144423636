export default {
  selectedUser: state => {
    return state.selectedUser;
  },

  filteredUserData: (state, getters, rootState) => {
    const project = state.projectUserData.find(
      pro =>
        pro.project === rootState.projects.selectedProjectId && pro.project,
    );

    return (
      project?.data?.find(us => us.user === state.selectedUser?.id) || null
    );
  },

  userProfileData: (state, getters, rootState) => {
    const profileId = rootState.auth.profile?.id;
    const selProjectId = rootState.projects.selectedProjectId;

    if (!Number.isInteger(profileId) || !selProjectId) return null;

    const project = state.projectUserData.find(
      pro => pro.project === selProjectId,
    );

    return project?.data?.find(us => us.user === profileId) || null;
  },

  selectedListPerms: (state, rootGetters, rootState) => {
    const listId = rootState.lists.selectedListId;
    const profileId = rootState.auth.profile.id;
    const selProjectId = rootState.projects.selectedProjectId;

    if (!Number.isInteger(profileId) || !selProjectId || !listId) return null;

    const project = state.projectUserData.find(
      pro => pro.project === selProjectId,
    );

    const data = project?.data?.find(us => us.user === profileId) || null;

    return data?.lists?.find(l => l.listId === listId) || null;
  },

  userPermsByAllProjects: (state, rootGetters, rootState) => {
    const profileId = rootState?.auth?.profile?.id;

    return (
      state.projectUserData
        .map(p => p.data.find(us => us.user === profileId))
        .filter(u => !!u) || []
    );
  },

  mePermsBySelectedProject: (state, rootGetters, rootState) => {
    const profileId = rootState?.auth?.profile?.id;
    const proId = rootState?.projects?.selectedProjectId;

    return (
      state.projectUserData
        .find(p => p.project === proId && proId)
        ?.data.find(p => p.user === profileId && profileId) || null
    );
  },

  projectUserData: state => {
    return state.projectUserData;
  },

  roles: state => {
    return state.roles;
  },

  defaultRoleIds: state => {
    return state.defaultRoleIds;
  },
};
