export default {
  toast: state => {
    return state.toast;
  },

  deleteModalMessage: state => {
    return state.deleteModalMessage;
  },

  deleteAction: state => {
    return state.deleteAction;
  },

  showVerifySaveModalMessage: state => {
    return state.showVerifySaveModalMessage;
  },

  navigationButton: state => {
    return state.navigationButton;
  },
};
