import AxiosHandler from '@/helpers/axios';

export default {
  notification_created({ commit }, m) {
    commit('ADD_NOTIFICATION', m.notification_created);
    commit('REMOVE_DUPLICATE_NOTIFICATIONS');
  },

  notification_updated({ commit }, m) {
    commit('UPDATE_NOTIFICATION', m.notification_updated);
  },

  async getNotificationIds({ commit }) {
    const Ids = await AxiosHandler.call(
      '',
      `/notification`,
      'get',
      'application/json',
    );
    commit('SET_IDS_FOR_NOTIFICATIONS', Ids.data);
    return Ids.data;
  },

  async getNotifications({ commit }) {
    const res = await AxiosHandler.call(
      '',
      `/notification/?limit=15&offset=0`,
      'get',
      '',
    );

    commit('SET_UNREAD_NOTIFICATIONS', res.data.unread_count);

    commit('SET_NOTIFICATIONS_COUNT', res.data.count);

    commit('SET_OFFSET', 15);

    commit('SET_NOTIFICATIONS', res.data.results);

    commit('REMOVE_DUPLICATE_NOTIFICATIONS');
  },

  async loadMoreNotifications({ commit, state }) {
    const res = await AxiosHandler.call(
      '',
      `/notification/?limit=15&offset=${state.offset}`,
      'get',
      '',
    );

    commit('SET_OFFSET', state.offset + 15);

    commit('SET_NOTIFICATIONS', res.data.results);

    commit('SET_NOTIFICATIONS_COUNT', res.data.count);
  },

  async markAllNotificationsRead({ commit }) {
    await AxiosHandler.call(
      '',
      `/notification/mark_all_as_read`,
      'put',
      'application/json',
    );

    commit('SET_NOTIFICATIONS_READ');
  },

  async deleteAllReadNotifications({ commit }) {
    await AxiosHandler.call(
      '',
      `/notification/delete_all_read`,
      'delete',
      'application/json',
    );

    commit('DELETE_READ_NOTIFICATIONS');
  },

  async deleteNotification({ commit }, id) {
    await AxiosHandler.call(
      '',
      `/notification/${id}`,
      'delete',
      'application/json',
    );

    commit('DELETE_NOTIFICATION', id);
  },

  deleteNoteFromState({ commit, dispatch, rootGetters }, id) {
    dispatch('auth/getUser', {}, { root: true });

    const notification = rootGetters['notifications/notifications'].find(
      n => n.id === id,
    );
    if (notification && notification.read === false) {
      const unreadNote = rootGetters['notifications/unreadNotifications'];

      commit('SET_UNREAD_NOTIFICATIONS', unreadNote - 1);
    }

    commit('DELETE_NOTIFICATION', id);
  },

  async readNotification({ commit }, id) {
    commit('READ_NOTIFICATION', id);

    await AxiosHandler.call(
      '',
      `/notification/${id}/read`,
      'put',
      'application/json',
    );
  },
};
