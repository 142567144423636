export default {
  setToast({ commit, state }, [text, time]) {
    commit('SET_TOAST_TEXT', text);

    if (state.timeoutID !== null) {
      clearTimeout(state.timeoutID);
    }

    const timeoutID = setTimeout(() => {
      commit('SET_TOAST_TEXT', null);
    }, time);

    commit('SET_TIMEOUT', timeoutID);
  },
};
