import getEnv from '../../../../app.config';
import {
  HIDE_TASK_STATUSES,
  BACKEND_TASK_STATUSES,
  USER_PERMISSIONS,
} from '@/helpers/constants';
import AxiosHandler from '@/helpers/axios';

const urlMedia = getEnv('VUE_APP_ATTACHMENTS_ENDPOINT');

export default {
  async addTask({ dispatch, rootGetters }, { task, listId }) {
    const data = JSON.stringify({
      title: task.title,
      list_id: listId,
    });

    const pendingObj = { taskToAdd: task.title, method: 'post' };

    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            data,
            `/task`,
            'post',
            'application/json',
          );

          const newTask = res.data;
          const list = rootGetters['lists/selectedList'];

          if (list.id === newTask.list_id) {
            newTask['comments'] = [];

            newTask['actualComments'] = [];

            if (!list.actualTasks.some(t => t.id === newTask.id)) {
              list.actualTasks.unshift(newTask);

              dispatch('addDeleteTaskReorder', {
                list: list,
                option: 'add',
                taskId: newTask.id,
              });
            } else {
              dispatch('lists/updateListInProject', list, { root: true });
            }
          }

          return newTask;
        },
      },
      { root: true },
    );
  },

  addDeleteTaskReorder({ dispatch }, { list, option = 'add', taskId }) {
    if (option === 'add') {
      list.actualTasks.forEach(at => {
        if (at.id !== taskId) {
          at.position += 1;
        }
      });
    }

    if (option === 'delete') {
      const deletePos = list.actualTasks.find(at => at.id === taskId)?.position;

      if (Number.isInteger(deletePos)) {
        list.actualTasks.forEach(at => {
          if (at.position > deletePos) {
            at.position -= 1;
          }
        });
      }
    }

    dispatch('lists/updateListInProject', list, { root: true });
  },

  async deleteTask({ rootGetters, dispatch }, task) {
    await AxiosHandler.call(null, `/task/${task.id}`, 'delete', '');

    const list = rootGetters['projects/allLists'].find(
      l => l.id === task.list_id,
    );

    if (list) {
      list.actualTasks = list.actualTasks.filter(t => t.id !== task.id);

      dispatch('lists/updateListInProject', list, { root: true });

      dispatch('addDeleteTaskReorder', {
        list: list,
        option: 'delete',
        taskId: task.id,
      });
    }
  },

  async getTaskById({ dispatch, rootGetters }, id) {
    const pendingObj = { taskId: id, method: 'get' };

    const data = await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          try {
            const res = await AxiosHandler.call(null, `/task/${id}`, 'get', '');

            if (!res) return;
            const tsk = res.data;

            tsk['actualComments'] = [];

            if (
              !rootGetters['projects/allLists'].some(l => l.id === tsk.list_id)
            ) {
              await dispatch('lists/getListById', tsk.list_id, { root: true });
            }

            dispatch('updateTaskInList', tsk);

            return tsk;
          } catch (e) {
            // change back after BE changes for loading timesheets
            if (e.status === 404) {
              return {
                id: id,
                list_key: id,
                key: id,
                title: 'Not allowed',
                status: 404,
              };
            }
          }
        },
      },
      { root: true },
    );

    return data;
  },

  async getTask({ dispatch, rootGetters }, taskId) {
    const lists = rootGetters['projects/allLists'];
    let task = null;

    for (let list of lists) {
      task = list.actualTasks.find(t => t.id === taskId);

      if (task) {
        break;
      }
    }

    if (!task) {
      task = await dispatch('getTaskById', taskId);
    }

    return task;
  },

  async updateTask({ dispatch, rootGetters, commit }, [task, id, actionType]) {
    const settings = rootGetters['auth/userSettings'];
    const profile = rootGetters['auth/userProfile'];
    const curTask = rootGetters['lists/allTasks'].find(t => t.id === id);

    commit('SET_TASK_UPDATING', true);

    const oldStatus = curTask.status;

    if (task.created_by === null) {
      task.created_by = profile.id;
    }

    task['last_edited_by'] = profile.id;

    const data = JSON.stringify(task);

    const res = await AxiosHandler.call(
      data,
      `/task/${id}`,
      'patch',
      'application/json',
    );

    const updtdTask = res.data;

    updtdTask['actualComments'] = task.actualComments;
    updtdTask['actionType'] = actionType ? actionType : '';
    updtdTask['oldStatus'] = oldStatus;

    if (
      settings.Task_Preferences.find(s => s.setting === 'Auto-save task')
        .toggled
    ) {
      updtdTask['description'] = task.description;
    }

    const tempPosDragTask = rootGetters['tasks/tempPosOfDraggedTask'];
    if (actionType === 'drag' && tempPosDragTask?.id === updtdTask.id) {
      updtdTask['position'] = tempPosDragTask.pos;

      commit('SET_TEMP_POS_OF_DRAGGED_TASK', null);
    }

    // update task in lists
    dispatch('updateTaskInList', updtdTask);
    commit('SET_TASK_UPDATING', false);
  },

  updateTaskInList({ rootGetters, dispatch }, updtdTask) {
    updtdTask.users = [...new Set(updtdTask.users)];
    updtdTask.tags = [...new Set(updtdTask.tags)];

    const list = rootGetters['projects/allLists'].find(
      l => l.id === updtdTask.list_id,
    );

    if (!list) {
      return;
    }

    if (!list.actualTasks) {
      list.actualTasks = [updtdTask];
    }

    if (!list.actualTasks.some(t => t.id === updtdTask.id)) {
      list.actualTasks.push(updtdTask);
    }

    // shouldn't work for dragged task
    if (
      updtdTask.actionType !== undefined &&
      updtdTask.status === 5 &&
      updtdTask.list_id === list.id &&
      updtdTask.oldStatus !== 5 &&
      updtdTask.actionType !== 'drag'
    ) {
      dispatch(
        'lists/sortListTaskOrder',
        {
          list,
          draggedTask: updtdTask,
          droppedOnTask: list.actualTasks[0],
        },
        { root: true },
      );
    }

    list.actualTasks = [
      ...list.actualTasks.map(at => (at.id === updtdTask.id ? updtdTask : at)),
    ];

    dispatch('lists/updateListInProject', list, { root: true });
  },

  async deleteTaskAttachment({ dispatch }, [atId, task]) {
    const data = JSON.stringify({ id: atId });

    await AxiosHandler.call(
      data,
      `/attachment/${task.id}/task`,
      'delete',
      'application/json',
    );

    task.attachments = task.attachments.filter(at => at.id !== atId);

    dispatch('tasksState/setTaskToEdit', task, { root: true });
  },

  async readAttachment({ dispatch }, [a, task]) {
    try {
      await AxiosHandler.call(
        null,
        `${urlMedia}/${a.path}`,
        'get',
        'application/json',
      );
    } catch (e) {
      dispatch(
        'popups/setToast',
        [`File not found. Unable to view this file.`, 3000],
        {
          root: true,
        },
      );
    }

    dispatch('tasksState/setTaskToEdit', task, { root: true });
  },

  addUserTask({ dispatch, rootGetters }, [task, users]) {
    const selList = rootGetters['lists/selectedList'];
    const taskEdit = rootGetters['tasksState/taskToEdit'];
    const tasksDisplayed = rootGetters['tasksState/tasksDisplayed'];
    const selUsers = rootGetters['tasksState/selectedUsers'];

    const addedUsers = users.filter(uId => !task.users.includes(uId));
    const taskHidden = tasksDisplayed[HIDE_TASK_STATUSES[task.status - 1]];

    if (addedUsers.find(au => selUsers.find(su => su === au))) {
      const taskColCount = rootGetters['tasks/tasksCountByStatus'];
      taskColCount[task.status] += 1;
    }

    if (selList && task.list_id === selList.id && !taskHidden) {
      addedUsers.forEach(auid => {
        const user = selList.users.find(u => u.id === auid);

        if (user) {
          user.taskCount[BACKEND_TASK_STATUSES[task.status - 1]] += 1;
        }
      });
    }

    if (taskEdit?.id === task.id) {
      taskEdit.users = users;
    }
    task.users = users;
    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  removeUserTask({ rootGetters, dispatch }, [task, users]) {
    const selList = rootGetters['lists/selectedList'];
    const taskEdit = rootGetters['tasksState/taskToEdit'];
    const tasksDisplayed = rootGetters['tasksState/tasksDisplayed'];
    const selUsers = rootGetters['tasksState/selectedUsers'];

    const taskHidden = tasksDisplayed[HIDE_TASK_STATUSES[task.status - 1]];
    const removedUsers = task.users.filter(uId => !users.includes(uId));

    if (removedUsers.find(au => selUsers.find(su => su === au))) {
      const taskColCount = rootGetters['tasks/tasksCountByStatus'];
      taskColCount[task.status] -= 1;
    }

    if (
      selList &&
      task.list_id === selList.id &&
      !taskHidden &&
      removedUsers.length > 0
    ) {
      const taskUsers = selList.users.filter(u => removedUsers.includes(u.id));
      taskUsers.map(
        u => (u.taskCount[BACKEND_TASK_STATUSES[task.status - 1]] -= 1),
      );
    }

    if (taskEdit?.id === task.id) {
      taskEdit.users = users;
    }
    task.users = users;
    dispatch('tasks/updateTaskInList', task, { root: true });
  },

  async addRemoveUserFromTask({ commit, dispatch }, [userId, task, method]) {
    try {
      const data = JSON.stringify({ user: userId });

      if (method === 'remove') {
        commit(
          'users/SET_USER_LOADING',
          { userId, loading: true },
          { root: true },
        );

        await AxiosHandler.call(
          data,
          `/task/${task.id}/remove-user`,
          'put',
          'application/json',
        );

        if (task.users.includes(userId)) {
          const users = task.users.filter(usId => usId !== userId);
          dispatch('removeUserTask', [task, users]);
        }
      } else if (task.users.length < 5) {
        await AxiosHandler.call(
          data,
          `/task/${task.id}/add-user`,
          'post',
          'application/json',
        );

        if (!task.users.includes(userId)) {
          const users = [...task.users, userId];
          dispatch('addUserTask', [task, users]);
        }
      }
    } catch (err) {
      console.error('failed to', method, 'user');
      return;
    } finally {
      commit(
        'users/SET_USER_LOADING',
        { userId, loading: false },
        { root: true },
      );
    }
  },

  async fetchPendingRequests({ dispatch }, t) {
    if (Number.isInteger(t.pending_request)) {
      const penReq = await AxiosHandler.call(
        null,
        `/pending_requests/${t.pending_request}`,
        'get',
        '',
      );

      t.pending_request = penReq.data;

      dispatch('updateTaskInList', t);
    }
  },

  async fetchTaskTags({ dispatch, rootGetters }, task) {
    if (task) {
      const pendingObj = { fetchTaskTags: task.id };

      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            const res = await AxiosHandler.call(
              null,
              `/tag/?task=${task.id}`,
              'get',
              '',
            );

            if (res.data.results.length > 0) {
              const fetchedTags = res.data.results;
              const pro = rootGetters['projects/projects'].find(
                p => p.id === task.project_id,
              );

              if (pro) {
                const previousTags = pro['tags'].filter(
                  el => !fetchedTags.find(t => t.id === el.id),
                );

                pro['tags'] = [...previousTags, ...fetchedTags];
              }

              task['tags'] = res.data.results;

              dispatch('updateTaskInList', task);
            }
          },
        },
        { root: true },
      );
    }
  },

  processTaskStatuses({ rootGetters }) {
    let setForFetching = [];

    HIDE_TASK_STATUSES.forEach(dis => {
      if (!rootGetters['tasksState/tasksDisplayed'][dis]) {
        setForFetching.push(HIDE_TASK_STATUSES.indexOf(dis) + 1);
      }
    });

    return setForFetching;
  },

  async fetchTasksByStatus(
    { state, dispatch, commit },
    [li, status, isReadPermissions],
  ) {
    const statusName = `${BACKEND_TASK_STATUSES[status - 1]}`;

    if (
      !li.taskOffset[statusName].fetched ||
      li.taskOffset[statusName].count !== li.taskOffset[statusName].offset ||
      isReadPermissions
    ) {
      const pendingObj = { list: li.id, tasksFetchByStatus: status };

      try {
        await dispatch(
          'common/pendingWrapper',
          {
            pendingObj,
            callback: async () => {
              dispatch('setTasksFetching', [status, 'add']);

              const res = await AxiosHandler.call(
                null,
                `/task/?list_id=${li.id}&limit=${
                  li.taskOffset[statusName].fetched
                    ? state.boardLimit
                    : state.listLimit
                }&offset=${li.taskOffset[statusName].offset}&status=${status}`,
                'get',
                '',
              );

              li.taskOffset[statusName].fetched = true;
              li.taskOffset[statusName].count = res.data.count;
              li.taskOffset[statusName].offset =
                li.taskOffset[statusName].offset + res.data.results.length;

              li.taskCount = res.data.total_tasks;

              // ------------------------------------------------------------------------------- //

              await dispatch('placeTasksInlist', [res.data.results, li]);

              dispatch('setTasksFetching', [status, 'remove']);
            },
          },
          { root: true },
        );
      } catch (e) {
        const statArr = state.tasksFetchingByStatus.filter(s => s !== status);
        commit('SET_TASKS_FETCHING_BY_STATUS', statArr);
      }
    }
  },

  setTasksFetching({ commit, state }, [status, action]) {
    if (action === 'remove') {
      const statArr = state.tasksFetchingByStatus.filter(s => s !== status);

      commit('SET_TASKS_FETCHING_BY_STATUS', statArr);
    } else {
      commit('SET_TASKS_FETCHING_BY_STATUS', [
        ...state.tasksFetchingByStatus,
        status,
      ]);
    }
  },

  async fetchTasksByUser(
    { dispatch, commit, rootGetters },
    [li, user, statusTofetch],
  ) {
    const statuses = statusTofetch
      ? [statusTofetch]
      : await dispatch('processTaskStatuses');

    try {
      const pendingObj = { list: li.id, tasksFetchByUser: user };

      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            const callApi = async (status, offset, taskCount) => {
              if (offset !== taskCount) {
                dispatch('setTasksFetching', [status, 'add']);
                const res = await AxiosHandler.call(
                  null,
                  `/task/?list_id=${li.id}&limit=15&offset=${offset}&user=${user}&status=${status}`,
                  'get',
                  '',
                );

                li.taskCount = res.data.total_tasks;

                // ------------------------------------------------------------------------------- //

                await dispatch('placeTasksInlist', [res.data.results, li]);

                dispatch('setTasksFetching', [status, 'remove']);
              }
            };

            const remUsers = rootGetters['lists/removedUsers'];
            const userInlist = li.users
              .concat(remUsers)
              .find(u => u.id === user);

            statuses.map(async st => {
              const tasksForUserPresentStatus = li.actualTasks.filter(
                ta => ta.users.includes(user) && ta.status === st,
              );

              await callApi(
                st,
                tasksForUserPresentStatus.length,
                userInlist.taskCount[BACKEND_TASK_STATUSES[st - 1]],
              );
            });

            // -----------------------------------------------------------------------------------//
          },
        },
        { root: true },
      );
    } catch (e) {
      commit('SET_TASKS_FETCHING_BY_STATUS', []);
    }
  },

  async getTotalTasksByList({ dispatch, rootGetters }, list) {
    const res = await AxiosHandler.call(
      null,
      `/task/?list_id=${list.id}&limit=1&offset=999999999&status=5`,
      'get',
      '',
    );

    const userLists = rootGetters['lists/userLists'];
    if (userLists && userLists.length > 0) {
      const userList = userLists.find(l => l.id === list.id);
      if (userList) {
        userList.taskCountFetched = true;
        userList.taskCount = res.data.total_tasks;
      }
    } else {
      list.taskCountFetched = true;
      list.taskCount = res.data.total_tasks;
      dispatch('lists/updateListInProject', list, { root: true });
    }
  },

  async getTaskCountByStatus({ rootGetters }, [list, selUsers]) {
    const tasksDisplayed = rootGetters['tasksState/tasksDisplayed'];
    const statuses = rootGetters['common/statuses'].filter(
      st => tasksDisplayed[st.hide] !== true,
    );
    const stats = statuses.map(st => st.id);
    const taskColCount = rootGetters['tasks/tasksCountByStatus'];

    const res = await AxiosHandler.call(
      null,
      `/list/count/?list_id=${list.id}&statuses=${stats}&users=${selUsers}`,
      'get',
      '',
    );

    BACKEND_TASK_STATUSES.forEach((st, index) => {
      taskColCount[index + 1] = res.data.results[st];
    });
  },

  /**
   * @param commit: {Function}
   * @param state: {Object}
   * @param dispatch: {Function}
   * @param propSearchObj: {searchObj}
   * @returns {Promise<void>}
   */
  async searchTasks({ commit, state, dispatch }, propSearchObj) {
    const defParams = { search: '', limit: 10, offset: 0 };
    const data = {
      ...defParams,
      ...propSearchObj,
    };

    data.search = escape(data.search);

    const pendingObj = { searchTasks: true, ...propSearchObj };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            '',
            `/task/?${Object.keys(data)
              .map(key => `${key}=${data[key]}`)
              .join('&')}`,
            'get',
            null,
          );

          dispatch('placeArrayTasksByStatuses', res.data.results);

          commit('SET_SEARCH_TASKS_IDS', [
            ...new Set([
              ...state.searchTasksIds,
              ...res.data.results.map(t => t.id),
            ]),
          ]);
        },
      },
      { root: true },
    );
  },

  async placeArrayTasksByStatuses({ dispatch, rootGetters }, tasks) {
    const lists = {};

    for await (let t of tasks) {
      if (lists[t.list_id]) {
        lists[t.list_id].tasks.push(t);
      } else {
        let li = rootGetters['projects/allLists'].find(l => l.id === t.list_id);

        // Getting information about the list, If no list is found or no permissions are found, then you need to get this data
        if (!li) {
          li = await dispatch('lists/getListById', t.list_id, {
            root: true,
          });
        }

        const proPerm = rootGetters['permissions/projectUserData'].find(
          pud => pud.project === li.project,
        );
        const userPerm = proPerm?.data.find(
          p => p.user === rootGetters['auth/userProfile'].id,
        );
        const listPerm = userPerm?.lists.find(l => l.listId === li.id);

        if (!listPerm) {
          await dispatch(
            'permissions/getUserTaskPermsByList',
            {
              listId: li.id,
              projectId: li.project,
            },
            { root: true },
          );
        }

        lists[t.list_id] = {
          tasks: [t],
          li,
        };
      }
    }

    Object.values(lists).forEach(list => {
      dispatch('placeTasksInlist', [list.tasks, list.li]);
    });
  },

  async fetchEpicsTasks({ commit, dispatch }, epic) {
    if (epic) {
      const limit = 10;
      const tasksOffset = epic.tasksOffset;

      const pendingObj = { fetchEpicsTasks: epic.id, offset: tasksOffset };
      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            if (epic.tasksOffset === 0 || epic.task_count > tasksOffset) {
              const res = await AxiosHandler.call(
                null,
                `/task/?epic=${epic.id}&offset=${tasksOffset}&limit=${limit}`,
                'get',
                '',
              );

              await dispatch('placeArrayTasksByStatuses', res.data.results);

              epic['tasksOffset'] =
                epic['tasksOffset'] + res.data.results.length;
              commit('epics/PATCH_EPIC', epic, { root: true });
            }
          },
        },
        { root: true },
      );
    }
  },

  async getTasksForExport({ dispatch, rootGetters }, [level, offset]) {
    const selProj = rootGetters['projects/selectedProject'];
    const selList = rootGetters['lists/selectedList'];
    const pendingObj = { exportingTasks: true };

    let query = `?offset=${offset}&limit=500`;
    if (level === 'list') {
      query += `&list_id=${selList.id}`;
    } else if (level === 'project') {
      query += `&project=${selProj.id}`;
    }

    const res = await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/task/${query}`,
            'get',
            '',
          );

          return res;
        },
      },
      { root: true },
    );

    return res;
  },

  async getTasksForList({ dispatch, rootGetters }, li) {
    if (!li) return;

    dispatch('lists/updateSelectedList', null, { root: true });
    const list = rootGetters['projects/allLists'].find(lst => lst.id === li.id);
    const usersSelected = rootGetters['tasksState/selectedUsers'];

    const statuses = await dispatch('processTaskStatuses');

    await new Promise(resolve => {
      // wait for list users to load

      const listFetchingUsers = setInterval(() => {
        const list = rootGetters['projects/allLists'].find(
          lst => lst.id === li.id,
        );
        if (
          !rootGetters['common/pendingData'].some(
            pd => pd?.option === 'task_count' && pd.list === list.id,
          ) &&
          list.users.length > 0
        ) {
          resolve('resolved');
          clearInterval(listFetchingUsers);
        }
      }, 200);
    });

    const remUsers = rootGetters['lists/removedUsers'];
    if (
      usersSelected.length > 0 &&
      list.users.concat(remUsers).some(u => usersSelected.includes(u.id))
    ) {
      usersSelected.forEach(async user => {
        if (list.users.concat(remUsers).some(u => u.id == user)) {
          await dispatch('fetchTasksByUser', [list, user, null]);
        }
      });
    } else {
      const isReadPermissions = list.permissions.includes(
        USER_PERMISSIONS.READ,
      );
      statuses.forEach(async status => {
        await dispatch('fetchTasksByStatus', [list, status, isReadPermissions]);
      });
    }
  },

  async placeTasksInlist({ dispatch, rootGetters }, [tasks, li]) {
    tasks = tasks.filter(
      task => !li.actualTasks.find(act => act.id === task.id),
    );

    const epics = rootGetters['epics/selectedProjectEpics'];
    const missingEpics = tasks
      .filter(task => task.epic && !epics.find(e => e.id === task.epic))
      .map(t => t.epic);
    missingEpics.forEach(e => {
      dispatch('epics/getEpicById', e, { root: true });
    });

    tasks.map(t => {
      t['actualComments'] = [];

      if ('pending_request' in t) {
        dispatch('fetchPendingRequests', t);
      }
      return t;
    });

    li.actualTasks = li.actualTasks.concat(tasks);

    dispatch('lists/updateListInProject', li, { root: true });
  },

  async getTaskByKeyAndProjectId({ dispatch, rootGetters }, payload) {
    const taskKey = Number(payload.taskKey);

    if (!taskKey) {
      dispatch('popups/setToast', ['You have no access to this task', 3000], {
        root: true,
      });

      return null;
    }

    let taskFound = rootGetters['lists/allTasks'].filter(elem => {
      return elem.key === taskKey && elem.project_id === payload.projectId;
    });

    if (taskFound.length > 0) {
      return [taskFound[0]];
    }

    const res = await AxiosHandler.call(
      null,
      `/task/?project=${payload.projectId}&key=${taskKey}`,
      'get',
      '',
    );

    if (res.data.count === 0) {
      return res.data.results;
    }

    const taskfetched = res.data.results;

    await new Promise(resolve => {
      let track = taskfetched.length;

      taskfetched.map(async t => {
        t['actualComments'] = [];

        if ('pending_request' in t) {
          dispatch('fetchPendingRequests', t);
        }

        const updateList = async li => {
          if (!li.actualTasks.some(at => at.id === t.id)) {
            li.actualTasks.unshift(t);
          }

          await dispatch('lists/updateListInProject', li, { root: true });
        };

        const listOfTask = rootGetters['projects/allLists'].find(
          l => l.id === t.list_id,
        );

        if (listOfTask === undefined) {
          const fetchLi = await dispatch('lists/getListById', t.list_id, {
            root: true,
          });
          updateList(fetchLi);
        } else {
          updateList(listOfTask);
        }

        track = track - 1;
        if (track === 0 || res.data.results.length === 0) {
          resolve(taskfetched);
        }
      });
    });
    return res.data.results;
  },

  async processFetchedTasks({ dispatch, rootGetters }, taskfetched) {
    await new Promise(resolve => {
      let track = taskfetched.length;

      taskfetched.map(async t => {
        t['actualComments'] = [];

        if ('pending_request' in t) {
          dispatch('fetchPendingRequests', t);
        }

        const updateList = async li => {
          if (!li.actualTasks.some(at => at.id === t.id)) {
            li.actualTasks.unshift(t);
          }

          await dispatch('lists/updateListInProject', li, { root: true });
        };

        const listOfTask = rootGetters['projects/allLists'].find(
          l => l.id === t.list_id,
        );

        if (listOfTask === undefined) {
          const fetchLi = await dispatch('lists/getListById', t.list_id, {
            root: true,
          });
          updateList(fetchLi[0]);
        } else {
          updateList(listOfTask);
        }

        track = track - 1;
        if (track === 0 || taskfetched.length === 0) {
          resolve(taskfetched);
        }
      });
    });
  },

  logout({ commit }) {
    commit('LOGOUT_USER');
  },
};
