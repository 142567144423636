export default {
  setInputFocused({ commit }, payload) {
    commit('SET_INPUT_FOCUSED', payload);
  },

  setFocusTask({ commit }, payload) {
    commit('SET_FOCUS_TASK', payload);
  },
  setInputTyped({ commit }, payload) {
    commit('SET_INPUT_TYPED', payload);
  },
};
