import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
  namespaced: true,
  state: () => {
    return {
      plans: [],
      plansDetails: null,
      planTypes: [],
      purchaseData: {},
      planColours: [
        {
          // orange plan
          id: 1,
          colour: 'var(--orange-plan)',
        },
        {
          // educational plan
          id: 2,
          colour: 'var(--edu-plan)',
        },
        {
          // champion plan
          id: 3,
          colour: 'var(--teamfu-pink)',
        },
        {
          // coral plan
          id: 4,
          colour: 'var(--coral-plan)',
        },
        {
          // blue plan
          id: 5,
          colour: 'var(--blue-plan)',
        },
        {
          // purple plan
          id: 6,
          colour: 'var(--purple-plan)',
        },
      ],
      openPurchasePlanModal: false,
      purchasingPlan: null,
      resetPlanModals: false,
      purchaseSelectedPlan: null,
      planBillingDetails: {},
      cardForPurchase: null,
      paymentDone: false,
      paymentLoading: false,
      paymentError: false,
      draggedUser: null,
      planRenew: false,
    };
  },
  actions,
  getters,
  mutations,
};
