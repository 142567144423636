import Tokens from '@/api/tokens';
import axiosRetry from 'axios-retry';
import axios from 'axios';
import { networkError } from '@/helpers/helpers';
import { stores } from '@/main';
import getEnv from '../../app.config';
import Cookies from '@/helpers/cookies';

const rootDomain = getEnv('VUE_APP_ROOT_API');
/**
 * Axios requests helpers class
 */
export default class AxiosHandler {
  /**
   *
   * @param url {string}
   * @returns {string}
   */
  static addCloseSlashToUrl(url) {
    const lastIndexSlash = url.lastIndexOf('/');

    if (lastIndexSlash === url.length - 1 || url.includes('/media/')) {
      return url;
    }

    if (url.lastIndexOf('?') > lastIndexSlash) {
      return url;
    }

    return url + '/';
  }

  /**
   * Api url constructor
   * @param url {string}
   * @returns {string}
   */
  static urlConstructor(url) {
    const subdomain = `api/v1`;
    const domain = `${rootDomain}/${subdomain}`;

    if (/^(http)/.test(url)) {
      return url;
    }

    const isStartWithSlash = url.startsWith('/');

    if (url.startsWith('/' + subdomain) || url.startsWith(subdomain)) {
      return rootDomain + (isStartWithSlash ? '' : '/') + url;
    }

    return domain + (isStartWithSlash ? '' : '/') + url;
  }

  /**
   * for calls made on condition users are logged in
   * @param data {string|null}
   * @param url {string}
   * @param method {'get'|'patch'|'post'|'put'|'delete'|'options'}
   * @param CT {'application/json'|null|''}
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async call(data, url, method, CT) {
    const cookies = Cookies.getAll();
    const refresh = Tokens.parse(cookies.refresh);
    if (refresh && refresh.exp < Date.now() / 1000) {
      // remove refresh and access tokens
      await Tokens.getAll();

      stores.commit('auth/SET_FORCE_LOGOUT', true);
      stores.dispatch('auth/logoutUser');

      return;
    }

    const tokens = await Tokens.getAll();
    const auth = tokens ? `JWT ${tokens.access}` : null;
    const reqUrl = AxiosHandler.addCloseSlashToUrl(
      AxiosHandler.urlConstructor(url),
    );

    const config = {
      method: method,
      url: reqUrl,
      timeout: reqUrl.includes('avatar') ? 120 * 1000 : 30 * 1000,
      headers: {
        Authorization: auth,
        'Content-Type': CT,
      },
      data: data,
    };

    axiosRetry(axios, {
      retries: 2,
      shouldResetTimeout: true,
      retryCondition: error => {
        return error.toJSON().message === 'timeout of 30000ms exceeded';
      },
      onRetry: retryCount => {
        if (
          retryCount === 2 &&
          !reqUrl.includes('users/?') &&
          !reqUrl.includes('avatar')
        ) {
          stores.dispatch('popups/setToast', [
            'Your connection is extremely slow, press to reload',
            3000,
          ]);
          networkError();
        }
      },
    });

    return AxiosHandler.request(config, false)
      .then(response => response)
      .catch(error => {
        if (
          error.toJSON().message !== undefined &&
          error.toJSON().message === 'timeout of 30000ms exceeded'
        ) {
          return;
        } else if (
          error.toJSON().message !== undefined &&
          error.toJSON().message === 'Network Error'
        ) {
          networkError();
          throw new CustomError('Network Error');
        } else if (!(`response` in error)) {
          throw error;
        } else if (error.response.status === 403) {
          stores.dispatch('popups/setToast', ['Forbidden request', 4000]);
        } else if (error.response.status === 401) {
          stores.dispatch('auth/logoutUser');
        } else {
          throw error.response;
        }
      });
  }

  /**
   * for calls made that do not require user to be logged in (with basic Handlers)
   * @param config {any}
   * @param isHandlers {boolean}
   * @returns {Promise<AxiosResponse<any>>}
   */
  static async request(config, isHandlers = true) {
    const reqUrl = AxiosHandler.addCloseSlashToUrl(
      AxiosHandler.urlConstructor(config.url),
    );

    if (isHandlers) {
      return axios({ ...config, url: reqUrl })
        .then(response => response)
        .catch(error => {
          console.error(error);
          if (error.toJSON().message === 'Network Error') {
            networkError();
            throw new CustomError('Network Error');
          } else {
            throw error.response;
          }
        });
    }

    return axios({ ...config, url: reqUrl });
  }
}

export class CustomError extends Error {
  constructor(message, ...params) {
    super(message, params);
    this.message = message;
  }
}
