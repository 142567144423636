<template>
  <div v-updated-show="globalLoading" class="loading-teamfu-logo">
    <img alt="teamfu-logo" src="@/assets/teamfu-h.png" loading="eager" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'global-logo-loading',

  computed: {
    ...mapGetters('viewState', ['globalLoading']),
  },
};
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

.loading-teamfu-logo {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: var(--bg-grey-000);
}

.loading-teamfu-logo img {
  width: 10%;
  animation: fadeIn 0.5s infinite alternate;
}
</style>
