import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// set state for the module in this file

export default {
  namespaced: true,
  state() {
    return {
      toast: null,
      timeoutID: null,
      deleteModalMessage: null,
      deleteAction: null,
      showVerifySaveModalMessage: null,
      navigationButton: 0,
    };
  },
  actions,
  getters,
  mutations,
};
