export default {
  SET_USERS: (state, users) => {
    const oldUsers = state.users.filter(u => !users.find(us => us.id === u.id));

    state.users = oldUsers.concat(users);
  },

  SET_SEARCH_USERS: (state, users) => {
    state.searchedUsers = users;
  },

  SET_IS_CHANGED_PROFILE: (state, payload) => {
    state.isChangedProfile = payload;
  },

  SET_USER_UPDATE_STATUS: (state, payload) => {
    state.userUpdateStatus = payload;
  },

  SET_OLD_USER_PROFILE: (state, payload) => {
    state.oldUserProfile = payload;
  },

  SET_HAS_ERRORS: (state, payload) => {
    state.hasErrors = payload;
  },

  SET_USER: (state, user) => {
    state.users = state.users.map(u => (u.id === user.id ? user : u));
  },

  SET_USER_LOADING: (state, data) => {
    const user = state.users.find(u => u.id === data.userId);
    if (user) user.loading = data.loading;
  },

  SET_SHOW_INVITATION_MODAL: (state, data) => {
    state.showInvitationModal = data;
  },

  SET_INVITATION_PARAMS: (state, data) => {
    state.invitationParams = data;
  },

  SET_USER_TO_VIEW: (state, payload) => {
    state.userToView = payload;
  },

  LOGOUT_USER: state => {
    (state.searchedUsers = []),
      (state.userToView = null),
      (state.isChangedProfile = false),
      (state.userUpdateStatus = ''),
      (state.oldUserProfile = null),
      (state.hasErrors = false);
  },
};
