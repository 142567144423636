import actions from './actions';
import getters from './getters';
import mutations from './mutations';
export default {
  namespaced: true,
  state() {
    return {
      creatingEpic: null,
      tempEpicId: 'creating-epic',
      epics: [],
      filterEpics: {},
      filteredEpics: [],
      showWeekCalendar: false,
      resizeModalEpicId: null,
      openMenuEpicId: null,
      updatedDate: {},
      epicProgressColours: [
        {
          colour: 'var(--epic-progress-behind)',
          description: 'Behind schedule',
        },
        {
          colour: 'var(--epic-progress-slightly-behind)',
          description: 'Slightly behind schedule',
        },
        {
          colour: 'var(--epic-progress-on)',
          description: 'On schedule',
        },
        {
          colour: 'var(--epic-progress-ahead)',
          description: 'Ahead of schedule',
        },
      ],
    };
  },
  actions,
  getters,
  mutations,
};
