import { uniqueMergeArrays, appendStringWithCommas } from '@/helpers/helpers';
import AxiosHandler from '@/helpers/axios';
import { ROUTE_NAMES, categories } from '@/helpers/constants';
import router from '../../../router/index';

export default {
  async getTimesheetsForExport({ dispatch, getters }, [limit, offset]) {
    const clndrView = getters.showTsCalendarView;
    const clndrDates = getters.calendarTsViews;

    let query;
    if (clndrView > 0) {
      query = await dispatch('timesheetsFilterQuery', ['date']);

      const dateString = await dispatch('processCalendarDates', clndrDates);
      query += `&date=${dateString}`;
    } else {
      query = await dispatch('timesheetsFilterQuery');
    }

    const pendingObj = { fetchAllTimesheetsByList: true };
    const result = await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/timesheet/?limit=${limit}&offset=${offset}${query}`,
            'get',
            '',
          );

          return res.data;
        },
      },
      { root: true },
    );

    return result;
  },

  async fetchTimesheetById({ commit, dispatch, getters }, id) {
    const timesheetExist = getters.filteredTimesheets.find(t => t.id === id);

    if (timesheetExist) {
      return timesheetExist;
    }

    const pendingObj = { timesheet: id, method: 'get' };

    const result = await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          try {
            const res = await AxiosHandler.call(
              null,
              `/timesheet/${id}`,
              'get',
              '',
            );

            commit('SET_TIMESHEET_LIST', [res.data]);

            return res.data;
          } catch (error) {
            if (error.status === 404) {
              router.push(router.currentRoute.value.path);
              dispatch(
                'popups/setToast',
                [`You don't have access to this time log`, 4000],
                { root: true },
              );
            }
          }
        },
      },
      { root: true },
    );

    return result;
  },

  async processCalendarDates({ getters }, dates) {
    const filter = getters.filterTimesheets;
    let dateFilters = [];
    if (filter.date) {
      dateFilters = filter.date
        .filter(date => !!date)
        .map(d => new Date(d + ' UTC'));
    }

    let dateString = '';
    if (dateFilters.length === 0) {
      dateString = dates.map(d => d.toJSON().slice(0, 10));
    } else if (dateFilters.length === 1) {
      dateString = dateFilters[0].toJSON().slice(0, 10);
    } else if (dateFilters.length === 2) {
      const startDate = dateFilters[0].toJSON().slice(0, 10);
      const endDate = dateFilters[1].toJSON().slice(0, 10);

      const clndrView = getters.showTsCalendarView;
      dateString = clndrView === 2 ? `${startDate}` : `${startDate},${endDate}`;
    }

    return dateString;
  },

  async fetchTsForCalendar({ commit, dispatch, getters }, dates) {
    let query = await dispatch('timesheetsFilterQuery', ['date']);

    const dateString = await dispatch('processCalendarDates', dates);

    query += `&date=${dateString}`;

    const pendingObj = { fetchingCalendarTsLogs: true };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/timesheet/?ordering=start_time${query}`,
            'get',
            '',
          );

          const filter = getters.filterTimesheets;
          let dateFilters = [];
          if (filter.date) {
            dateFilters = filter.date
              .filter(date => !!date)
              .map(d => new Date(d + ' UTC'));
          }

          if (dateFilters.length === 0) {
            const firstDay = new Date(dates[0]);
            firstDay.setHours(6);
            firstDay.setMinutes(31);

            const lastDay = new Date(dates[1]);
            lastDay.setDate(lastDay.getDate() + 1);
            lastDay.setHours(6);
            lastDay.setMinutes(29);

            res.data.results.forEach(ts => {
              const startDate = new Date(ts.start_time);
              const endDate = new Date(ts.end_time);
              if (startDate > lastDay || endDate < firstDay) {
                res.data.total_hours -= ts.hours;
              }
            });
            commit('SET_LOGGED_HOURS_BY_LIST', res.data.total_hours);
          } else {
            commit('SET_LOGGED_HOURS_BY_LIST', res.data.total_hours);
          }

          commit('timesheet/SET_FILTERED_TIMESHEETS', res.data.results, {
            root: true,
          });
        },
      },
      { root: true },
    );
  },

  async timesheetsFilterQuery(
    { dispatch, getters, rootGetters },
    excludedFields,
  ) {
    const remUsers = rootGetters['lists/removedUsers'];
    const selUsers = rootGetters['tasksState/selectedUsers'];
    const filter = getters.filterTimesheets;

    let query = '';
    let level = '';
    let list, project;

    const route = router.currentRoute.value;
    if (route.name === ROUTE_NAMES.TIMESHEETS) {
      level = 'list';

      list = await dispatch(
        'lists/getListByKeyAndProjectId',
        {
          listKey: route.params.listKey,
          projectId: route.params.projectId,
        },
        { root: true },
      );
    } else if (route.name === ROUTE_NAMES.PROJECT_TIMESHEETS) {
      level = 'project';

      const projects = rootGetters['projects/projects'];
      project = projects.find(pr => pr.id === route.params.projectId);
    } else if (route.name === ROUTE_NAMES.PORTFOLIO_TIMESHEETS) {
      level = 'portfolio';
    }

    if (!level) {
      return;
    }

    if (level === 'list' && list) {
      query += `&list_id=${list.id}`;
    } else if (level === 'project') {
      query += `&project=${route.params.projectId}`;
    }

    let tsUsers = [];
    if (level === 'list' && list) {
      tsUsers = list.users.map(u => u.id);
    } else if (level === 'project' && project) {
      tsUsers = project.users;
    }
    const users = selUsers.filter(
      u => tsUsers.includes(u) || remUsers.map(u => u.id).includes(u),
    );
    users.forEach(u => {
      query += '&user=' + u;
    });

    if (!excludedFields?.includes('date') && filter.date) {
      const filterDates = filter.date.filter(d => !!d?.toJSON());

      if (filterDates.length > 0) {
        const dateString = filterDates.map(d =>
          new Date(d + ' UTC').toISOString().slice(0, 10),
        );

        query += `&date=${dateString}`;
      }
    }

    if (filter.list_ids?.length) {
      query += appendStringWithCommas('&list_ids=', filter.list_ids);
    }

    if (filter.projects?.length) {
      query += appendStringWithCommas('&projects=', filter.projects);
    }

    if (filter.tags?.length) {
      let unassigned = false;
      let tags = filter.tags.filter(tag => {
        if (tag === '(Unassigned)') {
          unassigned = true;
          return false;
        }

        return true;
      });

      if (unassigned) {
        tags.unshift('unassigned');
      }

      query += appendStringWithCommas('&tags=', tags);
    }

    if (
      filter.activities?.length &&
      filter.activities.length !== categories.length
    ) {
      filter.activities.forEach(a => {
        query += `&activity=${a}`;
      });
    }

    return query;
  },

  async loadTimesheets({ commit, dispatch, getters }, [key, value]) {
    if (value?.length === 0) {
      value = 'empty';
    }

    const filter = getters.filterTimesheets;
    if (value && value !== 'empty') {
      if (Array.isArray(value)) {
        commit('PATCH_FILTER_TIMESHEETS', {
          [key]: [...value],
        });
      } else {
        commit('PATCH_FILTER_TIMESHEETS', {
          [key]: value,
        });
      }
    } else if (value === 'empty') {
      const filterValue = filter[key];

      commit('PATCH_FILTER_TIMESHEETS', {
        [key]: null,
      });

      if (!filterValue) {
        return;
      }
    } else if (filter[key]) {
      delete filter[key];
    } else if (key) {
      return;
    }

    if (Object.keys(getters.calendarTsViews).length > 0) {
      await dispatch('fetchTsForCalendar', getters.calendarTsViews);
    } else {
      const limit = getters.tsLimit;
      const query = await dispatch('timesheetsFilterQuery');

      const pendingObj = { loadingTimesheets: query };
      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            const res = await AxiosHandler.call(
              null,
              `/timesheet/?limit=${limit}&offset=0${query}`,
              'get',
              '',
            );

            commit('SET_FILTERED_TIMESHEETS', res.data.results);
            commit('SET_LOGGED_HOURS_BY_LIST', res.data.total_hours);
          },
        },
        { root: true },
      );
    }
  },

  async loadMoreTimesheets({ commit, dispatch, rootGetters }) {
    const filteredTimesheets = rootGetters['timesheet/filteredTimesheets'];
    let offset = filteredTimesheets.length;
    const limit = rootGetters['timesheet/tsLimit'];
    let query = await dispatch('timesheetsFilterQuery');

    const pendingObj = { loadingTimesheets: true };
    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          const res = await AxiosHandler.call(
            null,
            `/timesheet/?limit=${limit}&offset=${offset}${query}`,
            'get',
            '',
          );

          commit(
            'SET_FILTERED_TIMESHEETS',
            filteredTimesheets.concat(res.data.results),
          );
        },
      },
      { root: true },
    );
  },

  /* pagination of timesheets according to the transmitted task */
  async fetchTimesheetsByTask({ commit, dispatch }, task) {
    if (task) {
      const limit = 10;

      /* The pagination query returns the total number of schedules in the list. This variable
      is saved in order to be used to prevent unnecessary requests to the server */
      const maxTimesheetsCount = task['timesheetsCount'] || 0;
      const fetchedTimesheetsCount = task['fetchedTimesheetIds']?.length || 0;
      const offset = fetchedTimesheetsCount;

      const pendingObj = { fetchTaskTimesheets: task.id, offset };
      await dispatch(
        'common/pendingWrapper',
        {
          pendingObj,
          callback: async () => {
            if (
              maxTimesheetsCount === 0 ||
              maxTimesheetsCount > fetchedTimesheetsCount
            ) {
              const res = await AxiosHandler.call(
                null,
                `/timesheet/?task=${task.id}&offset=${offset}&limit=${limit}&ordering=-start_time`,
                'get',
                '',
              );

              commit('SET_TIMESHEET_LIST', res.data.results);
              commit('SET_LOGGED_HOURS_BY_TASK', res.data.total_hours);

              task['timesheetsCount'] = res.data.count;
              task['fetchedTimesheetIds'] = uniqueMergeArrays(
                task['fetchedTimesheetIds'],
                res.data.results.map(t => t.id),
              );

              dispatch('tasks/updateTaskInList', task, { root: true });
            }
          },
        },
        { root: true },
      );
    }
  },

  async setSelectedTimesheet({ commit, dispatch }, timeId) {
    if (!timeId) {
      dispatch('setDrawerState', false);
      commit('SELECTED_TIMESHEET_ID', null);
    } else {
      const data = await dispatch('fetchTimesheetById', +timeId);
      if (data) {
        if (data.task) {
          await dispatch('tasks/getTask', data.task, { root: true });
        }

        commit('SELECTED_TIMESHEET_ID', data.id);
        dispatch('setDrawerState', true);
      }
    }
  },

  async deleteTimesheet({ commit, dispatch }, id) {
    const pendingObj = { timesheet: id, method: 'delete' };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          await AxiosHandler.call(null, `/timesheet/${id}`, 'delete', '');

          commit('DELETE_TIMESHEET', id);
          dispatch('setSelectedTimesheet', null);
        },
      },
      { root: true },
    );
  },

  async addLog({ commit, dispatch, rootGetters }, data) {
    const pendingObj = { option: 'creating time log' };
    return await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          if (data) {
            try {
              const res = await AxiosHandler.call(
                JSON.stringify(data),
                `/timesheet`,
                'post',
                'application/json',
              );

              const timesheets = rootGetters['timesheet/filteredTimesheets'];
              if (!timesheets.some(ts => ts.id === res.data.id)) {
                commit('ADD_TIMESHEET', res.data);
              }

              return res.data;
            } catch (e) {
              dispatch(
                'popups/setToast',
                ['Logged times must be multiples of 15 minutes', 3000],
                {
                  root: true,
                },
              );
            }
          }
        },
      },
      { root: true },
    );
  },

  async patchLog({ commit, dispatch }, { log, id }) {
    const pendingObj = { timesheet: id, method: 'patch', ...log };

    await dispatch(
      'common/pendingWrapper',
      {
        pendingObj,
        callback: async () => {
          if (Object.keys(log).length > 0) {
            const res = await AxiosHandler.call(
              JSON.stringify(log),
              `/timesheet/${id}`,
              'patch',
              'application/json',
            );

            if (res) {
              commit('UPDATE_TIMESHEET', res.data);
            }
          }
        },
      },
      { root: true },
    );
  },

  setDrawerState({ commit }, data) {
    commit('OPEN_DRAWER', data);
  },
};
