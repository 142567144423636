import { orderListsByPosition } from '@/helpers/helpers';

export default {
  ADD_PROJECT: (state, project) => {
    if (!state.projects.some(pr => pr.id === project.id)) {
      orderListsByPosition(project);
      state.projects.unshift(project);
    }
  },

  UPDATE_PROJECT: (state, project) => {
    orderListsByPosition(project);

    state.projects = state.projects.map(e =>
      e.id === project.id ? project : e,
    );
  },

  SET_DRAGGED_LIST: (state, list) => {
    state.draggedList = list;
  },

  RESET_PROJECTS: (state, projects) => {
    state.projects = projects;
  },

  SET_PROJECTS: (state, projects) => {
    const oldProjects = state.projects.filter(
      p => !projects.find(pro => pro.id === p.id),
    );

    projects.forEach(pro => orderListsByPosition(pro));
    state.projects = oldProjects.concat(projects);
  },

  SELECT_PROJECT_ID: (state, prId) => {
    state.selectedProjectId = prId;
  },

  OPEN_PROJECT: (state, id) => {
    if (!state.openProjects.includes(id)) {
      state.openProjects.push(id);
    }
  },

  CLOSE_PROJECT: (state, id) => {
    state.openProjects = state.openProjects.filter(p => p !== id);
  },

  DELETE_PROJECT: (state, id) => {
    state.projects = state.projects.filter(pr => pr.id !== id);
  },

  SET_PROJECTS_COUNT: (state, payload) => {
    state.projectsCount = payload;
  },

  SET_PROJECTS_OFFSET: (state, payload) => {
    state.projectsOffset = payload;
  },

  SET_EXPIRED_PROJECT_USERS: (state, payload) => {
    state.expiredUsers = payload;
  },

  LOGOUT_USER: state => {
    (state.projects = []),
      (state.projectsCount = 0),
      (state.projectsOffset = 0);
    state.selectedProjectId = null;
    state.openProjects = [];
    state.expiredUsers = [];
  },

  OPEN_ADD_PROJECT: (state, payload) => {
    state.addProject = payload;
  },
};
